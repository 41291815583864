import { AxiosResponse } from "axios";
import { _post } from "./httpClient";

const BASE_URL = "/company/registration";

export interface ApiError {
  code: string;
  message: string;
}

export interface ICompanyData {
  id: string;
  name: string;
  contactName: string;
  contactPosition: string;
  email: string;
  phone: string;
  website?: string;
  address: string;
  taxNumber: string;
  accountOwnerId: string;
}

export interface IVerifyRequest {
  requestId: string;
  otp: string;
}

export interface IRegisterRequest {
  requestId: string;
  name: string;
  crNumber: string;
  website: string;
  email: string;
  phone: string;
  userId?: string;
  address: {
    addressLine1: string;
    addressLine2: string;
    city: string;
    stateOrProvince: string;
    country: string;
    postalCode: string;
    additionalInfo?: string;
  };
  isSaudiCompany: boolean;
  sector: number;
  productOrServiceProvided: string;
  companyRegistrationUri: string;
  authorizedSignatoriesUri: string;
  bankAccountDetailsUri: string;
  logoUri: string;
}
const getToken = () => sessionStorage.getItem("keycloak_token");
const getUserId = () => localStorage.getItem("userId");
// const userId = getUserId();
// const token = getToken();

export interface IRegisterResponse extends ICompanyData {}

export const requestRegistration = async (
  crNumber: string
): Promise<AxiosResponse<string>> => {
  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: `Bearer ${getToken()}`,
    "user-id": getUserId()?.replaceAll('"', ""),
  };

  const payload = `"${crNumber}"`;
  return await _post<string>(`${BASE_URL}/request`, payload, {
    headers,
  });
};

export const verifyRegistration = async (
  data: IVerifyRequest
): Promise<AxiosResponse<any>> => {
  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: `Bearer ${getToken()}`,
    "user-id": getUserId()?.replaceAll('"', ""),
  };

  return await _post<any>(`${BASE_URL}/verify`, data, { headers });
};

export const registerCompany = async (
  data: IRegisterRequest
): Promise<AxiosResponse<IRegisterResponse>> => {
  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
  };

  return await _post<IRegisterResponse>(`${BASE_URL}/register`, data, {
    headers,
  });
};
