// DebitIcon.tsx
import React from "react";

const DebitIcon: React.FC = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
  >
    <path
      d="M17.4284 17.4284L4.57129 4.57129M4.57129 4.57129V14.2141M4.57129 4.57129H14.2141"
      stroke="url(#paint0_linear_3417_24422)"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <defs>
      <linearGradient
        id="paint0_linear_3417_24422"
        x1="4.57129"
        y1="4.57129"
        x2="19.0303"
        y2="6.73789"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#F83D5C" />
        <stop offset="1" stopColor="#FD4B2F" />
      </linearGradient>
    </defs>
  </svg>
);

export default DebitIcon;
