const CreditButton: React.FC<CreditButtonProps> = ({
  children,
  variant,
  onClick,
  className,
}) => {
  const baseClasses =
    "flex flex-col justify-center  font-semibold text-right rounded-full";
  const variantClasses =
    variant === "primary"
      ? "text-white bg-[linear-gradient(129deg,#EF6B2A_0%,#F1BC62_100%)]"
      : "border border-orange-500 bg-zinc-900";

  return (
    <button
      className={`${className} ${baseClasses} ${variantClasses}`}
      onClick={onClick}
    >
      {variant === "secondary" ? (
        <span className="bg-clip-text text-transparent bg-[linear-gradient(129deg,#EF6B2A_0%,#F1BC62_100%)] text-[16px]">
          {children}
        </span>
      ) : (
        children
      )}
    </button>
  );
};

export default CreditButton;
