import React from "react";

const RewindIcon:React.FC<IconProps> = ({height,width}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 36 37"
      fill="none"
    >
      <path
        d="M4.5 18.75C4.5 21.42 5.29176 24.0301 6.77516 26.2502C8.25856 28.4703 10.367 30.2006 12.8338 31.2224C15.3006 32.2442 18.015 32.5115 20.6337 31.9906C23.2525 31.4697 25.6579 30.1839 27.5459 28.2959C29.4339 26.4079 30.7197 24.0025 31.2406 21.3837C31.7615 18.765 31.4942 16.0506 30.4724 13.5838C29.4506 11.117 27.7203 9.00856 25.5002 7.52516C23.2801 6.04176 20.67 5.25 18 5.25C14.2259 5.2642 10.6035 6.73684 7.89 9.36L4.5 12.75"
        stroke="white"
        strokeWidth="2.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.5 5.25V12.75H12"
        stroke="white"
        strokeWidth="2.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18 11.25V18.75L24 21.75"
        stroke="white"
        strokeWidth="2.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default RewindIcon;
