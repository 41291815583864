import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Layout3 from "../lib/components/layout/Layout3";
import AccountDocsUpload from "../lib/components/connect-account/AccountDocsUpload";
import Modal from "../lib/components/layout/Modal";
import { PageRoutes } from "../lib/constants";
import { useTranslation } from "react-i18next";

const ManualCreditScore = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const location = useLocation();

  const [isModalOpen, setIsModalOpen] = useState(
    location.state?.showModal ?? true
  );

  const handleCloseModal = () => {
    //consloe.log("closing modal");
    setIsModalOpen(false);
  };
  // useEffect(() => {
  //   const handlePopState = (event) => {
  //     navigate(PageRoutes.MANUAL_BANK_CONNECT);
  //   };

  //   window.history.pushState(null, "", window.location.href);
  //   window.addEventListener("popstate", handlePopState);

  //   return () => {
  //     window.removeEventListener("popstate", handlePopState);
  //   };
  // }, [navigate]);

  return (
    <Layout3>
      {/* to be uncomment after lean fixed */}
      {/* {isModalOpen && (
        <Modal
          title={t("modal.title")}
          buttonText={t("modal.buttonText")}
          is2Button={true}
          onSubmit={() => navigate(PageRoutes.LEAN)}
          button2Text={t("modal.button2Text")}
          isOpen={isModalOpen}
          onClose={handleCloseModal}
        >
          <div className="text-center">
            <h3 className="text-white">{t("modal.content")}</h3>
          </div>
        </Modal>
      )} */}
      <div className="relative flex flex-col items-center justify-center w-full p-4 min-h-dvh md:w-1/2 md:p-0 z-100">
        <img
          src="./assets/probuy.svg"
          className=""
          alt={t("layout.imageAlt")}
        />
        <AccountDocsUpload />
      </div>
    </Layout3>
  );
};

export default ManualCreditScore;
