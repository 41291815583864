import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import CreditButton from "../Shared/CreditButton";
import Modal from "../layout/Modal";
import DropCoinsIcon from "../Icons/DropCoinsIcon";

interface RequestCreditScoreCardProps {
  className?: string;
  version?: 1 | 2;
  onEdit: () => void;
}

const RequestCreditScoreCard: React.FC<RequestCreditScoreCardProps> = ({
  className,
  version,
  onEdit,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { t, i18n } = useTranslation();

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };
  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleClick = () => {
    onEdit(); // Call onEdit when confirmed
    handleCloseModal(); // Close the modal
  };

  return (
    <div
      dir={i18n.dir()}
      className="  col-span-1 flex flex-col  gap-8 flex-1 self-stretch keep-card-bg bg-[#1a1a1a] bg-top	  rounded-[16px]  overflow-hidden"
    >
      <div className="relative flex items-center justify-center w-full h-full px-6 py-8">
        <DropCoinsIcon
          className={`absolute  bottom-0 ${
            i18n.language === "en" ? "right-[20px]" : "left-[20px]"
          }`}
        />
        <div className="flex flex-col w-full text-white">
          <h1 className="text-[24px]  xl:px-4 font-bold text-nowrap max-sm:text-[18px]">
            {version === 1 ? t("creditScoreUpdate") : t("keepupToDate")}
          </h1>
          <p className=" my-2 xl:px-4 text-[16px]">
            {version === 1
              ? t("beforeRequestingUpdate")
              : t("keepConfirmationMessage")}
          </p>
          <div className="my-2 mt-4  w-60 xl:px-4">
            <CreditButton
              className="px-6 py-3"
              onClick={handleOpenModal}
              variant="secondary"
            >
              {version === 1 ? t("requestCreditUpdate") : t("keepConfirmation")}
            </CreditButton>
            {isModalOpen && (
              <Modal
                titleClassName="!text-white !font-[24px] !font-bold"
                className="!max-w-[500px]"
                title={t("requestConfirmation")}
                buttonText={t("No")}
                is2Button={true}
                button2Text={t("Yes")}
                onButton2Click={handleClick}
                isOpen={isModalOpen}
                onClose={handleCloseModal}
              >
                <div className="flex flex-col items-center justify-center w-full gap-1">
                  <p>{t("confirmationMessage1")}</p>
                  <p className="text-center">{t("confirmationMessage2")}</p>
                </div>
              </Modal>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default RequestCreditScoreCard;
