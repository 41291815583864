import React from "react";

const Modal: React.FC<ModalProps> = ({
  titleClassName = "",
  className = "",
  title,
  children,
  onClose,
  buttonText,
  onSubmit,
  isOpen,
  button2Text,
  onButton2Click,
  is2Button,
}) => {
  if (!isOpen) return null;
  return (
    <>
      <div
        onClick={onClose}
        className="fixed inset-0 bg-black xl:bg-opacity-10 overflow-x-hidden xl:backdrop-blur-md flex items-center justify-center z-[999]"
      >
        <div
          className={`${className} rounded-xl border border-solid bg-white bg-opacity-5 border-white border-opacity-20 text-white text-opacity-50 w-11/12 sm:w-3/4 md:w-1/2 xl:w-1/3 p-8 z-30`}
        >
          <div className="flex items-center justify-center pb-4 mb-4">
            <h2 className={`${titleClassName} text-xl font-semibold`}>
              {title}
            </h2>
          </div>
          <div className="space-y-4">{children}</div>
          <div className="flex flex-row mt-6">
            <button
              onClick={onSubmit ? onSubmit : onClose}
              className={`w-full font-bold mx-1 ${
                is2Button
                  ? "bg-clip-text text-transparent border border-orange-500 bg-gradient-bg"
                  : "bg-gradient-bg border-orange-500 hover:bg-orange-600 text-white"
              } py-2 px-4 rounded-3xl focus:outline-none`}
            >
              {buttonText}
            </button>
            {is2Button && (
              <button
                onClick={onButton2Click}
                className="w-full px-4 py-2 mx-1 text-white border border-orange-500 bg-gradient-bg hover:bg-orange-600 rounded-3xl focus:outline-none"
              >
                {button2Text}
              </button>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Modal;
