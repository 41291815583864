import React, { useEffect, useState } from "react";
import Layout2 from "../lib/components/layout/Layout2";
import Details from "../lib/components/Orders/Details";
import PaymentBill, { IAddress } from "../lib/components/Orders/PaymentBill";
import useApi from "../lib/hooks/useApi";
import { useLocation, useParams } from "react-router-dom";
import { PaymentStatus } from "../lib/constants";
import { useTranslation } from "react-i18next";
import PaymentOtp from "../lib/components/Payments/PaymentOtp";
import DetailsLoading from "../lib/components/Orders/DetailsLoading";

interface Payment {
  id: string;
  paymentTransactionId: string;
  paymentCode: string;
  paymentDate: string;
  amount: number;
  isPaid: boolean;
  dueDate: string;
  invoiceId: string;
  createdAt: string;
}

interface Supplier {
  id: string;
  name: string;
  crNumber: string;
  address?: IAddress;
}

interface OrderDetails {
  id: string;
  createdAt: string;
  orderNumber: string;
  totalAmount: number;
  description: string;
  status: number;
  creditAmount: number;
  installmentsPaid: number;
  pendingAmount: number;
  payments: Payment[];
  supplier: Supplier;
  paymentStatus: PaymentStatus;
  paymentPlanType: number;
}

const OrderDetailsPage: React.FC = () => {
  const { t, i18n } = useTranslation();
  const { id } = useParams<{ id: string }>();
  const { getData: getOrderData, data: orderData } = useApi<OrderDetails>();
  const [orderDetails, setOrderDetails] = useState<OrderDetails | null>(null);
  const [loading, setLoading] = useState(false);
  const [selectedInstallment, setSelectedInstallment] =
    useState<Payment | null>(null);
  const requestId = sessionStorage.getItem("requestId");
  const location = useLocation();
  const [isDepositSuccessful, setDepositSuccessful] = useState<boolean | null>(
    null
  );
  const isMidScreen = window.innerWidth >= 0 && window.innerWidth <= 900;

  const fetchOrderDetails = async () => {
    setLoading(true);
    const { data: response } = await getOrderData(`/order/${id}`);
    if (response) {
      setOrderDetails(response);
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchOrderDetails();
  }, []);

  // useEffect(() => {
  //   const searchParams = new URLSearchParams(location.search);
  //   const status = searchParams.get("status");
  //   const message = searchParams.get("message");
  //   const func = searchParams.get("func");

  //   if (status === "paid" && message === "APPROVED" && func === "Payment") {
  //     setDepositSuccessful(true);
  //   } else {
  //     setDepositSuccessful(false);
  //   }
  // }, [location.search]);

  // Function to handle query parameters and remove them from the URL
  const handleQueryParams = () => {
    const searchParams = new URLSearchParams(location.search);
    const status = searchParams.get("status");
    const message = searchParams.get("message");
    const func = searchParams.get("func");

    // If status is "paid" and message is "APPROVED", set the deposit as successful
    if (status === "paid" && message === "APPROVED" && func === "Payment") {
      setDepositSuccessful(true);
    } else {
      setDepositSuccessful(false);
    }

    // Remove the query params from the URL to prevent them from showing on back and forward
    if (searchParams.toString()) {
      window.history.replaceState(
        null,
        document.title,
        window.location.pathname // Replaces the URL without query params
      );
    }
  };
  useEffect(() => {
    handleQueryParams();
  }, [location.search]);

  const getInstallmentStatus = (payment: Payment) => {
    if (payment.isPaid) return "Paid";
    const today = new Date();
    const dueDate = new Date(payment.dueDate);
    return today > dueDate ? "Over Due" : "Upcoming";
  };

  const handleInstallmentClick = (installment: Payment) => {
    // Find the index of the clicked installment
    const indexofInstallment = sortedInstallments.findIndex(
      (payment) => payment.id === installment.id
    );

    if (selectedInstallment && selectedInstallment.id === installment.id) {
      setSelectedInstallment(null);
    } else {
      setSelectedInstallment(installment);
    }

    // Log the index to the console
    //consloe.log("Selected Installment Index:", indexofInstallment);
  };

  if (!orderDetails) {
    return (
      <Layout2>
        <div className="flex justify-between h-full ">
          <DetailsLoading />
        </div>
      </Layout2>
    );
  }

  const sortedInstallments = orderDetails
    ? [...orderDetails.payments].sort((a, b) => {
        return (
          new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
        );
      })
    : [];

  const count = sortedInstallments.length;

  const getInstallmentLabel = (index: number, lang: string) => {
    const englishLabels = [
      "First",
      "Second",
      "Third",
      "Fourth",
      "Fifth",
      "Sixth",
      "Seventh",
      "Eighth",
      "Ninth",
      "Tenth",
      "Eleventh",
      "Twelfth",
      "Thirteenth",
      "Fourteenth",
      "Fifteenth",
      "Sixteenth",
      "Seventeenth",
      "Eighteenth",
      "Nineteenth",
      "Twentieth",
    ];

    const arabicLabels = [
      "الأول",
      "الثاني",
      "الثالث",
      "الرابع",
      "الخامس",
      "السادس",
      "السابع",
      "الثامن",
      "التاسع",
      "العاشر",
      "الحادي عشر",
      "الثاني عشر",
      "الثالث عشر",
      "الرابع عشر",
      "الخامس عشر",
      "السادس عشر",
      "السابع عشر",
      "الثامن عشر",
      "التاسع عشر",
      "العشرون",
    ];

    const englishFormat =
      lang === "en"
        ? `${englishLabels[index]} ${t("Installment")}`
        : `${t("Installment")} ${arabicLabels[index]}`;

    return englishFormat;
  };
  const indexofInstallment = selectedInstallment
    ? sortedInstallments.findIndex(
        (payment) => payment.id === selectedInstallment.id
      )
    : -1;

  return (
    <Layout2 title={`${t("orderDetails")} - ${orderDetails.orderNumber}`}>
      <div className="flex justify-between h-full ">
        <Details
          indexofInstallment={indexofInstallment}
          paymentPlanType={orderDetails.paymentPlanType}
          orderNumber={orderDetails.orderNumber}
          orderDate={new Date(orderDetails.createdAt).toLocaleDateString()}
          orderId={orderDetails.id}
          pendingAmount={orderDetails.pendingAmount}
          orderValue={orderDetails.totalAmount}
          installmentsCount={count}
          paymentValue={orderDetails.pendingAmount.toLocaleString()}
          paymentStatus={orderDetails.paymentStatus}
          orderStatus={orderDetails.status}
          creditAmount={orderDetails.creditAmount}
          installments={sortedInstallments.map((payment, index) => ({
            id: index + 1,
            label: getInstallmentLabel(index, i18n.language),
            date: payment.dueDate,
            status: payment.isPaid ? PaymentStatus.Paid : PaymentStatus.Unpaid,
            amount: payment.amount,
            paymentData: payment,
          }))}
          onInstallmentClick={handleInstallmentClick}
        />
        {selectedInstallment && (
          <PaymentBill
            className={`${
              isMidScreen ? "hidden" : ""
            } max-sm:hidden !w-[400px] bg-[#171717]`}
            indexofInstallment={indexofInstallment}
            orderDetails={{
              supplierName: orderDetails.supplier?.name || "",
              crNumber: orderDetails.supplier?.crNumber || "",
              address: orderDetails.supplier?.address,
              description: orderDetails.description,
              orderNumber: orderDetails.orderNumber,
              createdAt: new Date(orderDetails.createdAt).toLocaleDateString(),
              totalAmount: orderDetails.totalAmount,
              installmentsPaid: orderDetails.installmentsPaid,
              paymentPlanType: orderDetails.paymentPlanType,
              payments: [
                {
                  ...selectedInstallment,
                  paymentValue: selectedInstallment.amount,
                },
              ],
            }}
            installmentStatus={getInstallmentStatus(selectedInstallment)}
          />
        )}
        {isDepositSuccessful && (
          <PaymentOtp requestId={requestId} isOpen={true} />
        )}
      </div>
    </Layout2>
  );
};

export default OrderDetailsPage;
