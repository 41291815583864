import React from "react";

interface RadioButtonOption {
  label: string;
  value: string | boolean | number;
}

interface RadioButtonGroupProps {
  label: string;
  mandStar?: boolean;
  name: string;
  options: RadioButtonOption[];
  selectedValue: string | boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const RadioButtonGroup: React.FC<RadioButtonGroupProps> = ({
  mandStar = false,
  label,
  name,
  options,
  selectedValue,
  onChange,
}) => {
  return (
    <fieldset className="flex flex-col gap-2">
      <span className="flex gap-1">
        <legend className="font-normal leading-6 text-white text-opacity-60">
          {label}
        </legend>
        {mandStar && (
          <span className="text-[#EF6B2A] font-[800] leading-6 text-[14px]">
            *
          </span>
        )}
      </span>
      <div className={`flex gap-4`}>
        {options.map((option) => (
          <label key={option.value.toString()} className="flex-1 gap-1">
            <input
              type="radio"
              name={name}
              value={option.value.toString()}
              checked={
                typeof option.value === "boolean"
                  ? selectedValue === option.value
                  : selectedValue === option.value.toString()
              }
              onChange={onChange}
              className="sr-only"
            />
            <div
              className={`flex flex-row justify-start py-4 px-8 items-center bg-white bg-opacity-[0.07] rounded-[16px] ${
                selectedValue === option.value
                  ? "border-[#EF6B2A] border border-solid"
                  : ""
              }`}
            >
              <span
                className={`flex flex-col justify-center items-center p-1 my-auto ${
                  selectedValue === option.value
                    ? "border border-[#EF6B2A]"
                    : "border border-gray-300"
                } border-solid h-[15px] rounded-[50px] w-[15px]`}
              >
                <span
                  className={`shrink-0 rounded-full ${
                    selectedValue === option.value
                      ? "bg-[linear-gradient(129deg,#EF6B2A_0%,#F1BC62_100%)]"
                      : "bg-transparent"
                  }  h-[9px] w-[9px]`}
                ></span>
              </span>
              <span className="h-full mx-1 text-sm leading-5 text-white">
                {option.label}
              </span>
            </div>
          </label>
        ))}
      </div>
    </fieldset>
  );
};

export default RadioButtonGroup;
