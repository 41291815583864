import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import * as Yup from "yup";
import Layout2 from "../lib/components/layout/Layout2";
import { Link } from "react-router-dom";
import ExitArrow from "../lib/components/Icons/ExitArrow";
import ExitArrow2 from "../lib/components/Icons/ExitArrow2";
import ProfileIcon from "../lib/components/Icons/ProfileIcon";
import { PageRoutes } from "../lib/constants";
import Loadingwithoutlogo from "./loadingwithoutlogo";
import useApi from "../lib/hooks/useApi";
import { isValidSaudiNumber } from "../lib/components/Schemas/CompanyDetailsSchema";
import PhoneInput from "react-phone-input-2";
import parsePhoneNumber from "libphonenumber-js";
import Toast, { IToast } from "../lib/components/Shared/Toast";
import UserIcon from "../lib/components/Icons/UserIcon";
import { useUserContext } from "../lib/context/UserContext";

interface IUser {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
  companyId: string;
  // phoneNumber: string;
  profileImageUrl: string;
}

const ProfilePage: React.FC = () => {
  const { t, i18n } = useTranslation();
  // const [userData, setUserData] = useState<IUser | null>(null);
  // const [userImage, setUserImage] = useState<File | null>(null);
  // const [loading, setLoading] = useState<boolean>(true);
  // const [error, setError] = useState<string | null>(null);
  // const [isValidPhone, setIsValidPhone] = useState(false);
  // const [toast, setToast] = useState<IToast | null>(null);
  // const { getData, putData } = useApi<IUser>();
  const { userData, loading, updateUserData } = useUserContext();

  // const fetchUserData = async (userId: string) => {
  //   setLoading(true);
  //   try {
  //     const { status, data } = await getData(`/user/${userId}`);
  //     if (status !== 200) {
  //       throw new Error("Failed to fetch user data");
  //     }
  //     const companyId = localStorage.getItem("companyId");
  //     if (!data.companyId && companyId)
  //       data.companyId = companyId.replaceAll('"', "");
  //     setUserData(data);
  //   } catch (err) {
  //     setToast({
  //       type: "error",
  //       titleKey: t("error"),
  //       messageKey: "something went wrong",
  //     });
  //     setError(err.message);
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  // const updateUserData = async (userData) => {
  //   setLoading(true);
  //   try {
  //     const { status, data } = await putData(`/user`, userData);
  //     if (status !== 200) {
  //       setToast({
  //         type: "error",
  //         titleKey: t("error"),
  //         messageKey: "something went wrong",
  //       });
  //       throw new Error("Failed to update user data");
  //     }
  //     setToast({
  //       type: "success",
  //       titleKey: t("updatedSuccess"),
  //       messageKey: t("dataUpdatedSuccessfully"),
  //     });
  //     setUserData(data);
  //   } catch (err) {
  //     setToast({
  //       type: "error",
  //       titleKey: t("error"),
  //       messageKey: t("somethingWentWrong"),
  //     });
  //     setError(err.message);
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  // useEffect(() => {
  //   const userId = localStorage.getItem("userId");

  //   if (userId) {
  //     fetchUserData(userId.replaceAll('"', ""));
  //   }
  // }, []);

  // const handleAvatarUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   const file = event.currentTarget.files?.[0];
  //   if (file) {
  //     setUserImage(file);
  //   }
  // };
  const isValidSaudiNumber = (phone: string) => {
    // Remove any non-digit characters
    const cleanPhone = phone.replace(/\D/g, "");

    // Check if the number starts with 966 (country code) and has a total length of 12 digits
    return cleanPhone.startsWith("966") && cleanPhone.length === 12;
  };

  const formik = useFormik<IUser>({
    initialValues: {
      id: "",
      email: "",
      firstName: "",
      lastName: "",
      companyId: "",
      // phoneNumber: "",
      profileImageUrl: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email("validationErrors.invalidEmail")
        .required("validationErrors.emailRequired"),
      firstName: Yup.string(),
      lastName: Yup.string(),
      // phoneNumber: Yup.string().test(
      //   "is-valid-saudi-number",
      //   "invalidphonenumber",
      //   function (value) {
      //     if (!value) return true;
      //     return isValidSaudiNumber(value);
      //   }
      // ),
    }),
    onSubmit: async (values) => {
      //consloe.log("Formik Values:", values); // Log values here
      // const formData = new FormData();
      // Object.entries(values).forEach(([key, value]) => {
      //   formData.append(key, value);
      // });
      // if (userImage) {
      //   formData.append("profileImage", userImage);
      // }
      await updateUserData(values);
    },
  });

  useEffect(() => {
    if (userData) {
      //consloe.log("this is userData:>", userData);
      formik.setValues(userData);
    }
  }, [userData]);

  const isFormChanged = () => {
    if (!userData) return false;
    return Object.keys(formik.values).some(
      (key) =>
        formik.values[key as keyof IUser] !== userData[key as keyof IUser]
    );
  };

  if (loading)
    return (
      <Layout2>
        <Loadingwithoutlogo />
      </Layout2>
    );
  // Uncomment if you want to show error message
  // if (error) return <div className="text-red-500">{error}</div>;

  return (
    <Layout2>
      <div className="flex items-center justify-center w-full h-full max-xl:flex-col max-xl:items-start max-xl:justify-start max-sm:gap-10 max-md:max-w-[500px] mx-auto">
        <Link className="xl:hidden" to={PageRoutes.DASHBOARD}>
          <h1 className="flex items-center mt-4 mr-2 text-xl text-white xl:text-2xl text-opacity-85">
            {i18n.language === "ar" ? <ExitArrow /> : <ExitArrow2 />}
            {t("userProfile")}
          </h1>
        </Link>
        <div className="flex justify-center w-full p-2 xl:w-1/2 xl:mx-auto ">
          <div className="w-full h-full bg-white rounded-xl bg-opacity-10">
            <div className="relative flex flex-col items-center p-4">
              {/* <label
                htmlFor="avatarInput"
                className="mx-4 text-white cursor-pointer"
              >
                {userImage ? (
                  <img
                    src={URL.createObjectURL(userImage)}
                    className="w-20 h-20 rounded-full"
                    alt="User Avatar"
                  />
                ) : userData?.profileImageUrl ? (
                  <img
                    src={userData.profileImageUrl}
                    className="w-20 h-20 rounded-full"
                    alt="User Avatar"
                  />
                ) : ( */}
              <div className="bg-[#2f2f2f] rounded-full p-[15px] flex justify-center items-center">
                <UserIcon width={86} height={90} />
              </div>
              {/* // )} */}
              {/* </label>
              <input
                id="avatarInput"
                type="file"
                accept="image/*"
                className="hidden"
                onChange={handleAvatarUpload}
              /> */}
            </div>
            <form className="w-full p-4" onSubmit={formik.handleSubmit}>
              <div>
                <label
                  className="text-slate-300 text-opacity-60"
                  htmlFor="firstName"
                >
                  {t("firstName")}
                </label>
                <input
                  type="text"
                  name="firstName"
                  value={formik.values.firstName}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  className="block w-full px-4 py-3 my-4 text-white bg-[#ffffff12] rounded-[12px] border border-gray-500 placeholder:text-white focus:outline-none"
                  // required
                />
                {formik.touched.firstName && formik.errors.firstName ? (
                  <span className="mt-1 text-sm text-red-500">
                    {formik.errors.firstName}
                  </span>
                ) : null}
              </div>

              <div>
                <label
                  className="text-slate-300 text-opacity-60"
                  htmlFor="lastName"
                >
                  {t("lastName")}
                </label>
                <input
                  type="text"
                  name="lastName"
                  value={formik.values.lastName}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  className="block w-full px-4 py-3 my-4 text-white bg-[#ffffff12] rounded-[12px] border border-gray-500 placeholder:text-white focus:outline-none"
                  // required
                />
                {formik.touched.lastName && formik.errors.lastName ? (
                  <span className="mt-1 text-sm text-red-500">
                    {formik.errors.lastName}
                  </span>
                ) : null}
              </div>

              {/* <div>
                <label
                  className="text-slate-300 text-opacity-60"
                  htmlFor="phoneNumber"
                >
                  {t("phoneNumber")}
                </label>
                <PhoneInput
                  country={"sa"}
                  value={formik.values.phoneNumber}
                  placeholder={t("EnterPhoneNumber")}
                  onChange={(phone, country) => {
                    const limitedPhone = phone.slice(0, 12);
                    formik.setFieldValue("phoneNumber", limitedPhone);
                    formik.setFieldTouched("phoneNumber", true, false);
                    setIsValidPhone(isValidSaudiNumber(limitedPhone));
                  }}
                  onlyCountries={["sa"]}
                  isValid={(value) => {
                    if (!value) return false;
                    try {
                      const phoneNumber = parsePhoneNumber(value, "SA");
                      return phoneNumber.isValid();
                    } catch (error) {
                      console.error("Error parsing phone number:", error);
                      return false;
                    }
                  }}
                  inputProps={{
                    name: "phoneNumber",
                    id: "phoneNumber",
                    // required: true,
                    maxLength: 16,
                  }}
                  containerClass="w-full my-4"
                  inputStyle={{
                    width: "100%",
                    backgroundColor: "#ffffff12",
                    color: "#fff",
                    borderRadius: "12px",
                    border:
                      formik.touched.phoneNumber && formik.errors.phoneNumber
                        ? "1px solid red"
                        : "1px solid #6b7280",
                    height: "auto",
                    paddingTop: "0.75rem",
                    paddingBottom: "0.75rem",
                    paddingLeft: "1rem",
                    paddingRight: "1rem",
                    textAlign: i18n.language === "ar" ? "right" : "left",
                  }}
                  buttonStyle={{ display: "none" }}
                  disableSearchIcon={true}
                />
                {formik.touched.phoneNumber && formik.errors.phoneNumber && (
                  <div className="-mt-3 text-sm text-red-500">
                    {t(`${formik.errors.phoneNumber}`)}
                  </div>
                )}
              </div> */}

              <div>
                <label
                  className="text-slate-300 text-opacity-60"
                  htmlFor="email"
                >
                  {t("email")}
                </label>
                <input
                  type="email"
                  name="email"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  className="block w-full px-4 py-3 my-4 text-white bg-[#ffffff12] rounded-[12px] border border-gray-500 placeholder:text-white focus:outline-none"
                  required
                />
                {formik.touched.email && formik.errors.email ? (
                  <span className="mt-1 text-sm text-red-500">
                    {t(formik.errors.email)}
                  </span>
                ) : null}
              </div>

              <button
                type="submit"
                disabled={!isFormChanged()}
                className={`w-full px-4 py-3 mt-6 text-white bg-gradient-to-r from-orange-500 to-orange-300 ${
                  !isFormChanged() ? "opacity-50" : ""
                } rounded-3xl focus:outline-none`}
              >
                {t("saveChanges")}
              </button>
            </form>
          </div>
        </div>
      </div>
    </Layout2>
  );
};

export default ProfilePage;
