const LoadingIcon = ({ size = "30px", color = "#EF6B2A" }) => {
  const spinnerStyle = {
    width: size,
    height: size,
    borderTopColor: color,
    borderWidth: "3px",
  };

  return <div className="spinner" style={spinnerStyle}></div>;
};

export default LoadingIcon;
