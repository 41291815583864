import React, { useEffect, useState } from "react";
import { useNavigate, Link, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Card from "../lib/components/cards/Card";
import RewindIcon from "../lib/components/Icons/RewindIcon";
import HandIcon from "../lib/components/Icons/HandIcon";
import FullyPayedIcon from "../lib/components/Icons/FullyPayedIcon";
import ParPayedIcon from "../lib/components/Icons/ParPayedIcon";
import OrderCard from "../lib/components/cards/OrderCard";
import Layout2 from "../lib/components/layout/Layout2";
import NoData from "../lib/components/Shared/NoData";
import DashboardCard from "../lib/components/cards/DashboardCard";
import SmallDashboardCard from "../lib/components/cards/SmallDashboardCard";
import Button from "../lib/components/Buttons/Button";
import RejectedCreditCard from "../lib/components/cards/RejectedCreditCard";
import useApi from "../lib/hooks/useApi";
import useLocalStorage from "../lib/hooks/useLocalStorage";
import { orderFlowDomain, OrderStatus, PageRoutes } from "../lib/constants";
import WalletCard from "../lib/components/Payments/WalletCard";
import SucessProcess from "../lib/components/Payments/SucessProcess";
import LoadingProbuy from "../lib/components/LoadingProbuy";
import OrderCardLoading from "../lib/components/cards/OrderCardLoading";
import DashboardGlobalCard from "../lib/components/Dashboard/DashboardGlobalCard";
import DashboardGlobalCardLoading from "../lib/components/Dashboard/DashboardGlobalCardLoading";
import { useDispatch } from "react-redux";
import { setCompanyStatus } from "../redux/slices/companySlice";

const Homepage = () => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const { getData: getDashboardData, data: dashboardData } =
    useApi<DashboardDto>();
  const { getData } = useApi<any>();
  const { getValue } = useLocalStorage<string>();
  const dispatch = useDispatch();
  const [loadingCompany, setLoadingCompany] = useState<boolean>(true);
  const [loadingDashboard, setLoadingDashboard] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [companyData, setCompanyData] = useState<any>(null);
  const location = useLocation();
  const [isPaymentSuccessful, setIsPaymentSuccessful] = useState<
    boolean | null
  >(null);
  const [isLargeScreen, setIsLargeScreen] = useState<boolean>(
    window.innerWidth > 767
  );

  const fetchCompanyData = async () => {
    setLoadingCompany(true);
    try {
      const userId = getValue("userId");
      if (!userId) throw new Error("User ID not found in localStorage");

      const { data } = await getData(`/company/byuser/${userId}`);
      if (!data) throw new Error("Failed to fetch company data");

      setCompanyData(data);
      dispatch(setCompanyStatus(data.status));
    } catch (error) {
      setError(error.message);
      console.error("Error fetching company data:", error);
    } finally {
      setLoadingCompany(false);
    }
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const status = searchParams.get("status");
    const message = searchParams.get("message");
    const func = searchParams.get("func");

    if (status === "paid" && message === "APPROVED" && func !== "Renew") {
      setIsPaymentSuccessful(true);
    } else {
      setIsPaymentSuccessful(false);
    }
  }, [location.search]);

  const fetchDashboardData = async () => {
    setLoadingDashboard(true);
    const companyId = getValue("companyId");
    try {
      await getDashboardData(`/dashboard/${companyId}`);
    } catch (error) {
      console.error("Error fetching dashboard data:", error);
    } finally {
      setLoadingDashboard(false);
    }
  };

  useEffect(() => {
    fetchCompanyData();
    fetchDashboardData();
  }, []);

  useEffect(() => {
    const handleResize = () => setIsLargeScreen(window.innerWidth > 767);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const companyStatus = companyData?.status;
  const companyName = companyData?.name;

  // Debugging: Log the dashboard data
  //consloe.log("Dashboard Data:", dashboardData);

  // Debugging: Check if creditRecord exists and log it
  if (dashboardData) {
    //consloe.log("Credit Record:", dashboardData.creditRecord);
  }
  const handleOrderClick = (order: OrderDto, e: React.MouseEvent) => {
    e.preventDefault();

    if (order.status === OrderStatus.Pending) {
      window.open(`${orderFlowDomain}/order/${order.id}`, "_blank");
    } else {
      navigate(`${PageRoutes.ORDER_DETAILS.replace(":id", order.id)}`);
    }
  };

  return (
    <Layout2 title={isLargeScreen && t("latestPayments")}>
      <div
        dir={i18n.dir()}
        className="flex flex-col h-full gap-6 p-6 overflow-auto overflow-x-hidden scrollbar-hide"
      >
        <h1 className="text-xl font-bold text-white md:text-2xl text-opacity-85">
          {t("dashboard")}
        </h1>
        <div className="flex items-start self-stretch gap-4 max-lg:flex-col max-xl:flex-col max-2xl:flex-col">
          <div className={`flex w-full self-stretch`}>
            <DashboardGlobalCard
              isLargeScreen={isLargeScreen}
              companyStatus={companyStatus}
              companyName={companyName}
              dashboardData={dashboardData}
              loadingDashboardData={loadingDashboard}
              loadingCompanyData={loadingCompany}
            />
          </div>
          <div className="flex flex-col items-start self-stretch flex-1 gap-2 ">
            <div className="flex items-start self-stretch flex-1 gap-2">
              <Card
                bgColor="bg-[#1F1F1F]"
                number={dashboardData?.orderCounts?.totalOrders ?? 0}
                title={t("totalOrders")}
                icon={<HandIcon color="#fff" height={44} width={44} />}
                className="flex-1 h-full"
                loading={loadingDashboard}
              />
              <Card
                bgColor="bg-[#DD1631]"
                number={dashboardData?.orderCounts?.overdueOrders ?? 0}
                title={t("orderdue")}
                icon={<RewindIcon height={44} width={44} />}
                className="flex-1 h-full"
                loading={loadingDashboard}
              />
            </div>
            <div className="flex items-start self-stretch flex-1 gap-2">
              <Card
                bgColor="bg-[#008E45]"
                number={dashboardData?.orderCounts?.paidOrders ?? 0}
                title={t("fullyPaid")}
                icon={<FullyPayedIcon height={44} width={44} />}
                className="flex-1 h-full"
                loading={loadingDashboard}
              />
              <Card
                bgColor="bg-[#DB9236]"
                number={dashboardData?.orderCounts?.partiallyPaidOrders ?? 0}
                title={t("partiallyPaid")}
                icon={<ParPayedIcon height={44} width={44} />}
                className="flex-1 h-full"
                loading={loadingDashboard}
              />
            </div>
            {/* <div className="w-full">
              <WalletCard isDisapled={companyStatus === 3} />
            </div> */}
          </div>
        </div>
        <div className="flex flex-col gap-4 ">
          <div className="flex justify-between gap-4 max-sm:items-center ">
            <h2 className="text-[18px] text-white 2xl:text-2xl text-opacity-60 text-nowrap">
              {t("currentPayables")}
            </h2>
            {companyStatus === 2 ? (
              <Button
                className="bg-gradient-to-r from-[#EF6B2A] to-[#F1BC62] py-2"
                label={t("viewAllOrders")}
                labelClassName="text-white"
                onClick={() => navigate(PageRoutes.ORDERS)}
              />
            ) : null}
          </div>
          <div className="flex flex-col justify-center h-full gap-2">
            {loadingDashboard ? (
              Array.from({ length: 4 }).map((_, index) => (
                <OrderCardLoading key={index} />
              ))
            ) : dashboardData?.orders?.data?.length ? (
              dashboardData.orders.data.map((order) => (
                <div
                  key={order.id}
                  onClick={(e) => handleOrderClick(order, e)}
                  className="cursor-pointer"
                >
                  {order.status === OrderStatus.Pending ? (
                    <OrderCard {...order} />
                  ) : (
                    <Link
                      to={`${PageRoutes.ORDER_DETAILS.replace(
                        ":id",
                        order.id
                      )}`}
                    >
                      <OrderCard {...order} />
                    </Link>
                  )}
                </div>
              ))
            ) : (
              <div className="flex h-[300px] justify-center items-end">
                <NoData />
              </div>
            )}
          </div>
        </div>
        {isPaymentSuccessful && (
          <SucessProcess isOpen={true} version="Deposit" />
        )}
      </div>
    </Layout2>
  );
};

export default Homepage;
