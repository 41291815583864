import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { OrderFilter } from "../../constants";

interface OrderStateProps {
  items: OrderFilter[];
  onSelect: (filter: OrderFilter) => void;
}

const OrderFilterComp: React.FC<OrderStateProps> = ({ items, onSelect }) => {
  const { t } = useTranslation();

  const [selected, setSelected] = useState<number>(0); // Initialize with 0 to match the "All" filter by default

  const handleClick = (index: number, item: OrderFilter) => {
    setSelected(index);
    onSelect(item);
  };

  return (
    <div className="flex gap-2 sm:flex-row md:flex-row lg:flex-row text-nowrap  overflow-x-auto hide-scrollbar">
      {items.map((item, index) => (
        <button
          style={{ border: "1px solid rgba(255, 255, 255, 0.16)" }}
          key={index}
          onClick={() => handleClick(index, item)}
          className={`cursor-pointer py-2 px-4 rounded-[12px] ${
            selected === index
              ? "bg-white text-[#191919]"
              : "bg-[#191919] text-white"
          }`}
        >
          {t(`${OrderFilter[item].toLowerCase()}`)}
        </button>
      ))}
    </div>
  );
};

export default OrderFilterComp;
