import React, { useEffect } from "react";
import { keycloak } from "../keycloak";
import Loadingwithoutlogo from "./loadingwithoutlogo";
import { useLocation, useNavigate } from "react-router-dom";
import { PageRoutes } from "../lib/constants";

const LogOut: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();

  // const currentUrl = `${window.location.origin}${location.pathname}${location.search}${location.hash}`;

  const clearCookies = () => {
    // Get all cookies and remove them
    document.cookie.split(";").forEach((cookie) => {
      const name = cookie.split("=")[0].trim();
      document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
    });
  };

  const handleLogout = async () => {
    localStorage.clear();
    sessionStorage.clear();
    clearCookies();
    await keycloak.logout({
      redirectUri: window.location.origin,
    });
  };

  useEffect(() => {
    const logoutIfInitialized = async () => {
      if (keycloak && keycloak.authenticated) {
        await handleLogout();
      } else {
        console.error("Keycloak is not authenticated");
        localStorage.clear();
        sessionStorage.clear();
        clearCookies();
        navigate(PageRoutes.SIGN_IN);
      }
    };
    logoutIfInitialized();
    // Pa$$w0rd!
  }, [keycloak]);
  return <Loadingwithoutlogo />;
};

export default LogOut;
