import React from "react";
import LoadingIcon from "../Icons/LoadingIcon";

interface ButtonProps {
  type?: "submit" | "reset" | "button";
  label?: string;
  icon?: React.ReactNode;
  iconPosition?: "left" | "right" | "only";
  onClick?: (event?: React.MouseEvent<HTMLButtonElement>) => void;
  className?: string;
  labelClassName?: string;
  disabled?: boolean;
  loading?: boolean;
}

const Button: React.FC<ButtonProps> = ({
  type = "button",
  label,
  icon,
  iconPosition = "left",
  onClick,
  className = "",
  labelClassName = "",
  disabled = false,
  loading = false,
}) => {
  return (
    <button
      type={type}
      onClick={(e) => {
        e.preventDefault();
        if (disabled) return;
        onClick?.(e);
      }}
      className={`${className} ${
        disabled === true ? "btnDisapled" : ""
      } flex md:min-w-[150px]  justify-center items-center gap-[6px] rounded-[24px] max-sm:w-full  `}
    >
      {icon && iconPosition === "left" && icon}
      {label && (
        <span
          className={` text-right font-almarai text-[16px] font-bold leading-[24px] ${labelClassName}`}
        >
          {label}
        </span>
      )}
      {icon && iconPosition === "right" && icon}
      {loading && (
        <div role="status">
          <LoadingIcon color="#fff" size="20px" />
        </div>
      )}
    </button>
  );
};

export default Button;
