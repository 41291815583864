import React from "react";
import InfoItem from "../../info/InfoItem";
import InfoSection from "../../info/InfoSection";
import BigCard from "../../cards/BalanceCard";
import RequestCreditScoreCard from "../../cards/RequestCreditScoreCard";
import { useTranslation } from "react-i18next";
import {
  OrganizationType,
  SalesChannelType,
  BusinessPremisesType,
} from "../../../constants";
import DocComponent from "../DocComponent";
import BasicDetailsIcon from "../../Icons/BasicDetailsIcon";
import BusnissInformationIcon from "../../Icons/BusnissInformationIcon";
import WhiteDocumentIcon from "../../Icons/WhiteDocumentIcon";
import Spinner from "../../Shared/Spinner";
import RejectedCreditCard from "../../cards/RejectedCreditCard";
import sectorList, { CompanySector } from "../../../types/sectorList";
import convertToHttps from "../../../utils/convertHttp";
import CompanyDetailsLoading from "./CompanyDetailsLoading";
import parsePhoneNumber from "libphonenumber-js";
import { formatNumber } from "../../../utils/formatNumber";

const CompanyDetailsScreen = ({
  companyData,
  onEdit,
}: {
  companyData: any;
  onEdit: () => void;
}) => {
  const { t } = useTranslation();

  if (!companyData) {
    return <CompanyDetailsLoading />;
  }

  const documentItems = [
    {
      title: t("documentsTitle.zakatAndTaxCertificate"),
      uri: companyData.zakatAndTaxCertificateUri,
    },
    {
      title: t("documentsTitle.auditedFinancialStatements"),
      uri: companyData.auditedFinancialStatementsUri,
    },
    {
      title: t("documentsTitle.rentContract"),
      uri: companyData.rentContractUri,
    },
  ].filter((doc) => doc.uri);

  const companyStatus = companyData?.status;
  const companyName = companyData?.name;

  const getTranslatedSector = (sectorValue: CompanySector, t: Function) => {
    const sector = sectorList.find((s) => s.value === sectorValue);
    return sector ? t(sector.labelKey) : sectorValue;
  };

  const formatPhoneNumber = (phone: string) => {
    try {
      const phoneNumber = parsePhoneNumber(phone, "SA");
      return phoneNumber ? phoneNumber.formatInternational() : phone;
    } catch (error) {
      console.error("Error formatting phone number:", error);
      return phone;
    }
  };

  return (
    <div className="flex flex-col gap-6">
      <div className="flex items-center justify-between sm:flex-row sm:items-center ">
        <h1 className="text-white text-nowrap opacity-[0.87] text-right text-[24px] max-sm:text-[20px] font-bold leading-[40px] ">
          {t("companyProfile")}
        </h1>
        <button
          type="button"
          className="flex items-center text-nowrap justify-center gap-[6px] bg-gradient-to-b from-[#EF6B2A] to-[#F1BC62] text-white font-bold text-[16px] leading-[24px] py-2 px-6 rounded-full hover:bg-orange-700"
          onClick={onEdit}
        >
          {t("editDetails")}
        </button>
      </div>

      <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 ">
        {companyStatus !== 2 || companyStatus === undefined ? (
          <RejectedCreditCard
            companyName={companyName}
            className="col-span-2"
          />
        ) : (
          <>
            <BigCard companyName={companyName} />
            <RequestCreditScoreCard version={2} onEdit={onEdit} />
          </>
        )}
      </div>

      <InfoSection icon={<BasicDetailsIcon />} title={t("basicDetails")}>
        <div className="flex flex-col w-full gap-4">
          <div className="grid w-full grid-cols-1 md:grid-cols-3">
            <div className="col-span-1">
              <InfoItem
                label={t("companyName")}
                value={companyData?.name ?? ""}
              />
            </div>
            <div className="col-span-1">
              <InfoItem
                label={t("crNumber")}
                value={companyData?.crNumber ?? ""}
              />
            </div>
            <div className="col-span-1">
              <InfoItem
                label={t("companyWebsite")}
                value={companyData?.website ?? ""}
              />
            </div>
          </div>
          <div className="grid w-full grid-cols-1 md:grid-cols-3">
            <div className="col-span-1">
              <InfoItem
                label={t("organizationType")}
                value={t(OrganizationType[companyData?.type] ?? "")}
              />
            </div>
            <div className="col-span-1">
              <InfoItem
                label={t("emailAddress")}
                value={companyData?.email ?? ""}
              />
            </div>
            <div className="col-span-1">
              <InfoItem
                label={t("phoneNumber")}
                value={formatPhoneNumber(companyData?.phone ?? "")}
              />
            </div>
          </div>
          <div className="grid w-full grid-cols-1 md:grid-cols-3">
          <div className="col-span-1">
              <InfoItem
                label={t("buildingNumber")}
                value={companyData?.addressLine2 ?? ""}
              />
            </div>
            <div className="col-span-1">
              <InfoItem
                label={t("streetName")}
                value={companyData?.addressLine1 ?? ""}
              />
            </div>
            
            
          </div>
          <div className="grid w-full grid-cols-1 md:grid-cols-3">
            <div className="col-span-1">
              <InfoItem label={t("cityName")} value={companyData?.city ?? ""} />
            </div>
            <div className="col-span-1">
              <InfoItem
                label={t("zipCode")}
                value={companyData?.postalCode ?? ""}
              />
            </div>
            <div className="col-span-1">
              <InfoItem
                label={t("districtName")}
                value={companyData?.stateOrProvince ?? ""}
              />
            </div>
          </div>
          <div className="grid w-full grid-cols-1 md:grid-cols-3">
            <div className="col-span-2">
              <InfoItem
                label={t("additionalInfo")}
                value={companyData?.additionalInfo ?? ""}
              />
            </div>
          </div>
        </div>
      </InfoSection>

      <InfoSection
        icon={<BusnissInformationIcon />}
        title={t("businessInformation")}
      >
        <div className="flex flex-col gap-4 xl:flex-row">
          <div className="grid w-full grid-cols-1 md:grid-cols-3">
            <div className="col-span-1">
              <InfoItem
                label={t("yearsInBusiness")}
                value={`${companyData?.yearsInBusiness} ${t("years")} & ${
                  companyData?.monthsInBusiness
                } ${t("months")}`}
              />
            </div>
            <div className="col-span-1">
              <InfoItem
                label={t("isSaudiCompany")}
                value={companyData?.isSaudiCompany ? t("Yes") : t("No")}
              />
            </div>
            <div className="col-span-1">
              <InfoItem
                label={t("sectorword")}
                value={getTranslatedSector(companyData?.sector, t)}
              />
            </div>
          </div>
        </div>
        <div className="flex flex-col gap-4 xl:flex-row">
          <div className="grid w-full grid-cols-1 md:grid-cols-3">
            <div className="col-span-1">
              <InfoItem
                label={t("productServiceProvided")}
                value={companyData?.productOrServiceProvided ?? ""}
              />
            </div>
            <div className="col-span-1">
              <InfoItem
                label={t("headcount")}
                value={
                  companyData?.headcount
                    ? formatNumber(companyData?.headcount)
                    : ""
                }
              />
            </div>
            <div className="col-span-1">
              <InfoItem
                label={t("salesChannelType")}
                value={t(SalesChannelType[companyData?.salesChannel] ?? "")}
              />
            </div>
          </div>
        </div>
        <div className="grid w-full grid-cols-1 md:grid-cols-3">
          <div className="col-span-1">
            <InfoItem
              label={t("businessPremisesType")}
              value={t(
                BusinessPremisesType[companyData?.businessPremises] ?? ""
              )}
            />
          </div>
          <div className="col-span-1">
            <InfoItem
              label={t("monthlyRent")}
              value={
                companyData?.monthlyRent
                  ? formatNumber(companyData?.monthlyRent)
                  : ""
              }
            />
          </div>
          <div className="col-span-1">
            <InfoItem
              label={t("annualRevenue")}
              value={
                companyData?.annualRevenue
                  ? formatNumber(companyData?.annualRevenue, true)
                  : ""
              }
            />
          </div>
        </div>
        <div className="grid w-full grid-cols-1 md:grid-cols-3">
          <div className="col-span-1">
            <InfoItem
              label={t("isAudited")}
              value={companyData?.isAudited ? t("yes") : t("no")}
            />
          </div>
          <div className="col-span-1">
            <InfoItem
              label={t("clientCount")}
              value={
                companyData?.clientCount
                  ? formatNumber(companyData?.clientCount)
                  : ""
              }
            />
          </div>
        </div>
      </InfoSection>

      <InfoSection icon={<WhiteDocumentIcon />} title={t("CompanyDocuments")}>
        <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
          {documentItems.map((doc) => (
            <DocComponent
              className="col-span-1"
              key={doc.title}
              title={doc.title}
              // date={new Date().toLocaleDateString()}
              uri={convertToHttps(doc.uri)}
            />
          ))}
        </div>
      </InfoSection>
    </div>
  );
};

export default CompanyDetailsScreen;
