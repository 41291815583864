interface UseLocalStorageState<T> {
  setValue: (key: string, value: T) => void;
  getValue: (key: string) => T | null;
  clearValue: (key: string) => void;
  clearAll: VoidFunction;
}

const useLocalStorage = <T,>(): UseLocalStorageState<T> => {
  const setValue = (key: string, value: T) => {
    localStorage.setItem(key, JSON.stringify(value));
  };

  const getValue = (key: string): T | null => {
    try {
      const value = localStorage.getItem(key);
      if (!value) {
        console.warn(`No value found in localStorage for key: ${key}`);
        return null;
      }

      try {
        const parsedValue = JSON.parse(value) as T;
        return parsedValue;
      } catch (parseError) {
        console.error(
          `Error parsing localStorage value for key ${key}:`,
          parseError
        );
        return value as unknown as T; // Return the raw value as a fallback
      }
    } catch (error) {
      console.error(`Error accessing localStorage for key ${key}:`, error);
      return null;
    }
  };

  const clearValue = (key: string) => {
    localStorage.removeItem(key);
  };

  const clearAll = () => {
    localStorage.clear();
  };

  return {
    getValue,
    setValue,
    clearValue,
    clearAll,
  };
};

export default useLocalStorage;
