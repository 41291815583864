import React from "react";

const AddFileIcon:React.FC<IconProps>= ({className}) => {
  return (
  <div className={className}>
      <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 25 24"
      fill="none"
    >
      <path
        d="M4.5 22H18.5C19.0304 22 19.5391 21.7893 19.9142 21.4142C20.2893 21.0391 20.5 20.5304 20.5 20V7L15.5 2H6.5C5.96957 2 5.46086 2.21071 5.08579 2.58579C4.71071 2.96086 4.5 3.46957 4.5 4V8"
        stroke="url(#paint0_linear_712_1884)"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.5 2V6C14.5 6.53043 14.7107 7.03914 15.0858 7.41421C15.4609 7.78929 15.9696 8 16.5 8H20.5"
        stroke="url(#paint1_linear_712_1884)"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.5 15H9.5"
        stroke="url(#paint2_linear_712_1884)"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.5 12V18"
        stroke="url(#paint3_linear_712_1884)"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_712_1884"
          x1="4.5"
          y1="2"
          x2="24.8685"
          y2="15.3196"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EF6B2A" />
          <stop offset="1" stopColor="#F1BC62" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_712_1884"
          x1="14.5"
          y1="2"
          x2="21.0368"
          y2="7.34328"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EF6B2A" />
          <stop offset="1" stopColor="#F1BC62" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_712_1884"
          x1="3.5"
          y1="15"
          x2="3.93524"
          y2="17.1346"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EF6B2A" />
          <stop offset="1" stopColor="#F1BC62" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_712_1884"
          x1="6.5"
          y1="12"
          x2="8.2843"
          y2="12.2431"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EF6B2A" />
          <stop offset="1" stopColor="#F1BC62" />
        </linearGradient>
      </defs>
    </svg>
  </div>
  );
};

export default AddFileIcon;
