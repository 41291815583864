import React, { useEffect, useState, useCallback } from "react";
import {
  PageRoutes,
  OrderFilter,
  PaymentStatus,
  PaymentPlanType,
  orderFlowDomain,
} from "../lib/constants";
import { Link, useNavigate } from "react-router-dom";
import useLocalStorage from "../lib/hooks/useLocalStorage";
import { useTranslation } from "react-i18next";
import useApi from "../lib/hooks/useApi";
import OrderCard from "../lib/components/cards/OrderCard";
import Layout2 from "../lib/components/layout/Layout2";
import OrdersCountCards from "../lib/components/Orders/OrdersCountCards";
import OrderFilterComp from "../lib/components/Orders/OrderFilter";
import OrderSearchInput from "../lib/components/Orders/OrderSearchInput";
import Button from "../lib/components/Buttons/Button";
import Spinner from "../lib/components/Shared/Spinner";
import Calender from "../lib/components/Orders/Calender";
import { OrderStatus } from "../lib/constants";
import Pagination from "../lib/components/Shared/Pagination";
import NoData from "../lib/components/Shared/NoData";
import OrderCardLoading from "../lib/components/cards/OrderCardLoading";

interface CompanyOrdersResponse {
  companyId: string;
  filterRequest: FilterRequest;
  orders: OrdersData;
  countByStatus: CountByStatus;
}

interface OrderDto {
  id: string;
  createdAt: string;
  lastModifiedAt: string | null;
  orderNumber: string;
  description: string;
  status: OrderStatus;
  paymentPlanType: PaymentPlanType;
  supplierName: string;
  totalAmount: number;
  isPaid: boolean;
  installments: number;
  installmentsPaid: number;
  paymentStatus: PaymentStatus;
  paymentDate: string | null;
  dueDate: string | null;
}

interface FilterRequest {
  pageSize: number;
  pageIndex: number;
  selectedFilter: number;
  companyId: string;
  startTime: string | null;
  endTime: string | null;
  searchKeyword: string;
}

interface OrdersData {
  pageIndex: number;
  pageSize: number;
  count: number;
  pageCount: number;
  data: OrderDto[];
}

interface CountByStatus {
  totalOrders: number;
  pendingOrders: number;
  acceptedOrders: number;
  partiallyPaid: number;
  paid: number;
  overdue: number;
  archived: number;
}

const filters: OrderFilter[] = [
  OrderFilter.All,
  OrderFilter.Pending,
  // OrderFilter.Accepted,
  OrderFilter.PartiallyPaid,
  OrderFilter.FullyPaid,
  OrderFilter.OverDue,
];

const OrdersPage: React.FC = () => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const { getValue: getIsAuthenticated } = useLocalStorage<boolean>();
  const { data, postData } = useApi<CompanyOrdersResponse>();
  const { getValue: getCompanyId } = useLocalStorage<string>();
  const [searchValue, setSearchValue] = useState<string>("");
  const [fromDate, setFromDate] = useState<Date | null>(null);
  const [toDate, setToDate] = useState<Date | null>(null);
  const [selectedFilter, setSelectedFilter] = useState<OrderFilter>(
    OrderFilter.All
  );
  const [loading, setLoading] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState<number>(1);

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
  };

  const fetchOrders = useCallback(
    async (
      filter: OrderFilter,
      page: number,
      startDate?: Date | null,
      endDate?: Date | null
    ) => {
      const companyId = getCompanyId("companyId");

      if (!companyId) {
        console.error("Company ID is not available");
        return;
      }

      setLoading(true);
      const requestData: FilterRequest = {
        pageSize: 10,
        pageIndex: page - 1,
        selectedFilter: filter,
        companyId,
        startTime: startDate ? startDate.toISOString() : null,
        endTime: endDate ? endDate.toISOString() : null,
        searchKeyword: searchValue,
      };

      try {
        const url = `/orders/company/${companyId}`;
        await postData(url, requestData);
      } catch (error) {
        console.error("Failed to fetch orders:", error);
      } finally {
        setTimeout(() => setLoading(false), 2000);
      }
    },
    [searchValue, postData, getCompanyId]
  );

  // const handleSearchClick = useCallback(async () => {
  //   await fetchOrders(selectedFilter, currentPage);
  // }, [searchValue]);

  const handleFilterClick = useCallback(async (filter: OrderFilter) => {
    setSelectedFilter(filter);
    setCurrentPage(1);
    await fetchOrders(filter, 1, fromDate, toDate);
  }, []);

  const handleDateFilterClick = useCallback(async () => {
    await fetchOrders(selectedFilter, 1, fromDate, toDate);
  }, [fetchOrders, selectedFilter, fromDate, toDate]);

  const handleDateFilterRemove = useCallback(async () => {
    setFromDate(null);
    setToDate(null);
    await fetchOrders(selectedFilter, 1, null, null);
  }, [fetchOrders, selectedFilter]);

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
    fetchOrders(selectedFilter, page, fromDate, toDate);
  };

  const handleGoToArchive = () => {
    navigate(PageRoutes.ARCHIVE);
  };

  useEffect(() => {
    fetchOrders(selectedFilter, currentPage);
  }, [selectedFilter]);

  useEffect(() => {
    const debounceSearch = setTimeout(() => {
      fetchOrders(selectedFilter, currentPage, fromDate, toDate);
    }, 300); // Adjust the debounce time as needed

    return () => clearTimeout(debounceSearch);
  }, [searchValue]);

  const handleOrderClick = (order: OrderDto, e: React.MouseEvent) => {
    e.preventDefault();

    if (order.status === OrderStatus.Pending) {
      window.open(`${orderFlowDomain}/order/${order.id}`, "_blank");
    } else {
      navigate(`${PageRoutes.ORDER_DETAILS.replace(":id", order.id)}`);
    }
  };

  return (
    <Layout2 title={t("currentPayables")}>
      <div className="flex flex-col h-full gap-4 p-6 overflow-auto scrollbar-hide">
        <h1 className="text-2xl font-bold text-white text-opacity-85">
          {t("orders")}
        </h1>
        <div dir={i18n.dir()} className="flex flex-col gap-6">
          <OrdersCountCards
            countByStatus={data?.countByStatus ?? null}
            // loading={true}
            loading={!data || !data.countByStatus}
          />
          <div className="flex flex-col gap-6">
            <div
              dir={i18n.dir()}
              className="grid items-center grid-cols-1 gap-4 md:grid-cols-2 md:gap-6 "
            >
              <div className="flex items-center justify-between max-sm:flex-col max-sm:gap-2 max-sm:items-start">
                <h2 className="text-xl text-white text-opacity-60 text-nowrap">
                  {t("orderList")}
                </h2>
                <Button
                  onClick={handleGoToArchive}
                  label={t("archived")}
                  className="md:hidden  flex px-6 py-2 justify-center items-center gap-[6px] rounded-[24px] border border-solid border-[#EF6B2A] text-transparent bg-clip-text bg-gradient-to-r from-[#EF6B2A] to-[#F1BC62]"
                />
              </div>
              <div className="flex items-center justify-end gap-4 max-sm:justify-start lg:gap-2">
                <Button
                  onClick={handleGoToArchive}
                  label={t("archived")}
                  className="max-sm:hidden flex px-6 py-2 justify-center items-center gap-[6px] rounded-[24px] border border-solid border-[#EF6B2A] text-transparent bg-clip-text bg-gradient-to-r from-[#EF6B2A] to-[#F1BC62]"
                />
                <OrderSearchInput
                  placeholder="clientnameorOrdernumber"
                  searchValue={searchValue}
                  onSearchChange={handleSearchChange}
                  // onSearchClick={handleSearchClick}
                />
                <Calender
                  label={t("alltimes")}
                  fromDate={fromDate}
                  toDate={toDate}
                  onFromDateChange={setFromDate}
                  onToDateChange={setToDate}
                  onFilterClick={handleDateFilterClick}
                  onFilteRemove={handleDateFilterRemove}
                />
              </div>
            </div>
            <div className="flex">
              <div className="flex w-full pb-6">
                <OrderFilterComp items={filters} onSelect={handleFilterClick} />
              </div>
            </div>
            <div className="flex flex-col gap-2 scrollbar-track-gray-100 scrollbar-thumb-gray-500 hover:scrollbar-thumb-gray-600">
              {loading ? (
                <div className="flex flex-col gap-2">
                  {Array.from({ length: 4 }).map((_, index) => (
                    <OrderCardLoading key={index} />
                  ))}
                </div>
              ) : //
              data && data.orders.data.length ? (
                <>
                  {data.orders.data.map((order) => (
                    <div
                      key={order.id}
                      onClick={(e) => handleOrderClick(order, e)}
                      className="cursor-pointer"
                    >
                      {order.status === OrderStatus.Pending ? (
                        <OrderCard {...order} />
                      ) : (
                        <Link
                          to={`${PageRoutes.ORDER_DETAILS.replace(
                            ":id",
                            order.id
                          )}`}
                        >
                          <OrderCard {...order} />
                        </Link>
                      )}
                    </div>
                  ))}
                  <Pagination
                    currentPage={data.orders.pageIndex + 1}
                    totalPages={data.orders.pageCount}
                    onPageChange={handlePageChange}
                  />
                </>
              ) : (
                <NoData />
              )}
            </div>
          </div>
        </div>
      </div>
    </Layout2>
  );
};

export default OrdersPage;
