import Keycloak, { KeycloakConfig, KeycloakInitOptions } from "keycloak-js";
import { clearUrlParams } from "./lib/utils/clearUrlParams";
// import { keycloakConfigs } from "./lib/constants/api-constants";
//consloe.log("this is env var", { ...process.env });
export const keycloakConfig: KeycloakConfig = {
  url: process.env.REACT_APP_KEYCLOAK_URL,
  realm: process.env.REACT_APP_KEYCLOAK_REALM,
  clientId: process.env.REACT_APP_KEYCLOAK_CLIENT_ID,
};

const keycloak: Keycloak = new Keycloak(keycloakConfig);

let initPromise: Promise<boolean> | null = null;

export const initKeycloak = (): Promise<boolean> => {
  if (!initPromise) {
    const initOptions: KeycloakInitOptions = {
      onLoad: "login-required",
      checkLoginIframe: false,
      pkceMethod: "S256",
      silentCheckSsoRedirectUri:
        window.location.origin + "/silent-check-sso.html",
      flow: "implicit",
    };

    initPromise = keycloak
      .init(initOptions)
      .then((authenticated) => {
        //consloe.log("Keycloak initialized", authenticated);
        if (authenticated) {
          clearUrlParams(); // Clear URL parameters after successful login
          setInterval(() => {
            keycloak
              .updateToken(60)
              .then((refreshed) => {
                // consloe.log(
                //   "Token refreshed",
                //   keycloak,
                //   "and this is refreshed:>",
                //   refreshed
                // );
                if (refreshed) {
                  // consloe.log(
                  //   "Token refreshed",
                  //   keycloak,
                  //   "and this is refreshed:>",
                  //   refreshed
                  // );
                  sessionStorage.setItem("keycloak_token", keycloak.token);
                } else {
                  // consloe.log(
                  //   "Token not refreshed, valid for " +
                  //     Math.round(
                  //       (keycloak.tokenParsed?.exp as number) +
                  //         keycloak.timeSkew -
                  //         new Date().getTime() / 1000
                  //     ) +
                  //     " seconds"
                  // );
                }
              })
              .catch((err) => {
                console.error("Failed to refresh token", err);
              });
          }, 60000);
        }
        return authenticated;
      })
      .catch((error) => {
        console.error("Failed to initialize Keycloak", error);
        throw error;
      });
  }
  return initPromise;
};

export { keycloak };
