import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { API_BASE_URL } from "../constants/api-constants";

const BASE_URL = API_BASE_URL;
const getToken = () => sessionStorage.getItem("keycloak_token");
const getUserId = () => localStorage.getItem("userId");

const apiClient = axios.create({
  baseURL: BASE_URL,
  headers: {
    "Content-Type": "application/json",
    Authorization: `Bearer ${getToken()}`,
    "user-id": getUserId()?.replaceAll('"', ""),
  },
});

const _get = <T>(
  url: string,
  config?: AxiosRequestConfig
): Promise<AxiosResponse<T>> => {
  return apiClient.get<T>(url, config);
};

const _delete = <T>(
  url: string,
  config?: AxiosRequestConfig
): Promise<AxiosResponse<T>> => {
  return apiClient.delete<T>(url, config);
};

const _put = <T>(
  url: string,
  data: any = {},
  config?: AxiosRequestConfig
): Promise<AxiosResponse<T>> => {
  return apiClient.put<T>(url, data, config);
};

const _post = <T>(
  url: string,
  data: any = {},
  config?: AxiosRequestConfig
): Promise<AxiosResponse<T>> => {
  return apiClient.post<T>(url, data, config);
};

// Export API methods
export { _get, _delete, _put, _post, apiClient };
