import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Button from "../Buttons/Button";
import InCorrectIcon from "../Icons/InCorrectIcon";
import useApi from "../../hooks/useApi";
import useLocalStorage from "../../hooks/useLocalStorage";
import { PageRoutes } from "../../constants";
import IssueIcon from "../Icons/IssueIcon";

interface CreditData {
  id: string;
  createdAt: string;
  lastModifiedAt: string;
  companyId: string;
  creditLimit: number;
  availableBalance: number;
  outstandingBalance: number;
  dueFromCredit: number;
  dueFromInstallments: number;
}

interface CreditCardProps {
  className?: string;
  companyName?: string;
  creditData?: CreditData;
  onTryAnotherBank?: () => void;
  onProceedManually?: () => void;
}

const RejectedCreditCard: React.FC<CreditCardProps> = ({
  className,
  companyName,
  creditData,
  onTryAnotherBank,
  onProceedManually,
}) => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  // const { getValue: getCompanyId } = useLocalStorage();
  // const companyId = getCompanyId("companyId");
  // const { getData } = useApi<CreditData>();

  // const [creditData, setCreditData] = useState<CreditData | undefined>(
  //   undefined
  // );
  // const [loading, setLoading] = useState(false);
  // const [error, setError] = useState<string | undefined>(undefined);

  // const fetchCreditData = async () => {
  //   if (companyId) {
  //     setLoading(true);
  //     const response = await getData(`/credit/${companyId}`);
  //     if (response.status === 200) {
  //       setCreditData(response.data);
  //       setError(undefined);
  //     } else {
  //       setError(response.error);
  //       setCreditData(undefined);
  //     }
  //     setLoading(false);
  //   }
  // };

  // useEffect(() => {
  //   fetchCreditData();
  // }, []);

  const handleTryAnotherBank = () => {
    navigate(PageRoutes.LEAN);
  };

  const handleProceedManually = () => {
    navigate(PageRoutes.MANUAL_BANK_CONNECT, { state: { showModal: false } });
  };

  const formatAmount = (amount: number | undefined) => {
    return amount !== undefined ? amount.toFixed(2) : "0.00";
  };

  return (
    <div
      dir={i18n.dir()}
      className={`${className} w-full self-stretch items-start rounded-xl flex flex-col dash-card-bg py-8 px-6`}
    >
      <div className="flex flex-col md:flex-row pb-4 items-start md:items-center gap-4 self-stretch border-b border-solid border-[#000] border-opacity-20">
        <div className="flex h-[60px] flex-col justify-center items-start gap-2 flex-1">
          <p className="text-white font-bold text-[20px] md:text-[24px]">
            {companyName}
          </p>
          <div className="flex flex-wrap items-end justify-center gap-1">
            <div className="flex flex-row items-center justify-between w-20 h-6 text-white bg-black md:h-7 md:w-24 rounded-3xl">
              <InCorrectIcon />
              <p className="text-nowrap mx-auto text-[8px] md:text-[10px]">
                {t("installment")}
              </p>
            </div>
            <div className="mx-[0.5px]" />
            <div className="flex flex-row items-center justify-between w-24 h-6 text-white bg-black md:h-7 md:w-28 rounded-3xl">
              <InCorrectIcon />
              <p className="text-nowrap mx-auto text-[8px] md:text-[10px]">
                {t("payment_postponement")}
              </p>
            </div>
          </div>
        </div>
        <div className="flex flex-row items-start flex-1 w-full gap-4 md:flex-row md:items-center">
          <div className="flex items-start self-center justify-center">
            <IssueIcon width={32} height={32} />
          </div>
          <p className="max-w-[286px] flex text-[16px] text-white font-bold leading-[22px]">
            {t("rejected_message")}
          </p>
          {/* <div className="flex flex-col items-start flex-1 gap-1">
            <p className="text-[14px] text-white md:text-[16px] font-normal">
              {t("CreditLimit")}
            </p>
            <div className="flex items-baseline gap-1">
              <p className="text-[28px] md:text-[32px] text-white font-extrabold leading-8">
                {formatAmount(creditData?.creditLimit)}
              </p>
              <p className="text-white text-[14px] font-normal leading-8">
                {t("SAR")}
              </p>
            </div>
          </div> */}
          {/* <div className="flex flex-col items-start flex-1 gap-1 text-white">
            <p className="text-[14px] md:text-[16px] font-normal">
              {t("AvailableBalance")}
            </p>
            <div className="flex items-baseline gap-1 text-white">
              <p className="text-[28px] md:text-[32px] font-extrabold leading-8">
                {formatAmount(creditData?.availableBalance)}
              </p>
              <p className="text-white text-[14px] font-normal leading-8">
                {t("SAR")}
              </p>
            </div>
          </div> */}
        </div>
      </div>
      <div className="flex flex-col items-start self-stretch gap-4 mt-4 md:flex-row">
        <div className="flex pr-0 md:pr-4 flex-col items-start gap-2 flex-1 border-r md:border-r-[#000] md:border-opacity-20 max-md:border-none">
          <p className="text-[#000] text-[20px] md:text-[24px] font-bold leading-6">
            {t("AutomaticProcess")}
          </p>
          <p className="text-white text-[14px] md:text-[16px] font-normal leading-[20px] md:leading-[22px]">
            {t("AutomaticProcessDesc")}
          </p>
          {/* to be uncommented after lean fixed  */}
          {/* <Button
            label={t("TryAnotherBank")}
            className="py-[6px] px-[16px] bg-gradient-to-r from-[#EF6B2A] to-[#F1BC62]"
            labelClassName="text-white text-[12px] md:text-[14px] font-normal"
            onClick={onTryAnotherBank || handleTryAnotherBank}
          /> */}
        </div>
        <div className="flex flex-col items-start gap-2 flex-1 max-md:pt-4 max-md:border-t max-md:border-t-[#000] max-md:border-opacity-20">
          <p className="text-[#000] text-[20px] md:text-[24px] font-bold leading-6">
            {t("ManualProcess")}
          </p>
          <p className="text-[14px] md:text-[16px] font-normal leading-[20px] md:leading-[22px] text-white">
            {t("ManualProcessDesc")}
          </p>
          <Button
            label={t("ProceedManually")}
            className="flex py-[6px] px-[16px] justify-center items-center gap-[6px] bg-[#1A1A1A] border border-solid border-[#EF6B2A]"
            labelClassName=" text-[12px] md:text-[14px] font-normal bg-gradient-to-r from-[#EF6B2A] to-[#F1BC62] gradient-text"
            onClick={onProceedManually || handleProceedManually}
          />
        </div>
      </div>
    </div>
  );
};

export default RejectedCreditCard;
