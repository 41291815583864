import React from "react";

const InfoSection: React.FC<any> = ({ icon, title, children }) => (
  <section className="flex gap-[18px] flex-col p-6  rounded-2xl bg-white bg-opacity-5 max-md:px-5 max-md:max-w-full">
    <header className="flex gap-2 text-lg font-bold leading-6 text-white max-md:flex-wrap">
      <i>{icon}</i>
      <h2 className="flex-1 max-md:max-w-full">{title}</h2>
    </header>
    {children}
  </section>
);

export default InfoSection;
