import { combineReducers } from "redux";
import cssReducer from "../slices/cssSlice";
import loadingReducer from "../slices/loadingSlice";
import subscriptionReducer from "../slices/subscriptionSlice";
import companyReducer from "../slices/companySlice";
const rootReducer = combineReducers({
  css: cssReducer,
  loading: loadingReducer,
  subscription: subscriptionReducer,
  company: companyReducer,
});

export type RootState = ReturnType<typeof rootReducer>;
export default rootReducer;
