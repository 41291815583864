import React, { useEffect, useState, useCallback } from "react";
import useApi from "../lib/hooks/useApi";
import useLocalStorage from "../lib/hooks/useLocalStorage";
import { useTranslation } from "react-i18next";
import Layout2 from "../lib/components/layout/Layout2";
import TransactionCard from "../lib/Transactions/TransactionCard";
import WalletCard from "../lib/components/Payments/WalletCard";
import { useLocation } from "react-router-dom";
import SucessProcess from "../lib/components/Payments/SucessProcess";
import Pagination from "../lib/components/Shared/Pagination";
import TransactionCardLoading from "../lib/Transactions/TransactionCardLoading";
import { useSelector } from "react-redux";
import { selectCompanyStatus } from "../redux/slices/companySlice";
import { RootState } from "../redux/store/rootReducer";
import Calender from "../lib/components/Orders/Calender";

enum TransactionTypeFilter {
  All = 0,
  Deposit = 1,
  Withdraw = 2,
}

interface PaymentDto {
  id: string;
  createdAt: string;
  lastModifiedAt?: string;
  amount: number;
  walletId: string;
  type: number;
  jsonPaymentData: string;
}

interface FilterParams {
  selectedFilter: TransactionTypeFilter;
  pageIndex: number;
  pageSize: number;
  fromDate: Date | null;
  toDate: Date | null;
}

const TransactionsPage: React.FC = () => {
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const [displayedData, setDisplayedData] = useState(null);
  const [responseData, setResponseData] = useState(null);
  const { getValue: getCompanyId } = useLocalStorage<string>();
  const { postData: postPaymentData } = useApi<PaginatedItems<PaymentDto>>();
  const { getData: getWallet } = useApi<Wallet>();
  const companyStatus = useSelector((state: RootState) =>
    selectCompanyStatus(state)
  );

  const [loading, setLoading] = useState<boolean>(true);
  const [isPaymentSuccessful, setIsPaymentSuccessful] = useState<
    boolean | null
  >(null);
  const [isLargeScreen, setIsLargeScreen] = useState<boolean>(
    window.innerWidth > 1024
  );

  const [filters, setFilters] = useState<FilterParams>({
    selectedFilter: TransactionTypeFilter.All,
    pageIndex: 0,
    pageSize: 10,
    fromDate: null,
    toDate: null,
  });

  const [isInitialLoad, setIsInitialLoad] = useState(true);

  const companyId = getCompanyId("companyId");

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const status = searchParams.get("status");
    const message = searchParams.get("message");

    setIsPaymentSuccessful(status === "paid" && message === "APPROVED");
  }, [location.search]);

  const fetchPaymentData = useCallback(
    async (params: FilterParams) => {
      setLoading(true);
      //consloe.log("Fetching with filters:", params);

      if (companyId) {
        const queryParams = new URLSearchParams({
          PageSize: String(params.pageSize),
          PageIndex: String(params.pageIndex),
        });

        const url = `/transactions/${companyId}?${queryParams.toString()}`;
        const body: any = {
          companyId,
          type: params.selectedFilter,
          ...(params.fromDate && { startDate: params.fromDate.toISOString() }),
          ...(params.toDate && { endDate: params.toDate.toISOString() }),
        };

        try {
          const { data: response } = await postPaymentData(url, body);
          //consloe.log("Response data:", response);
          setResponseData(response);
          const parsedData = response?.data.map((payment) => {
            try {
              return {
                ...payment,
                jsonPaymentData: JSON.parse(payment.jsonPaymentData),
              };
            } catch (error) {
              console.error("Failed to parse jsonPaymentData", error);
              return payment;
            }
          });
          setDisplayedData(parsedData);
          await getWallet(`/wallet/${companyId}`);
        } catch (error) {
          console.error("Error fetching payment data:", error);
        } finally {
          setLoading(false);
          setIsInitialLoad(false);
        }
      } else {
        setLoading(false);
        setIsInitialLoad(false);
      }
    },
    [companyId, postPaymentData, getWallet]
  );

  const handleFilterChange = useCallback((filter: TransactionTypeFilter) => {
    setFilters((prev) => ({
      ...prev,
      selectedFilter: filter,
      pageIndex: 0,
    }));
  }, []);

  const handleDateFilterClick = useCallback(() => {
    fetchPaymentData(filters);
  }, [fetchPaymentData, filters]);

  const handleDateFilterRemove = useCallback(() => {
    const newFilters = {
      ...filters,
      fromDate: null,
      toDate: null,
      pageIndex: 0,
    };
    setFilters(newFilters);
    fetchPaymentData(newFilters);
  }, [filters, fetchPaymentData]);

  useEffect(() => {
    if (isInitialLoad) {
      fetchPaymentData(filters);
    }
  }, [filters]);

  useEffect(() => {
    if (!isInitialLoad) {
      fetchPaymentData(filters);
    }
  }, [filters.selectedFilter, filters.pageIndex, filters.pageSize]);

  const totalPages = Math.ceil((responseData?.count || 0) / filters.pageSize);

  const handlePageChange = (page: number) => {
    setFilters((prev) => ({ ...prev, pageIndex: page - 1 }));
  };

  return (
    <Layout2 title={isLargeScreen && t("paymentSummary")}>
      <div
        dir={i18n.dir()}
        className="flex flex-col h-full gap-6 p-4 overflow-auto scrollbar-hide"
      >
        {/* <WalletCard isDisapled={companyStatus === 3} /> */}

        <div className="flex flex-row items-center justify-between px-4">
          <h1
            className={`text-xl md:text-2xl text-white text-opacity-85 ${
              i18n.language === "ar" ? "text-right" : "text-left"
            } text-nowrap pr-2`}
          >
            {t("payment_operations")}
          </h1>
          <div className="flex flex-row gap-4">
            <Calender
              label={t("alltimes")}
              fromDate={filters.fromDate}
              toDate={filters.toDate}
              onFromDateChange={(date) =>
                setFilters((prev) => ({ ...prev, fromDate: date }))
              }
              onToDateChange={(date) =>
                setFilters((prev) => ({ ...prev, toDate: date }))
              }
              onFilterClick={handleDateFilterClick}
              onFilteRemove={handleDateFilterRemove}
            />
          </div>
        </div>

        <div className="flex flex-row justify-start w-full gap-3">
          <button
            onClick={() => handleFilterChange(TransactionTypeFilter.All)}
            className={`flex py-[10px] px-4 justify-center items-center gap-2 flex-1 rounded-[12px] border border-white border-opacity-[0.16] w-full ${
              filters.selectedFilter === TransactionTypeFilter.All
                ? "bg-[#fff] text-black text-14px font-bold leading-6"
                : "bg-[#fff] bg-opacity-[0.07] text-white text-14px font-bold leading-6"
            }`}
          >
            {t("all")}
          </button>

          <button
            onClick={() => handleFilterChange(TransactionTypeFilter.Withdraw)}
            className={`flex py-[10px] px-4 justify-center items-center gap-2 flex-1 rounded-[12px] border border-white border-opacity-[0.16] w-full ${
              filters.selectedFilter === TransactionTypeFilter.Withdraw
                ? "bg-[#fff] text-black text-14px font-bold leading-6"
                : "bg-[#fff] bg-opacity-[0.07] text-white text-14px font-bold leading-6"
            }`}
          >
            {t("debits")}
          </button>

          <button
            onClick={() => handleFilterChange(TransactionTypeFilter.Deposit)}
            className={`flex py-[10px] px-4 justify-center items-center gap-2 flex-1 rounded-[12px] border border-white border-opacity-[0.16] w-full ${
              filters.selectedFilter === TransactionTypeFilter.Deposit
                ? "bg-[#fff] text-black text-14px font-bold leading-6"
                : "bg-[#fff] bg-opacity-[0.07] text-white text-14px font-bold leading-6"
            }`}
          >
            {t("credits")}
          </button>
        </div>

        <div className="flex flex-col gap-2">
          {loading || !displayedData
            ? Array.from({ length: 3 }).map((_, index) => (
                <TransactionCardLoading key={index} />
              ))
            : displayedData?.map((payment) => {
                const { jsonPaymentData } = payment;
                const amount = payment.amount;
                return (
                  <TransactionCard
                    key={payment.id}
                    InstallmentNumber={jsonPaymentData.InstallmentNumber || ""}
                    id={payment.id}
                    type={payment.type}
                    amount={amount}
                    date={new Date(payment.createdAt)}
                    visaNumber={jsonPaymentData.source?.number || ""}
                    currency={jsonPaymentData.currency || "SAR"}
                    orderNumber={jsonPaymentData.OrderNumber}
                  />
                );
              })}
        </div>
        {displayedData && !loading && (
          <Pagination
            currentPage={responseData.pageIndex + 1} // Convert 0-based index to 1-based
            totalPages={totalPages}
            onPageChange={handlePageChange}
          />
        )}
        {isPaymentSuccessful && (
          <SucessProcess isOpen={true} version="Deposit" />
        )}
      </div>
    </Layout2>
  );
};

export default TransactionsPage;
