// src/store/companySlice.ts
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface CompanyState {
  companyStatus: number | null;
}

const initialState: CompanyState = {
  companyStatus: null,
};

const companySlice = createSlice({
  name: "company",
  initialState,
  reducers: {
    setCompanyStatus(state, action: PayloadAction<number | null>) {
      state.companyStatus = action.payload;
    },
    clearCompanyStatus(state) {
      state.companyStatus = null;
    },
  },
});

export const { setCompanyStatus, clearCompanyStatus } = companySlice.actions;

export const selectCompanyStatus = (state: { company: CompanyState }) =>
  state.company.companyStatus;

export default companySlice.reducer;
