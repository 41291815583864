// import i18n from "i18next";
// import { initReactI18next } from "react-i18next";
// import Backend from "i18next-http-backend";
// import ar from "./lib/locale/ar/translation.json";
// import en from "./lib/locale/en/translation.json";

// i18n
//   .use(initReactI18next)
//   .use(Backend)
//   .init({
//     debug: true,
//     fallbackLng: "en",
//     returnObjects: true,
//     resources: {
//       en: {
//         translation: en,
//       },
//       ar: {
//         translation: ar,
//       },
//     },
//     interpolation: {
//       escapeValue: false,
//     },
//   });

// export default i18n;
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";
import ar from "./lib/locale/ar/translation.json";
import en from "./lib/locale/en/translation.json";

// Function to get the stored language or return a default
const getStoredLanguage = () => {
  const storedLang = localStorage.getItem("language");
  return storedLang || "en";
};

// Function to set the lang and dir attributes in the HTML element
const setHtmlAttributes = (lang: string) => {
  const htmlElement = document.documentElement;

  // Set the lang attribute to the selected language
  htmlElement.lang = lang;

  // Set the dir attribute based on the language (rtl for Arabic, ltr for others)
  if (lang === "ar") {
    htmlElement.dir = "rtl";
  } else {
    htmlElement.dir = "ltr";
  }
};

i18n.use(initReactI18next).init({
  debug: false,
  fallbackLng: "en",
  lng: getStoredLanguage(),
  returnObjects: true,
  resources: {
    en: {
      translation: en,
    },
    ar: {
      translation: ar,
    },
  },
  interpolation: {
    escapeValue: false,
  },
  // Set the lang and dir on initial load
  react: {
    useSuspense: false, // optional, set if you're not using Suspense
  },
});

// Set the language and direction based on the initial value
setHtmlAttributes(i18n.language);

// Listen for language changes and update HTML attributes accordingly
i18n.on("languageChanged", (lng) => {
  setHtmlAttributes(lng);
});

// Function to change the language and store the preference
export const changeLanguage = (lang: string) => {
  i18n.changeLanguage(lang);
  localStorage.setItem("language", lang);
};

export default i18n;
