// context/payment-context.tsx
import { createContext, ReactNode } from "react";
import usePayment from "../hooks/usePayment";
import { PaymentState } from "../types/types";

const PaymentContext = createContext<PaymentState>({
  payment: {
    id: "",
    amount: 0,
    date: "",
    status: "",
    createdAt: "",
    orderId: "",
    isPaid: false,
    dueDate: "",
  },
  setPayment: () => {},
});

const PaymentContextProvider = ({ children }: { children: ReactNode }) => {
  const paymentContextValue = usePayment();

  return (
    <PaymentContext.Provider value={paymentContextValue}>
      {children}
    </PaymentContext.Provider>
  );
};

export { PaymentContext, PaymentContextProvider };
