import React from "react";
import PrevIcon from "../Icons/PrevIcon";
import NextIcon from "../Icons/NextIcon";
import { useTranslation } from "react-i18next";
interface PaginationProps {
  currentPage: number;
  totalPages: number;
  onPageChange: (page: number) => void;
}

const Pagination: React.FC<PaginationProps> = ({
  currentPage,
  totalPages,
  onPageChange,
}) => {
  const { i18n } = useTranslation();
  const handlePageChange = (page: number) => {
    if (page > 0 && page <= totalPages) {
      onPageChange(page);
    }
  };

  const hoverStyle = {
    background: "linear-gradient(129deg, #ef6b2a 0%, #f1bc62 100%)",
  };

  const pageButtons = [];

  const addPageButton = (page: number) => {
    pageButtons.push(
      <button
        key={page}
        style={{
          transition: "all 0.3s ease-in-out",
          borderColor:
            page === currentPage ? "#EF6B2A" : "rgba(255, 255, 255, 0.16)",
        }}
        className="flex w-[38px] h-[38px] py-[10px] justify-center items-center gap-2 text-white rounded-[12px] border-solid border"
        onClick={() => handlePageChange(page)}
        onMouseEnter={(e) =>
          (e.currentTarget.style.background = hoverStyle.background)
        }
        onMouseLeave={(e) =>
          (e.currentTarget.style.background = "rgba(255, 255, 255, 0.07)")
        }
      >
        {page}
      </button>
    );
  };

  if (totalPages <= 6) {
    // If there are 6 or fewer pages, show all buttons
    for (let i = 1; i <= totalPages; i++) {
      addPageButton(i);
    }
  } else {
    // If there are more than 6 pages, show the appropriate buttons
    if (currentPage > 4) {
      addPageButton(1);
      if (currentPage > 5) {
        pageButtons.push(
          <button
            style={{ transition: "all 0.3s ease-in-out" }}
            className="flex w-[38px] h-[38px] py-[10px] justify-center text-white items-center gap-2 rounded-[12px] border-solid border border-[#fff] border-opacity-[0.16] bg-[#fff] bg-opacity-[0.07]"
          >
            ...
          </button>
        );
      }
    }

    // Show pages around the current page, but limit to 5 pages in total
    let startPage = Math.max(currentPage - 2, 1);
    let endPage = Math.min(currentPage + 2, totalPages);

    if (currentPage - 2 < 1) {
      endPage = Math.min(5, totalPages);
    } else if (currentPage + 2 > totalPages) {
      startPage = Math.max(totalPages - 4, 1);
    }

    for (let i = startPage; i <= endPage; i++) {
      addPageButton(i);
    }

    if (currentPage < totalPages - 3) {
      if (currentPage < totalPages - 4) {
        pageButtons.push(
          <button
            style={{ transition: "all 0.3s ease-in-out" }}
            className="flex w-[38px] h-[38px] py-[10px] justify-center text-white items-center gap-2 rounded-[12px] border-solid border border-[#fff] border-opacity-[0.16] bg-[#fff] bg-opacity-[0.07]"
          >
            ...
          </button>
        );
      }
      addPageButton(totalPages);
    }
  }
  if (totalPages == 1) return null;
  return (
    <div className="flex items-center self-stretch justify-center gap-2 py-4">
      <button
        style={{ transition: "all 0.3s ease-in-out" }}
        className="flex w-[38px] h-[38px] py-[10px] justify-center items-center gap-2 rounded-[12px] border-solid border border-[#fff] border-opacity-[0.16] bg-[#fff] bg-opacity-[0.07]"
        onClick={() => handlePageChange(currentPage - 1)}
        disabled={currentPage === 1}
        onMouseEnter={(e) =>
          (e.currentTarget.style.background = hoverStyle.background)
        }
        onMouseLeave={(e) =>
          (e.currentTarget.style.background = "rgba(255, 255, 255, 0.07)")
        }
      >
        {i18n.language === "ar" ? (
          <NextIcon color={currentPage === totalPages ? "#444444" : "white"} />
        ) : (
          <PrevIcon color={currentPage === 1 ? "#444444" : "white"} />
        )}
      </button>
      {pageButtons}
      <button
        style={{ transition: "all 0.3s ease-in-out" }}
        className="flex w-[38px] h-[38px] py-[10px] justify-center items-center gap-2 rounded-[12px] border-solid border border-[#fff] border-opacity-[0.16] bg-[#fff] bg-opacity-[0.07]"
        onClick={() => handlePageChange(currentPage + 1)}
        disabled={currentPage === totalPages}
        onMouseEnter={(e) =>
          (e.currentTarget.style.background = hoverStyle.background)
        }
        onMouseLeave={(e) =>
          (e.currentTarget.style.background = "rgba(255, 255, 255, 0.07)")
        }
      >
        {i18n.language === "ar" ? (
          <PrevIcon color={currentPage === 1 ? "#444444" : "white"} />
        ) : (
          <NextIcon color={currentPage === totalPages ? "#444444" : "white"} />
        )}
      </button>
    </div>
  );
};

export default Pagination;
