import React from "react";

interface ExitIconProps {
  className?: string;
  width?: string | number;
  height?: string | number;
}

const ExitIcon: React.FC<ExitIconProps> = ({
  className,
  width = 24,
  height = 25,
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 24 25"
    fill="none"
    className={className}
  >
    <path
      d="M7.00023 6.25L18.3139 17.5637M18.0002 6.25L6.68652 17.5637"
      stroke="#1A1A1A"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default ExitIcon;
