import React from "react";

const HandIcon: React.FC<IconProps> = ({ color, height, width, className }) => {
  return (
    <svg
      className={className}
      width={width || 18}
      height={height || 18}
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
            <path
        d="M10 14H12C12.5304 14 13.0391 13.7893 13.4142 13.4142C13.7893 13.0391 14 12.5304 14 12C14 11.4696 13.7893 10.9609 13.4142 10.5858C13.0391 10.2107 12.5304 10 12 10H9C8.4 10 7.9 10.2 7.6 10.6L2 16M6 20L7.6 18.6C7.9 18.2 8.4 18 9 18H13C14.1 18 15.1 17.6 15.8 16.8L20.4 12.4C20.7859 12.0353 21.0111 11.5323 21.0261 11.0016C21.0411 10.4708 20.8447 9.95589 20.48 9.57C20.1153 9.18411 19.6123 8.95889 19.0816 8.94389C18.5508 8.92888 18.0359 9.12533 17.65 9.49L13.45 13.39M1 15L7 21M17.9 8C17.9 9.60163 16.6016 10.9 15 10.9C13.3984 10.9 12.1 9.60163 12.1 8C12.1 6.39837 13.3984 5.1 15 5.1C16.6016 5.1 17.9 6.39837 17.9 8ZM8 4C8 5.65685 6.65685 7 5 7C3.34315 7 2 5.65685 2 4C2 2.34315 3.34315 1 5 1C6.65685 1 8 2.34315 8 4Z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />

    </svg>
  );
};

export default HandIcon;
