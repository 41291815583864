import React, { useEffect, useState } from "react";
import Layout3 from "../lib/components/layout/Layout3";
import useLocalStorage from "../lib/hooks/useLocalStorage";
import useApi from "../lib/hooks/useApi";
import { useNavigate } from "react-router-dom";
import Spinner from "../lib/components/Shared/Spinner";
import { useTranslation } from "react-i18next";
import Loadingwithoutlogo from "./loadingwithoutlogo";
import { PageRoutes } from "../lib/constants";
import { PLATFORM_URL } from "../lib/constants/api-constants";
import LoadingSpinner from "../lib/components/Shared/LoadingSpinner";

const LeanPage: React.FC = () => {
  const { getValue: getCompanyId } = useLocalStorage<string>();
  const { getData: getLeanData } = useApi<any>();
  const [leanLoaded, setLeanLoaded] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const { i18n } = useTranslation();

  useEffect(() => {
    const handlePopState = (event) => {
      navigate(PageRoutes.LEAN);
    };

    window.history.pushState(null, "", window.location.href);
    window.addEventListener("popstate", handlePopState);

    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, [navigate]);

  useEffect(() => {
    // Ensure the Lean library is loaded
    const script = document.createElement("script");
    script.src =
      "https://cdn.leantech.me/link/loader/prod/sa/latest/lean-link-loader.min.js";
    script.async = true;
    document.body.appendChild(script);

    script.onload = () => {
      // //consloe.log("Lean script loaded");
      setLeanLoaded(true);
    };

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const callbackMethod = (cbObject: any) => {
    if (
      (cbObject.status == "CANCELLED" || cbObject.status == "ERROR") &&
      [
        "UNSUPPORTED_BANK_REQUEST_SUCCESS",
        "UNSUPPORTED_BANK_REQUEST",
        "INITIAL",
        "BANK_SELECTION",
        "OPEN_BANKING",
        "FAIL",
      ].includes(cbObject.exit_point)
    ) {
      navigate(PageRoutes.MANUAL_BANK_CONNECT);
    } else if (cbObject.status === "SUCCESS") {
      navigate(PageRoutes.CREDIT_SCORE);
    }
  };

  const handleButtonClick = async () => {
    setIsLoading(true);
    try {
      const companyId = getCompanyId("companyId");
      //consloe.log("companyid=>", companyId);
      if (!companyId) {
        console.error("Company ID is missing");
        alert("Company ID is missing");
        return;
      }

      const { data: response, status } = await getLeanData(
        `/bank/${companyId}/connect-with-lean`
      );
      if (status !== 200) throw new Error("API request failed");
      const today = new Date();
      const access_to = new Date(today);
      const access_from = new Date(today);

      access_to.setDate(today.getDate() + 1);
      access_from.setMonth(today.getMonth() - 6);

      if (window.Lean) {
        window.Lean.connect({
          app_token: response?.appId,
          permissions: [
            "identity",
            "accounts",
            "balance",
            "transactions",
            "identities",
            "scheduled_payments",
            "standing_orders",
            "direct_debits",
            "beneficiaries",
          ],
          callback: callbackMethod,
          customer_id: response?.customerId,
          sandbox: process.env.REACT_APP_IS_LEAN_SANDBOX,
          access_to: access_to,
          access_from: access_from,
          access_token: response?.customerAccessToken,
          fail_redirect_url: `${PLATFORM_URL}/manual-bank-connect`,
          success_redirect_url: `${PLATFORM_URL}/credit-score`,
          // "https://probuycompanyportaldev.z1.web.core.windows.net/manual-pending-credit-score",
          account_type: "business",
          customization: {
            overlay_color: "#000000",
            theme_color: "#fc6f03",
            link_color: "#fc6f03",
            button_text_color: "#ffffff",
            button_border_radius: "30",
          },
          language: i18n.language === "ar" ? "ar" : "en", // Set language based on i18n.language
        });
      } else {
        console.error("Lean connect function is not available");
        // alert("Lean connect function is not available");
        navigate(PageRoutes.MANUAL_BANK_CONNECT);
      }
    } catch (error) {
      console.error("Error performing API calls:", error);
      // alert("An error occurred");
      navigate(PageRoutes.MANUAL_BANK_CONNECT);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (leanLoaded) {
      handleButtonClick();
    }
  }, [leanLoaded]);

  if (!leanLoaded || isLoading)
    return (
      <Layout3>
        <LoadingSpinner />
      </Layout3>
    );

  return (
    <Layout3>
      <div className="min-h-dvh ">
        <img
          src="./assets/probuy.svg"
          className="w-20 h-10 mx-auto mb-8 md:w-40 md:h-20"
          alt="probuy logo"
        />
      </div>
    </Layout3>
  );
};

export default LeanPage;
