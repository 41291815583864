import React from "react";
import { useTranslation } from "react-i18next";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

function DashboardGlobalCardLoading() {
  const { t, i18n } = useTranslation();

  return (
    <SkeletonTheme baseColor="#fee0b7e3" highlightColor="#f5f5f5eb">
      <div
        dir={i18n.dir()}
        className={`w-full self-stretch items-start rounded-xl flex flex-col dash-card-bg py-8 px-6`}
      >
        <div className="flex flex-col md:flex-row pb-4 items-start md:items-center gap-4 self-stretch border-b border-solid border-[#000] border-opacity-20">
          <div className="flex h-[60px] flex-col justify-center items-start gap-2 flex-1">
            <p className="text-white font-bold text-[20px] md:text-[24px] leading-6">
              <Skeleton height={24} width={300} className="max-sm:!w-[200px]" />
            </p>
            <div className="flex flex-wrap items-end justify-center gap-1">
              <Skeleton height={24} width={80} />

              <div className="mx-[0.5px]" />
              <Skeleton height={24} width={80} />
            </div>
          </div>
          <div className="flex flex-row items-start flex-1 w-full gap-4 md:flex-row md:items-center">
            <div className="flex flex-col items-start flex-1 gap-1 text-white">
              <p className="text-[14px] md:text-[16px] font-normal">
                <Skeleton height={20} width={60} />
              </p>
              <div className="flex items-baseline gap-1 text-white">
                <p className="text-[28px] md:text-[32px] font-extrabold leading-8">
                  <Skeleton height={25} width={100} />
                </p>
                <p className="text-white text-[14px] font-normal leading-8">
                  <Skeleton height={14} width={30} />
                </p>
              </div>
            </div>
            <div className="flex flex-col items-start flex-1 gap-1 text-white">
              <p className="text-[14px] md:text-[16px] font-normal">
                <Skeleton height={20} width={60} />
              </p>
              <div className="flex items-baseline gap-1 text-white">
                <p className="text-[28px] md:text-[32px] font-extrabold leading-8">
                  <Skeleton height={25} width={100} />
                </p>
                <p className="text-white text-[14px] font-normal leading-8">
                  <Skeleton height={14} width={30} />
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col items-start self-stretch gap-4 mt-4 md:flex-row">
          <div className="flex pr-0 md:pr-4 flex-col items-start gap-2 flex-1 border-r md:border-r-[#000] md:border-opacity-20 max-md:border-none">
            <p className="text-[#000] text-[20px] md:text-[24px] font-bold leading-6">
              <Skeleton height={25} width={60} />
            </p>
            <p className="text-white text-[14px] md:text-[16px] font-normal leading-[20px] md:leading-[22px]w-full">
              <Skeleton height={18} width={200} />
              <Skeleton height={18} width={250} />
              <Skeleton height={18} width={180} />
            </p>
            <Skeleton height={35} width={150} />
          </div>
          <div className="flex pr-0 md:pr-4 flex-col items-start gap-2 flex-1 border-r md:border-r-[#000] md:border-opacity-20 max-md:border-none">
            <p className="text-[#000] text-[20px] md:text-[24px] font-bold leading-6">
              <Skeleton height={25} width={60} />
            </p>
            <p className="text-white text-[14px] md:text-[16px] font-normal leading-[20px] md:leading-[22px]w-full">
              <Skeleton height={18} width={200} />
              <Skeleton height={18} width={250} />
              <Skeleton height={18} width={180} />
            </p>
            <Skeleton height={35} width={150} />
          </div>
        </div>
      </div>
    </SkeletonTheme>
  );
}

export default DashboardGlobalCardLoading;
