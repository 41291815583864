import { useState } from "react";
import { PageRoutes } from "../constants";

const useApp = (): AppState => {
  const [userImage, setUserImage] = useState<File | null>(null);
  const [currentPage, setCurrentPage] = useState<string>(PageRoutes.DASHBOARD);
  const [isAuthenticated, setAuthenticated] = useState<boolean>(false);
  return {
    isAuthenticated,
    setAuthenticated,
    userImage,
    setUserImage,
    currentPage,
    setCurrentPage,
  };
};

export default useApp;
