import { useState } from "react";
import { API_BASE_URL } from "../constants/api-constants";
import { PageRoutes } from "../constants";
import { useLocation, useNavigate } from "react-router-dom";
import { keycloak } from "../../keycloak";
import { useToast } from "../context/ToastContext";
import { clearUrlParams } from "../utils/clearUrlParams";

interface APIResponse<T> {
  status: number;
  data: T | undefined;
  error: string | undefined;
}

interface APIState<T> {
  status: number | undefined;
  data: T | undefined;
  error: string | undefined;
  getData: (url: string) => Promise<APIResponse<T>>;
  postData: (url: string, body: unknown) => Promise<APIResponse<T>>;
  putData: (url: string, body: unknown) => Promise<APIResponse<T>>;
  deleteData: (url: string) => Promise<APIResponse<T>>;
}

const useApi = <T,>(): APIState<T> => {
  const { toast } = useToast();
  const [data, setData] = useState<T | undefined>();
  const [error, setError] = useState<string | undefined>();
  const [status, setStatus] = useState<number | undefined>();
  const navigate = useNavigate();
  const location = useLocation();

  const currentUrl = `${window.location.origin}${location.pathname}${location.search}${location.hash}`;

  const getToken = () => sessionStorage.getItem("keycloak_token");
  const getUserId = () => localStorage.getItem("userId");

  const fetchData = async (
    url: string,
    method: string,
    body?: unknown
  ): Promise<APIResponse<T>> => {
    const userId = getUserId();
    const token = getToken();
    const headers = new Headers({
      Authorization: `Bearer ${token}`,
      "user-id": userId.replaceAll('"', ""),
    });

    if (method !== "GET" && method !== "DELETE") {
      headers.append("Content-Type", "application/json");
    }

    try {
      const response = await fetch(`${API_BASE_URL}${url}`, {
        method,
        headers,
        body: body ? JSON.stringify(body) : undefined,
      });

      if (response.status == 401) {
        try {
          await keycloak.login({ redirectUri: currentUrl }).then((res: any) => {
            if (res) clearUrlParams();
          });
        } catch (error) {
          toast({
            type: "error",
            titleKey: "Error",
            messageKey: "Your session expired",
          });
          navigate(PageRoutes.SIGN_IN);
        }
      }

      const responseData = await response.json();
      setStatus(response.status);

      if (!response.ok) {
        throw new Error(`Error ${method} data: ${response.statusText}`);
      }

      setData(responseData);
      return { status: response.status, data: responseData, error: undefined };
    } catch (err: any) {
      console.error(err);
      setError(err.message);
      return { status: 0, data: undefined, error: err.message };
    }
  };

  const getData = (url: string) => fetchData(url, "GET");
  const postData = (url: string, body: unknown) => fetchData(url, "POST", body);
  const putData = (url: string, body: unknown) => fetchData(url, "PUT", body);
  const deleteData = (url: string) => fetchData(url, "DELETE");

  return { status, data, error, getData, postData, putData, deleteData };
};

export default useApi;
