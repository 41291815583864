import React from "react";

const Eligible = () => {
  return (
    <div>
      <h2 className="text-white text-3xl my-1 text-center">Congratulations!</h2>
      <p className="text-white my-1 text-center">
        You are eligible to use ProBuy services.
      </p>
      <img
        loading="lazy"
        src="./assets/elig.svg"
        alt="eligible"
        className="mx-auto w-40 h-40 md:w-60 md:h-60"
      />
      
    </div>
  );
};

export default Eligible;
