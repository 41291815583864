//display English numbers only

export function formatNumber(
  value: number,
  isDecimal: boolean = false
): string {
  const options: Intl.NumberFormatOptions = {
    minimumFractionDigits: isDecimal ? 2 : 0,
    maximumFractionDigits: isDecimal ? 2 : 0,
  };

  return value ? new Intl.NumberFormat("en-US", options).format(value) : "0.00";
}

//this for using the ARABIC NUMBERS IF NEEDED JSUT UNCOMMENT

// import i18n from "i18next";

// export function formatNumber(
//   value: number,
//   isDecimal: boolean = false
// ): string {
//   const language = i18n.language; // Get the current language from i18next

//   // Map languages to locales
//   const localeMap: { [key: string]: string } = {
//     en: "en-US",
//     ar: "ar-SA",
//   };

//   // Get the appropriate locale based on the current language
//   const locale = localeMap[language] || "en-US"; // Default to 'en-US' if not found

//   const options: Intl.NumberFormatOptions = {
//     minimumFractionDigits: isDecimal ? 2 : 0,
//     maximumFractionDigits: isDecimal ? 2 : 0,
//   };

//   return new Intl.NumberFormat(locale, options).format(value);
// }
