import React from "react";

const ErrorComponent: React.FC = () => {
  return (
    <div className="flex items-center justify-center min-h-screen overflow-hidden text-white bg-black">
      <div className="relative w-full max-w-4xl">
        {/* Animated background */}
        <div className="absolute inset-0 bg-grid-white/[0.05] bg-[size:40px_40px] animate-pulse"></div>

        {/* Glowing orb */}
        <div className="absolute w-64 h-64 transform -translate-x-1/2 -translate-y-1/2 bg-orange-500 rounded-full top-1/2 left-1/2 filter blur-3xl opacity-20 animate-pulse"></div>

        {/* Main content */}
        <div className="relative z-10 p-8 bg-black border border-orange-500 shadow-2xl bg-opacity-40 backdrop-filter backdrop-blur-lg rounded-2xl">
          <h1 className="mb-6 text-6xl font-extrabold text-center text-transparent bg-gradient-text bg-clip-text bg-gradient-to-r from-purple-400 to-pink-600">
            System Malfunction
          </h1>
          <p className="mb-8 text-xl text-center text-gray-300">
            We are currently experiencing an issue, and our team is actively
            working to resolve it. Please try again later. Thank you for your
            patience!{" "}
          </p>
          <div className="flex justify-center space-x-4">
            <button
              onClick={() => window.location.reload()}
              className="px-6 py-3 text-white transition duration-300 ease-in-out transform rounded-full shadow-lg bg-gradient-text gradiantbg bg-gradient-to-r from-cyan-500 to-blue-500 hover:from-cyan-600 hover:to-blue-600 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-opacity-50 hover:scale-105"
            >
              Reload
            </button>
          </div>
        </div>

        {/* Decorative elements */}
        <div className="absolute top-0 left-0 w-32 h-32 bg-purple-500 rounded-full mix-blend-multiply filter blur-xl opacity-20 animate-blob"></div>
        <div className="absolute top-0 right-0 w-32 h-32 bg-yellow-500 rounded-full mix-blend-multiply filter blur-xl opacity-20 animate-blob animation-delay-2000"></div>
        <div className="absolute bottom-0 w-32 h-32 bg-pink-500 rounded-full left-20 mix-blend-multiply filter blur-xl opacity-20 animate-blob animation-delay-4000"></div>
      </div>
    </div>
  );
};

export default ErrorComponent;
