import React from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

const TransactionCardLoading: React.FC = () => {
  return (
    <SkeletonTheme baseColor="#202020" highlightColor="#444">
      <div className="flex py-4 items-start gap-3 pr-4 self-stretch bg-[#1f1f1f] rounded-[16px] w-full md:max-h-[96px]">
        <Skeleton
          circle
          width={48}
          height={48}
          containerClassName=" flex p-[13.714px] justify-end items-start self-center gap-[6.857px] rounded-full"
        />
        <div className="flex w-full max-sm:flex-col max-sm:gap-3">
          <div className="flex flex-col items-start justify-end flex-1 max-sm:gap-2">
            <div className="flex items-center gap-2">
              <p>
                <Skeleton
                  width={210}
                  height={24}
                  className="max-sm:!w-[140px]"
                  containerClassName="text-white text-[16px]  font-bold leading-6"
                />
              </p>
              <p>
                <Skeleton
                  width={50}
                  height={24}
                  containerClassName="text-white text-[16px]  font-bold leading-6"
                />
              </p>
            </div>
            <p>
              <Skeleton
                width={270}
                height={24}
                className="max-sm:!w-[160px]"
                containerClassName="text-white text-[16px]  font-bold leading-6"
              />
            </p>
          </div>
          <div className="flex md:min-w-[190px] flex-col justify-center items-end gap-[6px]">
            <div className="flex flex-row items-start justify-center gap-1 max-sm:justify-start">
              <Skeleton
                width={155}
                height={24}
                className="max-sm:!w-[110px]"
                containerClassName="text-white text-[16px]  font-bold leading-6"
              />
              <Skeleton
                width={25}
                height={24}
                containerClassName="text-white text-[16px]  font-bold leading-6"
              />
            </div>
            <Skeleton
              width={60}
              height={20}
              containerClassName="text-white text-[16px]  font-bold leading-6"
            />
          </div>
        </div>
      </div>
    </SkeletonTheme>
  );
};

export default TransactionCardLoading;
