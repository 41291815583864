import React from "react";
import { useTranslation } from "react-i18next";

const ConnectAccount = ({ onPageChange }) => {
  const { t } = useTranslation();

  const handleConnect = () => {
    onPageChange(2);
    // navigate(PageRoutes.MANUAL_BANK_CONNECT);
  };

  return (
    <div>
      <div className="flex flex-col w-full h-screen justify-center items-center p-4 md:p-0">
        <img
          src="./assets/probuy.svg"
          className=""
          alt="probuy logo"
        />
        <div className="flex flex-col w-full md:w-3/4 items-center justify-center">
          <div className="w-full md:w-[500px] bg-white bg-opacity-5 p-4 rounded-xl shadow-xl flex flex-col justify-center items-center">
            <h1 className="text-xl font-bold md:text-3xl text-center mb-4">
              {t("connectAccount.title")}
              <br /> {t("connectAccount.subtitle")}
            </h1>
            <p className="text-white text-center text-base md:text-lg my-8">
              {t("connectAccount.description")}
            </p>
            <button
              onClick={handleConnect}
              className="bg-gradient-bg text-white py-2 px-4 rounded-3xl focus:outline-none"
            >
              {t("connectAccount.button")}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConnectAccount;
