import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import CopyIcon from "../Icons/CopyIcon";
import { formatNumber } from "../../utils/formatNumber";
import { PaymentPlanType } from "../../constants";

interface PaymentSummaryProps {
  isPaid: boolean;
  paymentPlanType: number;
  installmentNumber: number;
  paymentCode: string;
  paymentDate: string;
  paymentValue: number;
  invoiceId?: string;
  color?: string;
  indexofInstallment: number;
}

const PaymentSummary: React.FC<PaymentSummaryProps> = ({
  isPaid,
  paymentPlanType,
  installmentNumber,
  paymentCode,
  paymentDate,
  paymentValue,
  invoiceId,
  indexofInstallment,
  color = "bg-[#1F1F1F]",
}) => {
  const { t, i18n } = useTranslation();
  const [copied, setCopied] = useState(false);

  const handleCopy = () => {
    navigator.clipboard.writeText(paymentCode);
    setCopied(true); // Set copied to true
    //consloe.log("copySuccessMessage");

    // Reset copied state after 2 seconds
    setTimeout(() => {
      setCopied(false);
    }, 2000);
  };

  const getCornerClasses = () => {
    const isLTR = i18n.language === "en";
    return {
      topLeft: isLTR ? "rounded-tl-[8px]" : "rounded-tr-[8px]",
      topRight: isLTR ? "rounded-tr-[8px]" : "rounded-tl-[8px]",
      bottomLeft: isLTR ? "rounded-bl-[8px]" : "rounded-br-[8px]",
      bottomRight: isLTR ? "rounded-br-[8px]" : "rounded-bl-[8px]",
    };
  };

  const corners = getCornerClasses();

  return (
    <div className="flex flex-col items-start self-stretch gap-3 py-2">
      <h2 className="text-[12px] text-white text-opacity-60 font-bold leading-6">
        {t("paymentSummaryTitle")}
      </h2>
      <div className="flex flex-col items-center justify-normal gap-[1px] self-stretch">
        {paymentPlanType === PaymentPlanType.Installment && (
          <div className="flex justify-center items-center gap-[1px] self-stretch">
            <div
              className={`flex w-[110px] py-3 px-4 justify-start self-stretch items-center gap-[9px] ${color} ${corners.topLeft}`}
            >
              <p className="text-white text-opacity-60 text-[10px] font-normal leading-[22.7px]">
                {t("installmentNumberLabel")}
              </p>
            </div>
            <div
              className={`flex px-4 py-3 justify-start items-center self-stretch gap-[9px] flex-1 ${color} ${corners.topRight}`}
            >
              <p className="text-white text-[12px] font-bold leading-[22.8px] tracking-[0.095px]">
                {indexofInstallment + 1}
              </p>
            </div>
          </div>
        )}
        {isPaid && (
          <div className="flex justify-center items-center gap-[1px] self-stretch">
            <div
              className={`flex w-[110px] py-3 px-4 justify-start self-stretch items-center gap-[9px] ${color}`}
            >
              <p className="text-white text-opacity-60 text-[10px] font-normal leading-[22.7px]">
                {t("paymentCodeLabel")}
              </p>
            </div>
            <div
              className={`flex px-4 py-3 justify-start items-center self-stretch gap-[9px] flex-1 ${color}`}
            >
              <div className="flex w-full justify-between items-center gap-2 rounded-[8px] bg-[#000] bg-opacity-[0.32]">
                <p className="text-white text-[12px] font-bold leading-[22.8px] tracking-[0.095px] px-3 py-2 break-all">
                  {paymentCode}
                </p>
                <div
                  onClick={handleCopy}
                  className={`relative w-10 h-10 cursor-pointer flex items-center justify-center ${
                    i18n.language === "en" ? "mr-1" : "ml-1"
                  }`}
                >
                  <CopyIcon />
                  {copied && (
                    <div
                      className="absolute top-[-20px] right-[-10px] px-2 py-1 bg-black text-white rounded-[8px] text-[12px] whitespace-nowrap"
                      style={{ transform: "translateY(-50%)" }}
                    >
                      {t("Copied")}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}

        {/* {isPaid && (
          <div className="flex justify-center items-center gap-[1px] self-stretch">
            <div
              className={`flex !w-[110px] py-2 px-2 justify-start items-center self-stretch gap-[9px]  ${color} ${
                paymentPlanType === 0 ? corners.topLeft : ""
              }`}
            >
              <p className="text-white text-nowrap text-opacity-60 text-[10px] font-normal leading-[22.7px]">
                {t("paymentCodeLabel")}
              </p>
            </div>
            <div
              className={`flex px-4 py-3 justify-start items-center gap-[9px]  ${color} ${
                paymentPlanType === 0 ? corners.topRight : ""
              }`}
            >
              <div className="flex px-1 py-1 justify-between items-center gap-2 flex-1 self-stretch rounded-[8px] bg-[#000] bg-opacity-[0.32]">
                <p className="text-white text-[12px] font-bold leading-[22.8px] tracking-[0.095px] max-sm:text-ellipsis max-sm:max-w-[120px] max-sm:overflow-hidden max-sm:whitespace-nowrap">
                  {paymentCode}
                </p>
                <div
                  onClick={handleCopy}
                  className={`relative w-6 h-6 cursor-pointer flex items-center justify-center ${
                    i18n.language === "en" ? "mr-2" : "ml-2"
                  }`}
                >
                  <CopyIcon />
                  {copied && (
                    <div
                      className="absolute top-[-20px] right-[-10px] md:px-2 md:py-1 bg-black text-white rounded-[8px] text-[12px] whitespace-nowrap"
                      style={{ transform: "translateY(-50%)" }}
                    >
                      {t("Copied")}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        )} */}

        <div className="flex justify-center items-center gap-[1px] self-stretch flex-1">
          <div
            className={`flex w-[110px] py-3 px-4 self-stretch justify-start items-center gap-[9px] ${color} ${
              !isPaid && paymentPlanType === PaymentPlanType.Credit
                ? corners.topLeft
                : ""
            }`}
          >
            <p className="text-white text-opacity-60 text-[10px] font-normal leading-[22.7px]">
              {t("paymentDateLabel")}
            </p>
          </div>
          <div
            className={`flex px-4 py-3 justify-start items-center gap-[9px] self-stretch flex-1 ${color} ${
              !isPaid && paymentPlanType === PaymentPlanType.Credit
                ? corners.topRight
                : ""
            }`}
          >
            <p className="text-white text-[12px] font-bold leading-[22.8px] tracking-[0.095px]">
              {paymentDate}
            </p>
          </div>
        </div>

        <div className="flex justify-center items-center gap-[1px] self-stretch">
          <div
            className={`flex w-[110px] py-3 px-4 justify-start items-center gap-[9px] ${color} self-stretch ${corners.bottomLeft}`}
          >
            <p className="text-white text-opacity-60 text-[10px] font-normal leading-[22.7px]">
              {t("paymentValueLabel")}
            </p>
          </div>
          <div
            className={`flex px-4 py-3 justify-start items-center gap-[9px] self-stretch flex-1 ${color} ${corners.bottomRight}`}
          >
            <p className="text-white text-[16px] font-extrabold leading-[24px]">
              {formatNumber(paymentValue, true)}
              <span className="text-white text-[12px] font-normal leading-[24px]">
                &nbsp;{t("SAR")}
              </span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentSummary;
