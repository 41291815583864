import React, { useState } from "react";
import Layout3 from "../lib/components/layout/Layout3";
import GetOtp from "../lib/components/GetOtp";
import SendOtp from "../lib/components/SendOtp";

const Register = () => {
  const [screen, setScreen] = useState<string>("getOtp");
  return (
    <Layout3>
      {screen === "getOtp" ? (
        <GetOtp setScreen={setScreen} />
      ) : screen === "sendOtp" ? (
        <SendOtp setScreen={setScreen} />
      ) : null}
    </Layout3>
  );
};

export default Register;
