import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { PageRoutes } from "../../constants";
import useLocalStorage from "../../hooks/useLocalStorage";
import Eligible from "./Eligible";
import Ineligble from "./Ineligble";
import ElligbleCard from "../cards/ElligbleCard";
import SmallEligibleCard from "../cards/SmallEligibleCard";
import Button from "../Buttons/Button";
import { t } from "i18next";

const CreditScore = ({
  isEligible,
  creditData,
  companyData,
}: {
  isEligible: boolean;
  creditData: CreditScoreResponse | number;
  companyData?: any;
}) => {
  const { setValue: setIsProcessPending } = useLocalStorage<boolean>();
  const [isLargeScreen, setIsLargeScreen] = useState<boolean>(
    window.innerWidth > 1024
  );
  const navigate = useNavigate();

  useEffect(() => {
    if (!isEligible) {
      setTimeout(() => navigate(PageRoutes.DASHBOARD), 15000);
    }
    const handleResize = () => {
      setIsLargeScreen(window.innerWidth > 1024);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleNavigate = () => {
    navigate(PageRoutes.DASHBOARD);
    setIsProcessPending("isProcessPending", false);
  };

  const handleSupport = () => {
    // Logic to navigate to the support page or open support modal
    // navigate(PageRoutes.SUPPORT);
  };

  return (
    <div className="flex flex-col items-center justify-center w-full p-4 min-h-dvh md:p-0">
      <img src="./assets/probuy.svg" className="" alt="probuy logo" />
      <div className="w-full md:w-[500px] p-4 rounded-xl shadow-xl flex flex-col justify-center items-center">
        {isEligible ? <Eligible /> : <Ineligble />}
        {isLargeScreen ? (
          <ElligbleCard
            creditData={creditData}
            isEllgible={isEligible}
            companyData={companyData}
          />
        ) : (
          <SmallEligibleCard
            creditData={creditData}
            isEllgible={isEligible}
            companyData={companyData}
          />
        )}
        {isEligible ? (
          <button
            onClick={handleNavigate}
            className="w-40 px-4 py-2 my-4 text-white border border-orange-500 gradient-text rounded-3xl focus:outline-none"
          >
            Go to my Account
          </button>
        ) : (
          <div className="flex items-center justify-center gap-2">
            <Button
              onClick={handleSupport}
              className="w-40 px-4 py-2 my-4 text-white border border-orange-500 gradient-text rounded-3xl focus:outline-none"
              disabled
              label={t("Get_Support")}
            ></Button>
            <button
              onClick={() => navigate(PageRoutes.LOGOUT)}
              className="w-40 px-4 py-2 my-4 text-white border border-orange-500 gradient-text rounded-3xl focus:outline-none"
            >
              Logout
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default CreditScore;
