import React from "react";

interface CopyIconProps {
  className?: string;
  width?: number | string;
  height?: number | string;
  onClick?: () => void;
}

const CopyIcon: React.FC<CopyIconProps> = ({
  className,
  width = 24,
  height = 24,
  onClick,
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 16 16"
    fill="none"
    className={className}
  >
    <g clipPath="url(#clip0_3417_20458)">
      <path
        d="M13.333 5.3335H6.66634C5.92996 5.3335 5.33301 5.93045 5.33301 6.66683V13.3335C5.33301 14.0699 5.92996 14.6668 6.66634 14.6668H13.333C14.0694 14.6668 14.6663 14.0699 14.6663 13.3335V6.66683C14.6663 5.93045 14.0694 5.3335 13.333 5.3335Z"
        stroke="url(#paint0_linear_3417_20458)"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.66634 10.6668C1.93301 10.6668 1.33301 10.0668 1.33301 9.3335V2.66683C1.33301 1.9335 1.93301 1.3335 2.66634 1.3335H9.33301C10.0663 1.3335 10.6663 1.9335 10.6663 2.66683"
        stroke="url(#paint1_linear_3417_20458)"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_3417_20458"
        x1="5.33301"
        y1="5.3335"
        x2="15.5014"
        y2="13.6453"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#EF6B2A" />
        <stop offset="1" stopColor="#F1BC62" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_3417_20458"
        x1="1.33301"
        y1="1.3335"
        x2="11.5014"
        y2="9.64527"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#EF6B2A" />
        <stop offset="1" stopColor="#F1BC62" />
      </linearGradient>
      <clipPath id="clip0_3417_20458">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default CopyIcon;
