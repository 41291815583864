interface ProductService {
  value: string;
  labelKey: string; // Key to look up in the translation files
}

const productServiceList: ProductService[] = [
  { value: "Web Hosting", labelKey: "productService.webHosting" },
  { value: "Graphic Design", labelKey: "productService.graphicDesign" },
  { value: "Digital Marketing", labelKey: "productService.digitalMarketing" },
  { value: "Cloud Storage", labelKey: "productService.cloudStorage" },
  {
    value: "E-commerce Platform",
    labelKey: "productService.ecommercePlatform",
  },
  { value: "IT Consulting", labelKey: "productService.itConsulting" },
];

export default productServiceList;
