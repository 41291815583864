import React from "react";
import { useTranslation } from "react-i18next";
import CreditOrderGoldIcon from "../Icons/CreditOrderGoldIcon";
import InstallmentsOrderGold from "../Icons/InstallmentsOrderGold";
import { formatDistanceToNowStrict, isToday } from "date-fns";
import { enUS, ar } from "date-fns/locale";
import PaymentStatusLapel from "../Orders/PaymentStatusLapel";
import OrderStatusLabel from "../Orders/OrderStatusLabel";
import { OrderStatus, PaymentStatus, PaymentPlanType } from "../../constants";
import { formatNumber } from "../../utils/formatNumber";
import PartiallyPaidStatusLabel from "../Orders/PartiallyPaidStatus";
import OrderCardIcon from "../Icons/OrderCardIcon";

const OrderCard: React.FC<OrderDto> = ({
  id,
  createdAt,
  installments,
  installmentsPaid,
  isPaid,
  paymentStatus,
  orderNumber,
  totalAmount,
  paymentDate,
  lastModifiedAt,
  description,
  supplierName,
  paymentPlanType,
  status,
}) => {
  const { t, i18n } = useTranslation();

  const locale = i18n.language === "ar" ? ar : enUS;
  const isOrderToday = isToday(new Date(createdAt));
  const timeAgo = isOrderToday
    ? t("today")
    : `${formatDistanceToNowStrict(new Date(createdAt), { locale })} ${t(
        "ago"
      )}`;

  const IconToRender =
    paymentPlanType === PaymentPlanType.Credit
      ? CreditOrderGoldIcon
      : paymentPlanType === PaymentPlanType.Installment
      ? InstallmentsOrderGold
      : paymentPlanType === PaymentPlanType.None
      ? OrderCardIcon
      : undefined;

  return (
    <div
      dir={i18n.dir()}
      className="flex p-4 items-start gap-3 self-stretch rounded-[16px] bg-[#1f1f1f]"
    >
      {IconToRender && (
        <div
          style={{
            background:
              "linear-gradient(129deg, rgba(239, 107, 42, 0.00) 0%, rgba(241, 188, 98, 0.60) 100%)",
          }}
          className="flex p-3 justify-end items-start gap-[7px] rounded-full"
        >
          <IconToRender />
        </div>
      )}
      <div className="flex items-start justify-end flex-1 gap-2 max-sm:gap-1">
        <div className="flex flex-col justify-end items-start flex-1 max-sm:gap-[6px]">
          <div className="flex flex-row">
            <p className="text-[#8A8A8A] text-lg mx-1 max-md:hidden">
              {t("orderNo")}
            </p>
            <span className="text-white text-[20px] font-extrabold leading-6 md:max-lg:max-w-[400px] sm:max-md:max-w-[330px] max-sm:max-w-[150px] text-ellipsis overflow-hidden">
              {orderNumber}
            </span>
          </div>
          <div className="flex flex-row max-sm:flex-col max-sm:gap-[6px]">
            <p className="mx-1 text-sm text-white text-opacity-60">
              {supplierName}
            </p>
            <div className="max-sm:hidden w-1.5 h-1.5 my-2 mx-1 bg-gray-300 rounded-full" />
            <p className="mx-1 text-sm gradient-text text-nowrap">
              {paymentPlanType === PaymentPlanType.Installment
                ? `${installments} ${t("installments")}`
                : paymentPlanType === PaymentPlanType.Credit
                ? `${t("creditC")}`
                : null}
            </p>
          </div>
        </div>
        <div className="flex md:[min-w-190px] flex-col justify-center items-end md:gap-[6px]">
          <div className="flex gap-3 items-start max-sm:flex-col max-sm:items-end max-sm:gap-[2px]">
            <div className="flex flex-col items-start gap-3">
              {status === OrderStatus.Active ? (
                paymentStatus === PaymentStatus.PartiallyPaid ? (
                  <PartiallyPaidStatusLabel
                    status={`${installmentsPaid}/${installments}`}
                    className="px-[10px] py-[6px]"
                  />
                ) : (
                  <PaymentStatusLapel
                    className="px-[10px] py-[6px]"
                    showIcon={false}
                    status={paymentStatus}
                  />
                )
              ) : (
                <OrderStatusLabel status={status} />
              )}
            </div>
            <div className="flex items-baseline gap-1 text-white">
              <p className="text-2xl max-sm:text-[16px] font-bold max-sm:overflow-hidden max-sm:text-ellipsis max-sm:max-w-[60px]">
                {formatNumber(totalAmount, true)}
              </p>
              <p className="max-sm:text-[10px] text-[14px]">{t("currency")}</p>
            </div>
          </div>
          <div className="my-1">
            <p className="text-[#8A8A8A] text-sm">{timeAgo}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderCard;
