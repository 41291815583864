import React, { ForwardedRef } from "react";
import { useField } from "formik";
import { useTranslation } from "react-i18next";

interface InputFieldProps {
  inputClassName?: string;
  mandStar?: boolean;
  id: string;
  label: string;
  type?: string;
  defaultValue?: string;
  value?: string | number;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  className?: string;
  placeholder?: string;
  name: string;
  required?: boolean;
}

const InputField = React.forwardRef(
  (
    {
      inputClassName,
      mandStar,
      id,
      label,
      placeholder,
      type = "text",
      defaultValue = "",
      value = "",
      onChange,
      disabled = false,
      className,
      name,
      required = false,
    }: InputFieldProps,
    ref: ForwardedRef<HTMLInputElement>
  ) => {
    const [field, meta] = useField(name);
    const { t, i18n } = useTranslation();

    // Determine text direction based on i18n language direction
    const direction = i18n.dir(); // 'ltr' or 'rtl'

    // Handler to restrict invalid characters only for number input type
    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (type !== "number") return;

      const invalidChars = ["e", "+", "-", ",", ".", " "];
      if (invalidChars.includes(e.key)) {
        e.preventDefault();
      }
    };

    // Handler to allow only numbers for tel input
    const handleInput = (e: React.FormEvent<HTMLInputElement>) => {
      if (type !== "tel" && type !== "number") return;

      const input = e.currentTarget;
      let sanitizedValue = input.value.replace(/[^0-9]/g, "");

      if (type === "tel") {
        if (input.value !== sanitizedValue) {
          input.value = sanitizedValue;
        }
      } else if (type === "number") {
        // Prevent the user from inputting '-1'
        if (sanitizedValue === "1" && input.value.startsWith("-")) {
          sanitizedValue = "";
        }

        if (input.value !== sanitizedValue) {
          input.value = sanitizedValue;
        }
      }
    };

    // Handler to sanitize pasted content for number inputs
    const handlePaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
      if (type !== "number") return;

      const paste = e.clipboardData.getData("text");
      let sanitizedPaste = paste.replace(/[^0-9]/g, "");

      // Prevent the user from pasting '-1'
      if (sanitizedPaste === "1" && paste.startsWith("-")) {
        sanitizedPaste = "";
      }

      if (paste !== sanitizedPaste) {
        e.preventDefault();
        const input = e.currentTarget;
        const { selectionStart, selectionEnd } = input;
        input.value =
          input.value.slice(0, selectionStart!) +
          sanitizedPaste +
          input.value.slice(selectionEnd!);
        if (onChange) {
          // Manually trigger onChange to update the value in the parent component
          onChange(e as unknown as React.ChangeEvent<HTMLInputElement>);
        }
      }
    };

    return (
      <div className={`flex flex-col flex-1 gap-2 ${className}`}>
        <span className="flex gap-1">
          <label className="text-white text-opacity-60">{label}</label>
          {mandStar && (
            <span className="text-[#EF6B2A] font-[800] leading-6 text-[14px]">
              *
            </span>
          )}
        </span>
        <input
          placeholder={placeholder}
          ref={ref}
          id={id}
          className={`${inputClassName} justify-center px-4 py-3 text-white text-opacity-50 rounded-xl border border-solid bg-white bg-opacity-10 border-white border-opacity-20  focus:border-[#EF6B2A] focus:ring-1 focus:ring-[#EF6B2A] ${
            type === "number" ? "no-spinner" : ""
          } ${meta.touched && meta.error ? "border-red-500" : ""}`}
          type={type}
          value={value}
          defaultValue={defaultValue}
          onChange={onChange}
          onInput={handleInput}
          disabled={disabled}
          onKeyDown={handleKeyDown}
          onPaste={handlePaste}
          required={required}
          dir={direction} // Set direction attribute dynamically
          {...(type === "tel" ? { minLength: 9, maxLength: 12 } : {})}
          {...field}
        />
        {meta.touched && meta.error && (
          <span className="text-red-500  text-opacity-80">
            {t(`${meta.error}`)}
          </span>
        )}
      </div>
    );
  }
);

export default InputField;
