import React from "react";

interface IconProps {
  className?: string;
  width?: number | string;
  height?: number | string;
}

const GoldenDownloadIcon: React.FC<IconProps> = ({
  className,
  width = 24,
  height = 24,
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.287 4.18525C10.4043 3.83866 9.45419 3.69752 8.5088 3.77249C7.56341 3.84747 6.64748 4.13661 5.83038 4.61801C5.01328 5.0994 4.31645 5.76043 3.79267 6.55103C3.26888 7.34162 2.93188 8.24105 2.80719 9.18118C2.6825 10.1213 2.77339 11.0775 3.07297 11.9773C3.37255 12.8771 3.87297 13.6969 4.53633 14.3747C4.82606 14.6707 4.82097 15.1455 4.52495 15.4353C4.22893 15.725 3.75408 15.7199 3.46435 15.4239C2.64179 14.5835 2.02126 13.5669 1.64978 12.4511C1.27829 11.3354 1.16559 10.1497 1.32021 8.98395C1.47483 7.81819 1.89271 6.70291 2.5422 5.72257C3.19169 4.74223 4.05577 3.92256 5.06897 3.32562C6.08217 2.72869 7.21792 2.37016 8.39021 2.27719C9.5625 2.18422 10.7406 2.35924 11.8352 2.789C12.9298 3.21876 13.9123 3.89199 14.7082 4.7577C15.3763 5.48439 15.8982 6.33071 16.2476 7.25026H17.5003C17.5002 7.25026 17.5003 7.25026 17.5003 7.25026C18.6267 7.25015 19.7233 7.61232 20.6281 8.28328C21.5329 8.95425 22.1979 9.89844 22.5248 10.9764C22.8518 12.0543 22.8234 13.2088 22.4438 14.2694C22.0642 15.3299 21.3536 16.2403 20.4169 16.8659C20.0725 17.096 19.6067 17.0033 19.3767 16.6588C19.1466 16.3144 19.2393 15.8487 19.5838 15.6186C20.2528 15.1717 20.7604 14.5214 21.0315 13.7639C21.3027 13.0064 21.3229 12.1817 21.0894 11.4118C20.8559 10.6418 20.3809 9.9674 19.7346 9.48813C19.0883 9.00887 18.305 8.75017 17.5004 8.75026H15.7103C15.3788 8.75026 15.0865 8.53255 14.9917 8.21484C14.7204 7.30611 14.2458 6.47105 13.6039 5.7729C12.9621 5.07476 12.1698 4.53183 11.287 4.18525ZM12.0003 11.2503C12.4146 11.2503 12.7503 11.5861 12.7503 12.0003V19.1896L15.47 16.4699C15.7629 16.177 16.2378 16.177 16.5307 16.4699C16.8236 16.7628 16.8236 17.2377 16.5307 17.5306L12.5307 21.5306C12.39 21.6712 12.1993 21.7503 12.0003 21.7503C11.8014 21.7503 11.6107 21.6712 11.47 21.5306L7.47001 17.5306C7.17712 17.2377 7.17712 16.7628 7.47001 16.4699C7.7629 16.177 8.23778 16.177 8.53067 16.4699L11.2503 19.1896V12.0003C11.2503 11.5861 11.5861 11.2503 12.0003 11.2503Z"
        fill="url(#paint0_linear_1654_1523)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1654_1523"
          x1="1.25293"
          y1="2.25293"
          x2="22.8113"
          y2="21.6832"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EF6B2A" />
          <stop offset="1" stopColor="#F1BC62" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default GoldenDownloadIcon;
