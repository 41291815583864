import React from "react";
import CalendarIcon from "../Icons/CalendarIcon";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";
import { format } from "date-fns";

interface OrderDateInputProps {
  fromDate: Date | null;
  toDate: Date | null;
  onFromDateChange: (date: Date | null) => void;
  onToDateChange: (date: Date | null) => void;
  onFilterClick: () => void;
  onFilterRemove: () => void;
  isFilterButtonDisabled?: boolean;
}

const OrderDateInput: React.FC<OrderDateInputProps> = ({
  fromDate,
  toDate,
  onFromDateChange,
  onToDateChange,
  onFilterClick,
  onFilterRemove,
  isFilterButtonDisabled = false,
}) => {
  const { t } = useTranslation();

  // Format dates in a way that considers local time zone
  const formatDate = (date: Date | null) => {
    return date ? format(date, "yyyy-MM-dd") : t("fromDate");
  };
  const formatToDate = (date: Date | null) => {
    return date ? format(date, "yyyy-MM-dd") : t("toDate");
  };

  return (
    <div className="flex w-[292px] p-4 flex-col items-end gap-2 rounded-[12px] border border-white border-opacity-[0.16] bg-[#191919]">
      <div className="flex items-center gap-2 self-stretch relative">
        <div className="w-full flex py-2 px-4 justify-center items-center gap-2 flex-1 bg-[#1F1F1F] rounded-[20px] cursor-pointer">
          <DatePicker
            selected={fromDate}
            onChange={(date: Date | null) => {
              onFromDateChange(date);
              if (toDate && date && toDate < date) {
                onToDateChange(null);
              }
            }}
            customInput={
              <div className="flex justify-between items-center gap-2 whitespace-nowrap">
                <p className="text-white text-opacity-40 text-[14px] font-normal leading-6">
                  {formatDate(fromDate)}
                </p>
                <CalendarIcon />
              </div>
            }
            wrapperClassName="date-picker-wrapper"
            popperPlacement="bottom-start"
          />
        </div>

        <div className="w-full flex py-2 px-4 justify-center text-nowrap items-center gap-2 flex-1 bg-[#1F1F1F] rounded-[20px] cursor-pointer">
          <DatePicker
            selected={toDate}
            onChange={onToDateChange}
            minDate={fromDate}
            customInput={
              <div className="flex justify-between items-center gap-2 whitespace-nowrap">
                <p className="text-white text-opacity-40 text-[14px] font-normal leading-6">
                  {formatToDate(toDate)}
                </p>
                <CalendarIcon />
              </div>
            }
            wrapperClassName="date-picker-wrapper"
            popperPlacement="bottom-start"
          />
        </div>
      </div>
      <button
        onClick={onFilterClick}
        disabled={isFilterButtonDisabled}
        className={`
          ${isFilterButtonDisabled ? "cursor-not-allowed" : ""}
          flex px-6 py-2 justify-center items-center gap-[6px] self-stretch rounded-[24px] border border-solid border-[#EF6B2A] bg-[#1A1A1A]`}
      >
        <span
          style={{
            background: "linear-gradient(129deg, #EF6B2A 0%, #F1BC62 100%)",
            backgroundClip: "text",
            WebkitBackgroundClip: "text",
            WebkitTextFillColor: "transparent",
          }}
          className="text-center text-[16px] font-bold leading-6"
        >
          {t("filterNow")}
        </span>
      </button>
      <button
        onClick={onFilterRemove}
        className={`flex px-6 py-2 justify-center items-center gap-[6px] self-stretch rounded-[24px] border border-solid border-[#EF6B2A] bg-[#1A1A1A]`}
      >
        <span
          style={{
            background: "linear-gradient(129deg, #EF6B2A 0%, #F1BC62 100%)",
            backgroundClip: "text",
            WebkitBackgroundClip: "text",
            WebkitTextFillColor: "transparent",
          }}
          className="text-center text-[16px] font-bold leading-6"
        >
          {t("clearFilter")}
        </span>
      </button>
    </div>
  );
};

export default OrderDateInput;