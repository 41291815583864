import React from "react";

interface DashboardIconProps {
  color?: string;
  width?: number;
  height?: number;
  className?: string;
}

const DashboardIcon: React.FC<DashboardIconProps> = ({
  color = "#8A8A8A",
  width = 24,
  height = 24,
  className,
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g id="Frame">
        <path
          id="Vector (Stroke)"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.7627 4.78214C13.1377 3.51826 10.8623 3.51826 9.23727 4.78214L5.23727 7.89326C4.14113 8.74581 3.5 10.0567 3.5 11.4453V19.9999C3.5 20.3977 3.65804 20.7793 3.93934 21.0606C4.22064 21.3419 4.60218 21.4999 5 21.4999H11.3181V13.9999C11.3181 13.7238 11.542 13.4999 11.8181 13.4999C12.0943 13.4999 12.3181 13.7238 12.3181 13.9999V21.4999H19C19.3978 21.4999 19.7794 21.3419 20.0607 21.0606C20.342 20.7793 20.5 20.3977 20.5 19.9999V11.4453C20.5 10.0567 19.8589 8.74581 18.7627 7.89326L14.7627 4.78214ZM19 22.4999C19.663 22.4999 20.2989 22.2365 20.7678 21.7677C21.2366 21.2988 21.5 20.663 21.5 19.9999V11.4453C21.5 9.74809 20.7164 8.14591 19.3767 7.1039L15.3767 3.99279C13.3906 2.44804 10.6094 2.44804 8.62333 3.99279L4.62333 7.1039C3.2836 8.14591 2.5 9.74809 2.5 11.4453V19.9999C2.5 20.663 2.76339 21.2988 3.23223 21.7677C3.70107 22.2365 4.33696 22.4999 5 22.4999H19Z"
          fill={color}
        />
      </g>
    </svg>
  );
};

export default DashboardIcon;
