import React from "react";
import { useTranslation } from "react-i18next";
import CorrectIcon from "../Icons/CorrectIcon";
import WrongIcon from "../Icons/WrongIcon";
import Chip from "../Chip";

const SmallEligibleCard = ({
  isEllgible,
  creditData,
  companyData,
}: {
  isEllgible: boolean;
  creditData: CreditScoreResponse | number;
  companyData?: any;
}) => {
  const { t, i18n } = useTranslation();
  return (
    <div
      dir={i18n.dir()}
      className="p-1 h-40 self-stretch rounded-lg 2xl:mt-1 mb-0.5 flex flex-col w-96 justify-between card-bg"
    >
      <div className="flex flex-col items-center justify-center mt-4">
        <h1
          className={`text-lg mx-1 text-white font-bold ${
            i18n.language === "ar" ? "text-right" : "text-left"
          }`}
        >
          {companyData.name}
        </h1>
        <div className="flex flex-row p-1 pr-2">
          {/* <div className="flex flex-row justify-between items-center 2xl:mx-0.5 h-7 w-24 bg-black text-white rounded-3xl">
            {isEllgible ? <CorrectIcon /> : <WrongIcon />}
            <p className="text-nowrap mx-auto text-[10px]">
              {t("installment")}
            </p>
          </div> */}
          <Chip
            label="installment"
            icon={isEllgible ? <CorrectIcon /> : <WrongIcon />}
          />
          <div className="mx-[0.5px]" />
          {/* <div className="flex flex-row justify-between items-center 2xl:mx-0.5 h-7 w-[100px] bg-black text-white rounded-3xl">
            {isEllgible ? <CorrectIcon /> : <WrongIcon />}
            <p className="text-nowrap mx-auto text-[10px]">
              {t("payment_postponement")}
            </p>
          </div> */}
          <Chip
            label="payment_postponement"
            icon={isEllgible ? <CorrectIcon /> : <WrongIcon />}
          />
        </div>
        <div className="flex flex-col my-2">
          <h2 className="my-1 text-xl font-light">Credit Limit</h2>
          <p className="flex flex-row justify-center text-center">
            <span className="text-2xl font-bold text-white">
              <span className="text-2xl font-bold text-white">
                {isEllgible
                  ? (creditData as CreditScoreResponse).creditLimit
                  : (creditData as number)}
              </span>
            </span>
            <span className="mx-1 mt-1 2xl:text-xl text-nowrap">
              {t("sar")}
            </span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default SmallEligibleCard;
