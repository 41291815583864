import React from "react";

interface CompanyProfileIconProps {
  color?: string;
  width?: number;
  height?: number;
  className?: string;
}

const CompanyProfileIcon: React.FC<CompanyProfileIconProps> = ({
  color = "#8A8A8A",
  width = 24,
  height = 24,
  className,
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g id="Frame">
        <path
          id="Vector (Stroke)"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8 2.5C7.60218 2.5 7.22064 2.65804 6.93934 2.93934C6.65804 3.22064 6.5 3.60218 6.5 4V21.5H17.5V4C17.5 3.60218 17.342 3.22064 17.0607 2.93934C16.7794 2.65804 16.3978 2.5 16 2.5H8ZM18.5 8.5V4C18.5 3.33696 18.2366 2.70107 17.7678 2.23223C17.2989 1.76339 16.663 1.5 16 1.5H8C7.33696 1.5 6.70107 1.76339 6.23223 2.23223C5.76339 2.70107 5.5 3.33696 5.5 4V11.5H4C3.33696 11.5 2.70107 11.7634 2.23223 12.2322C1.76339 12.7011 1.5 13.337 1.5 14V20C1.5 20.663 1.76339 21.2989 2.23223 21.7678C2.70107 22.2366 3.33696 22.5 4 22.5H20C20.663 22.5 21.2989 22.2366 21.7678 21.7678C22.2366 21.2989 22.5 20.663 22.5 20V11C22.5 10.337 22.2366 9.70107 21.7678 9.23223C21.2989 8.76339 20.663 8.5 20 8.5H18.5ZM18.5 9.5V21.5H20C20.3978 21.5 20.7794 21.342 21.0607 21.0607C21.342 20.7794 21.5 20.3978 21.5 20V11C21.5 10.6022 21.342 10.2206 21.0607 9.93934C20.7794 9.65804 20.3978 9.5 20 9.5H18.5ZM5.5 12.5H4C3.60218 12.5 3.22064 12.658 2.93934 12.9393C2.65804 13.2206 2.5 13.6022 2.5 14V20C2.5 20.3978 2.65804 20.7794 2.93934 21.0607C3.22064 21.342 3.60218 21.5 4 21.5H5.5V12.5ZM9.5 6C9.5 5.72386 9.72386 5.5 10 5.5H14C14.2761 5.5 14.5 5.72386 14.5 6C14.5 6.27614 14.2761 6.5 14 6.5H10C9.72386 6.5 9.5 6.27614 9.5 6ZM9.5 10C9.5 9.72386 9.72386 9.5 10 9.5H14C14.2761 9.5 14.5 9.72386 14.5 10C14.5 10.2761 14.2761 10.5 14 10.5H10C9.72386 10.5 9.5 10.2761 9.5 10ZM9.5 14C9.5 13.7239 9.72386 13.5 10 13.5H14C14.2761 13.5 14.5 13.7239 14.5 14C14.5 14.2761 14.2761 14.5 14 14.5H10C9.72386 14.5 9.5 14.2761 9.5 14ZM9.5 18C9.5 17.7239 9.72386 17.5 10 17.5H14C14.2761 17.5 14.5 17.7239 14.5 18C14.5 18.2761 14.2761 18.5 14 18.5H10C9.72386 18.5 9.5 18.2761 9.5 18Z"
          fill={color}
        />
      </g>
    </svg>
  );
};

export default CompanyProfileIcon;
