import React from "react";

interface PrevIconProps {
  className?: string;
  color?: string;
  width?: string | number;
  height?: string | number;
}

const PrevIcon: React.FC<PrevIconProps> = ({
  className,
  color = "#444444",
  width = 24,
  height = 24,
}) => {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Frame">
        <path
          id="Vector"
          d="M15 18L9 12L15 6"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
};

export default PrevIcon;
