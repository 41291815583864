import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ExitIcon from "../Icons/ExitIcon";
import i18n from "../../../i18n";
import { formatNumber } from "../../utils/formatNumber";

interface PaymentWithDepositProps {
  paymentId: string;
  companyId: any;
  onClose: () => void;
  amountFromWallet: number;
  amountToBePaid: number;
  api?: string;
  homeFunc?: string;
}

const PaymentWithDeposit: React.FC<PaymentWithDepositProps> = ({
  paymentId,
  companyId,
  onClose,
  amountFromWallet,
  amountToBePaid,
  api,
  homeFunc,
}) => {
  const { t } = useTranslation();
  const [enteredAmount, setEnteredAmount] = useState<number>(1);
  const [moyasarInitialized, setMoyasarInitialized] = useState(false);

  const handleAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (value.match(/^\d*\.?\d{0,2}$/)) {
      setEnteredAmount(amountToBePaid);
    }
  };

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://cdn.moyasar.com/mpf/1.14.0/moyasar.js";
    script.onload = () => {
      setMoyasarInitialized(true);
    };
    document.body.appendChild(script);
  }, []);

  useEffect(() => {
    if (moyasarInitialized && enteredAmount) {
      window.Moyasar.init({
        fixed_width: false,
        element: ".mysr-form",
        amount: amountToBePaid * 100,
        currency: "SAR",
        description: paymentId,
        publishable_api_key: api,
        callback_url: `${window.location.href}?func=${homeFunc}`,

        language: i18n.language,

        methods: ["creditcard"],
        credit_card: {
          save_card: "true",
        },
        on_completed: function (payment) {
          //consloe.log("this is the payment from on_completed:>", payment);

          onClose();
        },
        on_failure: function (error) {
          //consloe.log(error);
        },
        metadata: {
          company_id: companyId,
        },
      });
    }
  }, [moyasarInitialized, enteredAmount]);

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-white rounded-lg p-6 w-full max-sm:w-[calc(100%-20px)] max-w-md">
        <div className="flex items-center justify-between mb-4">
          <h2 className="text-xl font-bold">{t("payment")}</h2>
          <button
            onClick={onClose}
            className="text-gray-500 hover:text-gray-700"
          >
            <ExitIcon />
          </button>
        </div>

        <div className="flex items-start self-stretch gap-4 mb-4">
          <div className="flex flex-col items-start justify-center flex-1">
            <p className="text-[#1a1a1a] text-[16px] font-normal leading-6">
              {t("toBePaid")}
            </p>
            <div className="flex items-baseline self-stretch gap-1">
              <p className="text-[#1a1a1a] text-[24px] font-extrabold leading-8">
                {formatNumber(amountToBePaid, true)}
              </p>
              <p className="text-[#1a1a1a] text-[14px] font-normal leading-8">
                {t("currency")}
              </p>
            </div>
          </div>
        </div>
        <div className="mb-4 mysr-form"></div>
      </div>
    </div>
  );
};

export default PaymentWithDeposit;
