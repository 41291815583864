import React, { useState } from "react";
import DebitIcon from "../components/Icons/DebitIcon";
import CreditIcon from "../components/Icons/CreditIcon";
import CopyIcon from "../components/Icons/CopyIcon";
import { useTranslation } from "react-i18next";
import { formatNumber } from "../utils/formatNumber";

// Utility function to mask the visa number with exactly five asterisks before the last four digits
const maskVisaNumber = (visaNumber: string) => {
  const length = visaNumber.length;
  if (length > 4) {
    const stars = "*".repeat(5);
    const visibleDigits = visaNumber.slice(-4);
    return `${stars}${visibleDigits}`;
  }
  return visaNumber; // If visaNumber has 4 or fewer characters, return it as is
};

// Utility function to format the date to "Today," "1 day ago," or "x days ago"
const formatDate = (date: Date, t: any) => {
  const now = new Date();
  const differenceInTime = now.getTime() - date.getTime();
  const differenceInMinutes = Math.floor(differenceInTime / (1000 * 60));
  const differenceInHours = Math.floor(differenceInMinutes / 60);
  const differenceInDays = Math.floor(differenceInTime / (1000 * 3600 * 24));

  if (differenceInDays === 0) {
    // If the difference is less than 60 minutes, show minutes ago
    if (differenceInMinutes < 60) {
      return t("transactionCard.minutesAgo", { count: differenceInMinutes });
    }
    // If the difference is less than 24 hours, show hours ago
    if (differenceInHours < 24) {
      return t("transactionCard.hoursAgo", { count: differenceInHours });
    }
    return t("transactionCard.today");
  } else if (differenceInDays === 1) {
    return t("transactionCard.dayAgo");
  } else {
    return t("transactionCard.daysAgo", { count: differenceInDays });
  }
};

interface TransactionCardProps {
  InstallmentNumber: string;
  orderNumber: string;
  id: string;
  type: number;
  amount: number;
  date: Date;
  visaNumber: string;
  currency: string;
}

const TransactionCard: React.FC<TransactionCardProps> = ({
  type,
  amount,
  date,
  InstallmentNumber,
  id,
  orderNumber,
  visaNumber,
  currency = "SAR",
}) => {
  const { t } = useTranslation();
  const [copied, setCopied] = useState(false);
  // const [showAmountOnMobile, setShowAmountOnMobile] = useState(false);

  const handleCopy = () => {
    navigator.clipboard.writeText(id);
    setCopied(true); // Set copied to true
    //consloe.log(t("copySuccessMessage"));

    // Reset copied state after 2 seconds
    setTimeout(() => {
      setCopied(false);
    }, 2000);
  };

  // const handleCardClick = () => {
  // setShowAmountOnMobile(!showAmountOnMobile);
  // };

  // Render the order number with gradient text if it's a debit transaction

  const orderNumberStyled =
    type !== 0 && orderNumber ? (
      <span className="bg-gradient-to-r from-[#EF6B2A] to-[#F1BC62] bg-clip-text text-transparent font-almarai text-[16px] max-sm:text-[12px] font-bold leading-[24px]">
        #{orderNumber}
      </span>
    ) : null;

  // Determine the transaction type message
  const transactionMessage =
    type === 0
      ? t("transactionCard.creditMessage", {
          visaNumber: maskVisaNumber(visaNumber),
        })
      : t("transactionCard.debitMessage", {
          installmentNumber: InstallmentNumber,
        });

  return (
    <div
      className="flex p-4 items-start gap-3 self-stretch bg-[#1f1f1f] rounded-[16px] w-full md:max-h-[96px]"
      // onClick={handleCardClick}
    >
      <div
        style={{
          background:
            type === 0
              ? "linear-gradient(90deg, rgba(0, 142, 69, 0.12) 0%, rgba(5, 93, 25, 0.12) 100%)"
              : "linear-gradient(90deg, rgba(248, 61, 92, 0.12) 0%, rgba(253, 75, 47, 0.12) 100%)",
        }}
        className="w-[48px] h-[48px] flex p-[13.714px] justify-end items-start self-center max-sm:self-start gap-[6.857px] rounded-full"
      >
        {type === 0 ? <CreditIcon /> : <DebitIcon />}
      </div>
      <div className="flex items-start w-full">
        <div className="flex flex-col items-start justify-end flex-1 max-sm:gap-2">
          <div className="flex items-center gap-2">
            <p className="text-white text-[16px] max-sm:text-[12px]  font-bold leading-6">
              {transactionMessage}
              {type !== 0 && orderNumber && orderNumberStyled}
            </p>
          </div>
          <div className="flex px-2 py-1 justify-center items-center gap-2 rounded-[8px] bg-[#000] bg-opacity-[0.32]">
            <p className="font-bold text-white text-opacity-60 leading-[20px] text-[14px]">
              <div className="flex gap-2 ">
                <div className="max-sm:w-[137px] max-sm:text-nowrap max-sm:overflow-hidden max-sm:text-[12px] max-sm:text-ellipsis">
                  {id}
                </div>
                <div
                  className="relative flex items-center justify-center cursor-pointer"
                  onClick={handleCopy}
                >
                  {copied && (
                    <div
                      className="absolute top-[-20px] right-[-10px] md:px-2 md:py-1 bg-black text-white rounded-[8px] text-[12px] whitespace-nowrap"
                      style={{ transform: "translateY(-50%)" }}
                    >
                      {t("Copied")}
                    </div>
                  )}
                  <CopyIcon className="max-sm:w-[16px] max-sm:h-[16px]" />
                </div>
              </div>
            </p>
          </div>
        </div>
        <div
          className={`flex md:min-w-[190px] flex-col justify-center items-end gap-[6px] 
            `}
        >
          {/* ${
          showAmountOnMobile ? "max-sm:flex" : "max-sm:hidden"
        } */}
          <div className="flex flex-row items-start justify-center gap-1 max-sm:justify-start max-sm:flex-wrap">
            <p className="text-white text-[24px] font-extrabold leading-6 max-sm:text-[18px] text-ellipsis overflow-hidden max-sm:max-w-[60px]">
              {formatNumber(amount, true)}
            </p>
            <p className="text-white text-[14px] font-normal leading-8 max-sm:text-[12px]">
              {t(`transactionCard.currency.${currency}`)}
            </p>
          </div>
          <p className="text-[#8A8A8A] text-[14px] leading-[18px] font-normal">
            {formatDate(date, t)}
          </p>
        </div>
      </div>
    </div>
  );
};

export default TransactionCard;
