import React from "react";

const Ineligble = () => {
  return (
    <div>
      <h2 className="text-white text-3xl my-1">Sorry, Try Again Later!</h2>
      <p className="text-white my-1 text-center">
        Check your Credit Score below.
      </p>
      <img
        loading="lazy"
        src="./assets/inelig.svg"
        alt="eligible"
        className="mx-auto w-40 h-40 md:w-60 md:h-60"
      />
    </div>
  );
};

export default Ineligble;
