import React from "react";
import RejectedCreditCard from "../cards/RejectedCreditCard";
import DashboardCard from "../cards/DashboardCard";
import SmallDashboardCard from "../cards/SmallDashboardCard";
import DashboardGlobalCardLoading from "./DashboardGlobalCardLoading";

function DashboardGlobalCard({
  isLargeScreen,
  companyStatus,
  companyName,
  dashboardData,
  loadingDashboardData,
  loadingCompanyData,
}) {
  if (loadingDashboardData) return <DashboardGlobalCardLoading />;
  return isLargeScreen ? (
    companyStatus !== 2 || companyStatus === undefined ? (
      <RejectedCreditCard
        companyName={companyName}
        className="w-full"
        creditData={dashboardData?.creditRecord}
      />
    ) : (
      <DashboardCard
        companyName={companyName}
        creditRecord={dashboardData?.creditRecord}
      />
    )
  ) : companyStatus !== 2 || companyStatus === undefined ? (
    <RejectedCreditCard
      companyName={companyName}
      className="w-full"
      creditData={dashboardData?.creditRecord}
    />
  ) : (
    <SmallDashboardCard
      companyName={companyName}
      creditData={dashboardData?.creditRecord}
    />
  );
}

export default DashboardGlobalCard;
