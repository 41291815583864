import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import CopyIcon from "../Icons/CopyIcon";

interface OrderIdCardProps {
  id: string;
  textSize?: string;
  titleSize?: string;
}

const OrderIdCard: React.FC<OrderIdCardProps> = ({
  id,
  textSize,
  titleSize,
}) => {
  const { t } = useTranslation();
  const [copied, setCopied] = useState(false);
  const handleCopyClick = () => {
    navigator.clipboard.writeText(id);
    setCopied(true); // Set copied to true
    //consloe.log(t("copySuccessMessage"));

    // Reset copied state after 2 seconds
    setTimeout(() => {
      setCopied(false);
    }, 2000);
  };

  return (
    <div
      style={{
        background:
          "linear-gradient(129deg, rgba(239, 107, 42, 0.00) 0%, rgba(241, 188, 98, 0.60) 100%)",
      }}
      className="flex  px-4 py-2 justify-center max-sm:justify-between items-center gap-2 self-stretch rounded-[12px] border border-dashed border-[#EF6B2A]"
    >
      <div className="max-sm:flex-col flex flex-row text-[16px] font-bold leading-8 text-nowrap md:gap-2">
        <span className={`${titleSize} gradient-text`}>
          {t("orderIdLabel")}
        </span>
        <span className={` ${textSize} text-white`}>{id}</span>
      </div>
      <div className="relative flex items-center" onClick={handleCopyClick}>
        <CopyIcon className="copy-icon cursor-copy" />
        {copied && (
          <div
            className="absolute top-[-20px] right-[-10px] md:px-2 md:py-1 bg-black text-white rounded-[8px] text-[12px] whitespace-nowrap"
            style={{ transform: "translateY(-50%)" }}
          >
            {t("Copied")}
          </div>
        )}
      </div>
    </div>
  );
};

export default OrderIdCard;
