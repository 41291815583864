import React, { useEffect, useState } from "react";
import { useFormikContext } from "formik";
import useLocalStorage from "../../hooks/useLocalStorage";
import { BusinessPremisesType, SalesChannelType } from "../../constants";
import { useTranslation } from "react-i18next";
import sectorList from "../../types/sectorList";
import Toast, { IToast } from "../Shared/Toast";
import InputField from "../Forms/InputField";
import RadioButtonGroup from "../Forms/RadioGroup";
import Dropdown from "../Forms/Dropdown";
import LoadingIcon from "../Icons/LoadingIcon";
import NewInputField from "../Forms/NewInputField";

interface ICompanyDetails {
  isSaudiCompany: boolean;
  businessPremises: BusinessPremisesType;
  isAudited: boolean;
  yearsInBusiness: number;
  monthsInBusiness: number;
  sector: string;
  productOrServiceProvided: string;
  headcount: number | string;
  salesChannel: SalesChannelType | null;
  monthlyRent: number | string;
  annualRevenue: number | string;
  numberOfClients: number | string;
}

const BusinessInformationSignIn = ({ step, setStep, onSubmit, loading }) => {
  const { t } = useTranslation();

  const { values, errors, touched, setFieldValue, validateField, setTouched } =
    useFormikContext<ICompanyDetails>();
  const { setValue: setOrgValue, getValue: getOrgValue } =
    useLocalStorage<Organization>();

  const [currentOpenDropdown, setCurrentOpenDropdown] = useState<string | null>(
    null
  );
  const [isSaudiCompany, setIsSaudiCompany] = useState<boolean>(true);
  const [isBusinessPremisesTypeRented, setIsBusinessPremisesTypeRented] =
    useState<boolean>(false);
  const [isExternalAudited, setIsExternalAudited] = useState<boolean>(true);
  const [toast, setToast] = useState<IToast | null>(null);
  const { getValue: getReqId } = useLocalStorage<string>();
  const { getValue: getUserId } = useLocalStorage<string>();
  const { getValue: getCrNumber } = useLocalStorage<string>();
  const [selectedSector, setSelectedSector] = useState<string>("Select Sector");
  const [selectedYears, setSelectedYears] = useState<number | null>(null);
  const [selectedMonths, setSelectedMonths] = useState<number | null>(null);
  const [selectedSalesChannel, setSelectedSalesChannel] =
    useState<SalesChannelType | null>(null);

  const org = getOrgValue("orgData") || ({} as Organization);

  useEffect(() => {
    setOrgValue("orgData", {
      ...org,
      isSaudiCompany: true,
      businessPremises: BusinessPremisesType.Owned,
      isAudited: true,
    });
  }, []);

  const handleSelect = (value: string | number, type: "years" | "months") => {
    if (typeof value === "number") {
      handleItemClick(value, type);
    }
  };

  const handleItemClick = (value: number, type: "years" | "months") => {
    if (type === "years") {
      setSelectedYears(value);
      setFieldValue("yearsInBusiness", value);
    } else if (type === "months") {
      setSelectedMonths(value);
      setFieldValue("monthsInBusiness", value);
    }
    // Close the dropdown after selection
    setTimeout(() => setCurrentOpenDropdown(null), 100);
  };

  const toggleDropdown = (dropdown: string) => {
    setCurrentOpenDropdown((prev) => (prev === dropdown ? null : dropdown));
  };

  const handleSectorItemClick = (item: string) => {
    setSelectedSector(item);
    setFieldValue("sector", item);
    setCurrentOpenDropdown(null); // Close the dropdown after selection
  };

  const handleProductServiceChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const item = event.target.value;
    setFieldValue("productOrServiceProvided", item);
  };
  const handleHeadCountChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const item = event.target.value;
    setFieldValue("headcount", item);
  };

  const handleSalesChannelItemClick = (item: SalesChannelType) => {
    setSelectedSalesChannel(item);
    setFieldValue("salesChannel", item);
    setCurrentOpenDropdown(null); // Close the dropdown after selection
  };

  const handleInputChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >
  ) => {
    const { name, value } = e.target;

    if (name === "isSaudiCompany") {
      const isSaudi = value === "true";
      setIsSaudiCompany(isSaudi);
      setFieldValue(name, isSaudi);
      return;
    }

    if (name === "businessPremises") {
      const isRented = value === "true";
      setIsBusinessPremisesTypeRented(isRented);
      setFieldValue(
        name,
        isRented ? BusinessPremisesType.Rent : BusinessPremisesType.Owned
      );
      return;
    }

    if (name === "isAudited") {
      const isAudited = value === "true";
      setIsExternalAudited(isAudited);
      setFieldValue(name, isAudited);
      return;
    }

    setFieldValue(name, value);
  };

  const handleNext = async () => {
    const stepFields = [
      "yearsInBusiness",
      "monthsInBusiness",
      "sector",
      "productOrServiceProvided",
      "headcount",
      "salesChannel",
      "monthlyRent",
      "annualRevenue",
      "isAudited",
      "numberOfClients",
    ];

    // Custom validation for years and months
    if (values.yearsInBusiness === 0 && values.monthsInBusiness === 0) {
      setToast({
        type: "error",
        messageKey: "czeroboth",
      });
      return;
    }
    if (
      values.yearsInBusiness === 0 &&
      values.monthsInBusiness > 0 &&
      values.monthsInBusiness <= 6
    ) {
      setToast({
        type: "error",
        messageKey: "monthsissue",
      });
      return;
    }

    await Promise.all(stepFields.map((field) => validateField(field)));

    const currentErrors = stepFields.reduce((acc, field) => {
      if (errors[field]) {
        acc[field] = errors[field];
      }
      return acc;
    }, {});

    const isStepValid = Object.keys(currentErrors).length === 0;
    //consloe.log("Form Values before next step:", values);

    if (isStepValid) {
      setStep(step + 1); // Move to the next step
    } else {
      setTouched({
        yearsInBusiness: true,
        monthsInBusiness: true,
        sector: true,
        productOrServiceProvided: true,
        headcount: true,
        salesChannel: true,
        monthlyRent: true,
        annualRevenue: true,
        isAudited: true,
        numberOfClients: true,
      });
      setToast({
        type: "error",
        titleKey: "Error",
        messageKey: "regFail",
      });
    }
  };

  const SalesChannelTypeLabels = {
    [SalesChannelType.Online]: t("online"),
    [SalesChannelType.Offline]: t("offline"),
    [SalesChannelType.Both]: t("both"),
  };

  const yearsOptions = Array.from({ length: 100 }, (_, index) => ({
    value: index,
    label: `${index} ${index === 0 || index === 1 ? t("year") : t("years")}`,
  }));
  const monthOptions = Array.from({ length: 12 }, (_, index) => ({
    value: index,
    label: `${index} ${index === 0 || index === 1 ? t("month") : t("months")}`,
  }));

  return (
    <section className="flex flex-col gap-8 rounded-2xl max-md:px-5 max-md:max-w-full">
      <div className="flex flex-col gap-8">
        <div className="grid grid-cols-1 gap-4 md:grid-cols-2 cols-1-ipad">
          <div className="flex flex-col gap-1">
            <span className="flex gap-1">
              <label className="text-white text-opacity-60">
                {t("yearsInBusiness")}
                <span className="text-[#EF6B2A] font-[800] leading-6 text-[14px]">
                  {` *`}
                </span>
              </label>
            </span>
            <div className="flex gap-4">
              <Dropdown
                name="yearsInBusiness"
                placeholder={t("years")}
                value={selectedYears !== null ? selectedYears : undefined}
                options={yearsOptions}
                onSelect={(value) => handleSelect(value, "years")}
                isOpen={currentOpenDropdown === "yearsInBusiness"}
                toggleDropdown={() => toggleDropdown("yearsInBusiness")}
              />
              <Dropdown
                name="monthsInBusiness"
                placeholder={t("months")}
                value={selectedMonths !== null ? selectedMonths : undefined}
                options={monthOptions}
                onSelect={(value) => handleSelect(value, "months")}
                isOpen={currentOpenDropdown === "monthsInBusiness"}
                toggleDropdown={() => toggleDropdown("monthsInBusiness")}
              />
            </div>
          </div>
          <div className="flex flex-col">
            <RadioButtonGroup
              label={t("isSaudiCompany")}
              mandStar={true}
              name="isSaudiCompany"
              options={[
                { label: t("yes"), value: true },
                { label: t("no"), value: false },
              ]}
              selectedValue={values.isSaudiCompany}
              onChange={handleInputChange}
            />
          </div>
        </div>

        <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
          <Dropdown
            name="sector"
            placeholder={t("selectSector")}
            mandStar={true}
            label={t("sectorLabel")}
            value={values.sector}
            options={sectorList.map((sector) => ({
              value: sector.value,
              label: t(sector.labelKey),
            }))}
            onSelect={(value) => setFieldValue("sector", value)}
            isOpen={currentOpenDropdown === "sector"}
            toggleDropdown={() => toggleDropdown("sector")}
          />

          <InputField
            mandStar={true}
            id="productOrServiceProvided"
            label={t("productOrServiceProvided")}
            placeholder={t("enterProductOrService")}
            type="text"
            onChange={handleProductServiceChange}
            name="productOrServiceProvided"
          />
        </div>

        <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
          <NewInputField
            mandStar={true}
            id="headcount"
            label={t("headcount")}
            placeholder={t("enterMemberCount")}
            type="number"
            onChange={handleHeadCountChange}
            name="headcount"
            formatNumber
          />
          <Dropdown
            placeholder={t("selectType")}
            mandStar={true}
            label={t("salesChannelType")}
            value={values.salesChannel}
            options={Object.keys(SalesChannelType)
              .filter((key) => isNaN(Number(key)))
              .map((key) => ({
                value: SalesChannelType[key as keyof typeof SalesChannelType],
                label:
                  SalesChannelTypeLabels[
                    SalesChannelType[key as keyof typeof SalesChannelType]
                  ],
              }))}
            onSelect={(value) => setFieldValue("salesChannel", value)}
            name="salesChannel"
            isOpen={currentOpenDropdown === "salesChannel"}
            toggleDropdown={() => toggleDropdown("salesChannel")}
          />
        </div>

        <div className="grid grid-cols-1 gap-4 md:grid-cols-2 cols-1-ipad">
          <div className="flex flex-col items-start justify-start max-md:pr-5 max-md:max-w-full">
            <div className="flex flex-col w-full max-w-full">
              <fieldset>
                <div className="flex flex-col w-full max-w-full">
                  <div className="flex flex-col">
                    <RadioButtonGroup
                      label={t("businessPremisesType")}
                      mandStar={true}
                      name="businessPremises"
                      options={[
                        { label: t("rented"), value: true },
                        { label: t("owned"), value: false },
                      ]}
                      selectedValue={
                        values.businessPremises === BusinessPremisesType.Rent
                      }
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
              </fieldset>
            </div>
          </div>
          <NewInputField
            mandStar={true}
            id="monthlyRent"
            label={t("monthlyRentForBranch")}
            placeholder={t("enterMonthlyRent")}
            type="number"
            onChange={handleInputChange}
            name="monthlyRent"
            inputClassName={`${
              values.businessPremises !== BusinessPremisesType.Rent
                ? "inputDisapled"
                : ""
            }`}
            disabled={values.businessPremises !== BusinessPremisesType.Rent}
            formatNumber
          />
        </div>

        <div className="grid grid-cols-1 gap-4 md:grid-cols-2 cols-1-ipad">
          <NewInputField
            mandStar={true}
            id="annualRevenue"
            label={t("annualRevenue")}
            placeholder={t("enterAnnualRevenue")}
            type="number"
            onChange={handleInputChange}
            name="annualRevenue"
            formatNumber
          />

          <div className="flex flex-col">
            <fieldset>
              <div className="flex flex-col">
                <RadioButtonGroup
                  label={t("isExternallyAudited")}
                  mandStar={true}
                  name="isAudited"
                  options={[
                    { label: t("yes"), value: true },
                    { label: t("no"), value: false },
                  ]}
                  selectedValue={values.isAudited}
                  onChange={handleInputChange}
                />
              </div>
            </fieldset>
          </div>
        </div>

        <NewInputField
          mandStar={true}
          id="numberOfClients"
          label={t("numberOfClients")}
          onChange={handleInputChange}
          name="numberOfClients"
          type="number"
          placeholder={t("enterNumberOfClients")}
          value={values.numberOfClients as string}
          formatNumber
        />
      </div>
      <div className="flex items-center justify-center">
        <div className="flex items-center justify-between gap-4 max-sm:flex-col max-sm:w-full">
          <button
            onClick={() => {
              setStep(step - 1);
            }}
            className="flex px-6 py-3 border border-solid border-[#EF6B2A] gradient-text rounded-3xl max-sm:w-full items-center justify-center"
          >
            <span
              style={{
                background: "linear-gradient(129deg, #EF6B2A 0%, #F1BC62 100%)",
                backgroundClip: "text",
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent",
              }}
              className="flex items-center gap-2 justify-center text-center text-[16px] font-bold leading-6"
            >
              {t("back")}
            </span>
          </button>
          <button
            style={{
              background: "linear-gradient(129deg, #EF6B2A 0%, #F1BC62 100%)",
            }}
            type="button"
            onClick={handleNext}
            className="sm:!w-auto flex items-center justify-center gap-2 md:w-[300px] px-6 py-3 text-white rounded-[24px] max-w-[300px] max-sm:w-full "
          >
            <span className="flex items-center justify-center text-center text-[16px] font-bold leading-6">
              {t("next")}
            </span>
          </button>
        </div>
        {toast && (
          <Toast
            onClose={() => setToast(null)}
            titleKey={
              toast.type === "success"
                ? t("toastCongratulationsTitle")
                : t("toastTryAgainTitle")
            }
            type={toast.type}
            messageKey={toast.messageKey}
          />
        )}
      </div>
    </section>
  );
};

export default BusinessInformationSignIn;
