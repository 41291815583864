import React, { useState } from "react";
import { Formik, Form, FormikProps } from "formik";
import * as Yup from "yup";
import BasicDetails from "./BasicDetails";
import BusinessInformation from "./BusinessInformation";
import InfoSection from "../../info/InfoSection";
import WhiteDocumentIcon from "../../Icons/WhiteDocumentIcon";
import { useTranslation } from "react-i18next";
import DocComponent from "../DocComponent";
import useApi from "../../../hooks/useApi";
import Button from "../../Buttons/Button";
import { t } from "i18next";
import Toast, { IToast } from "../../Shared/Toast";
import { BusinessPremisesType } from "../../../constants";

const isValidSaudiNumber = (phone: string) => {
  const cleanPhone = phone.replace(/\D/g, "");
  //consloe.log("Validating phone number in Yup schema:", cleanPhone);

  const validPrefix = cleanPhone.startsWith("966");
  const validLength = cleanPhone.length === 12;

  //consloe.log("Valid prefix:", validPrefix);
  //consloe.log("Valid length:", validLength);

  return validPrefix && validLength;
};

const validationSchema = Yup.object({
  name: Yup.string(),
  crNumber: Yup.string(),
  website: Yup.string().url("Invalid URL"),
  email: Yup.string()
    .email("Invalid email")
    .required("validationErrors.fieldRequired"),
  phone: Yup.string()
    .test(
      "is-valid-saudi-number",
      "validationErrors.invalidPhoneNumber",
      function (value) {
        if (!value) return true; // Allow empty values if the field is not required
        const phoneWithPrefix = value.startsWith("966") ? value : `966${value}`;
        return isValidSaudiNumber(phoneWithPrefix);
      }
    )
    .required("validationErrors.phoneRequired"),
  type: Yup.string(),
  yearsInBusiness: Yup.number(),
  monthsInBusiness: Yup.number(),
  isSaudiCompany: Yup.boolean(),
  sector: Yup.string(),
  productOrServiceProvided: Yup.string().required(
    "validationErrors.fieldRequired"
  ),
  salesChannel: Yup.string(),
  annualRevenue: Yup.string()
    .min(1, "validationErrors.Annual revenue must be greater than 0")
    .required("validationErrors.fieldRequired"),
  monthlyRent: Yup.string().when("businessPremises", {
    is: (val: BusinessPremisesType) => val === BusinessPremisesType.Rent,
    then: (schema) =>
      schema
        .min(1, "validationErrors.Annual revenue must be greater than 0")
        .required("validationErrors.monthlyRentRequired"),
    otherwise: (schema) => schema.notRequired(),
  }),
  isAudited: Yup.boolean(),
  headcount: Yup.string()
    .min(1, "validationErrors.Headcount must be greater than 0")
    .required("validationErrors.fieldRequired"),
  businessPremisesType: Yup.string(),
  clientCount: Yup.string()
    .min(1, "validationErrors.Client count must be greater than 0")
    .required("validationErrors.fieldRequired"),
  stateOrProvince: Yup.string().required("validationErrors.fieldRequired"),
  city: Yup.string().required("validationErrors.fieldRequired"),
  postalCode: Yup.string().required("validationErrors.fieldRequired"),
  addressLine1: Yup.string().required("validationErrors.fieldRequired"),
  addressLine2: Yup.string().required("validationErrors.fieldRequired"),
});

const CompanyProfileForm: React.FC<{
  companyData: any;
  onSave: (data: any) => void;
  onCancel: () => void;
  isLoading?: boolean;
}> = ({ companyData, onSave, onCancel, isLoading }) => {
  const { t } = useTranslation();
  const { putData } = useApi<any>();
  const [toast, setToast] = useState<IToast | null>(null);
  const [hasDocumentChanges, setHasDocumentChanges] = useState(false);

  const documents = [
    {
      fieldName: "RentContractUri",
      deleteFieldName: "rentContractUri",
      uri: companyData.rentContractUri,
      title: t("documentsTitle.rentContract"),
      date: "2024-08-25",
    },
    {
      fieldName: "AuditedFinancialStatementsUri",
      deleteFieldName: "auditedFinancialStatementsUri",
      uri: companyData.auditedFinancialStatementsUri,
      title: t("documentsTitle.auditedFinancialStatements"),
      date: "2024-08-25",
    },
    {
      fieldName: "ZakatAndTaxCertificateUri",
      deleteFieldName: "zakatAndTaxCertificateUri",
      uri: companyData.zakatAndTaxCertificateUri,
      title: t("documentsTitle.zakatAndTaxCertificate"),
      date: "2024-08-25",
    },
  ];
  const handleDocumentDelete = async (field: string, setFieldValue: any) => {
    //consloe.log(`Deleting document: ${field}`);

    try {
      const updatedData = { ...companyData, [field]: null };
      //consloe.log("Updated data to be sent:", updatedData);

      const response = await putData(`/company`, updatedData);

      if (response) {
        setFieldValue(field, null);
        //consloe.log("Updated form values after deletion:", field, null);
      } else {
        console.error("Failed to delete document");
      }
    } catch (error) {
      console.error("Error deleting document:", error);
    }
  };

  function lowercaseFirstLetter(str) {
    if (!str) return str;
    return str.charAt(0).toLowerCase() + str.slice(1);
  }

  return (
    <Formik
      initialValues={companyData}
      validationSchema={validationSchema}
      onSubmit={(values, actions) => {
        // Create a copy of values to modify
        const processedValues = { ...values };

        // Find all input fields with formatNumber={true} in the form
        const formElements = document.querySelectorAll(
          'input[data-format-number="true"]'
        );

        formElements.forEach((element) => {
          const fieldName = element.getAttribute("name");
          if (fieldName && processedValues[fieldName]) {
            // Convert comma-formatted string to number
            processedValues[fieldName] = Number(
              processedValues[fieldName].toString().replace(/,/g, "")
            );
          }
        });

        if (processedValues.phone) {
          const cleanPhone = processedValues.phone.replace(/^\+966/, "");
          processedValues.phone = `+966${cleanPhone}`;
        }

        if (
          processedValues.yearsInBusiness === 0 &&
          processedValues.monthsInBusiness === 0
        ) {
          setToast({
            type: "error",
            titleKey: "Error",
            messageKey: "monthsissue",
          });
          return;
        }

        if (
          processedValues.yearsInBusiness === 0 &&
          processedValues.monthsInBusiness > 0 &&
          processedValues.monthsInBusiness <= 6
        ) {
          setToast({
            type: "error",
            titleKey: "Error",
            messageKey: "monthsissue",
          });
          return;
        }

        //consloe.log("Form submitted with values:", processedValues);
        onSave(processedValues);
      }}
    >
      {({
        values,
        setFieldValue,
        handleSubmit,
        dirty,
        errors,
        touched,
      }: FormikProps<any>) => {
        const isPhoneValid = !errors.phone || !touched.phone;
        const isSaveDisabled =
          isLoading || (!dirty && !hasDocumentChanges) || !isPhoneValid;

        return (
          <Form className="flex flex-col gap-6">
            <div className="flex flex-col justify-between gap-4 sm:flex-row sm:items-center sm:justify-between">
              <h1 className="text-white opacity-[0.87] text-[24px] font-bold leading-[40px]">
                {t("companyProfile")}
              </h1>
              <div className="grid grid-cols-2 gap-2 sm:gap-4 ">
                <button
                  type="button"
                  className="flex items-center justify-center gap-[6px] bg-gradient-to-b from-[#EF6B2A] to-[#F1BC62] text-center text-transparent font-bold text-[16px] leading-[24px] py-2 px-6 rounded-full hover:bg-orange-700 border-[2px] border-[#EF6B2A] bg-clip-text w-full"
                  onClick={onCancel}
                  style={{
                    WebkitBackgroundClip: "text",
                    WebkitTextFillColor: "transparent",
                  }}
                >
                  {t("cancel")}
                </button>
                <Button
                  loading={isLoading}
                  disabled={isSaveDisabled}
                  label={t("saveChanges")}
                  onClick={() => {
                    if (!isSaveDisabled) {
                      handleSubmit();
                    }
                  }}
                  className={`text-nowrap flex items-center justify-center gap-[6px] bg-gradient-to-b from-[#EF6B2A] to-[#F1BC62] text-white font-bold text-[16px] leading-[24px] py-2 px-6 rounded-full hover:bg-orange-700 w-full ${
                    isSaveDisabled ? "opacity-50 cursor-not-allowed" : ""
                  }`}
                ></Button>
              </div>
            </div>

            <div className="flex flex-col gap-6">
              <BasicDetails />
              <BusinessInformation />
            </div>

            <InfoSection
              icon={<WhiteDocumentIcon />}
              title={t("companyDocuments")}
            >
              <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
                {documents.map((doc: any) => (
                  <DocComponent
                    key={doc.fieldName}
                    className="col-span-1"
                    title={doc.title}
                    uri={doc.uri}
                    fieldName={doc.fieldName}
                    isEdit={true}
                    // onEdit={() => //consloe.log("Edit document:", doc.uri)}
                    onDelete={() => {
                      handleDocumentDelete(doc.deleteFieldName, setFieldValue);
                      setHasDocumentChanges(true);
                    }}
                    onUploadComplete={(field, uri) => {
                      // consloe.log(
                      //   "Upload complete for field:",
                      //   lowercaseFirstLetter(field)
                      // );
                      //consloe.log("URI:", uri);
                      setFieldValue(lowercaseFirstLetter(field), uri);
                      setHasDocumentChanges(true);
                    }}
                  />
                ))}
              </div>
            </InfoSection>
            {toast && (
              <Toast
                type={toast.type}
                titleKey={toast.titleKey}
                messageKey={toast.messageKey}
                onClose={() => setToast(null)}
              />
            )}
          </Form>
        );
      }}
    </Formik>
  );
};

export default CompanyProfileForm;
