import React from "react";

interface CreditOrderGoldIconProps {
  className?: string;
  width?: string | number;
  height?: string | number;
}

const CreditOrderGoldIcon: React.FC<CreditOrderGoldIconProps> = ({
  className,
  width = 24,
  height = 24,
}) => (
  <svg
    width={width}
    height={height}
    className={className}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Frame">
      <g id="Vector">
        <path
          d="M19.3127 17.0626H17.1564C16.6387 17.0626 16.2189 16.6429 16.2189 16.1251V15.0002C16.2189 14.4824 16.6387 14.0627 17.1564 14.0627H19.3127C19.8305 14.0627 20.2502 14.4824 20.2502 15.0002V16.1251C20.2502 16.6429 19.8305 17.0626 19.3127 17.0626Z"
          fill="url(#paint0_linear_3417_20793)"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M0.137695 17.0568C0.137695 19.052 1.75508 20.6693 3.75019 20.6693H20.2501C22.2452 20.6693 23.8626 19.052 23.8626 17.0568V6.93183C23.8626 4.93671 22.2453 3.31934 20.2501 3.31934H3.75019C1.75507 3.31934 0.137695 4.93671 0.137695 6.93183V17.0568ZM3.75019 19.0693C2.63871 19.0693 1.7377 18.1683 1.7377 17.0568V6.93183C1.7377 5.82036 2.63872 4.91933 3.75019 4.91933H20.2501C21.3616 4.91933 22.2626 5.82036 22.2626 6.93183V17.0568C22.2626 18.1683 21.3616 19.0693 20.2501 19.0693H3.75019Z"
          fill="url(#paint1_linear_3417_20793)"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M0.606445 8.62515C0.606445 9.06697 0.964617 9.42515 1.40645 9.42515H22.2658C22.7076 9.42515 23.0658 9.06697 23.0658 8.62515C23.0658 8.18332 22.7076 7.82515 22.2658 7.82515H1.40645C0.964617 7.82515 0.606445 8.18332 0.606445 8.62515Z"
          fill="url(#paint2_linear_3417_20793)"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M3.8877 12.3751C3.8877 12.817 4.24587 13.1751 4.6877 13.1751H12.422C12.8639 13.1751 13.222 12.817 13.222 12.3751C13.222 11.9333 12.8639 11.5751 12.422 11.5751H4.6877C4.24587 11.5751 3.8877 11.9333 3.8877 12.3751Z"
          fill="url(#paint3_linear_3417_20793)"
        />
      </g>
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_3417_20793"
        x1="0.137695"
        y1="3.31934"
        x2="19.3065"
        y2="24.7454"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#EF6B2A" />
        <stop offset="1" stop-color="#F1BC62" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_3417_20793"
        x1="0.137695"
        y1="3.31934"
        x2="19.3065"
        y2="24.7454"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#EF6B2A" />
        <stop offset="1" stop-color="#F1BC62" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_3417_20793"
        x1="0.137695"
        y1="3.31934"
        x2="19.3065"
        y2="24.7454"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#EF6B2A" />
        <stop offset="1" stop-color="#F1BC62" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_3417_20793"
        x1="0.137695"
        y1="3.31934"
        x2="19.3065"
        y2="24.7454"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#EF6B2A" />
        <stop offset="1" stop-color="#F1BC62" />
      </linearGradient>
    </defs>
  </svg>
);

export default CreditOrderGoldIcon;
