import React, { useEffect, useState } from "react";
import WalletCard from "./WalletCard";
import ExitIcon from "../Icons/ExitIcon";
import useApi from "../../hooks/useApi";
import useLocalStorage from "../../hooks/useLocalStorage";
import PaymentOtp from "./PaymentOtp";
import PaymentWithDeposit from "./PaymentWithDeposit";
import { useTranslation } from "react-i18next";
import { formatNumber } from "../../utils/formatNumber";
import DebitIcon from "../Icons/DebitIcon";
import CreditCardIcon from "../Icons/CreditCardIcon";
import CustomCheckbox from "./CustomCheckbox";
import WalletIcon from "../Icons/WalletIcon";
import Loader from "../Shared/Spinner";

enum PaymentRequestType {
  WalletRecharge = 0,
  OutstandingInstallments,
  OutstandingCredit,
  DueInstallments,
  DueCredit,
  OrderInstallment,
  OrderPayment,
  renew,
}

interface PaymentData {
  requestId: string;
  companyId: string;
  walletId: string;
  apiKey: string;
  paymentRequestType: PaymentRequestType;
  amountRequested: number; // total
  canUseWalletBalance: boolean;
  canPayCustomAmount: boolean;
  isWalletRecharge: boolean;
  useWalletBalanceOnly: boolean;
  amountFromWallet: number; // come from wallet
  amountToBePaid: number; // come from another
}

interface PaymentModalProps {
  orderId?: string;
  paymentId?: string;
  paymentRequestType: PaymentRequestType;
  onClose?: () => void;
  func?: string;
}

const PaymentModal: React.FC<PaymentModalProps> = ({
  paymentId,
  orderId,
  paymentRequestType,
  onClose,
  func,
}) => {
  const { t } = useTranslation(); // Initialize useTranslation hook
  const [requestData, setRequestData] = useState<PaymentData>(null);

  // const [userCanPayCustomAmount, setUserCanPayCustomAmount] =
  //   useState<boolean>(true);
  // const [requestId, setRequestId] = useState<string>(null);
  // const [companyId, setCompanyId] = useState<string>(null);
  // const [canUseWalletBalance, setUserCanUseWalletBalance] =
  // useState<boolean>(true);
  const [amount, setAmount] = useState<number>(0);
  const [showOtp, setShowOtp] = useState<boolean>(false);
  const [showDeposit, setShowDeposit] = useState<boolean>(false);
  const { getValue } = useLocalStorage<string>();
  const { postData: paymentRequest, data: PaymentRequestData } =
    useApi<PaymentData>();
  const { putData: updatePaymentRequest, data: updatedPaymentRequestData } =
    useApi<PaymentData>();
  const { postData: requestOtp, data: OtpData } = useApi<void>();
  const [inputHidden, setInputHidden] = useState(false);
  const { getValue: getCompanyId } = useLocalStorage();
  const { getData: getWallet, data: wallet } = useApi<{ balance: number }>();
  useEffect(() => {
    const fetchPaymentRequest = async () => {
      try {
        const companyId = getValue("companyId");
        if (companyId) {
          const { data: response } = await paymentRequest(
            `/payments/${companyId}/request`,
            {
              paymentId,
              companyId,
              orderId,
              paymentRequestType,
            }
          );
          //consloe.log("this is response from paymentRequest:>", response);
          if (response) {
            setRequestData(response);
            sessionStorage.setItem("requestId", response.requestId);
          }
          if (response?.canPayCustomAmount === true) {
            setInputHidden(true);
          } else {
            setInputHidden(false);
          }
        }
      } catch (err) {
        console.error("Error fetching payment request:", err);
      }
    };

    const fetchWallet = async () => {
      const companyId = getCompanyId("companyId");
      await getWallet(`/wallet/${companyId}`);
    };

    fetchWallet();
    fetchPaymentRequest();
  }, []);

  const handleIgnoreWallet = async () => {
    try {
      const { data: response } = await updatePaymentRequest(
        `/payments/request`,
        {
          requestId: requestData.requestId,
          useWalletBalance: false,
          paymentRequestType: requestData.paymentRequestType,
          amountRequested: requestData.amountRequested,
        }
      );
      //consloe.log("this is response from update paymentRequest:>", response);

      if (response) {
        //consloe.log("this is the updated data:", response);
        setRequestData(response);
      }
    } catch (error) {
      console.error("error while updating:>>", error);
    }
  };
  const handleUseWalletBalance = async () => {
    try {
      const { data: response } = await updatePaymentRequest(
        `/payments/request`,
        {
          requestId: requestData.requestId,
          useWalletBalance: true,
          paymentRequestType: requestData.paymentRequestType,
          amountRequested: requestData.amountRequested,
        }
      );
      //consloe.log("this is response from update paymentRequest:>", response);

      if (response) {
        //consloe.log("this is the updated data:", response);
        setRequestData(response);
      }
    } catch (error) {
      console.error("error while updating:>>", error);
    }
  };

  const requestOtpAndShow = async () => {
    if (requestData) {
      const { requestId } = requestData;
      try {
        await requestOtp(`/payments/${requestId}/request-otp`, {
          requestId,
        });
        setShowOtp(true);
      } catch (error) {
        console.error("Error requesting OTP:", error);
      }
    }
  };

  const handlePayNow = async () => {
    if (requestData) {
      const { amountRequested, amountFromWallet } = requestData;
      if (amountRequested <= amountFromWallet) {
        await requestOtpAndShow(); // Handle OTP request and show
      } else {
        // Handle cases where amountRequested > amountFromWallet
        setShowDeposit(true);
      }
    }
  };

  if (showOtp) {
    return <PaymentOtp isOpen={showOtp} requestId={requestData.requestId} />;
  }
  if (showDeposit) {
    return (
      <PaymentWithDeposit
        api={requestData.apiKey}
        paymentId={requestData.companyId}
        companyId={requestData.companyId}
        amountFromWallet={requestData.amountFromWallet}
        amountToBePaid={requestData.amountToBePaid}
        onClose={onClose}
        homeFunc={func}
      />
    );
  }

  if (!requestData) return <Loader />;

  if (
    wallet?.balance > 0 &&
    wallet?.balance < requestData?.amountRequested &&
    requestData?.canUseWalletBalance
  ) {
    // update this canUseWalletBalance to be false
    handleIgnoreWallet();

    return (
      <div
        style={{ backdropFilter: "blur(18px)" }}
        className={`fixed inset-0 z-50 flex bg-[#000] bg-opacity-[0.24] items-center justify-center transition-opacity duration-300 ease-in-out shrink-0`}
      >
        <Loader />
      </div>
    );
  }
  return (
    <div
      style={{ backdropFilter: "blur(18px)" }}
      className={`fixed inset-0 z-50 flex bg-[#000] bg-opacity-[0.24] items-center justify-center transition-opacity duration-300 ease-in-out shrink-0`}
    >
      <div className="flex w-[600px] max-sm:w-[calc(100%-20px)] p-6 flex-col items-center gap-8 bg-[#FFF] rounded-[16px]">
        <div className="flex items-center gap-[10px] self-stretch">
          <div className="flex-1">
            <p className="text-[#1a1a1a] text-[24px] font-bold leading-8">
              {t("paymenting.title")}
            </p>
          </div>
          <button onClick={onClose}>
            <ExitIcon width={24} height={24} />
          </button>
        </div>
        <div className="flex flex-col items-start self-stretch justify-center">
          <div className="self-stretch">
            <p className="text-[#1a1a1a] text-[16px] font-normal leading-6">
              {t("paymenting.totalAmountToBePaid")}
            </p>
          </div>
          <div className="flex items-baseline gap-1">
            <p className="text-[#1a1a1a] text-[24px] font-extrabold leading-8">
              {formatNumber(requestData?.amountRequested, true)}
            </p>
            <p className="text-[#1a1a1a] text-[14px] font-normal leading-8">
              {t("currency")}
            </p>
          </div>
        </div>
        {inputHidden && (
          <div className="flex h-[76px] flex-col items-start gap-1 self-stretch">
            <div className="flex flex-col self-stretch justify-center h-6 shrink-0">
              <p className="text-[#8a8a8a] text-[14px] font-normal leading-6">
                {t("paymenting.enterAmountToPay")}
              </p>
            </div>
            {requestData && requestData.canPayCustomAmount ? (
              <input
                className="flex py-3 px-4 justify-center items-center gap-[6px] self-stretch rounded-[12px] border border-solid border-[#000] border-opacity-[0.16] bg-[#000] bg-opacity-[0.07] placeholder:flex-1 placeholder:text-[#000] placeholder:text-opacity-50 placeholder:font-normal placeholder:leading-6"
                value={requestData.amountToBePaid}
                onChange={(e) => setAmount(Number(e.target.value))}
              />
            ) : (
              <input
                className="flex py-3 px-4 justify-center items-center gap-[6px] self-stretch rounded-[12px] border border-solid border-[#000] border-opacity-[0.16] bg-[#000] bg-opacity-[0.07] placeholder:flex-1 placeholder:text-[#000] placeholder:text-opacity-50 placeholder:font-normal placeholder:leading-6"
                value={requestData.amountToBePaid}
                onChange={(e) => setAmount(Number(e.target.value))}
                disabled
              />
            )}
          </div>
        )}
        <div className="grid w-full grid-cols-2 gap-4 max-md:grid-cols-1">
          <CheckboxCard
            Icon={CreditCardIcon}
            checked={!requestData?.canUseWalletBalance}
            disabled={requestData?.amountToBePaid > wallet?.balance}
            onChange={(checked: boolean) => {
              if (checked) handleIgnoreWallet();
              else handleUseWalletBalance();
              //consloe.log("Checkbox is now:", checked);
            }}
          >
            <div className="gradient-text text-[16px] flex-1 font-normal">
              Pay with card
            </div>
          </CheckboxCard>
          {requestData?.canUseWalletBalance}
          <CheckboxCard
            Icon={WalletIcon}
            checked={requestData?.canUseWalletBalance}
            // disabled={
            //   wallet?.balance >= requestData.amountRequested &&
            //   requestData?.canUseWalletBalance
            // }
            hideCheckbox={requestData?.amountRequested > wallet?.balance}
            onChange={(checked: boolean) => {
              if (checked) handleUseWalletBalance();
              else handleIgnoreWallet();
              // //consloe.log("Checkbox is now:", checked);
            }}
          >
            <div className="flex flex-col items-start self-stretch flex-1 gap-1">
              <p
                className={`text-[16px] font-normal ${
                  requestData?.amountToBePaid < wallet?.balance
                    ? "gradient-text"
                    : "text-[#C72C41]"
                }`}
              >
                {requestData?.amountToBePaid < wallet?.balance
                  ? t("UseWalletBalance")
                  : "Insufficient Wallet Balance"}
              </p>
              <div className="flex items-end text-white">
                <p className="text-[24px] font-extrabold leading-8">
                  {formatNumber(wallet?.balance, true) ?? 0}
                </p>
                <p className="text-[14px] font-normal leading-[25px] ml-1">
                  &nbsp;{t("SAR")}
                </p>
              </div>
            </div>
          </CheckboxCard>
        </div>
        <div className="flex items-start self-stretch justify-center gap-4">
          <button
            className="flex py-3 px-4 justify-center items-center gap-[6px] flex-1 rounded-[24px] bg-[#EF6B2A]"
            onClick={handlePayNow}
          >
            <span className="text-white text-[18px] font-bold leading-6">
              {t("paymenting.payNow")}
            </span>
          </button>
        </div>
      </div>
    </div>
  );
};

interface CheckboxCardProps {
  children: React.ReactNode;
  Icon: React.FC;
  checked?: boolean;
  disabled?: boolean;
  onChange: (checked: boolean) => void;
  hideCheckbox?: boolean;
}
const CheckboxCard: React.FC<CheckboxCardProps> = ({
  children,
  Icon,
  checked,
  disabled,
  hideCheckbox = false,
  onChange,
}) => {
  return (
    <div className="flex w-full min-h-[80px] justify-between p-4 items-center gap-3 self-stretch bg-[#171717] rounded-[16px] max-sm:flex-wrap">
      <div className="flex items-center justify-center w-9 h-9">
        <Icon />
      </div>
      {children}
      {hideCheckbox ? (
        ""
      ) : (
        <CustomCheckbox
          checked={checked}
          onChange={onChange}
          disabled={disabled} // if only use wallet balance
        />
      )}
    </div>
  );
};

export default PaymentModal;
