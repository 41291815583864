import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import CorrectIcon from "../Icons/CorrectIcon";
import { formatNumber } from "../../utils/formatNumber";
import PaymentModal from "../Payments/PaymentModal";
import Chip from "../Chip";

enum PaymentRequestType {
  WalletRecharge = 0,
  OutstandingInstallments,
  OutstandingCredit,
  DueInstallments,
  DueCredit,
  OrderInstallment,
  OrderPayment,
  renew,
}

const SmallDashboardCard = ({
  creditData,
  companyName,
}: {
  creditData: CreditScoreResponse;
  companyName: string;
}) => {
  const { t, i18n } = useTranslation();
  const [showPaymentModal, setShowPaymentModal] = useState(false);

  const [paymentRequestType, setPaymentRequestType] =
    useState<PaymentRequestType | null>(null);

  const handleButtonClick = (type: PaymentRequestType) => {
    setPaymentRequestType(type);
    setShowPaymentModal(true);
  };

  return (
    <section className="flex w-full h-full px-6 py-8 overflow-hidden flex-col items-start gap-4 self-stretch  dash-card-mob-bg rounded-[16px]">
      <div className="flex flex-col pb-4 items-start gap-4 self-stretch w-full border-b border-solid border-[#000] border-opacity-[0.20]">
        <div className="flex flex-col h-[60px] justify-center items-start gap-2 self-stretch w-full">
          <h1
            className={`text-[20px] md:text-[24px] mx-1 text-white font-bold ${
              i18n.language === "ar" ? "text-right" : "text-left"
            }`}
          >
            {companyName}
          </h1>
          <div className="flex flex-row gap-1">
            <Chip label={"installment"} icon={<CorrectIcon />} />
            <div className="mx-[0.5px]" />
            <Chip label={"payment_postponement"} icon={<CorrectIcon />} />
          </div>
        </div>
        <div className="flex items-center self-stretch w-full gap-4">
          <div className="flex flex-col flex-1 text-white ">
            <p className="flex text-nowrap">{t("credit_limit")}</p>
            <div className="flex flex-row items-baseline gap-1">
              <span className="text-[24px] text-white font-extrabold max-sm:text-[16px]">
                {formatNumber(creditData?.creditLimit, true) ?? "0,00"}
              </span>
              <span className="text-sm text-nowrap">{t("sar")}</span>
            </div>
          </div>
          <div className="flex flex-col flex-1 ml-4 mr-4 text-white">
            <p className="text-nowrap">{t("available_balance")}</p>
            <div className="flex flex-row items-baseline gap-1">
              <span className="text-[24px] text-white font-extrabold max-sm:text-[16px]">
                {formatNumber(creditData?.availableBalance, true) ?? "0,00"}
              </span>
              <span className="text-sm text-nowrap">{t("sar")}</span>
            </div>
          </div>
        </div>
      </div>

      <div className="flex flex-col items-start self-stretch w-full gap-4 text-white">
        <div className="flex flex-col pb-4 items-start gap-2 self-stretch w-full border-b border-solid border-[#000] border-opacity-[0.20]">
          <div className="flex flex-col items-start self-stretch w-full gap-1">
            <p className="text-nowrap text-[16px] font-normal text-[#000] ">
              {t("Outstanding")}
            </p>
            <div className="flex flex-row items-baseline gap-1">
              <span className="text-[24px]  text-[#000] font-extrabold max-sm:text-[16px]">
                {formatNumber(creditData?.outstandingBalance, true) ?? "0,00"}
              </span>
              <span className=" text-sm   text-[#000]  text-nowrap">
                {t("sar")}
              </span>
            </div>
          </div>
          <div className="flex items-start self-stretch w-full gap-4">
            <div className="flex flex-col flex-1 gap-1">
              <div>
                <p className="text-nowrap text-[16px] font-normal">
                  {t("installment")}
                </p>
                <div className="flex flex-row items-baseline gap-1">
                  <span className="text-[24px] text-white font-extrabold max-sm:text-[16px]">
                    {formatNumber(creditData?.dueFromInstallments, true) ??
                      "0,00"}
                  </span>
                  <span className="text-sm text-nowrap">{t("sar")}</span>
                </div>
              </div>
              <div>
                {creditData?.dueFromInstallments !== 0 && (
                  <button
                    onClick={() =>
                      handleButtonClick(
                        PaymentRequestType.OutstandingInstallments
                      )
                    }
                    className="px-4 py-2 text-sm text-white bg-gradient-to-r text-nowrap from-orange-500 to-orange-300 rounded-3xl focus:outline-none"
                  >
                    {t("payAdvance")}
                  </button>
                )}
              </div>
            </div>
            <div className="flex flex-col flex-1 gap-1">
              <div>
                <p className="text-nowrap text-[16px] font-normal">
                  {t("credit")}
                </p>
                <div className="flex flex-row items-baseline gap-1">
                  <span className="text-[24px] text-white font-extrabold max-sm:text-[16px]">
                    {formatNumber(creditData?.dueFromCredit, true) ?? "0,00"}
                  </span>
                  <span className="text-sm text-nowrap">{t("sar")}</span>
                </div>
              </div>
              <div>
                {creditData?.dueFromCredit !== 0 && (
                  <button
                    onClick={() =>
                      handleButtonClick(PaymentRequestType.OutstandingCredit)
                    }
                    className="px-4 py-2 text-sm text-white bg-gradient-to-r text-nowrap from-orange-500 to-orange-300 rounded-3xl focus:outline-none"
                  >
                    {t("payAdvance")}
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col items-start self-stretch w-full gap-2">
          <div className="flex flex-col items-start self-stretch w-full gap-1">
            <p className="text-nowrap  text-[16px] font-normal text-[#000]">
              {t("DueAmount")}
            </p>
            <div className="flex flex-row items-baseline gap-1">
              <span className="text-[24px]  text-[#000] font-extrabold max-sm:text-[16px]">
                {formatNumber(creditData?.currentDueBalance, true) ?? "0,00"}
              </span>
              <span className=" text-sm  text-[#000]  text-nowrap">
                {t("sar")}
              </span>
            </div>
          </div>
          <div className="flex items-start self-stretch w-full gap-4">
            <div className="flex flex-col flex-1 gap-1">
              <div>
                <p className="text-nowrap text-[16px] font-normal">
                  {t("installment")}
                </p>
                <div className="flex flex-row items-baseline gap-1">
                  <span className="text-[24px] text-white font-extrabold max-sm:text-[16px]">
                    {formatNumber(
                      creditData?.currentDueFromInstallments,
                      true
                    ) ?? "0,00"}
                  </span>
                  <span className="text-sm text-nowrap">{t("sar")}</span>
                </div>
              </div>
              {creditData?.currentDueFromInstallments !== 0 && (
                <button
                  onClick={() =>
                    handleButtonClick(PaymentRequestType.DueInstallments)
                  }
                  className="max-w-[86px] text-nowrap bg-black border border-solid border-orange-500 px-4 py-2 rounded-3xl text-sm focus:outline-none"
                >
                  <span className="gradient-text">{t("payNow")}</span>
                </button>
              )}
            </div>
            <div className="flex flex-col flex-1 gap-1">
              <div>
                <p className="text-nowrap text-[16px] font-normal ">
                  {t("credit")}
                </p>
                <div className="flex flex-row items-baseline gap-1 ">
                  <span className="text-[24px] text-white font-extrabold max-sm:text-[16px]">
                    {formatNumber(creditData?.currentDueFromCredit, true) ??
                      "0,00"}
                  </span>
                  <span className="text-sm text-nowrap">{t("sar")}</span>
                </div>
              </div>
              {creditData?.currentDueFromCredit !== 0 && (
                <button
                  onClick={() =>
                    handleButtonClick(PaymentRequestType.DueCredit)
                  }
                  className="max-w-[86px] text-nowrap bg-black border border-solid  border-orange-500 px-4 py-2 rounded-3xl text-sm focus:outline-none"
                >
                  <span className="gradient-text">{t("payNow")}</span>
                </button>
              )}
            </div>
          </div>
        </div>
        {showPaymentModal && paymentRequestType !== null && (
          <PaymentModal
            paymentRequestType={paymentRequestType}
            onClose={() => setShowPaymentModal(false)}
            func="DashPay"
          />
        )}
      </div>
    </section>
  );
};

export default SmallDashboardCard;
