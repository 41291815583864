import React from "react";

const UserIcon = ({ width = 28, height = 32 }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 28 32"
      fill="none"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M14 17.6C17.7271 17.6 21.1167 18.7104 23.6102 20.2752C24.8547 21.056 25.9187 21.9776 26.6871 22.9792C27.4431 23.9632 28 25.1408 28 26.4C28 27.752 27.3607 28.8176 26.4398 29.5776C25.5687 30.2976 24.4191 30.7744 23.198 31.1072C20.7433 31.7744 17.4673 32 14 32C10.5327 32 7.25667 31.776 4.802 31.1072C3.58089 30.7744 2.43133 30.2976 1.56022 29.5776C0.637777 28.816 0 27.752 0 26.4C0 25.1408 0.556889 23.9632 1.31289 22.9776C2.08133 21.9776 3.14378 21.0576 4.38978 20.2736C6.88333 18.712 10.2744 17.6 14 17.6ZM14 20.8C10.8531 20.8 8.022 21.744 6.01067 23.0064C5.00422 23.6368 4.24511 24.3216 3.75511 24.9616C3.25111 25.6176 3.11111 26.1136 3.11111 26.4C3.11111 26.5952 3.16867 26.8016 3.50778 27.0816C3.89511 27.4016 4.56867 27.7328 5.59844 28.0128C7.64867 28.5728 10.5949 28.8 14 28.8C17.4067 28.8 20.3513 28.5712 22.4 28.0128C23.4313 27.7328 24.1049 27.4016 24.4922 27.0816C24.8313 26.8016 24.8889 26.5952 24.8889 26.4C24.8889 26.112 24.7489 25.6176 24.2464 24.9616C23.7549 24.3216 22.9958 23.6368 21.9893 23.0064C19.978 21.7424 17.1469 20.8 14 20.8ZM14 0C16.0628 0 18.0411 0.842855 19.4997 2.34315C20.9583 3.84344 21.7778 5.87827 21.7778 8C21.7778 10.1217 20.9583 12.1566 19.4997 13.6569C18.0411 15.1571 16.0628 16 14 16C11.9372 16 9.9589 15.1571 8.50028 13.6569C7.04166 12.1566 6.22222 10.1217 6.22222 8C6.22222 5.87827 7.04166 3.84344 8.50028 2.34315C9.9589 0.842855 11.9372 0 14 0ZM14 3.2C13.3872 3.2 12.7803 3.32416 12.2141 3.56538C11.648 3.8066 11.1335 4.16017 10.7002 4.60589C10.2668 5.05161 9.92308 5.58076 9.68856 6.16312C9.45404 6.74548 9.33333 7.36966 9.33333 8C9.33333 8.63035 9.45404 9.25452 9.68856 9.83688C9.92308 10.4192 10.2668 10.9484 10.7002 11.3941C11.1335 11.8398 11.648 12.1934 12.2141 12.4346C12.7803 12.6758 13.3872 12.8 14 12.8C15.2377 12.8 16.4247 12.2943 17.2998 11.3941C18.175 10.4939 18.6667 9.27304 18.6667 8C18.6667 6.72696 18.175 5.50606 17.2998 4.60589C16.4247 3.70571 15.2377 3.2 14 3.2Z"
        fill="url(#paint0_linear_7608_2281)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_7608_2281"
          x1="0"
          y1="0"
          x2="33.6655"
          y2="24.0788"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#EF6B2A" />
          <stop offset="1" stop-color="#F1BC62" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default UserIcon;
