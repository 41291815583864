import React from "react";

const WalletIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      viewBox="0 0 36 37"
      fill="none"
    >
      <path
        d="M28.5 11V6.5C28.5 6.10218 28.342 5.72064 28.0607 5.43934C27.7794 5.15804 27.3978 5 27 5H7.5C6.70435 5 5.94129 5.31607 5.37868 5.87868C4.81607 6.44129 4.5 7.20435 4.5 8C4.5 8.79565 4.81607 9.55871 5.37868 10.1213C5.94129 10.6839 6.70435 11 7.5 11H30C30.3978 11 30.7794 11.158 31.0607 11.4393C31.342 11.7206 31.5 12.1022 31.5 12.5V18.5M31.5 18.5H27C26.2044 18.5 25.4413 18.8161 24.8787 19.3787C24.3161 19.9413 24 20.7044 24 21.5C24 22.2956 24.3161 23.0587 24.8787 23.6213C25.4413 24.1839 26.2044 24.5 27 24.5H31.5C31.8978 24.5 32.2794 24.342 32.5607 24.0607C32.842 23.7794 33 23.3978 33 23V20C33 19.6022 32.842 19.2206 32.5607 18.9393C32.2794 18.658 31.8978 18.5 31.5 18.5Z"
        stroke="white"
        strokeWidth="2.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.5 8V29C4.5 29.7956 4.81607 30.5587 5.37868 31.1213C5.94129 31.6839 6.70435 32 7.5 32H30C30.3978 32 30.7794 31.842 31.0607 31.5607C31.342 31.2794 31.5 30.8978 31.5 30.5V24.5"
        stroke="white"
        strokeWidth="2.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default WalletIcon;
