import React, { useContext, useEffect } from "react";
import { RxExit } from "react-icons/rx";
import { PageRoutes } from "../../constants";
import { HiLanguage } from "react-icons/hi2";
import { CgProfile } from "react-icons/cg";
import useLocalStorage from "../../hooks/useLocalStorage";
import CrownIcon from "../Icons/CrownIcon";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import TowerIcon from "../Icons/TowerIcon";
import { RiHome5Line } from "react-icons/ri";
import { AiOutlineDollar } from "react-icons/ai";
import HandIcon from "../Icons/HandIcon";
import { AppContext } from "../../context/app-context";
import { initKeycloak, keycloak } from "../../../keycloak";
import SubscribeCard from "../cards/Subscribe";
import { useSelector } from "react-redux";
import { selectCompanyStatus } from "../../../redux/slices/companySlice";
import { RootState } from "../../../redux/store/rootReducer";

interface SidebarProps {
  onClick: () => void;
  isOpen: boolean;
}

const SidebarMobile: React.FC<SidebarProps> = ({ onClick, isOpen }) => {
  const navigate = useNavigate();
  const { clearValue } = useLocalStorage();
  const { t, i18n } = useTranslation();
  const { getValue, setValue } = useLocalStorage();
  const { setValue: setAuth } = useLocalStorage<boolean>();
  const { currentPage, setCurrentPage, isAuthenticated, setAuthenticated } =
    useContext<AppState>(AppContext);
  const active = currentPage;

  const handleLinkClick = (route: string) => {
    setCurrentPage(route);
    setValue("currentPage", route);
  };

  useEffect(() => {
    const initialRoute = getValue("currentPage") || PageRoutes.DASHBOARD;
    setCurrentPage(initialRoute as string);
  }, [getValue, setCurrentPage]);

  const changeLanguage = () => {
    const newLang = i18n.language === "ar" ? "en" : "ar";
    i18n.changeLanguage(newLang);
    sessionStorage.setItem("lang", newLang);
  };

  const handleCardClick = (e: React.MouseEvent) => {
    // Stop the click event from propagating to parent elements
    e.stopPropagation();
  };
  const companyStatus = useSelector((state: RootState) =>
    selectCompanyStatus(state)
  );

  return (
    <div
      onClick={onClick}
      dir={i18n.dir()}
      className={`z-20 flex flex-col w-full px-3 pb-3 bg-black min-h-dvh transform transition-transform duration-300 ${
        isOpen ? "translate-x-0" : "-translate-x-full"
      }`}

      // className="z-20 flex flex-col w-full px-3 pb-3 bg-black min-h-dvh"
    >
      <ul className="flex flex-col items-center w-full mt-2 mb-16">
        <Link
          to={PageRoutes.DASHBOARD}
          onClick={() => handleLinkClick(PageRoutes.DASHBOARD)}
          className="mx-2 text-sm text-bold w-80"
        >
          <li
            className={`flex flex-row my-1 w-full items-center justify-start bg-[#171717] rounded-xl py-2 px-4 outline-none ${
              active === PageRoutes.DASHBOARD ? "text-white" : "text-[#6b6b6b]"
            } `}
          >
            <RiHome5Line className="mx-1" />
            {t("dashboard")}
          </li>
        </Link>
        <Link
          onClick={() => handleLinkClick(PageRoutes.ORDERS)}
          to={PageRoutes.ORDERS}
          className="mx-2 text-sm text-bold w-80"
        >
          <li
            className={`flex flex-row  my-1 items-center justify-start bg-[#171717] rounded-xl py-2 px-4   w-full outline-none ${
              active === PageRoutes.ORDERS ? "text-white" : "text-[#6b6b6b]"
            } `}
          >
            <HandIcon
              className="w-4 h-4 mx-1"
              color={active === PageRoutes.ORDERS ? "white" : "#6b6b6b"}
            />
            {t("Orders")}
          </li>
        </Link>
        <Link
          onClick={() => handleLinkClick(PageRoutes.PAYMENTS)}
          to={PageRoutes.PAYMENTS}
          className="mx-1 text-sm text-bold text-nowrap w-80 "
        >
          <li
            className={`flex flex-row my-1 items-center justify-start bg-[#171717] rounded-xl py-2 px-4   w-full outline-none ${
              active === PageRoutes.PAYMENTS ? "text-white" : "text-[#6b6b6b]"
            } `}
          >
            <AiOutlineDollar className="w-4 h-4 mx-1" />
            {t("payments")}
          </li>
        </Link>
        <Link
          onClick={() => handleLinkClick(PageRoutes.COMPANY_PROFILE)}
          to={PageRoutes.COMPANY_PROFILE}
          className="mx-1 text-sm text-bold text-nowrap w-80 "
        >
          <li
            className={`flex flex-row my-1 items-center justify-start bg-[#171717] rounded-xl py-2 px-4   w-full outline-none ${
              active === PageRoutes.COMPANY_PROFILE
                ? "text-white"
                : "text-[#6b6b6b]"
            } `}
          >
            <TowerIcon
              className="mx-1"
              color={
                active === PageRoutes.COMPANY_PROFILE ? "white" : "#6b6b6b"
              }
            />
            {t("companyProfile")}
          </li>
        </Link>
        {/* <Link
          to={PageRoutes.USERS}
          onClick={() => handleLinkClick(PageRoutes.USERS)}
          className="h-8 mx-2 text-sm text-bold w-80 "
        >
          <li
            className={`flex flex-row my-1 h-full items-center justify-start bg-[#171717] rounded-xl py-2 px-4  w-full outline-none ${
              active === PageRoutes.USERS ? "text-white" : "text-[#6b6b6b]"
            } `}
          >
            <GoPeople className="mx-1 " />

            {t("users")}
          </li>
        </Link> */}
      </ul>
      <div className="flex flex-col mx-auto my-auto w-80">
        {/* <div onClick={handleCardClick}>
          {companyStatus !== 3 && <SubscribeCard />}
        </div> */}

        <ul className="flex flex-col items-center w-full mt-4">
          {[
            { route: PageRoutes.PROFILE, icon: CgProfile, label: "profile" },
            {
              route: PageRoutes.LANGUAGE,
              icon: HiLanguage,
              label: "language",
              isButton: true,
              action: changeLanguage,
            },
          ].map(({ route, icon: Icon, label, isButton, action }) => (
            <li
              key={route}
              className={`flex flex-row items-center justify-start w-full mt-2 py-2 outline-none ${
                active === route ? "text-white" : "text-[#6b6b6b]"
              }`}
            >
              {isButton ? (
                <button
                  onClick={action}
                  className="flex flex-row items-center w-full gap-2"
                >
                  <Icon />
                  <span className="text-bold">
                    {i18n.language === "ar" ? "English" : "العربية"}
                  </span>
                </button>
              ) : (
                <Link
                  to={route}
                  onClick={() => handleLinkClick(route)}
                  className="flex items-center w-full"
                >
                  <Icon className="mr-2" />
                  <span className="text-bold">{t(label)}</span>
                </Link>
              )}
            </li>
          ))}
          <li className="flex flex-row items-center justify-start w-full mt-2 py-2 outline-none text-[#6b6b6b]">
            <button
              onClick={() => navigate(PageRoutes.LOGOUT)}
              className="flex flex-row items-center gap-2 text-bold "
            >
              <RxExit />
              {t("logout")}
            </button>
          </li>
          <li className="text-xs flex flex-row items-center text-[#6b6b6b] justify-start my-1 mx-auto 2xl:py-2 w-36 outline-none font-mono">
            {process.env.REACT_APP_VERSION}
          </li>
        </ul>
      </div>
    </div>
  );
};

export default SidebarMobile;
