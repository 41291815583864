import React from "react";
import Lottie from "react-lottie";
import animationData from "../Icons/ProBuy_Credit_Animation.json";
import { useTranslation } from "react-i18next";

const Calculating = () => {
  const { t } = useTranslation();

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <div className="flex flex-col items-center justify-center w-full min-h-screen p-4 space-y-6 md:p-6 lg:p-8">
      <img
        src="./assets/probuy.svg"
        className="w-[200px] sm:w-[250px] md:w-[300px]"
        alt={t("layout.imageAlt")}
      />
      <div className="flex flex-col items-center max-w-2xl mx-auto text-center">
        <h1 className="mb-2 text-3xl font-bold sm:text-4xl md:text-5xl">
          {t("creditScore")}
        </h1>
        <h2 className="mb-3 text-xl font-bold sm:text-2xl md:text-2xl">
          {t("creditScoreText")}
        </h2>
        <p className="px-4 text-base text-center sm:text-lg">
          {t("autoCreditScorePara")}
        </p>
      </div>
      <div className="mt-4">
        <Lottie
          options={defaultOptions}
          height={
            window.innerWidth < 640 ? 200 : window.innerWidth < 768 ? 250 : 300
          }
          width={
            window.innerWidth < 640 ? 200 : window.innerWidth < 768 ? 250 : 300
          }
        />
      </div>
    </div>
  );
};

export default Calculating;
