import React from "react";

interface CompletedOrderIconProps {
  width?: string;
  height?: string;
  className?: string;
  color?: string;
}

const CompletedOrderIcon: React.FC<CompletedOrderIconProps> = ({
  width = "24",
  height = "24",
  className = "",
  color = "white",
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    className={className}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.8202 3.42073C13.9714 2.59693 11.9057 2.39284 9.93141 2.83892C7.95708 3.28499 6.17984 4.35732 4.86474 5.89599C3.54964 7.43465 2.76715 9.3572 2.63397 11.3769C2.50079 13.3966 3.02406 15.4053 4.12573 17.1033C5.2274 18.8013 6.84845 20.0977 8.74712 20.7992C10.6458 21.5007 12.7203 21.5696 14.6614 20.9957C16.6024 20.4218 18.3059 19.2358 19.5178 17.6146C20.7298 15.9935 21.3852 14.024 21.3864 11.9999V11.0802C21.3864 10.7413 21.6611 10.4666 22 10.4666C22.3389 10.4666 22.6136 10.7413 22.6136 11.0802V12.0002C22.6123 14.289 21.8712 16.5163 20.5008 18.3495C19.1304 20.1826 17.2042 21.5236 15.0093 22.1726C12.8145 22.8215 10.4687 22.7436 8.3218 21.9504C6.17489 21.1572 4.34188 19.6913 3.09617 17.7713C1.85045 15.8512 1.25877 13.5799 1.40936 11.2962C1.55995 9.01237 2.44475 6.83844 3.9318 5.0986C5.41885 3.35876 7.42847 2.14622 9.66094 1.64182C11.8934 1.13742 14.2291 1.36819 16.3197 2.29971C16.6293 2.43764 16.7684 2.80041 16.6305 3.10997C16.4926 3.41953 16.1298 3.55867 15.8202 3.42073ZM22.4339 3.56621C22.6735 3.80585 22.6735 4.19438 22.4339 4.43402L12.4339 14.434C12.1943 14.6737 11.8057 14.6737 11.5661 14.434L8.56608 11.434C8.32644 11.1944 8.32644 10.8058 8.56608 10.5662C8.80572 10.3266 9.19426 10.3266 9.4339 10.5662L12 13.1323L21.5661 3.56621C21.8057 3.32657 22.1943 3.32657 22.4339 3.56621Z"
      fill={color}
    />
  </svg>
);

export default CompletedOrderIcon;
