import React from "react";
import { useTranslation } from "react-i18next";

const ProgressBar: React.FC<ProgressBarProps> = ({ step, setStep }) => {
  const { t, i18n } = useTranslation();

  return (
    <div className="w-full lg:w-4/5 col-span-2 px-4 lg:px-6">
      <div className="flex pb-2">
        <button
          onClick={() => setStep(1)}
          className={`rounded-full p-2 sm:p-3 lg:p-4 ${
            step > 1
              ? "bg-orange-400 border border-orange-400"
              : "bg-neutral-900 border border-orange-400"
          }`}
        ></button>
        <div
          className={`w-full h-0.5 flex-1 ${
            step === 1
              ? "bg-gray-400"
              : "bg-orange-400"
          } m-auto`}
        ></div>

        <button
          onClick={() => setStep(2)}
          className={`rounded-full p-2 sm:p-3 lg:p-4 ${
            step > 2
              ? "bg-orange-400 border border-orange-400"
              : step === 2
              ? "bg-neutral-900 border border-orange-400"
              : "bg-neutral-900 border border-gray-400"
          }`}
        ></button>
        <div
          className={`w-full h-0.5 flex-1 ${
            step <= 2
              ? "bg-gray-400"
              : "bg-orange-400"
          } m-auto`}
        ></div>

        <button
          onClick={() => setStep(3)}
          className={`rounded-full p-2 sm:p-3 lg:p-4 ${
            step === 3
              ? "bg-neutral-900 border border-orange-400"
              : "bg-neutral-900 border border-gray-400"
          }`}
        ></button>
      </div>
      <div className="box-content relative flex items-end justify-between">
        <span
          className={`absolute top-0 text-white text-[10px] sm:text-[11px] md:text-[12px] lg:text-[14px] font-bold ${
            i18n.language == "ar"
              ? "right-0 left-auto translate-x-[20%]"
              : "left-0 right-auto -translate-x-1/4"
          }`}
        >
          {t("basicInformation")}
        </span>
        <span
          className={`absolute top-0 text-white text-[10px] sm:text-[11px] md:text-[12px] lg:text-[14px] font-bold left-1/2 transform -translate-x-1/2 text-center`}
        >
          {t("businessInformation")}
        </span>
        <span
          className={`absolute top-0 text-white text-[10px] sm:text-[11px] md:text-[12px] lg:text-[14px] font-bold ${
            i18n.language == "ar"
              ? "left-0 right-auto translate-x-[-30%]"
              : "right-0 left-auto translate-x-1/4"
          }`}
        >
          {t("businessAddress")}
        </span>
      </div>
    </div>
  );
};

export default ProgressBar;
