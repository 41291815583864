import React from "react";

interface OTPInputProps {
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  name: string;
  className?: string;
  disabled?: boolean;
}

// Use forwardRef to pass ref to the input component
const OTPInput = React.forwardRef<HTMLInputElement, OTPInputProps>(
  ({ value, onChange, name ,className,disabled}, ref) => (
    <input
      className={`${className} flex-1  text-center justify-center px-2 py-4 rounded-xl border border-solid bg-white bg-opacity-10 border-white border-opacity-20 no-spinner`}
      type="number"
      placeholder="-"
      value={value}
      onChange={(e) => {
        // Ensure only one character is entered
        if (e.target.value.length <= 1) {
          onChange(e);
        }
      }}
      inputMode="numeric" // Specify numeric input mode
      maxLength={1}
      ref={ref} // Pass ref to input
      name={name} // Pass name to input
      disabled={disabled}
    />
  )
);

export default OTPInput;
