import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Calculating from "../lib/components/connect-account/Calculating";
import CreditScore from "../lib/components/connect-account/CreditScore";
import Layout3 from "../lib/components/layout/Layout3";
import useLocalStorage from "../lib/hooks/useLocalStorage";
import useApi from "../lib/hooks/useApi";
import { PageRoutes } from "../lib/constants";

const CreditScorePage: React.FC = () => {
  const [isEligible, setIsEligible] = useState<boolean>(false);
  const [showCalculating, setShowCalculating] = useState(true);
  const [showContent, setShowContent] = useState(false);
  const [creditData, setCreditData] = useState<CreditScoreResponse | number>(0);
  const [companyId, setCompanyId] = useState<string | null>(null);
  const navigate = useNavigate();
  const { getValue } = useLocalStorage<string | null>();
  const { getData } = useApi<any>();
  const { getData: getCompanyData, data: companyData } = useApi<any>();
  const { getValue: getUserId } = useLocalStorage<string>();

  const userId = getUserId("userId");

  useEffect(() => {
    const id = getValue("companyId");
    setCompanyId(id);
    if (!id) {
      navigate(PageRoutes.LOGOUT, { replace: true });
      return;
    }
  }, [getValue, navigate]);

  useEffect(() => {
    if (userId) {
      const fetchCompanyStatus = async () => {
        await getCompanyData(`/company/byuser/${userId}/check`);
      };

      fetchCompanyStatus();

      const intervalId = setInterval(fetchCompanyStatus, 10000);
      return () => clearInterval(intervalId);
    }
  }, []);

  useEffect(() => {
    if (companyData) {
      checkCompanyStatus();

      const intervalId = setInterval(() => {
        checkCompanyStatus();
      }, 10000);

      return () => clearInterval(intervalId);
    }
  }, [companyData]);

  const getCreditRecord = async () => {
    if (!companyId) {
      navigate(PageRoutes.LOGOUT, { replace: true });
      return;
    }
    try {
      const { data } = await getData(`/credit/${companyId}`);
      setCreditData(data ?? 0);
      setShowContent(true);
    } catch (error) {
      console.error("Failed to get credit record:", error);
    } finally {
      setShowCalculating(false);
    }
  };

  const checkCompanyStatus = () => {
    if (companyData?.status === 2) {
      setIsEligible(true);
    } else if (
      companyData?.status === 3 ||
      companyData?.status === 4 ||
      companyData?.status === 5
    ) {
      setIsEligible(false);
    } else if (companyData?.status === 0 || companyData?.status === 1) {
      return;
    }
    getCreditRecord();
  };

  return (
    <Layout3>
      <div className="min-h-dvh">
        {showCalculating ? (
          <Calculating />
        ) : (
          showContent && (
            <CreditScore
              creditData={creditData}
              isEligible={isEligible}
              companyData={companyData}
            />
          )
        )}
      </div>
    </Layout3>
  );
};

export default CreditScorePage;
