import React from "react";

interface AddDocIconProps {
  className?: string;
  width?: string | number;
  height?: string | number;
}

const AddDocIcon: React.FC<AddDocIconProps> = ({
  className,
  width = 24,
  height = 24,
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    className={className}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.05546 2.05546C4.57118 1.53973 5.27065 1.25 6 1.25H15C15.1989 1.25 15.3897 1.32902 15.5303 1.46967L20.5303 6.46967C20.671 6.61032 20.75 6.80109 20.75 7V20C20.75 20.7293 20.4603 21.4288 19.9445 21.9445C19.4288 22.4603 18.7293 22.75 18 22.75H4C3.58579 22.75 3.25 22.4142 3.25 22C3.25 21.5858 3.58579 21.25 4 21.25H18C18.3315 21.25 18.6495 21.1183 18.8839 20.8839C19.1183 20.6495 19.25 20.3315 19.25 20V8.75H16C15.2707 8.75 14.5712 8.46027 14.0555 7.94454C13.5397 7.42882 13.25 6.72935 13.25 6V2.75H6C5.66848 2.75 5.35054 2.8817 5.11612 3.11612C4.8817 3.35054 4.75 3.66848 4.75 4V8C4.75 8.41421 4.41421 8.75 4 8.75C3.58579 8.75 3.25 8.41421 3.25 8V4C3.25 3.27065 3.53973 2.57118 4.05546 2.05546ZM14.75 2.81066V6C14.75 6.33152 14.8817 6.64946 15.1161 6.88388C15.3505 7.1183 15.6685 7.25 16 7.25H19.1893L14.75 2.81066ZM6 11.25C6.41421 11.25 6.75 11.5858 6.75 12V14.25H9C9.41421 14.25 9.75 14.5858 9.75 15C9.75 15.4142 9.41421 15.75 9 15.75H6.75V18C6.75 18.4142 6.41421 18.75 6 18.75C5.58579 18.75 5.25 18.4142 5.25 18V15.75H3C2.58579 15.75 2.25 15.4142 2.25 15C2.25 14.5858 2.58579 14.25 3 14.25H5.25V12C5.25 11.5858 5.58579 11.25 6 11.25Z"
      fill="url(#paint0_linear)"
    />
    <defs>
      <linearGradient
        id="paint0_linear"
        x1="2.25"
        y1="1.25"
        x2="24.7441"
        y2="17.0714"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#EF6B2A" />
        <stop offset="1" stopColor="#F1BC62" />
      </linearGradient>
    </defs>
  </svg>
);

export default AddDocIcon;
