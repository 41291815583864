import { Navigate } from "react-router-dom";
import { PageRoutes } from "./lib/constants";
import { UserProvider } from "./lib/context/UserContext";

export const PrivateRoute = ({ children }) => {
  const isAuthenticated = !!sessionStorage.getItem("keycloak_token");

  return isAuthenticated ? (
    <UserProvider>{children}</UserProvider>
  ) : (
    <Navigate to={PageRoutes.SIGN_IN} />
  );
};
