// src/contexts/UserContext.tsx
import React, { createContext, useState, useEffect, useContext } from "react";
import { useTranslation } from "react-i18next";

import Toast, { IToast } from "../components/Shared/Toast";
import useApi from "../hooks/useApi";

interface IUser {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
  companyId: string;
  profileImageUrl: string;
}

interface IUserContext {
  userData: IUser | null;
  loading: boolean;
  error: string | null;
  toast: IToast | null;
  fetchUserData: (userId: string) => Promise<void>;
  updateUserData: (userData: IUser) => Promise<void>;
}

const UserContext = createContext<IUserContext | undefined>(undefined);

export const UserProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { t, i18n } = useTranslation();
  const [userData, setUserData] = useState<IUser | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [toast, setToast] = useState<IToast | null>(null);
  const { getData, putData } = useApi<IUser>();

  const fetchUserData = async (userId: string) => {
    setLoading(true);
    try {
      const { status, data } = await getData(`/user/${userId}`);
      if (status !== 200) {
        throw new Error("Failed to fetch user data");
      }
      const companyId = localStorage.getItem("companyId");
      if (!data.companyId && companyId)
        data.companyId = companyId.replaceAll('"', "");
      setUserData(data);
    } catch (err) {
      setToast({
        type: "error",
        titleKey: t("error"),
        messageKey: "something went wrong",
      });
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const updateUserData = async (userData: IUser) => {
    setLoading(true);
    try {
      const { status, data } = await putData(`/user`, userData);
      if (status !== 200) {
        setToast({
          type: "error",
          titleKey: t("error"),
          messageKey: "something went wrong",
        });
        throw new Error("Failed to update user data");
      }
      setToast({
        type: "success",
        titleKey: t("updatedSuccess"),
        messageKey: t("dataUpdatedSuccessfully"),
      });
      setUserData(data);
    } catch (err) {
      setToast({
        type: "error",
        titleKey: t("error"),
        messageKey: t("somethingWentWrong"),
      });
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const userId = localStorage.getItem("userId");
    if (userId) {
      fetchUserData(userId.replaceAll('"', ""));
    }
  }, []);

  return (
    <UserContext.Provider
      value={{ userData, loading, error, toast, fetchUserData, updateUserData }}
    >
      {children}
      {toast && (
        <Toast
          type={toast.type}
          titleKey={toast.titleKey}
          messageKey={toast.messageKey}
          onClose={() => setToast(null)}
        />
      )}
    </UserContext.Provider>
  );
};

export const useUserContext = () => {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error("useUserContext must be used within a UserProvider");
  }
  return context;
};
