// hooks/usePayment.ts
import { useState } from "react";
import { PaymentDto, PaymentState } from "../types/types";

const usePayment = (): PaymentState => {
  const [payment, setPayment] = useState<PaymentDto>({
    id: "",
    amount: 0,
    date: "",
    status: "",
    createdAt: "",
    orderId: "",
    isPaid: false,
    dueDate: "",
  });

  return {
    payment,
    setPayment,
  };
};

export default usePayment;
