import React, { createContext, useContext, useState } from "react";
import Toast, { IToast } from "../components/Shared/Toast";

interface ToastContextType {
  toast: (toast: IToast) => void;
}

const ToastContext = createContext<ToastContextType | null>(null);

export const ToastProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [toasts, setToasts] = useState<IToast[]>([]);

  const toast = (toast: IToast) => {
    setToasts((prev) => [...prev, toast]);
  };

  const removeToast = (index: number) => {
    setToasts((prev) => prev.filter((_, i) => i !== index));
  };

  return (
    <ToastContext.Provider value={{ toast }}>
      {children}
      {toasts.map((toast, index) => (
        <Toast key={index} {...toast} onClose={() => removeToast(index)} />
      ))}
    </ToastContext.Provider>
  );
};

export const useToast = () => {
  const context = useContext(ToastContext);
  if (!context) {
    throw new Error("useToast must be used within a ToastProvider");
  }
  return context;
};
