import React, { createContext } from "react";
import useApp from "../hooks/useApp";
import { PageRoutes } from "../constants";

const AppContext = createContext<AppState>({
  isAuthenticated: false,
  setAuthenticated: () => {},
  userImage: null,
  setUserImage: (image: File | React.ReactNode) => {},
  currentPage: PageRoutes.DASHBOARD,
  setCurrentPage: (page: string) => {},
});

const AppContextProvider = ({ children }: ContextProviderProps) => {
  const appContext = useApp();
  return (
    <AppContext.Provider value={appContext}>{children}</AppContext.Provider>
  );
};

export { AppContext, AppContextProvider };
