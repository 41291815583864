export const DEFAULT_GET_API_HEADER = (accessToken: string) => {
  return {
    Authorization: `Bearer ${accessToken}`,
  };
};
export const DEFAULT_POST_API_HEADER = (accessToken?: string) => {
  return {
    Authorization: `Bearer ${accessToken}`,
    "Content-Type": "application/json",
  };
};

export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
// export const API_BASE_URL = "https://devcompanybff.probuy.me";
// export const API_BASE_URL = "https://uatcompanybff.probuy.me";
// "https://companybff.icycliff-aa197d1f.uaenorth.azurecontainerapps.io"

export const PLATFORM_URL = process.env.REACT_APP_PLATFORM_URL;
// export const PLATFORM_URL =
//   "https://probuycompanyportaldev.z1.web.core.windows.net";
// export const PLATFORM_URL =
// "https://probuycompanyportaluat.z1.web.core.windows.net";

export const keycloakConfigs = {
  // url: "https://identity-dev.probuy.me:8081/",

  // realm: "Company-UAT",
  // clientId: "company-uat-client",
  // realm: "Company-Dev",
  // clientId: "company-dev-client",
  // url: "https://identity.probuy.me:8081/",
  // realm: "Company-Prod",
  // clientId: "company-prod-client",

  url: process.env.REACT_APP_KEYCLOAK_URL,
  realm: process.env.REACT_APP_KEYCLOAK_REALM,
  clientId: process.env.REACT_APP_KEYCLOAK_CLIENT_ID,
};
