import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Layout3 from "../lib/components/layout/Layout3";
import ConnectAccount from "../lib/components/connect-account/ConnectAccount";
import HowToConnect from "../lib/components/connect-account/HowToConnect";
import Consent from "../lib/components/connect-account/Consent";
import useLocalStorage from "../lib/hooks/useLocalStorage";
import useApi from "../lib/hooks/useApi";
import { PageRoutes } from "../lib/constants";
import Spinner from "../lib/components/Shared/Spinner";
import Loadingwithoutlogo from "./loadingwithoutlogo";

const ConnectBankAccount = () => {
  const { getData: checkUser } = useApi<Organization>();

  const [pageIndex, setPageIndex] = useState<number>(1);
  const [loading, setLoading] = useState<boolean>(true);
  const navigate = useNavigate();
  const { getValue: getUserId } = useLocalStorage<string>();

  useEffect(() => {
    const handlePopState = (event) => {
      // navigate(PageRoutes.CONNECT_BANK);
      navigate(PageRoutes.MANUAL_BANK_CONNECT);
    };

    window.history.pushState(null, "", window.location.href);
    window.addEventListener("popstate", handlePopState);

    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, [navigate]);

  useEffect(() => {
    const checkCompanyStatus = async () => {
      try {
        const userId = getUserId("userId");
        const { data, status } = await checkUser(
          `/company/byuser/${userId}/check`
        );
        if (status == 200) {
          if (data?.status === 1) {
            navigate(PageRoutes.MANUAL_PENDING_CREDIT_SCORE);
          } else if (
            data?.status === 2 ||
            data?.status === 3 ||
            data?.status === 4 ||
            data?.status === 5
          ) {
            navigate(PageRoutes.CREDIT_SCORE);
          } else {
            setLoading(false);
          }
        } else throw new Error("Error checking company status");
      } catch (error) {
        console.error("Error checking company status:", error);
        setLoading(false);
      }
    };

    const timer = setTimeout(() => {
      checkCompanyStatus();
    }, 5000); // Delay of 5 seconds

    return () => clearTimeout(timer);
  }, []);

  const pageChange = (pageNumber: number) => {
    setPageIndex(pageNumber);
  };

  if (loading) {
    return <Loadingwithoutlogo />;
  }

  return (
    <Layout3>
      {pageIndex === 1 ? (
        <ConnectAccount onPageChange={pageChange} />
      ) : pageIndex === 2 ? (
        <HowToConnect onPageChange={pageChange} />
      ) : (
        <Consent onPageChange={pageChange} />
      )}
    </Layout3>
  );
};

export default ConnectBankAccount;
