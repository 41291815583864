import React from "react";

interface GoldenDocIconProps {
  className?: string;
  width?: number;
  height?: number;
}

const GoldenDocIcon: React.FC<GoldenDocIconProps> = ({
  className,
  width = 24,
  height = 24,
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      className={className}
    >
      <path
        d="M15 2H6C5.46957 2 4.96086 2.21071 4.58579 2.58579C4.21071 2.96086 4 3.46957 4 4V20C4 20.5304 4.21071 21.0391 4.58579 21.4142C4.96086 21.7893 5.46957 22 6 22H18C18.5304 22 19.0391 21.7893 19.4142 21.4142C19.7893 21.0391 20 20.5304 20 20V7L15 2Z"
        stroke="url(#paint0_linear_1038_12959)"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14 2V6C14 6.53043 14.2107 7.03914 14.5858 7.41421C14.9609 7.78929 15.4696 8 16 8H20"
        stroke="url(#paint1_linear_1038_12959)"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10 9H8"
        stroke="url(#paint2_linear_1038_12959)"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16 13H8"
        stroke="url(#paint3_linear_1038_12959)"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16 17H8"
        stroke="url(#paint4_linear_1038_12959)"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1038_12959"
          x1="4"
          y1="2"
          x2="24.3685"
          y2="15.3196"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EF6B2A" />
          <stop offset="1" stopColor="#F1BC62" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_1038_12959"
          x1="14"
          y1="2"
          x2="20.5368"
          y2="7.34328"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EF6B2A" />
          <stop offset="1" stopColor="#F1BC62" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_1038_12959"
          x1="8"
          y1="9"
          x2="8.9897"
          y2="10.618"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EF6B2A" />
          <stop offset="1" stopColor="#F1BC62" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_1038_12959"
          x1="8"
          y1="13"
          x2="8.33223"
          y2="15.1726"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EF6B2A" />
          <stop offset="1" stopColor="#F1BC62" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_1038_12959"
          x1="8"
          y1="17"
          x2="8.33223"
          y2="19.1726"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EF6B2A" />
          <stop offset="1" stopColor="#F1BC62" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default GoldenDocIcon;
