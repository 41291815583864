import React from "react";
import { useTranslation } from "react-i18next";

interface InstallmentStatusLabelProps {
  status: "Paid" | "Upcoming" | "Over Due";
  variant?: "small" | "large";
}

const InstallmentStatusLabel: React.FC<InstallmentStatusLabelProps> = ({
  status,
  variant = "large",
}) => {
  const { t } = useTranslation();

  const getStatusStyles = () => {
    switch (status) {
      case "Paid":
        return "bg-[#008E45] text-white";
      case "Upcoming":
        return "bg-[#DB9236] text-white";
      case "Over Due":
        return "bg-[#DD1631] text-white";
      default:
        return "";
    }
  };

  const variantStyles =
    variant === "small"
      ? "text-[12px] px-[10px] py-[6px]"
      : "text-[14px] leading-[18px] font-normal px-[10px] py-[6px]";

  return (
    <span
      className={`flex items-center justify-center rounded-[12px] ${variantStyles} ${getStatusStyles()}`}
    >
      {t(`installmentStatus.${status.toLowerCase()}`)}
    </span>
  );
};

export default InstallmentStatusLabel;
