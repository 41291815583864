import React, { useEffect, useState } from "react";
import { Navigate, useLocation } from "react-router-dom";
import Spinner from "./lib/components/Shared/Spinner";
import useApi from "./lib/hooks/useApi";
import useLocalStorage from "./lib/hooks/useLocalStorage";
import { PageRoutes } from "./lib/constants";
import { keycloak } from "./keycloak";
import LoadingProbuy from "./lib/components/LoadingProbuy";

const CheckingCompanyStatusRoute = ({
  element: Component,
  allowedStatuses = [],
  redirectRoutes = {},
  allowUndefinedStatus = false,
  ...rest
}) => {
  const location = useLocation();
  const [isAuthenticated, setIsAuthenticated] = useState(
    keycloak.authenticated || !!sessionStorage.getItem("keycloak_token")
  );
  const [loading, setLoading] = useState(true);
  const [redirectPath, setRedirectPath] = useState<string | null>(null);
  const { getData: checkUser } = useApi<Organization>();
  const { getValue: getUserId } = useLocalStorage<string>();

  useEffect(() => {
    const checkCompanyStatus = async () => {
      try {
        if (isAuthenticated) {
          const userId = getUserId("userId");
          //consloe.log("Checking company status...");
          const {
            data: { status },
          } = await checkUser(`/company/byuser/${userId}/check`);
          if (status === undefined && allowUndefinedStatus) {
            //consloe.log("Company status is undefined, allowing access.");
            setRedirectPath(null);
          } else if (!allowedStatuses.includes(status)) {
            const path = redirectRoutes[status] || PageRoutes.SIGN_IN;
            //consloe.log("Redirecting to:", path);
            setRedirectPath(path);
          } else {
            //consloe.log("User status allowed, rendering component");
            setRedirectPath(null);
          }

          setLoading(false);
        } else {
          //consloe.log("User not authenticated, redirecting to sign in");
          setRedirectPath(PageRoutes.SIGN_IN);
          setLoading(false);
        }
      } catch (error) {
        //consloe.log("Error in checking company status:", error);
        if (allowUndefinedStatus) {
          //consloe.log("Company status undefined, allowing access.");
          setRedirectPath(null);
        } else {
          //consloe.log("API error, redirecting to sign in");
          setRedirectPath(PageRoutes.SIGN_IN);
        }
        setLoading(false);
      }
    };

    checkCompanyStatus();
  }, [redirectRoutes]);

  if (loading) {
    return <LoadingProbuy />;
  }

  if (redirectPath) {
    //consloe.log("Navigating to:", redirectPath);
    return <Navigate to={redirectPath} state={{ from: location }} />;
  }

  return <Component {...rest} />;
};

export default CheckingCompanyStatusRoute;
