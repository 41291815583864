import React, { useState } from "react";
import { useFormikContext } from "formik";
import TowerIcon from "../../Icons/TowerIcon";
import InputField from "../../Forms/InputField";
import Dropdown from "../../Forms/Dropdown";
import { OrganizationType } from "../../../constants";
import { useTranslation } from "react-i18next";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import parsePhoneNumber from "libphonenumber-js";

const BasicDetails: React.FC = () => {
  const { t, i18n } = useTranslation();
  const { values, setFieldValue, setFieldTouched, errors, touched } =
    useFormikContext<any>();

  const OrganizationTypeLabels = {
    [OrganizationType.SoleProprietorship]: t("SoleProprietorship"),
    [OrganizationType.Partnership]: t("Partnership"),
    [OrganizationType.LimitedLiabilityCompany]: t("LimitedLiabilityCompany"),
    [OrganizationType.Franchise]: t("Franchise"),
    [OrganizationType.Nonprofit]: t("Nonprofit"),
    [OrganizationType.ListedCompany]: t("ListedCompany"),
    [OrganizationType.Other]: t("Other"),
    [OrganizationType.CompanyLimitedByGuarantee]: t(
      "CompanyLimitedByGuarantee"
    ),
    [OrganizationType.SmallMediumSizedEnterprise]: t(
      "SmallMediumSizedEnterprise"
    ),
  };

  const [currentOpenDropdown, setCurrentOpenDropdown] = useState<string | null>(
    null
  );

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFieldValue(name, value);
  };

  const toggleDropdown = (dropdown: string) => {
    setCurrentOpenDropdown((prev) => (prev === dropdown ? null : dropdown));
  };

  return (
    <section className="flex flex-col gap-[18px] p-6 text-sm leading-6 rounded-2xl bg-white bg-opacity-[0.05] max-w-full relative">
      <h2 className="flex flex-wrap gap-2 text-lg font-bold text-white">
        <div className="mt-1">
          <TowerIcon width={30} height={30} color="white" />
        </div>
        <span className="flex-1">{t("BasicDetails")}</span>
      </h2>

      <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-3">
        <InputField
          inputClassName="inputDisapled"
          className="col-span-1 "
          id="name"
          name="name"
          label={t("CompanyName")}
          value={values.name}
          onChange={handleInputChange}
          disabled
        />
        <InputField
          inputClassName="inputDisapled"
          className="col-span-1"
          id="crNumber"
          name="crNumber"
          label={t("CRNumber")}
          value={values.crNumber}
          onChange={handleInputChange}
          disabled
        />
        <InputField
          className="col-span-1"
          id="website"
          name="website"
          label={t("CompanyWebsite")}
          placeholder={t("EnterWebsiteURL")}
          type="url"
          value={values.website}
          onChange={handleInputChange}
        />
      </div>

      <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-3">
        <Dropdown
          label={t("organizationType")}
          placeholder={t("organizationTypePlaceholder")}
          mandStar={true}
          value={values.type}
          options={Object.entries(OrganizationTypeLabels).map(
            ([value, label]) => ({
              value: Number(value),
              label,
            })
          )}
          onSelect={(value) => setFieldValue("organizationType", value)}
          name="type"
          isOpen={currentOpenDropdown === "organizationType"}
          toggleDropdown={() => toggleDropdown("organizationType")}
        />

        <InputField
          className="col-span-1"
          id="email"
          name="email"
          label={t("EmailAddress")}
          placeholder={t("EnterEmailAddress")}
          type="email"
          value={values.email}
          onChange={handleInputChange}
        />
        <div className="col-span-1">
          <label
            htmlFor="phone"
            className="flex gap-1 text-sm font-medium text-gray-300"
          >
            {t("PhoneNumber")}
            <span className="text-[#EF6B2A] font-[800] leading-6 text-[14px]">
              *
            </span>
          </label>
          <div className="flex w-full gap-2 mt-2">
            <input
              id="phonePrefix"
              name="phonePrefix"
              type="text"
              className="flex-none w-[60px] placeholder-style text-[14px] placeholder:text-[#ffffff80] px-[14px] py-[12px]  bg-white bg-opacity-10 text-white font-normal rounded-[12px] border border-[#ffffff29] focus:outline-none focus:ring-2 focus:ring-orange-500"
              disabled
              value="+966"
            />
            <input
              id="phone"
              name="phone"
              type="text"
              className="w-full min-w-0 placeholder-style text-[14px] placeholder:text-[#ffffff80] px-[16px] py-[12px]  bg-white bg-opacity-10 text-white font-normal rounded-[12px] border border-[#ffffff29] focus:outline-none focus:ring-2 focus:ring-orange-500"
              placeholder={t("5XXXXXXXX")}
              maxLength={9}
              pattern="5\d{8}"
              onInput={(e) => {
                const input = e.currentTarget;
                let value = input.value.replace(/\D/g, "");
                if (value.length > 0 && value[0] !== "5") {
                  value = "5" + value.slice(1);
                }
                input.value = value.slice(0, 9);
                setFieldValue("phone", value);
              }}
              value={values.phone.replace(/^\+966/, "")}
              onChange={handleInputChange}
            />
          </div>
          {touched.phone && errors.phone && (
            <div className="mt-1 text-sm text-red-500">
              {t(String(errors.phone))}
            </div>
          )}
        </div>
      </div>

      <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-3">
        <InputField
          type="text"
          mandStar={true}
          id="addressLine1"
          name="addressLine1"
          label={t("buildingNumber")}
          placeholder={t("buildingNumber")}
          value={values.addressLine1}
          onChange={(e) => setFieldValue("addressLine1", e.target.value)}
          required={true}
        />
        <InputField
          className="col-span-2"
          type="text"
          mandStar={true}
          id="addressLine2"
          name="addressLine2"
          label={t("streetName")}
          placeholder={t("street")}
          value={values.addressLine2}
          onChange={(e) => setFieldValue("addressLine2", e.target.value)}
          required={true}
        />
      </div>
      <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-3">
        <InputField
          type="text"
          mandStar={true}
          id="stateOrProvince"
          name="stateOrProvince"
          label={t("districtName")}
          placeholder={t("districtName")}
          value={values.stateOrProvince}
          onChange={(e) => setFieldValue("stateOrProvince", e.target.value)}
          required={true}
        />

        <InputField
          type="text"
          mandStar={true}
          id="city"
          name="city"
          label={t("cityName")}
          placeholder={t("cityName")}
          value={values.city}
          onChange={(e) => setFieldValue("city", e.target.value)}
          required={true}
        />

        <InputField
          type="text"
          mandStar={true}
          id="postalCode"
          name="postalCode"
          label={t("zipCode")}
          placeholder={t("zipCode")}
          value={values.postalCode}
          onChange={(e) => setFieldValue("postalCode", e.target.value)}
          required={true}
        />
      </div>
      <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-3">
        <InputField
          className="col-span-3"
          type="text"
          mandStar={false}
          id="additionalInfo"
          name="additionalInfo"
          label={t("additionalInfo")}
          placeholder={t("additionalInfo")}
          value={values.additionalInfo}
          onChange={(e) => setFieldValue("additionalInfo", e.target.value)}
        />
      </div>
    </section>
  );
};

export default BasicDetails;
