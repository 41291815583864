import React from "react";

interface ParPayedIconProps {
  color?: string;
  width?: number;
  height?: number;
  className?: string;
}

const ParPayedIcon: React.FC<ParPayedIconProps> = ({
  color = "white",
  width = 44,
  height = 45,
  className,
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 44 45"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g id="Frame">
        <path
          id="Vector"
          d="M17.623 19.0793C17.623 20.6226 19.1457 21.8782 21.0174 21.8782C23.887 21.8782 26.2214 23.9385 26.2214 26.4711C26.2214 28.6655 24.4681 30.5044 22.1332 30.9565L22.1743 32.3623C22.1887 32.8575 21.7956 33.2704 21.2961 33.2849C21.2872 33.2851 21.2783 33.2852 21.2694 33.2852C20.7818 33.2852 20.3797 32.9006 20.3656 32.4143L20.3248 31.0221C17.7818 30.7221 15.8135 28.7963 15.8135 26.4711C15.8135 25.9757 16.2186 25.5741 16.7182 25.5741C17.2179 25.5741 17.623 25.9757 17.623 26.4711C17.623 28.0145 19.1457 29.2701 21.0174 29.2701C22.8892 29.2701 24.4119 28.0145 24.4119 26.4711C24.4119 24.9277 22.8892 23.6721 21.0174 23.6721C18.1479 23.6721 15.8135 21.6117 15.8135 19.0793C15.8135 16.8844 17.5674 15.0453 19.9029 14.5936L19.8606 13.1888C19.8457 12.6937 20.2385 12.2803 20.7379 12.2655C21.2372 12.2509 21.6544 12.6402 21.6693 13.1353L21.7112 14.5284C24.2538 14.8289 26.2216 16.7544 26.2216 19.0793C26.2216 19.5746 25.8165 19.9762 25.3168 19.9762C24.8171 19.9762 24.412 19.5746 24.412 19.0793C24.412 17.5359 22.8893 16.2803 21.0176 16.2803C19.1459 16.2803 17.623 17.5358 17.623 19.0793Z"
          fill={color}
        />
        <path
          id="Ellipse 70 (Stroke)"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M21 6.5C12.1634 6.5 5 13.6634 5 22.5C5 31.3366 12.1634 38.5 21 38.5C28.3689 38.5 34.5768 33.5175 36.4335 26.7359C36.5794 26.2032 37.1294 25.8897 37.6621 26.0355C38.1948 26.1813 38.5084 26.7314 38.3625 27.2641C36.2739 34.8925 29.2931 40.5 21 40.5C11.0589 40.5 3 32.4411 3 22.5C3 12.5589 11.0589 4.5 21 4.5C21.5523 4.5 22 4.94772 22 5.5C22 6.05228 21.5523 6.5 21 6.5Z"
          fill={color}
        />
        <path
          id="Ellipse 71 (Stroke)"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M24.6469 5.86564C24.7963 5.33394 25.3484 5.02399 25.8801 5.17336C27.3836 5.59571 28.8377 6.21446 30.2002 7.02376C30.675 7.30581 30.8313 7.91938 30.5493 8.39421C30.2673 8.86906 29.6537 9.02535 29.1789 8.74331C27.9687 8.0245 26.6764 7.47445 25.3392 7.09882C24.8075 6.94946 24.4976 6.39734 24.6469 5.86564ZM32.4767 9.91468C32.8727 9.52965 33.5058 9.53849 33.8908 9.93443C34.4318 10.4907 34.9427 11.0881 35.4191 11.7257C35.8955 12.3632 36.3236 13.0225 36.7038 13.699C36.9744 14.1804 36.8034 14.7901 36.322 15.0606C35.8405 15.3312 35.2309 15.1603 34.9603 14.6788C34.6223 14.0776 34.2415 13.4909 33.817 12.9228C33.3925 12.3547 32.9378 11.8232 32.457 11.3288C32.072 10.9328 32.0808 10.2997 32.4767 9.91468ZM37.2338 17.34C37.7691 17.2041 38.3133 17.5278 38.4492 18.0631C38.8391 19.5992 39.0204 21.169 38.9994 22.7305C38.9919 23.2828 38.5382 23.7244 37.986 23.717C37.4337 23.7095 36.9921 23.2558 36.9995 22.7036C37.0183 21.3148 36.857 19.9196 36.5107 18.5553C36.3748 18.02 36.6985 17.4759 37.2338 17.34Z"
          fill={color}
        />
      </g>
    </svg>
  );
};

export default ParPayedIcon;
