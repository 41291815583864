import React from "react";
import { useTranslation } from "react-i18next";

const Chip: React.FC<{ label: string; icon: React.ReactNode }> = ({
  label,
  icon,
}) => {
  const { t } = useTranslation();
  return (
    <div className="flex flex-row justify-between items-center 2xl:mx-0.5 h-7 bg-black text-white rounded-3xl w-auto p-1">
      <div className="h-full">{icon}</div>
      <p className="text-nowrap mx-1 text-[11px] ">{t(label)}</p>
    </div>
  );
};

export default Chip;
