import React, { useRef, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import * as Yup from "yup";
import useLocalStorage from "../hooks/useLocalStorage";
import useApi from "../hooks/useApi";
import { PageRoutes } from "../constants";
import { useNavigate } from "react-router-dom";
import OTPInput from "./Shared/OTPInput";
import Toast, { IToast } from "./Shared/Toast";
import LoadingIcon from "./Icons/LoadingIcon";
import {
  verifyRegistration,
  requestRegistration,
} from "../service/RegisterationService";

const SendOtp: React.FC<RegistrationProps> = ({ setScreen }) => {
  const { t } = useTranslation();
  const { getValue: getCR } = useLocalStorage<string>();
  const { setValue: setCompanyId } = useLocalStorage<string>();
  const { setValue: setCrNumber } = useLocalStorage<string>();
  const { setValue: setCompanyData } = useLocalStorage<CompanyDto>();
  const { postData: resendOtp } = useApi<any>();
  const navigate = useNavigate();
  const { setValue: setCompanyDetails } = useLocalStorage<string>();
  const { postData: sendOtp } = useApi<any>();
  const [loading, setLoading] = useState<boolean>(false);

  const [toast, setToast] = useState<IToast | null>(null);

  const inputRefs = useRef<HTMLInputElement[]>([]);

  // Validation schema for OTP
  const validationSchema = Yup.object({
    otp0: Yup.number().required(t("Required")).typeError(t("MustBeANumber")),
    otp1: Yup.number().required(t("Required")).typeError(t("MustBeANumber")),
    otp2: Yup.number().required(t("Required")).typeError(t("MustBeANumber")),
    otp3: Yup.number().required(t("Required")).typeError(t("MustBeANumber")),
  });

  // Initial values for Formik
  const initialValues = {
    otp0: "",
    otp1: "",
    otp2: "",
    otp3: "",
  };

  // Handle form submission
  const handleSubmit = async (values: any) => {
    const otp = `${values.otp0}${values.otp1}${values.otp2}${values.otp3}`;
    const requestId = getCR("requestId");
    try {
      setLoading(true);
      const response = await verifyRegistration({
        requestId,
        otp,
      });
      const data = response.data;

      setCompanyData("companyData", data);
      setCompanyId("companyId", data.id);
      setCompanyDetails("companyName", data.name);
      setCrNumber("CR", data.crNumber);
      setLoading(false);
      navigate(PageRoutes.COMPANY_INFO_REGISTER);
    } catch (error) {
      setLoading(false);
      //consloe.log("Error code:", error.response?.data?.code);
      const errorCode = error.response?.data?.code;

      switch (errorCode) {
        case "XX001":
          setToast({
            type: "error",
            titleKey: "toastErrorTitle",
            messageKey: "companyAlreadyExists",
          });
          break;
        case "XX002":
        case "XX003":
          setToast({
            type: "error",
            titleKey: "toastErrorTitle",
            messageKey: "invalidCR",
          });
          break;
        case "XX004":
          setToast({
            type: "error",
            titleKey: "toastErrorTitle",
            messageKey: "expiredCR",
          });
          break;
        case "XX005":
          setToast({
            type: "error",
            titleKey: "toastErrorTitle",
            messageKey: "managerNotFound",
          });
          break;
        default:
          setToast({
            type: "error",
            titleKey: "toastErrorTitle",
            messageKey: "toastOtpError",
          });
          break;
      }
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: handleSubmit,
    validateOnChange: false,
    validateOnBlur: false,
  });

  useEffect(() => {
    if (inputRefs.current[0]) {
      inputRefs.current[0].focus();
    }
  }, []);

  const cr = getCR("CR");

  const [resendTimer, setResendTimer] = useState<number>(120); // 2 minutes in seconds
  const [canResend, setCanResend] = useState<boolean>(false);

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (resendTimer > 0) {
      timer = setInterval(() => {
        setResendTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    } else {
      setCanResend(true);
    }

    return () => {
      if (timer) clearInterval(timer);
    };
  }, [resendTimer]);

  const handleResendOtp = async () => {
    const cr = getCR("CR");
    try {
      setLoading(true);
      const response = await requestRegistration(cr);

      // Reset the timer and disable resend button
      setResendTimer(120);
      setCanResend(false);
      setLoading(false);
      formik.setValues(initialValues);

      setToast({
        type: "success",
        titleKey: "successResendOtpTitleSendOTPP",
        messageKey: "successResendOtpMessageSendOTPP",
      });
    } catch (error) {
      setLoading(false);
      const errorCode = error.response?.data?.code;

      switch (errorCode) {
        case "XX001":
          setToast({
            type: "error",
            titleKey: "toastErrorTitle",
            messageKey: "companyAlreadyExists",
          });
          break;
        case "XX002":
        case "XX003":
          setToast({
            type: "error",
            titleKey: "toastErrorTitle",
            messageKey: "invalidCR",
          });
          break;
        case "XX004":
          setToast({
            type: "error",
            titleKey: "toastErrorTitle",
            messageKey: "expiredCR",
          });
          break;
        case "XX005":
          setToast({
            type: "error",
            titleKey: "toastErrorTitle",
            messageKey: "managerNotFound",
          });
          break;
        default:
          setToast({
            type: "error",
            titleKey: "errResendOtpTitleSendOTPP",
            messageKey: "errResendOtpMessageSendOTPP",
          });
      }
    }
  };

  const formatTime = (seconds: number): string => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `(${minutes.toString().padStart(2, "0")}:${remainingSeconds
      .toString()
      .padStart(2, "0")})`;
  };

  return (
    <div className="flex flex-col items-center justify-center w-full p-4 m-4 min-h-dvh md:w-1/2 md:p-0">
      <img src="./assets/probuy.svg" className="" alt="probuy logo" />
      <div className="flex flex-col items-center justify-center w-full md:w-3/4">
        <h1 className="mb-4 text-4xl text-center">{t("CompanyRegister")}</h1>
        <p className="my-4 text-base font-light text-center text-white md:text-xl">
          {t("OtpText")}
        </p>
      </div>
      <div className="w-full md:w-[500px] bg-white bg-opacity-10 p-4 rounded-xl shadow-xl flex flex-col justify-center items-center">
        <h2 className="my-1 text-2xl text-white">{t("Otp")}</h2>
        <p>
          {t("crNumber")}: {cr}
        </p>
        <form
          className="w-full p-4"
          onSubmit={(e) => {
            e.preventDefault();
            formik.validateForm().then((errors) => {
              if (Object.keys(errors).length) {
                setToast({
                  type: "error",
                  titleKey: "toastErrorTitle",
                  messageKey: "toastAllFieldsRequired",
                });
              } else {
                setToast(null);
                formik.handleSubmit();
              }
            });
          }}
        >
          <div className="flex gap-4 mt-8">
            {Object.keys(initialValues).map((key, index) => (
              <OTPInput
                className="w-10"
                key={index}
                value={formik.values[key]}
                onChange={(e) => {
                  formik.handleChange(e);
                  if (e.target.value && index < 3) {
                    inputRefs.current[index + 1]?.focus();
                  }
                  if (!e.target.value && index > 0) {
                    inputRefs.current[index - 1]?.focus();
                  }
                }}
                ref={(el) => (inputRefs.current[index] = el!)} // Store input reference
                name={key} // Pass name to OTPInput
              />
            ))}
          </div>
          <button
            disabled={loading}
            type="submit"
            className="flex items-center justify-center w-full gap-2 px-4 py-2 mt-4 text-white bg-gradient-to-r from-orange-500 to-orange-300 rounded-3xl focus:outline-none"
          >
            {t("SubmitOtp")}
            {loading && (
              <div role="status">
                <LoadingIcon color="#fff" />
              </div>
            )}
          </button>
        </form>
        <div className="flex items-center justify-center w-full gap-1 mt-2 text-center">
          <button
            onClick={handleResendOtp}
            disabled={!canResend || loading}
            className={`flex items-center justify-center  ${
              !canResend || loading
                ? "gradient-text opacity-50 cursor-not-allowed"
                : "gradient-text"
            }`}
          >
            {t("ResendOTP")}
          </button>
          <p
            dir="ltr"
            className={`gradient-text ${
              !canResend || loading ? "opacity-100" : ""
            } `}
          >
            {formatTime(resendTimer)}
          </p>
        </div>
      </div>
      {toast && (
        <Toast
          type={toast.type}
          titleKey={toast.titleKey}
          messageKey={toast.messageKey}
          onClose={() => setToast(null)}
        />
      )}
    </div>
  );
};

export default SendOtp;
