import React from "react";

interface NoteIconProps {
  width?: number;
  height?: number;
  className?: string;
  color?: string;
}

const NoteIcon: React.FC<NoteIconProps> = ({
  width = 18,
  height = 20,
  className = "",
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 18 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        id="Union"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.0002 17.6C13.8603 17.6 17.8002 13.6601 17.8002 8.8C17.8002 3.93989 13.8603 0 9.0002 0C4.14009 0 0.200195 3.93989 0.200195 8.8C0.200195 10.8426 0.896146 12.7227 2.06387 14.2161C2.50126 14.7755 2.8002 15.4443 2.8002 16.1544V17.6577C2.8002 18.9269 4.12684 19.7596 5.26974 19.2078L8.59386 17.6031C8.59637 17.6018 8.59632 17.5983 8.59378 17.5971C8.59048 17.5956 8.59166 17.5907 8.59527 17.5908C8.72948 17.5969 8.86448 17.6 9.0002 17.6Z"
        fill={"#EF8D32"}
      />
      <svg
        width="10"
        height="10"
        viewBox="0 0 10 10"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        x="4"
        y="5"
      >
        <g id="icon" clipPath="url(#clip0_6851_189768)">
          <path
            id="Vector"
            d="M4.87232 9.58966C4.5705 9.58935 4.28112 9.46726 4.06766 9.25016C3.8542 9.03307 3.73408 8.7387 3.73366 8.43163C3.73366 7.79276 4.24439 7.27313 4.87209 7.27313C5.49979 7.27313 6.01053 7.79276 6.01053 8.43163C6.0101 8.73862 5.89005 9.03292 5.67668 9.25C5.46332 9.46708 5.17406 9.58922 4.87232 9.58966ZM4.87232 7.68799C4.67855 7.68824 4.49278 7.76667 4.35576 7.90607C4.21875 8.04548 4.14166 8.23448 4.14142 8.43163C4.14166 8.62871 4.21872 8.81765 4.3557 8.95701C4.49267 9.09637 4.67838 9.17478 4.87209 9.17502C5.06584 9.17484 5.25161 9.09646 5.38863 8.9571C5.52566 8.81773 5.60275 8.62875 5.60299 8.43163C5.60275 8.23448 5.52567 8.04548 5.38865 7.90607C5.25163 7.76667 5.06609 7.68824 4.87232 7.68799ZM4.87232 6.69866C4.64135 6.69851 4.41841 6.61239 4.24554 6.45653C4.07267 6.30068 3.96181 6.08587 3.93387 5.8526L3.47198 1.96527C3.43614 1.66166 3.49655 1.35433 3.6444 1.08809C3.79226 0.821848 4.01983 0.610615 4.29395 0.485182C4.56807 0.359749 4.87439 0.326673 5.16828 0.390775C5.46217 0.454878 5.72825 0.612808 5.92774 0.841543C6.06001 0.993403 6.15911 1.17209 6.21849 1.3658C6.27787 1.55951 6.29618 1.76383 6.2722 1.96527L5.81031 5.8526C5.78248 6.08585 5.67173 6.30069 5.49894 6.45658C5.32615 6.61248 5.10327 6.69844 4.87232 6.69866ZM4.87232 0.77271C4.58565 0.77271 4.31205 0.89801 4.12193 1.11594C4.02787 1.224 3.95739 1.35112 3.91513 1.48891C3.87288 1.6267 3.8598 1.77204 3.87676 1.91534L4.33888 5.80313C4.35509 5.93545 4.41825 6.05718 4.51649 6.14544C4.61472 6.2337 4.74126 6.28241 4.87232 6.28241C5.00338 6.28241 5.12992 6.2337 5.22816 6.14544C5.32639 6.05718 5.38955 5.93545 5.40576 5.80313L5.86765 1.91534C5.88457 1.77211 5.87145 1.62685 5.82917 1.48915C5.78689 1.35145 5.71641 1.22443 5.62236 1.11646C5.52831 1.0085 5.41284 0.922037 5.28356 0.862785C5.15428 0.803533 5.01412 0.772834 4.87232 0.77271Z"
            fill="white"
          />
        </g>
        <defs>
          <clipPath id="clip0_6851_189768">
            <rect
              width="9.74359"
              height="9.74359"
              fill="white"
              transform="translate(0.128418 0.102539)"
            />
          </clipPath>
        </defs>
      </svg>
    </svg>
  );
};

export default NoteIcon;
