import * as Yup from "yup";
import { BusinessPremisesType, SalesChannelType } from "../../constants";
import { t } from "i18next";

export const isValidSaudiNumber = (phone: string) => {
  const cleanPhone = phone.replace(/\D/g, "");

  const validFormat = /^966[5]\d{8}$/;
  const isValid = validFormat.test(cleanPhone);

  //consloe.log("Validating phone number:", cleanPhone);
  //consloe.log("Is valid:", isValid);

  return isValid;
};

const validationSchema = Yup.object().shape({
  // Step 1 validations
  name: Yup.string().required("validationErrors.companyNameRequired"),
  cr: Yup.string().required("validationErrors.crNumberRequired"),
  website: Yup.string().url("validationErrors.invalidUrl"),
  email: Yup.string()
    .email("validationErrors.invalidEmailFormat")
    .required("validationErrors.emailRequired"),
  phone: Yup.string()
    .test(
      "is-valid-saudi-number",
      "validationErrors.invalidPhoneNumber",
      function (value) {
        if (!value) return true; // Allow empty values if the field is not required
        const phoneWithPrefix = value.startsWith("966") ? value : `966${value}`;
        return isValidSaudiNumber(phoneWithPrefix);
      }
    )
    .required("validationErrors.phoneRequired"),
  organizationType: Yup.string().required(
    "validationErrors.organizationTypeRequired"
  ),
  // addressLine1: Yup.string().required("validationErrors.addressRequired"),

  // Step 2 validations
  isSaudiCompany: Yup.boolean().required(
    "validationErrors.isSaudiCompanyRequired"
  ),
  businessPremises: Yup.mixed()
    .oneOf([BusinessPremisesType.Owned, BusinessPremisesType.Rent])
    .required("validationErrors.businessPremisesTypeRequired"),
  isAudited: Yup.boolean().required("validationErrors.isAuditedRequired"),
  yearsInBusiness: Yup.number().required(
    "validationErrors.yearsInBusinessRequired"
  ),
  monthsInBusiness: Yup.number().required(
    "validationErrors.monthsInBusinessRequired"
  ),
  sector: Yup.string()
    .notOneOf(["Select Sector"], "validationErrors.sectorRequired")
    .required("validationErrors.sectorRequired"),
  productOrServiceProvided: Yup.string()
    .notOneOf(
      ["Select Product/Service Provided"],
      "validationErrors.productOrServiceProvidedRequired"
    )
    .required("validationErrors.productOrServiceProvidedRequired"),
  headcount: Yup.string()
    .min(0, "validationErrors.headcountMin")
    .required("validationErrors.headcountRequired"),
  salesChannel: Yup.mixed()
    .oneOf([
      SalesChannelType.Online,
      SalesChannelType.Offline,
      SalesChannelType.Both,
    ])
    .nullable()
    .required("validationErrors.salesChannelTypeRequired"),
  monthlyRent: Yup.string().when("businessPremises", {
    is: (val: BusinessPremisesType) => val === BusinessPremisesType.Rent,
    then: (schema) =>
      schema
        .min(0, "validationErrors.monthlyRentMin")
        .required("validationErrors.monthlyRentRequired"),
    otherwise: (schema) => schema.notRequired(),
  }),
  annualRevenue: Yup.string()
    .min(0, "validationErrors.annualRevenueMin")
    .required("validationErrors.annualRevenueRequired"),
  numberOfClients: Yup.string()
    .min(0, "validationErrors.numberOfClientsMin")
    .required("validationErrors.numberOfClientsRequired"),

  // Address Info validations
  buildingNumber: Yup.string().required(
    "validationErrors.buildingNumberRequired"
  ),
  streetName: Yup.string().required("validationErrors.streetNameRequired"),
  districtName: Yup.string().required("validationErrors.districtNameRequired"),
  cityName: Yup.string().required("validationErrors.cityNameRequired"),
  zipCode: Yup.string()
    .matches(/^\d+$/)
    .required("validationErrors.zipCodeRequired"),
  additionalInfo: Yup.string().optional(),
});

export default validationSchema;
