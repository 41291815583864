import React from "react";

interface IconProps {
  className?: string;
  color?: string; // Add a color prop to specify the color or gradient
}

const DownloadIcon: React.FC<IconProps> = ({ className, color }) => {
  // Define the default gradient if no color prop is provided
  const defaultGradient = (
    <linearGradient
      id="defaultGradient"
      x1="1.25293"
      y1="2.25269"
      x2="22.8113"
      y2="21.683"
      gradientUnits="userSpaceOnUse"
    >
      <stop stopColor="#EF6B2A" />
      <stop offset="1" stopColor="#F1BC62" />
    </linearGradient>
  );

  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <defs>{color ? null : defaultGradient}</defs>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.287 4.185C10.4043 3.83842 9.45419 3.69727 8.5088 3.77225C7.56341 3.84723 6.64748 4.13637 5.83038 4.61776C5.01328 5.09916 4.31645 5.76019 3.79267 6.55078C3.26888 7.34138 2.93188 8.2408 2.80719 9.18093C2.6825 10.1211 2.77339 11.0772 3.07297 11.977C3.37255 12.8768 3.87297 13.6967 4.53633 14.3744C4.82606 14.6704 4.82097 15.1453 4.52495 15.435C4.22893 15.7247 3.75408 15.7196 3.46435 15.4236C2.64179 14.5832 2.02126 13.5666 1.64978 12.4509C1.27829 11.3351 1.16559 10.1495 1.32021 8.98371C1.47483 7.81795 1.89271 6.70266 2.5422 5.72233C3.19169 4.74199 4.05577 3.92231 5.06897 3.32538C6.08217 2.72845 7.21792 2.36992 8.39021 2.27695C9.5625 2.18397 10.7406 2.359 11.8352 2.78876C12.9298 3.21852 13.9123 3.89175 14.7082 4.75745C15.3763 5.48415 15.8982 6.33047 16.2476 7.25002H17.5003C17.5002 7.25002 17.5003 7.25002 17.5003 7.25002C18.6267 7.24991 19.7233 7.61208 20.6281 8.28303C21.5329 8.95401 22.1979 9.89819 22.5248 10.9761C22.8518 12.0541 22.8234 13.2086 22.4438 14.2691C22.0642 15.3297 21.3536 16.24 20.4169 16.8657C20.0725 17.0958 19.6067 17.003 19.3767 16.6586C19.1466 16.3142 19.2393 15.8484 19.5838 15.6184C20.2528 15.1714 20.7604 14.5212 21.0315 13.7637C21.3027 13.0061 21.3229 12.1815 21.0894 11.4115C20.8559 10.6416 20.3809 9.96715 19.7346 9.48789C19.0883 9.00862 18.305 8.74993 17.5004 8.75002H15.7103C15.3788 8.75002 15.0865 8.5323 14.9917 8.21459C14.7204 7.30587 14.2458 6.47081 13.6039 5.77266C12.9621 5.07451 12.1698 4.53158 11.287 4.185ZM12.0003 11.25C12.4146 11.25 12.7503 11.5858 12.7503 12V19.1894L15.47 16.4697C15.7629 16.1768 16.2378 16.1768 16.5307 16.4697C16.8236 16.7626 16.8236 17.2375 16.5307 17.5303L12.5307 21.5303C12.39 21.671 12.1993 21.75 12.0003 21.75C11.8014 21.75 11.6107 21.671 11.47 21.5303L7.47001 17.5303C7.17712 17.2375 7.17712 16.7626 7.47001 16.4697C7.7629 16.1768 8.23778 16.1768 8.53067 16.4697L11.2503 19.1894V12C11.2503 11.5858 11.5861 11.25 12.0003 11.25Z"
        fill={color || "url(#defaultGradient)"} // Use the passed color or default gradient
      />
    </svg>
  );
};

export default DownloadIcon;
