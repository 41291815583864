import React from "react";

interface StyledExclamationIconProps {
  className?: string;
  width?: string | number;
  height?: string | number;
}

const IssueIcon: React.FC<StyledExclamationIconProps> = ({
  className,
  width = 24,
  height = 24,
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 33 32"
      fill="none"
      className={className}
    >
      <g id="Frame 1984078018">
        <g id="Group 631681">
          <circle id="Ellipse 72" cx="16.5" cy="16" r="16" fill="#C72C41" />
          <path
            id="Vector"
            d="M16.5005 26C15.8585 25.9994 15.2427 25.735 14.7883 25.2645C14.3345 24.7939 14.0792 24.1561 14.0782 23.4911C14.0782 22.1067 15.1647 20.9812 16.5 20.9812C17.8352 20.9812 18.9218 22.1067 18.9218 23.4911C18.9207 24.1561 18.6654 24.7939 18.2116 25.2639C17.7578 25.7344 17.142 25.9989 16.5005 26ZM16.5005 21.88C16.0881 21.8806 15.6927 22.05 15.4014 22.3523C15.1102 22.6545 14.946 23.0639 14.9454 23.4911C14.946 23.9178 15.1102 24.3272 15.4014 24.6295C15.6927 24.9311 16.0876 25.1011 16.5 25.1017C16.9118 25.1011 17.3072 24.9317 17.5985 24.6295C17.8903 24.3278 18.054 23.9184 18.0545 23.4911C18.054 23.0639 17.8903 22.6545 17.5985 22.3523C17.3072 22.05 16.9123 21.8806 16.5005 21.88ZM16.5005 19.7362C16.0091 19.7362 15.5345 19.5495 15.1669 19.2117C14.7992 18.874 14.5636 18.4084 14.5042 17.9034L13.5212 9.48071C13.4454 8.82294 13.5736 8.15683 13.8883 7.58017C14.2025 7.00351 14.6869 6.54574 15.27 6.27407C15.8531 6.00185 16.5049 5.93019 17.13 6.06907C17.7551 6.20796 18.3212 6.55018 18.7456 7.04573C19.027 7.37517 19.2376 7.76239 19.3641 8.18183C19.4901 8.60127 19.5294 9.04405 19.4781 9.48071L18.4958 17.9034C18.4363 18.4084 18.2007 18.874 17.8331 19.2117C17.466 19.5495 16.9914 19.7356 16.5005 19.7362ZM16.5005 6.89684C15.8907 6.89684 15.3087 7.16851 14.904 7.64073C14.7038 7.87461 14.5538 8.15017 14.4643 8.4485C14.3743 8.74738 14.3465 9.06182 14.3825 9.37238L15.3654 17.7962C15.3998 18.0829 15.5345 18.3462 15.7434 18.5378C15.9523 18.729 16.2218 18.8345 16.5005 18.8345C16.7792 18.8345 17.0481 18.729 17.2571 18.5378C17.4665 18.3462 17.6007 18.0829 17.6351 17.7962L18.6174 9.37238C18.6534 9.06238 18.6256 8.74738 18.5356 8.44905C18.4456 8.15072 18.2961 7.87572 18.096 7.64184C17.8958 7.40795 17.6503 7.22017 17.3749 7.09184C17.1 6.96351 16.8021 6.89684 16.5005 6.89684Z"
            fill="white"
          />
        </g>
      </g>
    </svg>
  );
};

export default IssueIcon;
