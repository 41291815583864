import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import FileUpload from "../Shared/FileUpload";
import Document from "../Document";
import { useTranslation } from "react-i18next";
import { PageRoutes } from "../../constants";
import useApi from "../../hooks/useApi";
import useLocalStorage from "../../hooks/useLocalStorage";
import Toast, { IToast } from "../Shared/Toast";
import LoadingIcon from "../Icons/LoadingIcon";

const AccountDocsUpload = () => {
  const today = new Date();
  const formattedDate = `${String(today.getDate()).padStart(2, "0")}/${String(
    today.getMonth() + 1
  ).padStart(2, "0")}/${today.getFullYear()}`;

  const { t, i18n } = useTranslation();
  const { putData } = useApi<Organization>();
  const {
    getValue: getCompanyId,
    setValue: setCompanyDataToLocalStorage,
    getValue,
  } = useLocalStorage();
  const navigate = useNavigate(); // Use the navigate hook from react-router-dom

  const [zakatFile, setZakatFile] = useState<File | null>(null);
  const [financialStatementFile, setFinancialStatementFile] =
    useState<File | null>(null);
  const [rentContractFile, setRentContractFile] = useState<File | null>(null);
  const [allFilesUploaded, setAllFilesUploaded] = useState(false);
  const [companyData, setCompanyData] = useState<Organization | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const [toast, setToast] = useState<IToast | null>(null);

  const zakatFileRef = useRef<HTMLInputElement>(null);
  const financialFileRef = useRef<HTMLInputElement>(null);
  const rentFileRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    const allFilesPresent =
      zakatFile && financialStatementFile && rentContractFile;
    setAllFilesUploaded(!!allFilesPresent);
  }, [zakatFile, financialStatementFile, rentContractFile]);

  useEffect(() => {
    if (toast) {
      setToast({
        ...toast,
        titleKey: toast.titleKey,
        messageKey: toast.messageKey,
      });
    }
  }, [i18n.language]);

  const handleFileChange = (
    fileType: "zakat" | "financial" | "rent",
    setFile: React.Dispatch<React.SetStateAction<File | null>>,
    fileInputRef: React.RefObject<HTMLInputElement>
  ) => {
    if (
      fileInputRef.current &&
      fileInputRef.current.files &&
      fileInputRef.current.files[0]
    ) {
      const file = fileInputRef.current.files[0];
      if (file.type === "application/pdf") {
        setFile(file);
      } else {
        //consloe.log("Only PDF files are allowed.");
        fileInputRef.current.value = ""; // Clear the input
      }
    }
  };

  const handleZakatFileChange = () =>
    handleFileChange("zakat", setZakatFile, zakatFileRef);
  const handleFinancialFileChange = () =>
    handleFileChange("financial", setFinancialStatementFile, financialFileRef);
  const handleRentFileChange = () =>
    handleFileChange("rent", setRentContractFile, rentFileRef);

  const handleDeleteZakatFile = () => {
    setZakatFile(null);
  };

  const handleDeleteFinancialFile = () => {
    setFinancialStatementFile(null);
  };

  const handleDeleteRentFile = () => {
    setRentContractFile(null);
  };

  const handleUpload = async () => {
    if (!zakatFile || !financialStatementFile || !rentContractFile) return;
    setIsLoading(true);

    try {
      const readFilesAsBase64 = (file: File): Promise<string> => {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onload = () => resolve(reader.result as string);
          reader.onerror = (error) => reject(error);
          reader.readAsDataURL(file);
        });
      };

      const createDocumentRequestDto = async (
        file: File,
        documentName: string
      ): Promise<UploadDocumentRequestDto> => {
        const base64Content = await readFilesAsBase64(file);
        const fileContent = base64Content.split(",")[1];

        return {
          fileContent,
          fileExtension: file.name.split(".").pop() || "",
          documentName,
        };
      };

      const documents: UploadDocumentRequestDto[] = await Promise.all([
        createDocumentRequestDto(zakatFile, "ZakatAndTaxCertificateUri"),
        createDocumentRequestDto(
          financialStatementFile,
          "AuditedFinancialStatementsUri"
        ),
        createDocumentRequestDto(rentContractFile, "RentContractUri"),
      ]);

      const companyId = getCompanyId("companyId");
      if (!companyId) {
        throw new Error("Company ID is null or not found in local storage");
      }

      //consloe.log("Company ID:", companyId);
      //consloe.log("Documents to upload:", documents);

      const { data: response } = await putData(
        `/company/${companyId}/manual-rating-request`,
        documents
      );

      const existingCompanyData = getValue("companyData");

      if (
        typeof existingCompanyData === "object" &&
        existingCompanyData !== null &&
        typeof response === "object" &&
        response !== null
      ) {
        const updatedCompanyData = { ...existingCompanyData, ...response };
        setCompanyDataToLocalStorage("companyData", updatedCompanyData);
      }

      //consloe.log("Response from uploading documents:", response);
      if (response.status === 1) {
        navigate(PageRoutes.MANUAL_PENDING_CREDIT_SCORE);
        //consloe.log("navigaton to M done");
      }
      if (
        response.zakatAndTaxCertificateUri &&
        response.rentContractUri &&
        response.auditedFinancialStatementsUri
      ) {
        setCompanyData(response);
      } else {
        console.error("URIs for uploaded files are missing or null:", response);
      }
    } catch (error) {
      console.error("Error uploading documents:", error);
      setToast({
        type: "error",

        titleKey: "Error",
        messageKey: "uperr",
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="flex flex-col w-full gap-10">
      <div className="flex flex-col items-center justify-center gap-2 text-center ">
        <h1 className="mt-8 mb-2 text-2xl font-bold md:text-4xl leading-[56px]">
          {t("uploadDocs")}
        </h1>
        <div className="flex flex-col text-center max-w-[400px]">
          <p className=" text-[16px] font-normal leading-7s">
            {t("uploadDocsText")}
          </p>
          <p className="text-[16px] font-normal leading-7 gradient-text">
            {t("onlypdf")}
          </p>
        </div>
      </div>

      <div className="flex flex-col items-center justify-center p-4 bg-white h-3/4 bg-opacity-5 rounded-xl">
        <div>
          <h2 className="my-4 text-lg font-bold text-white md:text-2xl">
            {t("company_docs_title")}
          </h2>
        </div>
        <div className="w-full">
          {zakatFile ? (
            <Document
              span={3}
              type={"doc"}
              data={{
                file: zakatFile,
                date: formattedDate,
                name: t("zakat"),
              }}
              onDelete={handleDeleteZakatFile}
            />
          ) : (
            <FileUpload
              span={3}
              title={t("zakat")}
              inputRef={zakatFileRef}
              onFileChange={handleZakatFileChange}
            />
          )}
          {financialStatementFile ? (
            <Document
              span={3}
              type={"doc"}
              data={{
                file: financialStatementFile,
                date: formattedDate,
                name: t("financial"),
              }}
              onDelete={handleDeleteFinancialFile}
            />
          ) : (
            <FileUpload
              span={3}
              title={t("financial")}
              inputRef={financialFileRef}
              onFileChange={handleFinancialFileChange}
            />
          )}
          {rentContractFile ? (
            <Document
              span={3}
              type={"doc"}
              data={{
                file: rentContractFile,
                date: formattedDate,
                name: t("rent_contract"),
              }}
              onDelete={handleDeleteRentFile}
            />
          ) : (
            <FileUpload
              span={3}
              title={t("rent_contract")}
              inputRef={rentFileRef}
              onFileChange={handleRentFileChange}
            />
          )}
        </div>

        <div className="flex items-center justify-center w-full">
          <button
            onClick={() => {
              // window.location.href = PageRoutes.LEAN;
              navigate(-1);
            }}
            className="p-2 px-4 mx-4 border-2 border-orange-400 bg-neutral-900 gradient-text rounded-3xl"
          >
            {t("back")}
          </button>
          <button
            onClick={handleUpload}
            className={`flex items-center justify-center gap-2 w-1/2 mx-2 p-2 rounded-3xl bg-gradient-to-r from-orange-500 to-orange-300 text-white ${
              allFilesUploaded && !isLoading
                ? "opacity-100"
                : "opacity-50 cursor-not-allowed"
            }`}
            disabled={!allFilesUploaded || isLoading}
          >
            {t("uploaddocs")}

            {isLoading && (
              <div role="status">
                <LoadingIcon />
              </div>
            )}
          </button>
        </div>
      </div>
      {toast && (
        <Toast
          type={toast.type}
          titleKey={toast.titleKey}
          messageKey={toast.messageKey}
          onClose={() => setToast(null)}
        />
      )}
    </div>
  );
};

export default AccountDocsUpload;
