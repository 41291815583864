import React, { useEffect, useState } from "react";
import CompanyInfo from "../lib/components/signup/BasicInfo";
import ProgressBar from "../lib/components/signup/ProgressBar";
import { useTranslation } from "react-i18next";
import BusinessInformationSignIn from "../lib/components/signup/BusinessInfoSignIn";
import AddressInfo from "../lib/components/signup/AddressInfo";
import Layout3 from "../lib/components/layout/Layout3";
import { Formik, FormikHelpers } from "formik";
import useLocalStorage from "../lib/hooks/useLocalStorage";
import CompanyDetailsSchema from "../lib/components/Schemas/CompanyDetailsSchema";
import {
  BusinessPremisesType,
  PageRoutes,
  SalesChannelType,
} from "../lib/constants";
import { useNavigate } from "react-router-dom";
import { API_BASE_URL } from "../lib/constants/api-constants";
import { t } from "i18next";
import { UserInfo } from "./Signin";
import { IAPIAddressFormat } from "../lib/components/signup/AddressInfo";
import Toast, { IToast } from "../lib/components/Shared/Toast";
import LoadingSpinner from "../lib/components/Shared/LoadingSpinner";

export interface ICompanyDetails {
  // Step 1 fields
  name: string;
  cr: string;
  website: string;
  email: string;
  phone: string;
  organizationType: string;

  // Step 2 fields
  isSaudiCompany: boolean;
  businessPremises: BusinessPremisesType;
  isAudited: boolean;
  yearsInBusiness: string; // Changed to string
  monthsInBusiness: string; // Changed to string
  sector: string;
  productOrServiceProvided: string;
  headcount: string;
  salesChannel: SalesChannelType | null;
  monthlyRent: string;
  annualRevenue: string;
  numberOfClients: string;

  // Step 3 fields
  buildingNumber: string;
  streetName: string;
  districtName: string;
  cityName: string;
  zipCode: string;
  additionalInfo: string;
}

const registerCompany = async (url, body) => {
  try {
    const getToken = () => sessionStorage.getItem("keycloak_token");
    const token = getToken();
    const getUserId = () => localStorage.getItem("userId");
    const userId = getUserId();

    const response = await fetch(`${API_BASE_URL}${url}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "user-id": userId.replaceAll('"', ""),
      },

      body: JSON.stringify(body),
    });

    if (!response.ok) {
      const errorData = await response.json().catch(() => null);
      throw new Error(
        `Error posting data: ${response.statusText}. Details: ${JSON.stringify(
          errorData
        )}`
      );
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error during company registration:", error);
    return undefined;
  }
};

const CompanyDetailsSignIn = () => {
  const navigate = useNavigate();
  const { getValue: getCrNumber } = useLocalStorage<string>();
  const { getValue: getCompanyData } = useLocalStorage<ICompanyDetails>();
  const { getValue: getUserInfo } = useLocalStorage<UserInfo>();
  const { setValue: setCompanyId } = useLocalStorage<string>();
  const [loading, setLoading] = useState<boolean>(false);
  const [toast, setToast] = useState<IToast | null>(null);
  const [showSpinner, setShowSpinner] = useState(false);

  const [step, setStep] = useState(1);

  const { i18n } = useTranslation();
  const authLogo = "./assets/probuy.svg";

  useEffect(() => {
    const handlePopState = (event) => {
      navigate(PageRoutes.COMPANY_INFO_REGISTER);
    };

    window.history.pushState(null, "", window.location.href);
    window.addEventListener("popstate", handlePopState);

    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, [navigate]);
  useEffect(() => {
    //consloe.log("request Id:>", JSON.parse(localStorage.getItem("requestId")));
  }, []);

  const companyDetailsValues: ICompanyDetails = {
    //step 1
    name: getCompanyData("companyData")?.name || "",
    cr: getCrNumber("CR") || "",
    website: "",
    email: "",
    phone: "",
    organizationType: "",

    //step 2
    isSaudiCompany: true,
    businessPremises: BusinessPremisesType.Owned,
    isAudited: true,
    yearsInBusiness: "",
    monthsInBusiness: "",
    sector: "",
    productOrServiceProvided: "",
    headcount: "",
    salesChannel: null,
    monthlyRent: "",
    annualRevenue: "",
    numberOfClients: "",

    //step 3
    buildingNumber: "",
    streetName: "",
    districtName: "",
    cityName: "",
    zipCode: "",
    additionalInfo: "",
  };
  //consloe.log("Initial Values:", companyDetailsValues);

  const handleFormSubmit = async (
    values: ICompanyDetails,
    actions: FormikHelpers<ICompanyDetails>
  ) => {
    // consloe.log(
    //   "CompanyDetailsSignIn handleFormSubmit called with values:",
    //   values
    // );
    const userInfo = getUserInfo("userInfo");

    const requestBody = {
      requestId: JSON.parse(localStorage.getItem("requestId") || "{}"),
      name: values.name,
      crNumber: values.cr,
      website: values.website,
      email: values.email || userInfo?.email || "",
      phone: `+966${values.phone}`,
      type: Number(values.organizationType),
      userId: JSON.parse(localStorage.getItem("userId") || "{}"),
      address: {
        addressLine1: values.buildingNumber,
        addressLine2: values.streetName,
        city: values.cityName,
        stateOrProvince: values.districtName,
        country: "Saudi Arabia",
        postalCode: values.zipCode.toString(),
        additionalInfo: values.additionalInfo,
      },
      yearsInBusiness: Number(values.yearsInBusiness) || 0,
      monthsInBusiness: Number(values.monthsInBusiness) || 0,
      isSaudiCompany: values.isSaudiCompany,
      headcount: Number(values.headcount.toString().replace(/,/g, "")) || 0,
      sector: values.sector,
      productOrServiceProvided: values.productOrServiceProvided,
      businessPremises: Number(values.businessPremises),
      salesChannel: values.salesChannel ? Number(values.salesChannel) : null,
      monthlyRent: Number(values.monthlyRent.toString().replace(/,/g, "")) || 0,
      annualRevenue:
        Number(values.annualRevenue.toString().replace(/,/g, "")) || 0,
      isAudited: values.isAudited,
      clientCount:
        Number(values.numberOfClients.toString().replace(/,/g, "")) || 0,
    };

    //consloe.log("Request body prepared:", requestBody);

    try {
      setLoading(true);
      //consloe.log("Calling registerCompany...");
      const response = await registerCompany(
        "/company/registration/register",
        requestBody
      );

      //consloe.log("registerCompany response:", response);

      if (response) {
        setLoading(false);
        // consloe.log(
        //           "Company registration successful, navigating to CONNECT_BANK"
        //         );
        setCompanyId("companyId", response.id);
        setToast({
          type: "success",
          titleKey: "SuccessTitle",
          messageKey: "CompanyRegistrationSuccess",
        });
        setShowSpinner(true);
        setTimeout(() => {
          navigate(PageRoutes.MANUAL_BANK_CONNECT); // only for now until lean fixed
          // navigate(PageRoutes.CONNECT_BANK);
        }, 3000); // Navigate after 3 seconds to allow the user to see the toast
      } else {
        setLoading(false);
        console.error("Registration response is undefined");
        setToast({
          type: "error",
          titleKey: "ErrorTitle",
          messageKey: "CompanyRegistrationError",
        });
      }
    } catch (error) {
      setLoading(false);
      console.error("Error during company registration:", error);
      setToast({
        type: "error",
        titleKey: "ErrorTitle",
        messageKey: "CompanyRegistrationError",
      });
    }

    actions.setSubmitting(false);
  };

  const handleAddressSubmit = (apiFormattedAddress: IAPIAddressFormat) => {
    // Here you can send the apiFormattedAddress to your API
    //consloe.log("Formatted address for API:", apiFormattedAddress);
    // Call your API function here
  };

  return (
    <Layout3>
      {toast && (
        <Toast
          type={toast.type}
          titleKey={toast.titleKey}
          messageKey={toast.messageKey}
          onClose={() => setToast(null)}
        />
      )}
      <div dir={i18n.dir()} className="w-full md:w-1/2">
        <div className="flex flex-col gap-[40px] w-full bg-black ">
          <div className="flex flex-col items-center self-stretch justify-center">
            <img
              src={authLogo}
              alt="probuy"
              className="h-[96px] m-auto w-[209.455px]"
            />
            <div className="flex flex-col items-center justify-center gap-2 ">
              <h1 className=" mx-auto text-[36px] text-center leading-[56px] font-bold text-white max-sm:text-[28px] max-sm:leading-[36px] ">
                {t("CompanyRegister")}
              </h1>
              <span className="text-[16px] font-normal leading-[28px] text-center text-white">
                {t("CompanyDetailsText")}
              </span>
            </div>
          </div>

          <div className="flex flex-col gap-8 p-6  bg-white bg-opacity-5 rounded-[16px] mb-6 min-h-[50vh]">
            <div className="flex flex-col self-stretch text-center">
              <h2 className=" text-[24px] font-bold text-center text-white leading-8">
                {t("CompanyDetails")}
              </h2>
            </div>
            <div className="flex items-center self-stretch justify-center">
              <ProgressBar step={step} setStep={setStep} />
            </div>
            {showSpinner ? (
              <LoadingSpinner />
            ) : (
              <Formik
                initialValues={companyDetailsValues}
                validationSchema={CompanyDetailsSchema}
                onSubmit={handleFormSubmit}
              >
                {({ handleSubmit, isSubmitting, values }) => (
                  <>
                    {step === 1 && (
                      <CompanyInfo onPageChange={() => setStep(2)} />
                    )}
                    {step === 2 && (
                      <BusinessInformationSignIn
                        setStep={setStep}
                        step={step}
                        onSubmit={() => setStep(3)}
                        loading={loading}
                      />
                    )}
                    {step === 3 && (
                      <AddressInfo
                        step={step}
                        setStep={setStep}
                        onSubmit={handleAddressSubmit}
                        loading={isSubmitting || loading}
                      />
                    )}
                  </>
                )}
              </Formik>
            )}
          </div>
        </div>
      </div>
    </Layout3>
  );
};

export default CompanyDetailsSignIn;
