import React from "react";
import AddFileIcon from "../Icons/AddFileIcon";
import DocumentIcon from "../Icons/DocumentIcon";

const FileUpload = ({
  title,
  onFileChange,
  span,
  inputRef,
}: {
  span: number;
  title: string;
  onFileChange: () => void;
  inputRef: React.RefObject<HTMLInputElement>;
}) => {
  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      onFileChange();
    }
  };

  return (
    <div
      className={`bg-white bg-opacity-[0.07] w-full col-span-${span} mx-1 my-4 flex justify-between rounded-xl p-3`}
    >
      <div className="flex flex-row items-center justify-around">
        <div className="p-3 bg-opacity-75 rounded-full bg-gradient-to-r from-neutral-800 to-orange-400">
          <DocumentIcon />
        </div>
        <p
          dangerouslySetInnerHTML={{ __html: title }}
          className="mx-2 text-lg text-white md:text-xl"
        />
      </div>
      <div className="flex items-center justify-center w-12 h-12 p-1 bg-white rounded-full min-w-12 min-h-12 bg-opacity-5">
        <label
          htmlFor={`fileUpload-${title}`}
          className="flex flex-row text-white cursor-pointer"
        >
          <AddFileIcon className="mx-1" />
        </label>
        <input
          name={`fileUpload-${title}`}
          type="file"
          id={`fileUpload-${title}`}
          className="hidden"
          accept=".pdf" // Updated to only accept PDFs
          ref={inputRef}
          onChange={handleFileChange}
        />
      </div>
    </div>
  );
};

export default FileUpload;
