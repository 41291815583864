import React, { useContext, useEffect } from "react";
import Layout3 from "../lib/components/layout/Layout3";
import { useTranslation } from "react-i18next";
import useLocalStorage from "../lib/hooks/useLocalStorage";
import { PageRoutes } from "../lib/constants";
import { AppContext } from "../lib/context/app-context";
import { keycloak } from "../keycloak";
import { useNavigate } from "react-router-dom";
import ProcessIcon from "../lib/components/Icons/ProcessIcon";
import { ContentSection } from "../lib/components/PendingProcess/ContentSection";
import useApi from "../lib/hooks/useApi";

const ManualAccPending = () => {
  const { t } = useTranslation();
  const { clearValue, getValue } = useLocalStorage();
  const { setValue: setAuth } = useLocalStorage<boolean>();
  const { isAuthenticated } = useContext(AppContext);
  const navigate = useNavigate();
  const { getData } = useApi<any>();

  useEffect(() => {
    const handlePopState = (event) => {
      navigate(PageRoutes.MANUAL_PENDING_CREDIT_SCORE);
    };

    window.history.pushState(null, "", window.location.href);
    window.addEventListener("popstate", handlePopState);

    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, [navigate]);

  useEffect(() => {
    // consloe.log(
    //   "Component mounted: Adding history entry and popstate listener"
    // );

    // Add a new entry to the history stack
    window.history.pushState(null, "", window.location.href);

    const handlePopState = (event: PopStateEvent) => {
      //consloe.log("Popstate event triggered", event);
      // Redirect the user to MANUAL_PENDING_CREDIT_SCORE when they try to go back
      navigate(PageRoutes.MANUAL_PENDING_CREDIT_SCORE, { replace: true });
    };

    window.addEventListener("popstate", handlePopState);

    return () => {
      //consloe.log("Component unmounting: Removing popstate listener");
      window.removeEventListener("popstate", handlePopState);
    };
  }, [navigate]);

  useEffect(() => {
    const userId = getValue("userId");
    //consloe.log("Fetched userId from localStorage:", userId);

    const checkCompanyStatus = async () => {
      try {
        //consloe.log("Checking company status for userId:", userId);
        const data = await getData(`/company/byuser/${userId}/check`);
        //consloe.log("Company status response data:", data);
        const status = data?.status;

        if (status === 2 || status === 3) {
          //consloe.log("Status is 2, navigating to DASHBOARD page");
          navigate(PageRoutes.DASHBOARD, { replace: true });
        } else if ([4, 5].includes(status)) {
          //consloe.log(
            // "Status is  4, or 5, navigating to MANUAL_PENDING_CREDIT_SCORE page"
          // );
          navigate(PageRoutes.CREDIT_SCORE, { replace: true });
        } else {
          //consloe.log("Status is not 2, 3, 4, or 5, no navigation needed");
        }
      } catch (error) {
        console.error("Failed to check company status:", error);
      }
    };

    const intervalId = setInterval(checkCompanyStatus, 10000); // Check every 10 seconds
    //consloe.log("Started polling company status with interval ID:", intervalId);

    return () => {
      //consloe.log(
        // "Component unmounting: Clearing interval with ID:",
        // intervalId
      // );
      clearInterval(intervalId); // Clear the interval when the component unmounts
    };
  }, [navigate, getValue, getData]);

  return (
    <Layout3>
      <div className="flex flex-col items-center justify-center w-full p-4 min-h-dvh md:w-1/3 md:p-0">
        <img src="./assets/probuy.svg" className="" alt="probuy logo" />
        <div className="flex flex-col self-stretch justify-center text-center">
          <div className="self-center max-w-full aspect-square w-[194px] max-md:mt-10">
            <ProcessIcon />
          </div>
          <h1 className="w-full mt-4 text-4xl font-bold leading-10 text-white max-md:max-w-full">
            {t("creditScore")}
          </h1>
          <ContentSection
            description={t("creditScoreText")}
            subDescription={t("creditScorePara")}
          />
          <button
            className="flex items-center self-center justify-center max-w-full px-8 py-3 mt-4 text-base font-bold leading-6 text-right border border-orange-500 gradient-text rounded-3xl"
            onClick={() => navigate(PageRoutes.LOGOUT)}
          >
            {t("logout")}
          </button>
        </div>
      </div>
    </Layout3>
  );
};

export default ManualAccPending;
