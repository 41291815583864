import React from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { useNavigate } from "react-router-dom";
import { PageRoutes } from "../../constants";
import { useTranslation } from "react-i18next";
import ExitArrow2 from "../Icons/ExitArrow2";
import ExitArrow from "../Icons/ExitArrow";

function DetailsLoading() {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  return (
    <SkeletonTheme baseColor="#202020" highlightColor="#444">
      <div className="flex flex-col gap-6 bg-[#080808] p-6 w-full overflow-auto h-full scrollbar-hide">
        <div className="flex flex-col self-stretch gap-4">
          <div className="flex items-center self-stretch justify-between w-full gap-2 max-sm:flex-col max-sm:items-start ">
            <div className="flex items-center justify-center gap-2">
              <div
                onClick={() => navigate(PageRoutes.ORDERS)}
                className="cursor-pointer"
              >
                {i18n.language === "en" ? <ExitArrow2 /> : <ExitArrow />}
              </div>
              <div>
                <h2 className="text-[24px] flex gap-1 font-bold leading-7 text-white text-opacity-[0.87] ">
                  <Skeleton height={25} width={180} />
                  <Skeleton height={25} width={60} />
                  {/* {t("orderNumberLabel")}: {orderNumber} */}
                </h2>
                <p className="text-[14px] font-bold flex gap-1 leading-[18px] text-white text-opacity-60">
                  <Skeleton height={15} width={90} />
                  <Skeleton height={15} width={45} />
                  {/* {t("orderDateLabel")}: {orderDate} */}
                </p>
              </div>
            </div>
            <div className="flex max-sm:w-full">
              <Skeleton
                height={48}
                width={150}
                containerClassName="rounded-[24px]"
                className="!rounded-[24px]"
              />
            </div>
          </div>
          <div className="flex items-center self-stretch justify-between w-full gap-2 max-sm:gap-4 max-sm:flex-col max-sm:items-start ">
            <div className="flex  px-4 py-2 justify-center max-sm:justify-between items-center gap-2 self-stretch rounded-[12px]">
              <div className="max-sm:flex-col flex flex-row text-[16px] font-bold leading-8 text-nowrap md:gap-2">
                <Skeleton height={50} width={400} />
              </div>
            </div>
            <div className="flex items-center gap-4 justify-center p-4 rounded-[16px] ">
              <Skeleton height={30} width={150} className="!rounded-[16px]" />
            </div>
          </div>
        </div>
        <div className="flex flex-col self-stretch flex-1 gap-4">
          <div className="gap-2 md:grid md:grid-cols-3 max-sm:grid-rows-2 ">
            <SkeletonTheme baseColor="#cfcfcf" highlightColor="#fff">
              <div className="max-sm:mb-2 max-sm:row-span-1 max-sm:w-full col-span-1 flex p-4 items-center gap-2 flex-1 self-stretch rounded-[16px] bg-white">
                <div className="flex flex-col items-start justify-center flex-1 gap-1">
                  <div className="flex gap-1 text-nowrap">
                    <p className="text-[#1a1a1a] text-[18px] font-extrabold leading-[24px]">
                      <Skeleton height={20} width={70} />
                    </p>
                    <p className="text-[#1a1a1a] text-[14px] font-normal leading-[24px]">
                      <Skeleton height={20} width={40} />
                    </p>
                  </div>
                  <div className="text-[#1a1a1a] text-[14px] font-normal leading-[18px]">
                    <Skeleton height={15} width={65} />
                  </div>
                </div>
              </div>
            </SkeletonTheme>
            <div className="flex flex-row flex-1 col-span-2 gap-2 max-sm:row-span-2">
              <div className="flex p-4 items-center gap-2 flex-1 self-stretch rounded-[16px] bg-[#1f1f1f]">
                <div className="flex flex-col items-start justify-center flex-1 gap-1">
                  <div className="text-[20px] font-extrabold leading-8 gradient-text">
                    <Skeleton height={25} width={40} />
                  </div>
                  <div className="text-[14px] font-normal leading-[18px] gradient-text">
                    <Skeleton height={18} width={80} />
                  </div>
                </div>
              </div>
              <div className="flex p-4 items-center gap-2 flex-1 self-stretch rounded-[16px] bg-[#1f1f1f]">
                <div className="flex flex-col items-start justify-center flex-1 gap-1">
                  <div className="flex gap-1 text-nowrap">
                    <p className="text-[18px] font-extrabold leading-6 text-white">
                      <Skeleton height={20} width={70} />
                    </p>
                    <p className="text-[14px] font-normal leading-6 text-white">
                      <Skeleton height={20} width={40} />
                    </p>
                  </div>
                  <p className="text-[14px] text-white text-opacity-[0.87] font-normal leading-[18px]">
                    <Skeleton height={15} width={65} />
                  </p>
                </div>
              </div>
            </div>
          </div>
          {Array.from({ length: 3 }).map((_, index) => (
            <div
              key={index}
              className={`bg-[#1f1f1f] md:p-4 max-sm:px-3 max-sm:py-4 justify-between flex flex-col items-start self-stretch rounded-[16px] cursor-pointer gap-4`}
            >
              <div className="flex justify-between w-full">
                <div className="flex items-center gap-3 max-sm:items-start">
                  <div className="rounded-full w-[48px] h-[48px] flex items-center justify-center">
                    <Skeleton
                      containerClassName="w-full h-full  flex-1 flex"
                      circle
                    />
                  </div>
                  <div className="flex flex-col max-sm:gap-1">
                    <div className="gradient-text text-[14px] font-normal leading-6 text-nowrap">
                      <Skeleton height={20} width={90} />
                    </div>
                    <div className="text-sm text-gray-400">
                      <Skeleton height={15} width={80} />
                    </div>
                  </div>
                </div>
                <div className="flex flex-col gap-[6px] max-sm:gap-[2px]">
                  <div className="flex gap-3 max-sm:flex-col max-sm:gap-[2px]">
                    <div className="flex justify-end">
                      <Skeleton
                        height={30}
                        width={45}
                        className="!rounded-[12px]"
                      />
                    </div>
                    <div className="flex gap-1 text-white">
                      <Skeleton height={25} width={50} />
                      <Skeleton height={25} width={35} />
                    </div>
                  </div>
                  <div className="flex justify-end">
                    <div className="text-sm text-gray-400">
                      <Skeleton height={17} width={60} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </SkeletonTheme>
  );
}

export default DetailsLoading;
