export enum PageRoutes {
  LOGOUT = "/logout",
  DASHBOARD = "/dashboard",
  SIGN_IN = "/",
  REGISTER = "/register",
  ORDERS = "/orders",
  PAYMENTS = "/payments",
  CONNECT_BANK = "/connect-bank",
  COMPANY_PROFILE = "/company-profile",
  // USERS = "/users",
  PROFILE = "/user-profile",
  LANGUAGE = "/language",
  ORDER_DETAILS = "/order-details/:id",
  LEAN = "/lean",
  CREDIT_SCORE = "/credit-score",
  SUCCESS = "/success",
  PAY = "/pay",
  COMPANY_INFO_REGISTER = "/company-info-register",
  MANUAL_BANK_CONNECT = "/manual-bank-connect",
  MANUAL_PENDING_CREDIT_SCORE = "/manual-pending-credit-score",
  ARCHIVE = "/archived-orders",
  Loadingwithlogo = "/loading-with-logo",
  Loadingwithoutlogo = "/loading-without-logo",
  Loadingwithlogowhite = "/loading-with-logo-white",
  // UNHANDELD_ERROR = "/unhandeled-error"
}
export const orderFlowDomain = "https://orderflowdev.z1.web.core.windows.net";

export const DEFAULT_LOCAL_STORAGE_KEY_FOR_APP_STATE = "APP_STATE";

export enum FILTER_STATUS {
  All = "الكل",
  FullyPaid = "مدفوع",
  PartiallyPaid = "مدفوع جزئى",
  Unpaid = "غير مدفوع",
}
export enum FILTER_STATUS_EN {
  All = "All",
  FullyPaid = "Paid",
  PartiallyPaid = "Partially Paid",
  Unpaid = "Unpaid",
}

export enum PROCESSES_FILTER {
  Debits = 0,
  Credits,
}

export enum OrganizationType {
  SoleProprietorship = 1,
  Partnership,
  LimitedLiabilityCompany,
  Franchise,
  Nonprofit,
  ListedCompany,
  Other,
  CompanyLimitedByGuarantee,
  SmallMediumSizedEnterprise,
}

export enum BusinessPremisesType {
  Owned = 1,
  Rent,
}

export enum RegistrationStatus {
  Pending = 1,
  Approved,
  Rejected,
  Disabled,
  Cancelled,
}
export enum SubscriptionStatus {
  Active = 0,
  Inactive = 1,
  Pending = 2,
  Cancelled = 3,
  Expired = 4,
}

export enum SalesChannelType {
  Online = 1,
  Offline,
  Both,
}
export enum OrderFilter {
  All = 0,
  Pending,
  Accepted,
  PartiallyPaid,
  FullyPaid,
  OverDue,
}
export enum WalletTransactionType {
  Deposit = 1,
  Withdraw,
}
export enum PaymentRequestType {
  WalletRecharge = 0,
  OutstandingInstallments,
  OutstandingCredit,
  DueInstallments,
  DueCredit,
  OrderInstallment,
  OrderPayment,
}
export enum OrderStatus {
  Pending = 0,
  Accepted,
  Completed,
  Active,
  Archived,
}
export enum PaymentStatus {
  Unpaid = 0,
  PartiallyPaid,
  Paid,
  Overdue,
}
export enum PaymentPlanType {
  None = 0,
  Credit,
  Installment,
}
