import React from "react";
import SearchIcon from "../Icons/SearchIcon"; // Adjust the import path if necessary
import { useTranslation } from "react-i18next";
interface OrderSearchInputProps {
  searchValue: string;
  placeholder: string;
  onSearchChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onSearchClick?: () => void; // New prop for handling search click
}

const OrderSearchInput: React.FC<OrderSearchInputProps> = ({
  searchValue,
  onSearchChange,
  onSearchClick,
  placeholder,
}) => {
  const { t } = useTranslation();
  return (
    <div className="flex md:!w-[250px] px-4 py-2 items-center gap-2 rounded-[20px] bg-[#1F1F1F] max-sm:w-full">
      <div className="flex-1">
        <input
          type="text"
          value={searchValue}
          onChange={onSearchChange}
          placeholder={t(`${placeholder}`)}
          className=" text-[14px] w-full bg-[#1F1F1F]  text-white leading-6 outline-none placeholder-white placeholder-opacity-40 appearance-none"
        />
      </div>
      <button onClick={onSearchClick}>
        <SearchIcon />
      </button>
    </div>
  );
};

export default OrderSearchInput;
