import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import DocumentIcon from "./Icons/DocumentIcon";
import DownloadIcon from "./Icons/DownloadIcon";
import DustbinIcon from "./Icons/DustbinIcon";
import { PiDotsThreeVerticalBold } from "react-icons/pi";

interface DocumentProps {
  span: number;
  data: {
    file: File;
    date: string;
    name: string;
  };
  type: string;
  onDelete?: () => void;
  downloadLink?: string;
}

const Document: React.FC<DocumentProps> = ({
  span,
  data,
  type,
  onDelete,
  downloadLink,
}) => {
  const { t } = useTranslation();
  const [isLargeScreen, setIsLargeScreen] = useState<boolean>(
    window.innerWidth >= 768
  );

  useEffect(() => {
    const handleResize = () => {
      setIsLargeScreen(window.innerWidth >= 768);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div
      className={`bg-white bg-opacity-[0.07] w-full col-span-${span} mx-1 my-4 flex justify-between rounded-xl p-3`}
    >
      <div className="flex flex-row items-center justify-around">
        <div className="p-3 bg-opacity-75 rounded-full bg-gradient-to-r from-neutral-800 to-orange-400 ">
          <DocumentIcon />
        </div>
        <div className="mx-2">
          <p
            dangerouslySetInnerHTML={{ __html: data.name }}
            className="text-xl leading-normal text-white"
          />
          <div className="flex flex-row">
            <p className="text-sm text-neutral-400">{data.date}</p>
          </div>
        </div>
      </div>
      {isLargeScreen ? (
        <div className="flex items-center">
          {downloadLink && (
            <a
              className="rounded-full  p-3 bg-white bg-opacity-[0.07] mx-1"
              href={downloadLink}
              download
            >
              <DownloadIcon />
            </a>
          )}
          <button
            className="rounded-full  p-3 bg-white bg-opacity-[0.07] mx-1"
            onClick={onDelete}
          >
            <DustbinIcon className="w-6 h-6 text-white" />
          </button>
        </div>
      ) : (
        <div className="dropdown dropdown-end">
          <div tabIndex={0} role="button" className="p-0 m-0 btn bg-inherit">
            <PiDotsThreeVerticalBold className="w-6 h-6 text-white" />
          </div>
          <ul
            tabIndex={0}
            className="dropdown-content menu bg-[#191919] rounded-box border border-gray-500 z-50 w-52 p-2 shadow"
          >
            {downloadLink && (
              <li>
                <a
                  className="p-3 mx-1 my-1 rounded-full xl:block"
                  href={downloadLink}
                  download
                >
                  <DownloadIcon />
                  {t("download")}
                </a>
              </li>
            )}
            {/* <hr className="h-[1px] flex mx-auto w-[90%] bg-white bg-opacity-10 " /> */}
            <li>
              <button
                className="p-3 mx-1 my-1 rounded-full xl:block"
                onClick={onDelete}
              >
                <DustbinIcon className="w-6 h-6 text-white" />
                {t("delete")}
              </button>
            </li>
          </ul>
        </div>
      )}
    </div>
  );
};

export default Document;
