import React, { useState, useEffect, useContext } from "react";
import { initKeycloak, keycloak } from "../keycloak";
import useLocalStorage from "../lib/hooks/useLocalStorage";
import { useNavigate } from "react-router-dom";
import { PageRoutes } from "../lib/constants";
import { AppContext } from "../lib/context/app-context";
import useApi from "../lib/hooks/useApi";
import Loadingwithoutlogo from "./loadingwithoutlogo";
import ErrorComponent from "../lib/components/ErrorComponents/ErrorComponent";

export interface UserInfo {
  sub?: string;
  email?: string;
  name?: string;
  preferred_username?: string;
  given_name?: string;
  family_name?: string;
  locale?: string;
  email_verified?: boolean;
}

export interface Organization {
  id: string;
  name: string;
  contactName: string | null;
  contactPosition: string | null;
  email: string;
  phone: string;
  type: string;
  website: string;
  userId: string;
  hasCreditRecord?: boolean;
  status?: number;
}

const Signin: React.FC = () => {
  const navigate = useNavigate();
  const { setAuthenticated } = useContext(AppContext);
  const { setValue: setData } = useLocalStorage<UserInfo>();
  const { setValue: setCompanyId } = useLocalStorage<string>();
  const { setValue: setCompanyName } = useLocalStorage<string>();
  const { setValue: setCompanyEmail } = useLocalStorage<string>();

  const { setValue: setUserId } = useLocalStorage<string>();
  const { setValue: setIsAuth } = useLocalStorage<boolean>();
  const { getData: getCompanyData } = useApi<Organization>();
  const [loading, setLoading] = useState(true); // Loading state
  const [error, setError] = useState<string | null>(null);

  const handleCompanyData = (
    companyData: Organization | null,
    userInfoData: UserInfo
  ) => {
    if (!companyData) {
      navigate(PageRoutes.REGISTER);
    } else {
      setCompanyId("companyId", companyData.id);
      setCompanyName("companyName", companyData.name);
      setCompanyEmail("companyEmail", userInfoData.email);

      switch (companyData.status) {
        case 0 || undefined:
          navigate(PageRoutes.REGISTER);
          break;
        case 1:
          navigate(PageRoutes.MANUAL_PENDING_CREDIT_SCORE); // to be replaced after lean fixed
          // navigate(PageRoutes.CREDIT_SCORE);
          break;
        case 2:
          navigate(PageRoutes.DASHBOARD);
          break;
        case 3:
          navigate(PageRoutes.DASHBOARD);
          break;
        case 4:
          navigate(PageRoutes.DASHBOARD);
          break;
        case 5:
          navigate(PageRoutes.DASHBOARD);
          break;
        default:
          navigate(PageRoutes.MANUAL_BANK_CONNECT); // to be replaced after lean fixed
        // navigate(PageRoutes.CONNECT_BANK);
      }
    }
  };
  function getCookie(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(";").shift();
    return null;
  }

  useEffect(() => {
    const initializeKeycloak = async () => {
      try {
        //consloe.log("Starting Keycloak initialization");
        const auth = await initKeycloak();
        //consloe.log("Keycloak initialized, auth status:", auth);

        if (auth) {
          // Retrieve the value of the "KEYCLOAK_LOCALE" cookie
          const savedLanguage = getCookie("KEYCLOAK_LOCALE");

          //consloe.log("the saved lang is from cookie:>", savedLanguage);
          savedLanguage
            ? sessionStorage.setItem("lang", savedLanguage)
            : sessionStorage.setItem("lang", "en");
          //consloe.log(
          // "this is saved lang in session storage:>",
          // sessionStorage.getItem("lang")
          // );

          const userInfo = await keycloak.loadUserInfo();
          //consloe.log("User info loaded:", userInfo);
          sessionStorage.setItem("keycloak_token", keycloak.idToken);

          // Ensure userInfo is properly set
          const userInfoData = userInfo as UserInfo;
          setData("userInfo", userInfoData);
          //consloe.log("userInfo", userInfoData);
          setAuthenticated(auth);
          setIsAuth("isAuthenticated", auth);

          const userId = userInfoData.sub.toString();
          if (userId) {
            setUserId("userId", userId);

            const { data: companyData } = await getCompanyData(
              `/company/byuser/${userId}/check`
            );
            //consloe.log("Company data:", companyData);

            handleCompanyData(companyData, userInfoData);
          } else {
            throw new Error("User ID (sub) is missing in userInfo");
          }
        }
      } catch (err) {
        console.error(
          "Error during Keycloak initialization or user info loading:",
          err
        );
        setError("There was an issue signing in. Please try again later.");
      } finally {
        setLoading(false); // Set loading to false once done
      }
    };

    initializeKeycloak();
  }, []);

  if (loading) {
    return <Loadingwithoutlogo />;
  }

  return error ? <ErrorComponent /> : null;
};

export default Signin;
