import React from "react";

const PendingOrderIcon: React.FC<IconProps> = ({ height, width }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 44 44"
      fill="none"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M22 3C22.7321 3 23.3256 3.59348 23.3256 4.32558V11.3953C23.3256 12.1274 22.7321 12.7209 22 12.7209C21.2679 12.7209 20.6744 12.1274 20.6744 11.3953V4.32558C20.6744 3.59348 21.2679 3 22 3ZM8.56674 8.56674C9.08441 8.04907 9.92372 8.04907 10.4414 8.56674L15.4433 13.5686C15.9609 14.0863 15.9609 14.9256 15.4433 15.4433C14.9256 15.9609 14.0863 15.9609 13.5686 15.4433L8.56674 10.4414C8.04907 9.92372 8.04907 9.08441 8.56674 8.56674ZM35.4331 8.56674C35.9508 9.08441 35.9508 9.92372 35.4331 10.4414L30.4313 15.4433C29.9136 15.9609 29.0743 15.9609 28.5566 15.4433C28.0389 14.9256 28.0389 14.0863 28.5566 13.5686L33.5585 8.56674C34.0761 8.04907 34.9155 8.04907 35.4331 8.56674ZM3 22C3 21.2679 3.59348 20.6744 4.32558 20.6744H11.3953C12.1274 20.6744 12.7209 21.2679 12.7209 22C12.7209 22.7321 12.1274 23.3256 11.3953 23.3256H4.32558C3.59348 23.3256 3 22.7321 3 22ZM31.2791 22C31.2791 21.2679 31.8726 20.6744 32.6047 20.6744H39.6744C40.4065 20.6744 41 21.2679 41 22C41 22.7321 40.4065 23.3256 39.6744 23.3256H32.6047C31.8726 23.3256 31.2791 22.7321 31.2791 22ZM15.4433 28.5566C15.9609 29.0743 15.9609 29.9136 15.4433 30.4313L10.4414 35.4331C9.92372 35.9508 9.08441 35.9508 8.56674 35.4331C8.04907 34.9155 8.04907 34.0761 8.56674 33.5585L13.5686 28.5566C14.0863 28.0389 14.9256 28.0389 15.4433 28.5566ZM28.5566 28.5566C29.0743 28.0389 29.9136 28.0389 30.4313 28.5566L35.4331 33.5585C35.9508 34.0761 35.9508 34.9155 35.4331 35.4331C34.9155 35.9508 34.0761 35.9508 33.5585 35.4331L28.5566 30.4313C28.0389 29.9136 28.0389 29.0743 28.5566 28.5566ZM22 31.2791C22.7321 31.2791 23.3256 31.8726 23.3256 32.6047V39.6744C23.3256 40.4065 22.7321 41 22 41C21.2679 41 20.6744 40.4065 20.6744 39.6744V32.6047C20.6744 31.8726 21.2679 31.2791 22 31.2791Z"
        fill="white"
      />
    </svg>
  );
};

export default PendingOrderIcon;
