import React, { useState, useRef, useEffect } from "react";
import { FaCalendarAlt } from "react-icons/fa";
import OrderDateInput from "./OrderDateInput";
import { useTranslation } from "react-i18next";
import { format } from "date-fns";

interface CalenderProps {
  label: string;
  fromDate?: Date | null;
  toDate?: Date | null;
  onFromDateChange?: (date: Date | null) => void;
  onToDateChange?: (date: Date | null) => void;
  onFilterClick?: () => void;
  onFilteRemove?: () => void;
}

const Calender: React.FC<CalenderProps> = ({
  label,
  fromDate,
  toDate,
  onFromDateChange,
  onToDateChange,
  onFilterClick,
  onFilteRemove,
}) => {
  const { t, i18n } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [displayLabel, setDisplayLabel] = useState(label);
  const modalRef = useRef<HTMLDivElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        modalRef.current &&
        !modalRef.current.contains(event.target as Node) &&
        buttonRef.current &&
        !buttonRef.current.contains(event.target as Node)
      ) {
        closeModal();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (fromDate && toDate) {
      setDisplayLabel(
        `${format(fromDate, "dd/MM/yyyy")} - ${format(toDate, "dd/MM/yyyy")}`
      );
    } else if (fromDate) {
      setDisplayLabel(format(fromDate, "dd/MM/yyyy"));
    } else {
      setDisplayLabel(label);
    }
  }, [fromDate, toDate, label]);

  return (
    <div className="relative z-10">
      <button
        onClick={toggleModal}
        ref={buttonRef}
        className="lg:flex-row lg:w-[150px] justify-between flex items-center px-4 py-2 bg-[#1F1F1F] text-white rounded-full "
      >
        <div className="flex items-center justify-between w-full gap-2 lg:flex-row ">
          <span className="mr-2 max-sm:hidden max-lg:hidden max-mg:hidden text-nowrap">
            <p className="text-opacity-40 text-[12px]">{displayLabel}</p>
          </span>
          <FaCalendarAlt className="max-sm:!h-[20px] text-gray-400" />
        </div>
      </button>

      {isModalOpen && (
        <div
          ref={modalRef}
          className={`absolute top-[50px] shadow-lg ${
            i18n.language === "en" ? "right-0" : "left-0"
          }`}
        >
          <OrderDateInput
            onFilterRemove={() => {
              onFilteRemove?.();
              closeModal();
            }}
            fromDate={fromDate}
            toDate={toDate}
            onFromDateChange={(date) => {
              onFromDateChange?.(date);
              setDisplayLabel(date ? format(date, "dd/MM/yyyy") : label);
            }}
            onToDateChange={(date) => {
              onToDateChange?.(date);
              setDisplayLabel(date ? format(date, "dd/MM/yyyy") : label);
            }}
            onFilterClick={() => {
              if (fromDate || toDate) {
                onFilterClick && onFilterClick();
                closeModal();
              }
            }}
          />
        </div>
      )}
    </div>
  );
};

export default Calender;
