import React, { useState } from "react";
import useApi from "../../hooks/useApi";
import { useParams, useNavigate } from "react-router-dom";
import Button from "../Buttons/Button";
import { useTranslation } from "react-i18next";
import { PageRoutes } from "../../constants";

interface ArchiveConfirmationProps {
  isVisible: boolean;
  onClose: () => void;
  onArchiveSuccess: () => void;
  orderNumber: string;
}

const ArchiveConfirmation: React.FC<ArchiveConfirmationProps> = ({
  isVisible,
  onClose,
  onArchiveSuccess,
  orderNumber,
}) => {
  const { id } = useParams<{ id: string }>();
  const { putData, status } = useApi<void>();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [error, setError] = useState<string | undefined>();

  const handleArchive = async () => {
    try {
      if (id) {
        const response = await putData(`/order/${id}/archive`, null);

        if (response.status === 200) {
          onArchiveSuccess();
          onClose();
          navigate(PageRoutes.ARCHIVE);
        } else {
          setError("Order Can't be Archived");
        }
      }
    } catch (error) {
      console.error("Failed to archive the order.", error);
      setError(t("Order Can't be Archived"));
    }
  };

  if (!isVisible) return null;

  return (
    <div
      style={{ backdropFilter: "blur(18px)" }}
      className="fixed flex p-8 max-sm:p-4 flex-col inset-0 items-center justify-center gap-2 md:shrink-0 bg-[#000] bg-opacity-50"
    >
      <div
        style={{ background: "rgba(255, 255, 255, 0.05)" }}
        className="flex md:w-[500px] max-sm:w-[300px] p-6 flex-col items-end max-sm:items-center gap-8 rounded-[16px]"
      >
        <div className="self-stretch">
          <p className="text-white text-center text-[24px] font-bold leading-8">
            {t("archiveConfirmation.title")}
          </p>
        </div>

        <div className="flex flex-col items-center self-stretch justify-center">
          <p className="text-white text-opacity-60 text-center text-[16px] font-normal leading-6">
            {t("archiveConfirmation.message1")}
          </p>
          <div className="flex ">
            <p className="text-white text-opacity-60 text-center text-[16px] font-normal leading-6">
              {t("archiveConfirmation.message2")}
            </p>
            &nbsp;
            <p className="flex text-white text-[16px] font-bold leading-6">
              {`(`}
              {(t("archiveConfirmation.orderNumber"), orderNumber)}
              {`)`}
            </p>
            &nbsp;
            <p className="text-white text-opacity-60 text-center text-[16px] font-normal leading-6">
              {t("archiveConfirmation.questionMark")}
            </p>
          </div>
        </div>
        {error && (
          <p className="text-red-500 text-center text-[16px] font-bold leading-6 w-full">
            {error}
          </p>
        )}
        <div className="flex items-center self-stretch justify-center w-full gap-4">
          <Button
            label={t("buttons.no")}
            className="px-6 py-3 justify-center items-center gap-[6px] border border-solid border-[#EF6B2A]"
            onClick={onClose}
            labelClassName="text-center text-[16px] font-bold leading-6 gradient-text"
          />
          <Button
            label={t("buttons.yes")}
            className="px-6 py-3 justify-center items-center gap-[6px] gradient-bg"
            onClick={handleArchive}
            labelClassName="text-center text-[16px] font-bold leading-6 text-white"
          />
        </div>
      </div>
    </div>
  );
};

export default ArchiveConfirmation;
