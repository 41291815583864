import React from "react";
import { useTranslation } from "react-i18next";
import NoDataIcon from "../Icons/NoDataIcon";

const NoData = () => {
  const { t } = useTranslation();
  return (
    <div className="flex justify-center items-end">
      <div className="flex flex-col justify-center  items-center ">
        <NoDataIcon />

        <h1 className="text-sm leading-10 text-white text-opacity-60">
          {t("nodata")}
        </h1>
      </div>
    </div>
  );
};

export default NoData;
