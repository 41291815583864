import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import ExitIcon from "../Icons/ExitIcon";
import SuccessPaymentIcon from "../Icons/SuccessPaymentIcon";
import { useNavigate, useLocation } from "react-router-dom";
import { PageRoutes } from "../../constants";

interface SuccessProcessProps {
  version: "Deposit" | "Payment";
  isOpen: boolean;
}

const SuccessProcess: React.FC<SuccessProcessProps> = ({ isOpen, version }) => {
  const { t } = useTranslation();
  const [showSuccess, setShowSuccess] = useState(isOpen);
  const navigate = useNavigate();
  const location = useLocation(); // Get the current location
  const pathWithoutQuery = location.pathname;

  const handleClose = () => {
    setShowSuccess(false);
    if (location.pathname.includes("/home")) {
      navigate(PageRoutes.DASHBOARD, { replace: true });
    }
    if (location.pathname.includes("/payments")) {
      navigate(PageRoutes.PAYMENTS, { replace: true });
    }

    navigate(pathWithoutQuery, { replace: true });
    sessionStorage.removeItem("requestId");

    window.location.reload(); // Reload the page to reflect any updates
  };

  return (
    <>
      {isOpen && (
        <div
          style={{ backdropFilter: "blur(18px)" }}
          className="fixed inset-0 z-50 flex bg-[#000] bg-opacity-[0.24] items-center justify-center transition-opacity duration-300 ease-in-out shrink-0"
        >
          <div className="flex w-[600px] max-sm:w-[calc(100%-20px)] p-6 flex-col items-center gap-8 bg-[#FFF] rounded-[16px]">
            <div className="flex items-center gap-[10px] self-stretch">
              <div className="flex-1">
                <p className="text-[#1a1a1a] text-[24px] font-bold leading-8">
                  {t(`success.${version.toLowerCase()}.title`)}
                </p>
              </div>
              <button onClick={handleClose}>
                <ExitIcon width={24} height={24} />
              </button>
            </div>
            <div className="flex">
              <SuccessPaymentIcon />
            </div>
            <div className="flex flex-col items-center self-stretch">
              <p className="text-[#1A1A1A] items-center text-[24px] font-bold leading-8">
                {t(`success.${version.toLowerCase()}.success`)}
              </p>
              <p className="text-[#1A1A1A] items-center text-[16px] font-normal leading-6">
                {t(`success.transactionComplete`)}
              </p>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default SuccessProcess;
