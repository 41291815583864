import React, { useContext, useEffect, useState } from "react";
import { useLocation, Link, useNavigate } from "react-router-dom";
import { RxExit } from "react-icons/rx";
import { HiLanguage } from "react-icons/hi2";
import { CgProfile } from "react-icons/cg";
import useLocalStorage from "../../hooks/useLocalStorage";
import DashboardIcon from "../Icons/DashboardIcon";
import TotalOrdersIcon from "../Icons/TotalOrdersIcon";
import FullyPayedIcon from "../Icons/FullyPayedIcon";
import CompanyProfileIcon from "../Icons/CompanyProfileIcon";
import SubscribeCard from "../cards/Subscribe";
import { PageRoutes } from "../../constants";
import { useTranslation } from "react-i18next";
import { AppContext } from "../../context/app-context";
import { keycloak } from "../../../keycloak";
import DepositPayment from "../Payments/DepositPayment";
import PaymentModal from "../Payments/PaymentModal";
import { useSelector } from "react-redux";
import { selectCompanyStatus } from "../../../redux/slices/companySlice";
import { RootState } from "../../../redux/store/rootReducer";
import NavItem from "../NavItem";

const Sidebar: React.FC = () => {
  const { clearValue } = useLocalStorage();
  const { t, i18n } = useTranslation();
  const { setValue: setAuth } = useLocalStorage<boolean>();
  const { setValue } = useLocalStorage();
  const { currentPage, setCurrentPage, isAuthenticated } =
    useContext(AppContext);
  const navigate = useNavigate();

  // Use location to determine the current path
  const location = useLocation();

  useEffect(() => {
    const path = location.pathname as string;
    setCurrentPage(path);
    setValue("currentPage", path);
  }, [location, setCurrentPage, setValue]);

  const changeLanguage = () => {
    const newLang = i18n.language === "ar" ? "en" : "ar";
    i18n.changeLanguage(newLang);
    sessionStorage.setItem("lang", newLang);
  };
  const companyStatus = useSelector((state: RootState) =>
    selectCompanyStatus(state)
  );
  return (
    <div
      dir={i18n.dir()}
      className="flex flex-col items-end justify-between w-full min-h-screen gap-6 px-4 pt-6 pb-16 overflow-y-auto hide-scrollbar"
    >
      <ul className="flex flex-col items-center flex-1 w-full gap-2">
        <NavItem
          to={PageRoutes.DASHBOARD}
          IconComponent={DashboardIcon}
          label={t("dashboard")}
        />
        <NavItem
          to={PageRoutes.ORDERS}
          IconComponent={TotalOrdersIcon}
          label={t("Orders")}
        />
        <NavItem
          to={PageRoutes.PAYMENTS}
          IconComponent={FullyPayedIcon}
          label={t("payments")}
        />
        <NavItem
          to={PageRoutes.COMPANY_PROFILE}
          IconComponent={CompanyProfileIcon}
          label={t("companyProfile")}
        />
      </ul>

      {/* {companyStatus !== 3 && <SubscribeCard />} */}

      <ul className="flex flex-col items-start self-stretch gap-2">
        <li
          className={`w-full outline-none ${
            currentPage === PageRoutes.PROFILE ? "text-white" : "text-[#6b6b6b]"
          }`}
        >
          <Link
            to={PageRoutes.PROFILE}
            className="flex items-center justify-start gap-2 px-4 py-2 text-sm text-bold"
          >
            <CgProfile
              className="w-6 h-6"
              color={currentPage === PageRoutes.PROFILE ? "#FFFFFF" : "#6b6b6b"}
            />
            {t("profile")}
          </Link>
        </li>
        <li
          className={`w-full outline-none ${
            currentPage === PageRoutes.LANGUAGE
              ? "text-white"
              : "text-[#6b6b6b]"
          }`}
        >
          <button
            onClick={changeLanguage}
            className="flex flex-row items-center justify-start w-full gap-2 px-4 py-2 text-sm text-bold"
          >
            <HiLanguage
              className="w-6 h-6"
              color={
                currentPage === PageRoutes.LANGUAGE ? "#FFFFFF" : "#6b6b6b"
              }
            />
            {i18n.language === "ar" ? "English" : "العربية"}
          </button>
        </li>
        <li className="w-full outline-none md:text-sm text-[#6b6b6b]">
          <button
            onClick={() => navigate(PageRoutes.LOGOUT)}
            className="flex flex-row items-center justify-start w-full gap-2 px-4 py-2 text-sm text-bold"
          >
            <RxExit className="w-6 h-6" color="#6b6b6b" />
            {t("logout")}
          </button>
        </li>
        <li className="text-xs flex flex-row items-center text-[#6b6b6b] justify-start w-full outline-none font-mono">
          {process.env.REACT_APP_VERSION}
        </li>
      </ul>
    </div>
  );
};

export default Sidebar;
