import { NavLink } from "react-router-dom";

interface NavItemProps {
  to: string;
  IconComponent: React.ElementType;
  label: string;
  withBg?: boolean;
}

const NavItem: React.FC<NavItemProps> = ({
  to,
  IconComponent,
  label,
  withBg = true,
}) => {
  return (
    <NavLink
      to={to}
      id={to.replace("/", "")}
      className="w-full text-xs text-bold"
    >
      {({ isActive }) => (
        <li
          className={`flex flex-row w-full items-center  rounded-xl py-3 px-4 outline-none ${
            isActive ? "text-white" : "text-[#6b6b6b]"
          } ${withBg ? "bg-[#171717]" : ""}`}
        >
          <div className="flex flex-row items-center justify-center gap-2">
            <IconComponent
              width={24}
              height={24}
              color={isActive ? "#FFFFFF" : "#6b6b6b"}
            />
            <h4 className="font-bold text-[14px] leading-6">{label}</h4>
          </div>
        </li>
      )}
    </NavLink>
  );
};

export default NavItem;
