import React, { useEffect, useState } from "react";

interface CheckboxProps {
  checked?: boolean;
  onChange: (checked: boolean) => void;
  disabled?: boolean;
  label?: string;
}

const Checkbox: React.FC<CheckboxProps> = ({
  checked = false,
  onChange,
  disabled = false,
}) => {
  const [isChecked, setIsChecked] = useState(checked);

  const handleToggle = () => {
    if (!disabled) {
      const newCheckedState = !isChecked;
      setIsChecked(newCheckedState);
      onChange(newCheckedState);
    }
  };
  useEffect(()=>{
    setIsChecked(checked)
  }, [checked])

  return (
    <div
      className={`flex items-center cursor-pointer ${
        disabled ? "cursor-not-allowed opacity-50" : ""
      }`}
      onClick={handleToggle}
      onKeyPress={(e) => {
        if (e.key === "Enter" || e.key === " ") {
          handleToggle();
        }
      }}
      tabIndex={disabled ? -1 : 0}
      role="checkbox"
      aria-checked={isChecked}
      aria-disabled={disabled}
    >
      <div
        className={`relative w-6 h-6 flex items-center justify-center rounded-full ${
          isChecked ? "bg-custom-gradient" : "border border-orange-500"
        }`}
      >
        {isChecked && (
          <div className="absolute w-3 h-3 rounded-full bg-gradient-bg" />
        )}
      </div>
    </div>
  );
};

export default Checkbox;
