import React from "react";



export const ContentSection: React.FC<ContentSectionProps> = ({
  description,
  subDescription,
}) => {
  return (
    <section className="flex flex-col px-5 py-4 mt-4 w-full max-md:max-w-full">
      <h2 className="w-full text-2xl font-bold leading-10 text-white text-opacity-90 max-md:max-w-full">
        {description}
      </h2>
      <p className="text-wrap mt-2 text-base leading-7 text-white">
        {subDescription}
      </p>
    </section>
  );
};
