import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { CgClose } from "react-icons/cg";

export interface IToast {
  type: "success" | "error" | "warning";
  titleKey?: string;
  messageKey?: string;
  onClose?: () => void; // Pass onClose function to parent
}

const Toast: React.FC<IToast> = ({ titleKey, messageKey, type, onClose }) => {
  const { t, i18n } = useTranslation();
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsVisible(false);
      onClose();
    }, 3000); // Close the toast after 5 seconds

    return () => clearTimeout(timer); // Cleanup timeout on unmount
  }, [onClose]);

  const handleClose = () => {
    setIsVisible(false);
    onClose(); // Notify the parent component
  };

  if (!isVisible) return null; // Don't render the toast if it's not visible

  return (
    <div className="z-10 toast ">
      <section
        style={{
          backgroundImage: "url('/assets/toast-bg.svg')",
          backgroundPosition: i18n.language === "ar" ? "right" : "left",
          backgroundSize: "clamp(100px, 28%, 150px)",
          backgroundRepeat: "no-repeat",
        }}
        className={`!max-w-[438px]  alert w-full flex gap-3 p-4 items-start rounded-2xl text-white ${t(
          "toastPositionClass"
        )} ${
          type === "error"
            ? "alert-error"
            : type === "warning"
            ? "alert-warning"
            : "alert-success"
        }`}
      >
        <img
          loading="lazy"
          src={`/assets/${type}.svg`}
          alt=""
          className="-mt-10 w-[60px]"
        />
        <div className="flex flex-col flex-1 max-w-[304px] mr-4 ml-4">
          <h2 className="text-2xl font-bold text-start text-wrap">
            {t(titleKey)}
          </h2>
          <p className="mt-1.5 text-sm text-wrap text-start">{t(messageKey)}</p>
        </div>
        <button onClick={handleClose} className="mt-1">
          <CgClose size={16} />
        </button>
      </section>
    </div>
  );
};

export default Toast;
