import React, { useRef, useState } from "react";
import GoldenDocIcon from "../Icons/GoldenDocIcon";
import GoldenDownloadIcon from "../Icons/GoldenDownloadIcon";
import DeleteIcon from "../Icons/DeleteIcon";
import AddDocIcon from "../Icons/AddDocIcon";
import useApi from "../../hooks/useApi";
import useLocalStorage from "../../hooks/useLocalStorage";

interface DocComponentProps {
  className?: string;
  isEdit?: boolean;
  title: string;
  date?: string;
  uri?: string | null;
  fieldName?: string; 
  onEdit?: (uri: string | null) => void;
  onDelete?: (field: string) => void; 
  onUploadComplete?: (field: string, fileUri: any) => void; 
}

const DocComponent: React.FC<DocComponentProps> = ({
  title,
  date,
  uri: initialUri,
  className,
  isEdit = false,
  fieldName,
  onEdit,
  onDelete,
  onUploadComplete,
}) => {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [uri, setUri] = useState<string | null>(initialUri);
  const [isFileSelected, setIsFileSelected] = useState<boolean>(false);

  const { putData } = useApi<any>();
  const { getValue: getCompanyId, setValue: setCompanyDataToLocalStorage } =
    useLocalStorage();

  const handleFileUpload = async (file: File) => {
    const readFileAsBase64 = (file: File): Promise<string> => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => resolve(reader.result as string);
        reader.onerror = (error) => reject(error);
        reader.readAsDataURL(file);
      });
    };

    try {
      const companyId = getCompanyId("companyId");
      if (!companyId) {
        throw new Error("Company ID not found");
      }

      const base64Content = await readFileAsBase64(file);
      const fileContent = base64Content.split(",")[1];

      const documentRequestDto = {
        fileContent,
        fileExtension: file.name.split(".").pop() || "",
        documentName: fieldName, 
      };

      const response = await putData(`/company/${companyId}/files`, [
        documentRequestDto,
      ]);


      const existingCompanyData = getCompanyId("companyData");

      if (
        typeof existingCompanyData === "object" &&
        existingCompanyData !== null &&
        typeof response === "object" &&
        response !== null
      ) {
        const updatedCompanyData = { ...existingCompanyData, ...response };
        setCompanyDataToLocalStorage("companyData", updatedCompanyData);
      } else {
        console.error(
          "Either existingCompanyData or response is not an object."
        );
      }

      if (response[fieldName]) {
        setUri(
          response[fieldName.charAt(0).toLowerCase() + fieldName.slice(1)]
        );
        setIsFileSelected(false);
      }

      if (onUploadComplete) {
       
        onUploadComplete(
          fieldName!,
          response.data[fieldName.charAt(0).toLowerCase() + fieldName.slice(1)]
        );
      }
    } catch (error) {
      console.error("Error uploading file:", error);
    }
  };

  const handleAddClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileChange = () => {
    if (fileInputRef.current && fileInputRef.current.files) {
      const file = fileInputRef.current.files[0];
      if (file && file.type === "application/pdf") {
        handleFileUpload(file);
        setIsFileSelected(true);
      } else {
        //consloe.log("Only PDF files are allowed.");
      }
    }
  };

  const handleDeleteClick = () => {
    if (onDelete) {
      onDelete(fieldName!);
      setUri(null);
      setIsFileSelected(false);
    }
  };

  return (
    <div
      className={`${className} flex flex-1 items-center px-4 py-3 gap-3 bg-[#1F1F1F] rounded-[16px]`}
    >
      <div
        style={{
          background:
            "linear-gradient(129deg, rgba(239, 107, 42, 0.00) 0%, rgba(241, 188, 98, 0.60) 100%)",
        }}
        className="flex w-12 h-12 p-2 justify-center items-center gap-[6.857px] rounded-[32px] bg-[gradient-to-[129deg] from-[rgba(239,107,42,0)] to-[rgba(241,188,98,0.6)]]"
      >
        <GoldenDocIcon className="shrink-0" width={24} height={24} />
      </div>
      <div className="flex items-center flex-1 gap-1">
        <div className="flex flex-col items-start flex-1">
          <span className="text-white font-bold text-[14px] leading-[18px]">
            {title}
          </span>
          <span className="text-[#8A8A8A] font-bold text-[12px] leading-[18px]">
            {date}
          </span>
        </div>

        <div className="flex items-center gap-1">
          {isEdit ? (
            isFileSelected || uri ? (
              <div className="flex items-center gap-3">
                {/* <div className="flex px-3 py-2 justify-center items-center gap-[6px] self-stretch rounded-[32px] bg-white bg-opacity-[0.07]">
                  <button
                    onClick={() => onEdit && onEdit(uri)}
                    className="cursor-pointer flex justify-center items-center gap-[6px] self-stretch"
                  >
                    <GoldenDownloadIcon width={24} height={24} />
                  </button>
                </div> */}
                <div className="flex px-3 py-2 justify-center items-center gap-[6px] self-stretch rounded-[32px] bg-white bg-opacity-[0.07]">
                  <button
                    type="button"
                    onClick={handleDeleteClick} // Use the new delete handler
                    className="cursor-pointer flex justify-center items-center gap-[6px] self-stretch"
                  >
                    <DeleteIcon width={24} height={24} />
                  </button>
                </div>
              </div>
            ) : (
              <div className="flex items-center gap-3">
                <button
                  type="button"
                  onClick={handleAddClick}
                  className="cursor-pointer flex px-3 py-2 justify-center items-center gap-[6px] self-stretch rounded-[32px] bg-white bg-opacity-[0.07]"
                >
                  <AddDocIcon width={24} height={24} />
                </button>
                <input
                  type="file"
                  accept="application/pdf"
                  ref={fileInputRef}
                  onChange={handleFileChange}
                  style={{ display: "none" }}
                />
              </div>
            )
          ) : (
            uri && (
              <div className="flex px-3 py-2 justify-center items-center gap-[6px] self-stretch rounded-[32px] bg-white bg-opacity-[0.07]">
                <a
                  href={uri}
                  download="file.pdf"
                  className="cursor-pointer flex justify-center items-center gap-[6px] self-stretch"
                >
                  <GoldenDownloadIcon width={24} height={24} />
                </a>
              </div>
            )
          )}
        </div>
      </div>
    </div>
  );
};

export default DocComponent;
