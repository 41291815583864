import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import ExitIcon from "../Icons/ExitIcon";
import useApi from "../../hooks/useApi";
import SuccessProcess from "./SucessProcess";
import { useTranslation } from "react-i18next";

interface PaymentOtpProps {
  requestId: string;
  isOpen?: boolean;
}

const otpValidationSchema = Yup.object().shape({
  otp1: Yup.string()
    .length(1, "Required")
    .matches(/^\d$/, "OTP must be a digit")
    .required("Required"),
  otp2: Yup.string()
    .length(1, "Required")
    .matches(/^\d$/, "OTP must be a digit")
    .required("Required"),
  otp3: Yup.string()
    .length(1, "Required")
    .matches(/^\d$/, "OTP must be a digit")
    .required("Required"),
  otp4: Yup.string()
    .length(1, "Required")
    .matches(/^\d$/, "OTP must be a digit")
    .required("Required"),
});

const PaymentOtp: React.FC<PaymentOtpProps> = ({ requestId, isOpen }) => {
  const { t } = useTranslation();
  const { postData: VerifyOTP } = useApi<any>();
  const [showSuccess, setShowSuccess] = useState(false);
  const [payment, setPayment] = useState(false);
  const [showOtpModal, setShowOtpModal] = useState<boolean>(isOpen);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  // preventing back
  useEffect(() => {
    const preventBackNavigation = () => {
      window.history.pushState(null, document.title, window.location.href);
      window.onpopstate = () => {
        window.history.pushState(null, document.title, window.location.href);
      };
    };

    preventBackNavigation();

    return () => {
      window.onpopstate = null; // Clean up when the component is unmounted
    };
  }, []);
  const handleSubmit = async (values: {
    otp1: string;
    otp2: string;
    otp3: string;
    otp4: string;
  }) => {
    const otp = values.otp1 + values.otp2 + values.otp3 + values.otp4;

    // Check if all OTP fields are filled
    if (!otp || otp.length < 4) {
      alert("OTP is required"); // You can replace this with a better error handling mechanism
      return;
    }

    try {
      setIsButtonDisabled(true);
      const response = await VerifyOTP(
        `/payments/${requestId}/verify-then-pay`,
        {
          requestId,
          otp,
        }
      );

      if (response.status === 200) {
        setPayment(true);
        setShowSuccess(true);
      } else {
        console.error("Error submitting OTP: ", response.status);
      }
    } catch (error) {
      console.error("Error submitting OTP:", error);
    } finally {
      setIsButtonDisabled(false);
    }
  };

  const handleKeyUp = (
    e: React.KeyboardEvent<HTMLInputElement>,
    nextField: HTMLInputElement | null,
    prevField: HTMLInputElement | null
  ) => {
    const { value } = e.currentTarget;
    if (value.length === 1 && nextField) {
      nextField.focus();
    } else if (value.length === 0 && prevField) {
      prevField.focus();
    }
  };

  if (showSuccess && payment) {
    return <SuccessProcess version={"Payment"} isOpen={true} />;
  }

  if (!isOpen) return null;

  const handleClose = () => {
    setShowOtpModal(false);
  };

  return (
    showOtpModal && (
      <div
        style={{ backdropFilter: "blur(18px)" }}
        className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-30"
      >
        <div className="bg-white rounded-lg p-8 shadow-lg !max-w-[600px] max-sm:w-[calc(100%-20px)]">
          <div className="flex items-center justify-between mb-6">
            <h2 className="text-2xl font-bold text-gray-900">
              {t("otp.title")}
            </h2>
            <button onClick={handleClose}>
              <ExitIcon width={24} height={24} />
            </button>
          </div>
          <Formik
            initialValues={{ otp1: "", otp2: "", otp3: "", otp4: "" }}
            validationSchema={otpValidationSchema}
            onSubmit={handleSubmit}
          >
            {({ setFieldValue, errors }) => (
              <Form className="flex flex-col space-y-4">
                <div className="text-center">
                  <p className="text-[24px] font-bold leading-8 text-center text-[#1A1A1A]">
                    {t("otp.enterYourOtp")}
                  </p>
                  <p className="text-[16px] text-center font-normal leading-6 text-[#1A1A1A]">
                    {t("otp.otpDescription")}
                  </p>
                </div>
                <div className="flex justify-center gap-[6px] mt-4">
                  {["otp1", "otp2", "otp3", "otp4"].map((otpField, index) => (
                    <Field key={otpField} name={otpField} autoComplete="off">
                      {({ field }: any) => (
                        <input
                          {...field}
                          type="text"
                          placeholder="-"
                          maxLength={1}
                          autoComplete="one-time-code"
                          className="max-w-[125px] max-sm:max-w-[70px] flex py-5 px-4 justify-center items-center gap-[6px] flex-1 border rounded-[12px] border-solid border-[#000] border-opacity-[0.16] bg-[#000] placeholder:text-center bg-opacity-[0.07] placeholder:text-[#1A1A1A] placeholder:font-bold placeholder:leading-6 text-center"
                          onChange={(e) => {
                            if (/^\d*$/.test(e.target.value)) {
                              setFieldValue(otpField, e.target.value);
                            }
                          }}
                          onKeyUp={(e) =>
                            handleKeyUp(
                              e,
                              document.getElementById(
                                `otp${index + 2}`
                              ) as HTMLInputElement,
                              document.getElementById(
                                `otp${index}`
                              ) as HTMLInputElement
                            )
                          }
                          id={otpField}
                        />
                      )}
                    </Field>
                  ))}
                </div>
                <ErrorMessage name="otpComplete">
                  {(msg) => <div className="text-sm text-red-500">{msg}</div>}
                </ErrorMessage>
                <div className="flex items-center self-stretch justify-center w-full">
                  <button
                    type="submit"
                    disabled={isButtonDisabled}
                    className="flex w-[300px] px-6 py-3 justify-center items-center gap-[6px] self-stretch rounded-[24px] gradient-bg text-white text-[16px] font-bold"
                  >
                    {t("otp.submitOtp")}
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    )
  );
};

export default PaymentOtp;
