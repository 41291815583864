import React from "react";

interface BusnissInformationIconProps {
  className?: string;
  width?: string | number;
  height?: string | number;
}

const BusnissInformationIcon: React.FC<BusnissInformationIconProps> = ({
  className,
  width = 24,
  height = 24,
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    className={className}
  >
    <g clip-path="url(#clip0_1038_11688)">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.07291 2.87109C7.6564 2.87109 7.3125 3.21499 7.3125 3.6315V5.09766H16.6875V3.6315C16.6875 3.21498 16.3436 2.87109 15.9271 2.87109H8.07291ZM18.1875 5.09766V3.6315C18.1875 2.38658 17.1721 1.37109 15.9271 1.37109H8.07291C6.82797 1.37109 5.8125 2.38657 5.8125 3.6315V5.09766H2.10938C0.920583 5.09766 -0.046875 6.06519 -0.046875 7.25391V10.5819C-0.046875 11.4434 0.315541 12.1619 0.890625 12.7561V20.4727C0.890625 21.6604 1.85941 22.6289 3.04688 22.6289H20.9531C22.1406 22.6289 23.1094 21.6602 23.1094 20.4727V12.7561C23.6846 12.1618 24.0469 11.4434 24.0469 10.5819V7.25391C24.0469 6.06524 23.0794 5.09766 21.8906 5.09766H18.1875ZM21.6094 13.844C21.316 13.9999 21.0085 14.141 20.694 14.2683C18.9836 14.9604 16.7575 15.3808 14.4327 15.5511C14.2049 16.6198 13.2521 17.4258 12.1172 17.4258H11.8828C10.748 17.4258 9.79508 16.6199 9.56734 15.5512C7.22743 15.3797 4.98643 14.9549 3.27086 14.2541C2.96868 14.1306 2.67309 13.9942 2.39062 13.8441V20.4727C2.39062 20.8318 2.68774 21.1289 3.04688 21.1289H20.9531C21.3122 21.1289 21.6094 20.8317 21.6094 20.4727V13.844ZM11.0156 14.1914V15.0586C11.0156 15.5338 11.4076 15.9258 11.8828 15.9258H12.1172C12.5924 15.9258 12.9844 15.5338 12.9844 15.0586V14.1914H11.0156ZM14.4844 14.0429V13.4414C14.4844 13.0272 14.1486 12.6914 13.7344 12.6914H10.2656C9.85141 12.6914 9.51562 13.0272 9.51562 13.4414V14.0429C7.331 13.871 5.32174 13.4715 3.83812 12.8655C2.13915 12.1714 1.45312 11.3553 1.45312 10.5819V7.25391C1.45312 6.89359 1.74904 6.59766 2.10938 6.59766H21.8906C22.2509 6.59766 22.5469 6.89363 22.5469 7.25391V10.5819C22.5469 11.3604 21.8512 12.1819 20.1313 12.8778C18.6502 13.4771 16.6532 13.8722 14.4844 14.0429Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_1038_11688">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default BusnissInformationIcon;
