import React from "react";
import { useTranslation } from "react-i18next";
import { formatNumber } from "../../utils/formatNumber";
import { IAddress } from "./PaymentBill";

interface OrderSummaryProps {
  supplierName: string;
  crNumber: string;
  address: IAddress;
  description: string;
  orderNumber: string;
  createdAt: string;
  totalAmount: number;
  installmentsPaid: number;
  color?: string;
  paymentPlanType?: number;
}

const OrderSummary: React.FC<OrderSummaryProps> = ({
  supplierName,
  crNumber,
  address,
  description,
  orderNumber,
  createdAt,
  totalAmount,
  paymentPlanType,
  installmentsPaid,
  color = "bg-[#1F1F1F]",
}) => {
  const { t, i18n } = useTranslation();

  return (
    <div
      className={`flex flex-col items-start gap-3 self-stretch rounded-[8px]`}
    >
      <h2 className="text-[12px] text-white text-opacity-60 font-bold leading-6 ">
        {t("orderSummaryTitle")}
      </h2>
      <div
        className={`flex flex-col items-center justify-normal gap-[1px] self-stretch`}
      >
        <div
          className={`flex px-4 py-3 flex-col items-start gap-[9px] self-stretch ${color} rounded-t-[8px]`}
        >
          <div className="flex flex-col items-start self-stretch gap-2">
            <div className="flex flex-col items-start self-stretch gap-1">
              <p className="text-[14px] font-bold text-white leading-6 ">
                {supplierName}
              </p>
              <p className="text-[12px] font-bold text-white leading-6 text-opacity-60">
                {t("crNumberLabel")}:
                <span className="text-[12px] font-extrabold text-white leading-6 text-opacity-60">
                  {crNumber}
                </span>
              </p>
              <p className="text-[12px] font-normal text-white leading-6 text-opacity-60">
                {address.addressLine1}, {address.addressLine2}, {address.city},{" "}
                {address.stateOrProvince}, {address.postalCode},
                {address.country}
              </p>
            </div>

            <div className="flex flex-col items-start self-stretch gap-1">
              <p className="text-white text-[12px] font-bold leading-6">
                {t("orderDescriptionLabel")}:
              </p>
              <div className="self-stretch">
                <p className="text-white text-opacity-60 text-[12px] font-normal leading-6">
                  {description}
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="flex justify-center items-center gap-[1px] self-stretch">
          <div
            className={`flex w-[110px] py-3 px-4 justify-start items-center gap-[9px] ${color} self-stretch`}
          >
            <p className="text-white text-opacity-60 text-[12px] font-normal leading-[22.7px] ">
              {t("orderNumberLabel")}
            </p>
          </div>
          <div
            className={`flex px-4 py-3 justify-start items-center gap-[9px] flex-1 ${color} self-stretch`}
          >
            <p className="text-white text-[12px] font-bold leading-[22.8px] tracking-[0.095px] ">
              {orderNumber}
            </p>
          </div>
        </div>

        <div className="flex justify-center items-center gap-[1px] self-stretch">
          <div
            className={`flex w-[110px] py-3 px-4 justify-start items-center gap-[9px] ${color} self-stretch`}
          >
            <p className="text-white text-opacity-60 text-[12px] font-normal leading-[22.7px] ">
              {t("orderDateLabel")}
            </p>
          </div>
          <div
            className={`flex px-4 py-3 justify-start items-center gap-[9px] flex-1 ${color} self-stretch`}
          >
            <p className="text-white text-[12px] font-bold leading-[22.8px] tracking-[0.095px] ">
              {createdAt}
            </p>
          </div>
        </div>

        <div className="flex justify-center items-center gap-[1px] self-stretch">
          <div
            className={`flex w-[110px] py-3 px-4 justify-start items-center gap-[9px] ${color} ${
              i18n.language === "en" ? "rounded-bl-[8px]" : "rounded-br-[8px]"
            } self-stretch`}
          >
            <p className="text-white text-opacity-60 text-[12px] font-normal leading-[22.7px] ">
              {t("orderValueLabel")}
            </p>
          </div>
          <div
            className={`flex px-4 py-3 justify-start items-center gap-[9px] flex-1 ${color} ${
              i18n.language === "en" ? "rounded-br-[8px]" : "rounded-bl-[8px]"
            }`}
          >
            <p className="text-white text-[16px] font-extrabold leading-[24px]">
              {formatNumber(totalAmount, true)}
              <span className="text-white text-[12px] font-normal leading-[24px]">
                &nbsp;{t("SAR")}
              </span>
            </p>
          </div>
        </div>

        {/* {paymentPlanType === 1 && (
          <div className="flex justify-center items-center gap-[1px] self-stretch ">
            <div
              className={`flex w-[110px] py-2 px-2 justify-end items-center gap-[9px] ${color} ${
                i18n.language === "en" ? "rounded-bl-[8px]" : "rounded-br-[8px]"
              } self-stretch`}
            >
              <p className="text-white text-opacity-60 text-[12px] font-normal leading-[22.7px]">
                {t("installmentsPaidLabel")}
              </p>
            </div>
            <div
              className={`flex self-stretch px-2 py-2 justify-end items-center gap-[9px] flex-1 ${color} ${
                i18n.language === "en" ? "rounded-br-[8px]" : "rounded-bl-[8px]"
              }`}
            >
              <p className="text-white text-[12px] font-bold leading-[22.8px] tracking-[0.095px]">
                {installmentsPaid}
              </p>
            </div>
          </div>
        )} */}
      </div>
    </div>
  );
};

export default OrderSummary;
