import React from "react";
import DownIcon from "../Icons/DownIcon";
import { useTranslation } from "react-i18next";

const DataSharing: React.FC = () => {
  const { t } = useTranslation();

  const dataItems = [
    {
      icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/e762327e0874b84229008ca0dfb0e3dde2273ecf5e33e30cc244f2559a6404de?apiKey=eec791618aaf41dca83b63772a937206&",
      text: t("dataSharing.items.0.text"),
      message: t("dataSharing.items.0.message"),
    },
    {
      icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/7708544bc25193beab1e35c5444eacf86940c59f1e7872e8e6a74a6abed29abb?apiKey=eec791618aaf41dca83b63772a937206&",
      text: t("dataSharing.items.1.text"),
      message: t("dataSharing.items.1.message"),
    },
    {
      icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/a214f0357a442d20117ad5b24fa369f5a358dace835992f4aa1fe6cb65aefff9?apiKey=eec791618aaf41dca83b63772a937206&",
      text: t("dataSharing.items.2.text"),
      message: t("dataSharing.items.2.message"),
    },
    {
      icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/b2767fd22f8a89ddf7d0c556884e0bcdfb475c5c7670755b179c510f00a5154d?apiKey=eec791618aaf41dca83b63772a937206&",
      text: t("dataSharing.items.3.text"),
      message: t("dataSharing.items.3.message"),
    },
  ];

  return (
    <div className="flex flex-col w-full justify-center mt-8 text-lg leading-6 text-white ">
      <h2 className="font-bold mb-4">{t("dataSharing.title")}</h2>
      {dataItems.map((item, index) => (
        <div key={index} className="collapse mb-2">
          <input type="checkbox" className="peer" />
          <div className="collapse-title flex items-center gap-4 text-xl font-medium">
            <img
              loading="lazy"
              src={item.icon}
              alt=""
              className="shrink-0 mt-1 w-6 h-6"
            />
            <span className="flex-1">{item.text}</span>
            <DownIcon />
          </div>
          <div className="collapse-content">{item.message}</div>
        </div>
      ))}
    </div>
  );
};

export default DataSharing;
