import React from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css"; // Import CSS for styling
import InfoSection from "../../info/InfoSection";
import DashboardGlobalCardLoading from "../../Dashboard/DashboardGlobalCardLoading";
import InfoItem from "../../info/InfoItem";

function CompanyDetailsLoading() {
  return (
    <div className="flex flex-col gap-6">
      <SkeletonTheme baseColor="#202020" highlightColor="#444">
        <div className="flex items-center justify-between sm:flex-row sm:items-center ">
          <Skeleton
            width={200}
            height={40}
            className="text-white text-opacity-[0.87] max-sm:!w-[140px]"
          />
          <Skeleton width={120} height={40} className="!rounded-full" />
        </div>
      </SkeletonTheme>
      <DashboardGlobalCardLoading />
      <SkeletonTheme baseColor="#202020" highlightColor="#444">
        <InfoSection
          icon={<Skeleton circle height={30} width={30} />}
          title={<Skeleton width={100} />}
        >
          <div className="flex flex-col w-full gap-4">
            <div className="grid w-full grid-cols-1 md:grid-cols-3">
              <div className="col-span-2">
                <InfoItem
                  label={<Skeleton height={20} width={100} />}
                  value={<Skeleton height={20} width={150} />}
                />
              </div>
              <div className="col-span-1">
                <InfoItem
                  label={<Skeleton height={20} width={80} />}
                  value={<Skeleton height={20} width={200} />}
                />
              </div>
            </div>
            <div className="grid w-full grid-cols-1 md:grid-cols-3">
              <div className="col-span-1">
                <InfoItem
                  label={<Skeleton height={20} width={70} />}
                  value={<Skeleton height={20} width={120} />}
                />
              </div>
              <div className="col-span-1">
                <InfoItem
                  label={<Skeleton height={20} width={100} />}
                  value={<Skeleton height={20} width={150} />}
                />
              </div>
              <div className="col-span-1">
                <InfoItem
                  label={<Skeleton height={20} width={50} />}
                  value={<Skeleton height={20} width={130} />}
                />
              </div>
            </div>
            <div className="grid w-full grid-cols-1 md:grid-cols-3">
              <div className="col-span-1">
                <InfoItem
                  label={<Skeleton height={20} width={95} />}
                  value={<Skeleton height={20} width={160} />}
                />
              </div>
              <div className="col-span-2">
                <InfoItem
                  label={<Skeleton height={20} width={75} />}
                  value={<Skeleton height={20} width={110} />}
                />
              </div>
            </div>
          </div>
        </InfoSection>

        <InfoSection
          icon={<Skeleton circle height={30} width={30} />}
          title={<Skeleton width={100} />}
        >
          <div className="flex flex-col w-full gap-4">
            <div className="grid w-full grid-cols-1 md:grid-cols-3">
              <div className="col-span-2">
                <InfoItem
                  label={<Skeleton height={20} width={100} />}
                  value={<Skeleton height={20} width={150} />}
                />
              </div>
              <div className="col-span-1">
                <InfoItem
                  label={<Skeleton height={20} width={80} />}
                  value={<Skeleton height={20} width={200} />}
                />
              </div>
            </div>
            <div className="grid w-full grid-cols-1 md:grid-cols-3">
              <div className="col-span-1">
                <InfoItem
                  label={<Skeleton height={20} width={70} />}
                  value={<Skeleton height={20} width={120} />}
                />
              </div>
              <div className="col-span-1">
                <InfoItem
                  label={<Skeleton height={20} width={100} />}
                  value={<Skeleton height={20} width={150} />}
                />
              </div>
              <div className="col-span-1">
                <InfoItem
                  label={<Skeleton height={20} width={50} />}
                  value={<Skeleton height={20} width={130} />}
                />
              </div>
            </div>
            <div className="grid w-full grid-cols-1 md:grid-cols-3">
              <div className="col-span-1">
                <InfoItem
                  label={<Skeleton height={20} width={95} />}
                  value={<Skeleton height={20} width={160} />}
                />
              </div>
              <div className="col-span-2">
                <InfoItem
                  label={<Skeleton height={20} width={75} />}
                  value={<Skeleton height={20} width={110} />}
                />
                <InfoItem
                  label={<Skeleton height={20} width={75} />}
                  value={<Skeleton height={20} width={110} />}
                />
              </div>
            </div>
          </div>
        </InfoSection>
        <InfoSection
          icon={<Skeleton circle height={30} width={30} />}
          title={<Skeleton width={150} />}
        >
          <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
            {Array.from({ length: 3 }).map((_, index) => (
              <Skeleton key={index} height={70} />
            ))}
          </div>
        </InfoSection>
      </SkeletonTheme>
    </div>
  );
}

export default CompanyDetailsLoading;
