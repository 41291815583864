import { useTranslation } from "react-i18next";
import { enUS, ar } from "date-fns/locale";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

const OrderCardLoading = () => {
  const { t, i18n } = useTranslation();

  const locale = i18n.language === "ar" ? ar : enUS;
  return (
    <SkeletonTheme baseColor="#202020" highlightColor="#444">
      <div
        dir={i18n.dir()}
        className="flex p-4 items-start gap-3 self-stretch rounded-[16px] bg-[#1f1f1f]"
      >
        <div className="flex w-12 h-12 justify-end items-start gap-[7px]  rounded-full">
          <Skeleton containerClassName="w-full h-full  flex-1 flex" circle />
        </div>
        <div className="flex items-start justify-end flex-1 gap-2 max-sm:gap-1">
          <div className="flex flex-col justify-end items-start flex-1 max-sm:gap-[6px]">
            <div className="flex flex-row">
              <p className="text-[#8A8A8A] text-lg mx-1 max-md:hidden ">
                <Skeleton height={20} width={200} />
              </p>
              <span className="text-white text-[20px] font-extrabold leading-6">
                <Skeleton
                  height={20}
                  width={200}
                  className="max-sm:!w-[140px]"
                />
              </span>
            </div>
            <div className="flex flex-row max-sm:flex-col max-sm:gap-[6px]">
              <p className="mx-1 text-sm text-white text-opacity-60">
                <Skeleton height={15} width={100} />
              </p>
              <p className="mx-1 text-sm gradient-text text-nowrap">
                <Skeleton height={15} width={100} />
              </p>
            </div>
          </div>
          <div className="flex md:[min-w-190px] flex-col justify-center items-end md:gap-[6px]">
            <div className="flex gap-3 items-start max-sm:flex-col max-sm:items-end max-sm:gap-[2px]">
              <div className="flex flex-col items-start gap-3">
                <Skeleton height={30} width={90} />
              </div>
              <div className="flex items-baseline gap-1 text-white">
                <p className="text-2xl max-sm:text-[16px] font-bold">
                  <Skeleton height={30} width={90} />
                </p>
                <p className="max-sm:text-[10px] text-[14px]">
                  <Skeleton height={15} width={40} />
                </p>
              </div>
            </div>
            <div className="my-1">
              <p className="text-[#8A8A8A] text-sm">
                <Skeleton height={15} width={70} />
              </p>
            </div>
          </div>
        </div>
      </div>
    </SkeletonTheme>
  );
};

export default OrderCardLoading;
