import React from "react";

const CreditCardIcon: React.FC = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="36"
    height="36"
    viewBox="0 0 36 36"
    fill="none"
  >
    <path
      d="M31.5 7.03125H4.5C3.97786 7.03125 3.4771 7.23867 3.10788 7.60788C2.73867 7.9771 2.53125 8.47786 2.53125 9V27C2.53125 27.5221 2.73867 28.0229 3.10788 28.3921C3.4771 28.7613 3.97786 28.9688 4.5 28.9688H31.5C32.0221 28.9688 32.5229 28.7613 32.8921 28.3921C33.2613 28.0229 33.4688 27.5221 33.4688 27V9C33.4688 8.47786 33.2613 7.9771 32.8921 7.60788C32.5229 7.23867 32.0221 7.03125 31.5 7.03125ZM4.5 8.71875H31.5C31.5746 8.71875 31.6461 8.74838 31.6989 8.80113C31.7516 8.85387 31.7812 8.92541 31.7812 9V12.6562H4.21875V9C4.21875 8.92541 4.24838 8.85387 4.30113 8.80113C4.35387 8.74838 4.42541 8.71875 4.5 8.71875ZM31.5 27.2812H4.5C4.42541 27.2812 4.35387 27.2516 4.30113 27.1989C4.24838 27.1461 4.21875 27.0746 4.21875 27V14.3438H31.7812V27C31.7812 27.0746 31.7516 27.1461 31.6989 27.1989C31.6461 27.2516 31.5746 27.2812 31.5 27.2812ZM28.9688 23.625C28.9688 23.8488 28.8799 24.0634 28.7216 24.2216C28.5634 24.3799 28.3488 24.4688 28.125 24.4688H23.625C23.4012 24.4688 23.1866 24.3799 23.0284 24.2216C22.8701 24.0634 22.7812 23.8488 22.7812 23.625C22.7812 23.4012 22.8701 23.1866 23.0284 23.0284C23.1866 22.8701 23.4012 22.7812 23.625 22.7812H28.125C28.3488 22.7812 28.5634 22.8701 28.7216 23.0284C28.8799 23.1866 28.9688 23.4012 28.9688 23.625ZM19.9688 23.625C19.9688 23.8488 19.8799 24.0634 19.7216 24.2216C19.5634 24.3799 19.3488 24.4688 19.125 24.4688H16.875C16.6512 24.4688 16.4366 24.3799 16.2784 24.2216C16.1201 24.0634 16.0312 23.8488 16.0312 23.625C16.0312 23.4012 16.1201 23.1866 16.2784 23.0284C16.4366 22.8701 16.6512 22.7812 16.875 22.7812H19.125C19.3488 22.7812 19.5634 22.8701 19.7216 23.0284C19.8799 23.1866 19.9688 23.4012 19.9688 23.625Z"
      fill="white"
    />
  </svg>
);

export default CreditCardIcon;
