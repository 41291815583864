import React, { useEffect, useState } from "react";
import Layout2 from "../lib/components/layout/Layout2";
import CompanyDetailsScreen from "../lib/components/company/CompanyDetailsScreen/CompanyDetailsScreen";
import CompanyProfileForm from "../lib/components/company/CompanyProfileFormScreen/CompanyProfileForm";
import useApi from "../lib/hooks/useApi";
import useLocalStorage from "../lib/hooks/useLocalStorage";
import Toast, { IToast } from "../lib/components/Shared/Toast";
import ErrorComponent from "../lib/components/ErrorComponents/ErrorComponent";

const CompanyProfile: React.FC = () => {
  const { getData, putData } = useApi<any>();
  const { getValue } = useLocalStorage<string>();
  const [companyData, setCompanyData] = useState<any>(null);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [toast, setToast] = useState<IToast | null>(null);

  const fetchCompanyData = async () => {
    setLoading(true);
    try {
      const userId = getValue("userId");
      if (!userId) {
        throw new Error("User ID not found in localStorage");
      }

      const { data } = await getData(`/company/byuser/${userId}`);
      if (!data) {
        throw new Error("Failed to fetch company data");
      }

      setCompanyData(data);
    } catch (error) {
      setError(error.message);
      console.error("Error fetching company data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCompanyData();
  }, []);

  const handleSaveChangesClick = async (updatedData: any) => {
    setLoading(true);
    try {
      const userId = getValue("userId");
      if (!userId) {
        throw new Error("User ID not found in localStorage");
      }

      const dataToSend = {
        ...updatedData,
        userId,
      };

      const { data } = await putData("/company", dataToSend);
      if (data) {
        //consloe.log(" save changes success");

        setToast({
          type: "success",
          titleKey: "good",
          messageKey: "goodMessage",
        });
      }
      if (!data) {
        setToast({
          type: "error",
          titleKey: "notGood",
          messageKey: "notGoodMessage",
        });
        throw new Error("Failed to save changes");
      }
      setIsEditing(false);
      setCompanyData(data);
    } catch (error) {
      setToast({
        type: "error",
        titleKey: "Error",
        messageKey: "there is an error.",
      });
      console.error("Error saving company data:", error);
      setError("Error saving company data.");
    }

    setLoading(false);
  };

  if (error) {
    return <ErrorComponent/>;
  }

  return (
    <Layout2>
      <div className="flex flex-col w-full h-full bg-[#080808] p-6 gap-6  overflow-auto scrollbar-hide">
        {isEditing ? (
          <CompanyProfileForm
            companyData={companyData}
            onSave={handleSaveChangesClick}
            onCancel={() => setIsEditing(false)}
            isLoading={loading}
          />
        ) : (
          <CompanyDetailsScreen
            companyData={companyData}
            onEdit={() => setIsEditing(true)}
          />
        )}
      </div>
      {toast && (
        <Toast
          type={toast.type}
          titleKey={toast.titleKey}
          messageKey={toast.messageKey}
          onClose={() => setToast(null)}
        />
      )}
    </Layout2>
  );
};

export default CompanyProfile;
