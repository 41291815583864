import React from "react";

const SearchIcon:React.FC<IconProps> = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clip-rule="evenodd"
        d="M8.5 1.74989C7.61358 1.74989 6.73583 1.92448 5.91689 2.2637C5.09794 2.60292 4.35382 3.10012 3.72703 3.72692C3.10023 4.35371 2.60303 5.09783 2.26381 5.91678C1.92459 6.73572 1.75 7.61347 1.75 8.49989C1.75 9.38631 1.92459 10.2641 2.26381 11.083C2.60303 11.9019 3.10023 12.6461 3.72703 13.2729C4.35382 13.8997 5.09794 14.3969 5.91689 14.7361C6.73583 15.0753 7.61358 15.2499 8.5 15.2499C10.2902 15.2499 12.0071 14.5387 13.273 13.2729C14.5388 12.007 15.25 10.2901 15.25 8.49989C15.25 6.70968 14.5388 4.99279 13.273 3.72692C12.0071 2.46105 10.2902 1.74989 8.5 1.74989ZM0.25 8.49989C0.250175 7.17499 0.56944 5.86961 1.18079 4.69419C1.79214 3.51876 2.67759 2.50787 3.76224 1.74701C4.84689 0.986151 6.09883 0.497714 7.41216 0.323013C8.7255 0.148313 10.0616 0.292489 11.3074 0.743345C12.5533 1.1942 13.6722 1.93847 14.5695 2.91321C15.4669 3.88794 16.1163 5.06446 16.4628 6.34325C16.8094 7.62204 16.8428 8.96547 16.5603 10.2599C16.2778 11.5544 15.6878 12.7617 14.84 13.7799L19.53 18.4699C19.6037 18.5386 19.6628 18.6214 19.7038 18.7134C19.7448 18.8053 19.7668 18.9047 19.7686 19.0054C19.7704 19.1061 19.7518 19.2061 19.7141 19.2995C19.6764 19.3929 19.6203 19.4777 19.549 19.5489C19.4778 19.6201 19.393 19.6763 19.2996 19.714C19.2062 19.7517 19.1062 19.7703 19.0055 19.7685C18.9048 19.7667 18.8055 19.7447 18.7135 19.7037C18.6215 19.6627 18.5387 19.6036 18.47 19.5299L13.78 14.8399C12.5752 15.8434 11.1094 16.4828 9.55432 16.6831C7.99922 16.8835 6.41922 16.6366 4.99941 15.9713C3.5796 15.306 2.37878 14.2499 1.53763 12.9266C0.69648 11.6034 0.249828 10.0678 0.25 8.49989Z"
        fill="#8A8A8A"
      />
    </svg>
  );
};

export default SearchIcon;
