import React from "react";

interface UnpaidIconProps {
  color?: string;
  width?: number;
  height?: number;
  className?: string;
}

const UnpaidIcon: React.FC<UnpaidIconProps> = ({
  color = "white",
  width = 24,
  height = 24,
  className,
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 45 45"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g id="UnpaidIcon">
        {/* Circle */}
        <circle cx="22.5" cy="22.5" r="20" stroke={color} strokeWidth="3" />

        {/* Exclamation Mark */}
        <rect x="21" y="12" width="3" height="18" fill={color} rx="1.5" />
        <circle cx="22.5" cy="35" r="2" fill={color} />
      </g>
    </svg>
  );
};

export default UnpaidIcon;
