import React from "react";

interface NextIconProps {
  className?: string;
  color?: string;
  width?: string | number;
  height?: string | number;
}

const NextIcon: React.FC<NextIconProps> = ({
  className,
  color = "white",
  width = 24,
  height = 24,
}) => {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Frame">
        <path
          id="Vector"
          d="M9 6L15 12L9 18"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
};

export default NextIcon;
