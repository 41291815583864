import React from "react";
import { useTranslation } from "react-i18next";
import { OrderStatus } from "../../constants";

interface OrderStatusLabelProps {
  status: OrderStatus;
}

const statusMap = {
  [OrderStatus.Pending]: "orderStatus.pending",
  [OrderStatus.Accepted]: "orderStatus.accepted",
  [OrderStatus.Completed]: "orderStatus.completed",
  [OrderStatus.Active]: "orderStatus.active",
  [OrderStatus.Archived]: "orderStatus.archived",
};

const getStatusClasses = (status: OrderStatus): string => {
  switch (status) {
    case OrderStatus.Pending:
      return "bg-[#F83E57] text-white";
    case OrderStatus.Accepted:
    case OrderStatus.Completed:
    case OrderStatus.Active:
    case OrderStatus.Archived:
      return "bg-[#008E45] text-white";
    default:
      return "bg-gray-300 text-black";
  }
};

const OrderStatusLabel: React.FC<OrderStatusLabelProps> = ({ status }) => {
  const { t } = useTranslation();
  const statusText = t(statusMap[status] || "orderStatus.unknown");

  return (
    <span
      className={`flex px-[10px] py-[6px] rounded-full justify-center items-center gap-2 ${getStatusClasses(
        status
      )}`}
    >
      <span className="text-white text-[14px] leading-none font-normal max-sm:text-[12px]">
        {statusText}
      </span>
    </span>
  );
};

export default OrderStatusLabel;
