import React from 'react';

interface SuccessNoteIconProps {
  width?: number;
  height?: number;
  className?: string;
  color?: string;
}

const SuccessNoteIcon: React.FC<SuccessNoteIconProps> = ({
  width = 30,
  height = 31,
  className = '',
  color = 'white',
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 30 31"
      className={className}
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.9999 28.16C22.7761 28.16 29.0799 21.8562 29.0799 14.08C29.0799 6.30383 22.7761 0 14.9999 0C7.22375 0 0.919922 6.30383 0.919922 14.08C0.919922 18.4585 2.91851 22.3702 6.0532 24.9526C6.05819 24.9567 6.05374 24.9647 6.04762 24.9626C6.04385 24.9613 6.03993 24.9641 6.03993 24.9681V28.218C6.03993 29.9526 7.86822 31.0784 9.41714 30.2976L12.7489 28.6181C13.4123 28.2836 14.1558 28.1544 14.8988 28.1596C14.9325 28.1599 14.9662 28.16 14.9999 28.16Z"
        fill={color}
      />
      <g transform="translate(6, 6)">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M11.0712 2.37583C9.8702 1.84071 8.52842 1.70814 7.24595 1.99789C5.96348 2.28765 4.80903 2.98421 3.95478 3.98368C3.10053 4.98316 2.59224 6.23199 2.50573 7.54394C2.41922 8.85589 2.75912 10.1607 3.47474 11.2636C4.19035 12.3666 5.24334 13.2088 6.47666 13.6644C7.70998 14.12 9.05755 14.1648 10.3184 13.792C11.5792 13.4192 12.6858 12.6489 13.473 11.5958C14.2603 10.5427 14.686 9.2634 14.6868 7.9486V7.35122C14.6868 7.13108 14.8652 6.95262 15.0854 6.95262C15.3055 6.95262 15.484 7.13108 15.484 7.35122V7.94883C15.4831 9.43554 15.0017 10.8824 14.1115 12.0731C13.2214 13.2639 11.9701 14.135 10.5444 14.5565C9.11873 14.978 7.59497 14.9274 6.20039 14.4122C4.80581 13.897 3.61514 12.9448 2.80596 11.6975C1.99678 10.4503 1.61244 8.97498 1.71026 7.49149C1.80808 6.008 2.38282 4.59588 3.34877 3.46572C4.31471 2.33557 5.62011 1.54794 7.07026 1.22029C8.52042 0.892648 10.0376 1.04255 11.3956 1.64764C11.5967 1.73724 11.6871 1.97288 11.5975 2.17396C11.5079 2.37505 11.2723 2.46543 11.0712 2.37583ZM15.3672 2.47032C15.5229 2.62599 15.5229 2.87837 15.3672 3.03403L8.87149 9.52976C8.71583 9.68542 8.46345 9.68542 8.30779 9.52976L6.35907 7.58104C6.20341 7.42537 6.20341 7.17299 6.35907 7.01733C6.51473 6.86167 6.76711 6.86167 6.92278 7.01733L8.58964 8.68419L14.8035 2.47032C14.9592 2.31466 15.2116 2.31466 15.3672 2.47032Z" fill="white"/>

      </g>
    </svg>
  );
};

export default SuccessNoteIcon;
