// CreditIcon.tsx
import React from "react";

const CreditIcon: React.FC = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
  >
    <path
      d="M4.57059 4.57059L17.4277 17.4277M17.4277 17.4277V7.78488M17.4277 17.4277H7.78488"
      stroke="url(#paint0_linear_3417_24406)"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <defs>
      <linearGradient
        id="paint0_linear_3417_24406"
        x1="17.4277"
        y1="9.51565"
        x2="4.57059"
        y2="9.51565"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#008E45" />
        <stop offset="1" stopColor="#055D19" />
      </linearGradient>
    </defs>
  </svg>
);

export default CreditIcon;
