// src/context/PaymentOtpContext.tsx
import React, { createContext, useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import PaymentOtp from "../components/Payments/PaymentOtp";
interface PaymentOtpContextProps {
  isOpen: boolean;
  requestId: string | null;
}

const PaymentOtpContext = createContext<PaymentOtpContextProps | undefined>(
  undefined
);

export const PaymentOtpProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const location = useLocation();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [requestId, setRequestId] = useState<string | null>(null);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const status = searchParams.get("status");
    const message = searchParams.get("message");
    const func = searchParams.get("func");
    const requestIdParam = sessionStorage.getItem("requestId");

    if (status === "paid" && message === "APPROVED" && func === "Renew") {
      setIsOpen(true);
      setRequestId(requestIdParam);
    } else if (
      status === "paid" &&
      message === "APPROVED" &&
      func === "DashPay"
    ) {
      setIsOpen(true);
      setRequestId(requestIdParam);
    } else {
      setIsOpen(false);
      setRequestId(null);
    }
  }, [location.search]);

  return (
    <PaymentOtpContext.Provider value={{ isOpen, requestId }}>
      {children}
      {isOpen && requestId && (
        <PaymentOtp requestId={requestId} isOpen={true} />
      )}
    </PaymentOtpContext.Provider>
  );
};

export const usePaymentOtpContext = () => {
  const context = useContext(PaymentOtpContext);
  if (context === undefined) {
    throw new Error(
      "usePaymentOtpContext must be used within a PaymentOtpProvider"
    );
  }
  return context;
};
