import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export enum SubscriptionStatus {
  Active = 0,
  Inactive = 1,
  Pending = 2,
  Cancelled = 3,
  Expired = 4,
}

interface SubscriptionState {
  status: SubscriptionStatus | null;
  remainingDays: number;
}

const initialState: SubscriptionState = {
  status: null,
  remainingDays: 0,
};

const subscriptionSlice = createSlice({
  name: "subscription",
  initialState,
  reducers: {
    setSubscriptionStatus(state, action: PayloadAction<SubscriptionStatus>) {
      state.status = action.payload;
    },
    setRemainingDays(state, action: PayloadAction<number>) {
      state.remainingDays = action.payload;
    },
    resetSubscription(state) {
      state.status = null;
      state.remainingDays = 0;
    },
  },
});

export const { setSubscriptionStatus, setRemainingDays, resetSubscription } =
  subscriptionSlice.actions;

export default subscriptionSlice.reducer;
