import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { useLocation } from "react-router-dom";

const Card: React.FC<CardProps> = ({
  title,
  number,
  icon,
  bgColor,
  className,
  loading = false,
}) => {
  const { i18n } = useTranslation();
  const location = useLocation();
  const [isDashboard, setIsDashboard] = useState(false);

  useEffect(() => {
    setIsDashboard(location.pathname === "/home");
  }, [location]);

  return (
    <SkeletonTheme baseColor="#dededebf" highlightColor="#f5f5f55c">
      <div
        dir={i18n.dir()}
        className={`${className} ${bgColor} flex flex-1 h-[100px] min-w-[230px]  p-4 justify-between items-center gap-2 shrink-0 rounded-[16px] text-white `}
      >
        {!isDashboard ? (
          <>
            <div className="flex">
              {loading ? <Skeleton height={50} width={50} circle /> : icon}
            </div>
            <div className="flex flex-col items-end justify-center flex-1 gap-1">
              <p className="text-white text-opacity-[0.87] text-right text-[24px] font-extrabold leading-8">
                {loading ? (
                  <Skeleton
                    height={20}
                    width={60}
                    className="max-sm:!w-[40px]"
                  />
                ) : (
                  number
                )}
              </p>
              <p
                className={`text-white text-opacity-[0.87] ${
                  i18n.language === "en" ? "text-right" : "text-left"
                } text-[16px] font-normal leading-[18px] text-nowrap `}
              >
                {loading ? (
                  <Skeleton
                    height={15}
                    width={100}
                    className="max-sm:!w-[60px]"
                  />
                ) : (
                  title
                )}
              </p>
            </div>
          </>
        ) : (
          <>
            <div className="flex flex-col items-start justify-center flex-1 gap-1">
              <p className="text-white text-opacity-[0.87] text-right text-[24px] font-extrabold leading-8">
                {loading ? (
                  <Skeleton
                    height={20}
                    width={60}
                    className="max-sm:!w-[40px]"
                  />
                ) : (
                  number
                )}
              </p>
              <p
                className={`text-white text-opacity-[0.87]  ${
                  i18n.language === "en" ? "text-right" : "text-left"
                } text-[16px] font-normal leading-[18px]`}
              >
                {loading ? (
                  <Skeleton
                    height={15}
                    width={100}
                    className="max-sm:!w-[60px]"
                  />
                ) : (
                  title
                )}
              </p>
            </div>
            <div className="flex">
              {loading ? <Skeleton height={50} width={50} circle /> : icon}
            </div>
          </>
        )}
      </div>
    </SkeletonTheme>
  );
};

export default Card;
