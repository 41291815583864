import React from "react";

const ExitArrow = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="28"
      height="28"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M9 18L15 12L9 6"
        stroke="url(#paint0_linear_434_580)"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_434_580"
          x1="9"
          y1="6"
          x2="18.3437"
          y2="9.81884"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EF6B2A" />
          <stop offset="1" stopColor="#F1BC62" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default ExitArrow;
