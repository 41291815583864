import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import "./i18n";
import { AppContextProvider } from "./lib/context/app-context";
import { PaymentContextProvider } from "./lib/context/payment-context";
import { Provider } from "react-redux";
import store from "./redux/store/store";
import { ToastProvider } from "./lib/context/ToastContext";
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <AppContextProvider>
    <PaymentContextProvider>
      <ToastProvider>
        <React.Suspense fallback="loading...">
          <Provider store={store}>
            <App />
          </Provider>
        </React.Suspense>
      </ToastProvider>
    </PaymentContextProvider>
  </AppContextProvider>
);
