import React from "react";
import { useTranslation } from "react-i18next";

const HowToConnect = ({
  onPageChange,
}: {
  onPageChange: (pageNumber: number) => void;
}) => {
  const { t, i18n } = useTranslation();

  const toArabicIndicNumerals = (num) => {
    const arabicIndicDigits = [
      "٠",
      "١",
      "٢",
      "٣",
      "٤",
      "٥",
      "٦",
      "٧",
      "٨",
      "٩",
    ];
    return num.toString().replace(/\d/g, (digit) => arabicIndicDigits[digit]);
  };

  const getStepNumber = (num) => {
    return i18n.language === "ar" ? toArabicIndicNumerals(num) : num;
  };

  return (
    <div>
      <div className="flex flex-col w-full h-screen justify-center items-center p-4 md:p-0">
        <img
          src="./assets/probuy.svg"
          className=""
          alt="probuy logo"
        />
        <div className="flex flex-col w-full md:w-3/4 items-center justify-center">
          <div className="w-full md:w-[500px] bg-white bg-opacity-5 p-4 rounded-xl shadow-xl flex flex-col justify-center items-center">
            <h1 className="text-xl font-bold md:text-3xl text-center mb-4">
              {t("howToConnect.title")}
            </h1>
            <p className="text-white text-center text-base md:text-lg my-8">
              {t("howToConnect.description")}
            </p>
            <ol
              className={`flex p-2 flex-col text-left ${
                i18n.language === "ar" ? "text-right" : "text-left"
              }`}
            >
              <li>
                <div className="flex gap-2">
                  <span className="text-white opacity-30">
                    {getStepNumber(1)}{" "}
                  </span>
                  <span className="text-white mx-1 text-wrap">
                    {t("howToConnect.step1")}
                  </span>
                </div>
              </li>
              <hr className="w-full h-2 my-4 opacity-10" />
              <li>
                <div className="flex gap-2">
                  <span className="text-white opacity-30">
                    {getStepNumber(2)}{" "}
                  </span>
                  <span className="text-white mx-1 text-wrap">
                    {t("howToConnect.step2")}
                  </span>
                </div>
              </li>
              <hr className="w-full my-4 h-2 opacity-10" />
              <li>
                <div className="flex gap-2">
                  <span className="text-white opacity-30">
                    {getStepNumber(3)}
                  </span>
                  <span className="text-white mx-1 text-wrap">
                    {t("howToConnect.step3")}
                  </span>
                </div>
              </li>
            </ol>
            <button
              onClick={() => {
                onPageChange(3);
              }}
              className="bg-gradient-bg text-white py-2 px-4 rounded-3xl my-4 focus:outline-none"
            >
              {t("howToConnect.button")}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HowToConnect;
