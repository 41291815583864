import React from "react";
import { useFormikContext, Form, ErrorMessage } from "formik";
import { useTranslation } from "react-i18next";
import InputField from "../Forms/InputField";
import LoadingIcon from "../Icons/LoadingIcon";

interface IAddressDetails {
  buildingNumber: string;
  streetName: string;
  districtName: string;
  cityName: string;
  zipCode: string;
  additionalInfo?: string;
}

export interface IAPIAddressFormat {
  addressLine1: string;
  addressLine2: string;
  city: string;
  stateOrProvince: string;
  country: string;
  postalCode: string;
  additionalInfo?: string;
}

const AddressInfo: React.FC<{
  step: number;
  setStep: (step: number) => void;
  onSubmit: (apiFormattedAddress: IAPIAddressFormat) => void;
  loading: boolean;
}> = ({ step, setStep, onSubmit, loading }) => {
  const { t } = useTranslation();
  const { values, setFieldValue, handleSubmit, isValid, errors, touched } =
    useFormikContext<IAddressDetails>();

  const handleFormSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    //consloe.log("AddressInfo handleFormSubmit called, isValid:", isValid);
    //consloe.log("Form errors:", errors);
    //consloe.log("Form values:", values);
    if (isValid) {
      handleSubmit();
      const apiFormattedAddress: IAPIAddressFormat = {
        addressLine1: values.buildingNumber,
        addressLine2: values.streetName,
        city: values.cityName,
        stateOrProvince: values.districtName,
        country: "Saudi Arabia",
        postalCode: values.zipCode,
        additionalInfo: values.additionalInfo,
      };
      onSubmit(apiFormattedAddress);
    } else {
      //consloe.log("Form is not valid, cannot submit");
    }
  };

  return (
    <Form onSubmit={handleFormSubmit}>
      <section className="flex flex-col gap-8 rounded-2xl max-md:px-5 max-md:max-w-full">
        <div className="flex flex-col w-full gap-4">
          <InputField
            type="text"
            mandStar={true}
            id="buildingNumber"
            name="buildingNumber"
            label={t("buildingNumber")}
            placeholder={t("enterBuildingNumber")}
            value={values.buildingNumber}
            onChange={(e) => setFieldValue("buildingNumber", e.target.value)}
          />

          <InputField
            type="text"
            mandStar={true}
            id="streetName"
            name="streetName"
            label={t("streetName")}
            placeholder={t("streetName")}
            value={values.streetName}
            onChange={(e) => setFieldValue("streetName", e.target.value)}
          />

          <div className="flex flex-wrap gap-4 max-md:flex-col">
            <InputField
              type="text"
              mandStar={true}
              id="districtName"
              name="districtName"
              label={t("districtName")}
              placeholder={t("districtName")}
              value={values.districtName}
              onChange={(e) => setFieldValue("districtName", e.target.value)}
            />

            <InputField
              type="text"
              mandStar={true}
              id="cityName"
              name="cityName"
              label={t("cityName")}
              placeholder={t("cityName")}
              value={values.cityName}
              onChange={(e) => setFieldValue("cityName", e.target.value)}
            />

            <InputField
              type="number"
              mandStar={true}
              id="zipCode"
              name="zipCode"
              label={t("zipCode")}
              placeholder={t("zipCode")}
              value={values.zipCode}
              onChange={(e) =>
                setFieldValue("zipCode", e.target.value.toString())
              }
            />
          </div>

          <InputField
            type="text"
            mandStar={false}
            id="additionalInfo"
            name="additionalInfo"
            label={t("additionalInfo")}
            placeholder={t("additionalInfo")}
            value={values.additionalInfo}
            onChange={(e) => setFieldValue("additionalInfo", e.target.value)}
          />
        </div>
        <div className="flex items-center justify-center gap-4 sm:mt-6 ">
          <button
            type="button"
            onClick={() => setStep(step - 1)}
            className="flex px-6 py-3 border border-solid border-[#EF6B2A] gradient-text rounded-3xl"
          >
            {t("back")}
          </button>
          <button
            type="submit"
            disabled={loading}
            className="px-6 py-3 text-white rounded-3xl flex-1 max-w-[300px]"
            style={{
              background: "linear-gradient(129deg, #EF6B2A 0%, #F1BC62 100%)",
            }}
            // onClick={() => //consloe.log("Register Now button clicked")}
          >
            <span className="flex items-center gap-2 justify-center text-center text-[16px] font-bold leading-6">
              {t("registerNow")}
              {loading && (
                <div role="status">
                  <LoadingIcon color="#fff" />
                </div>
              )}
            </span>
          </button>
        </div>
      </section>
    </Form>
  );
};

export default AddressInfo;
