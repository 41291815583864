import React, { useEffect, useState, useCallback } from "react";
import {
  PageRoutes,
  PaymentStatus,
  PaymentPlanType,
  OrderStatus,
} from "../lib/constants";
import { Link, useNavigate } from "react-router-dom";
import useLocalStorage from "../lib/hooks/useLocalStorage";
import { useTranslation } from "react-i18next";
import useApi from "../lib/hooks/useApi";
import OrderCard from "../lib/components/cards/OrderCard";
import Layout2 from "../lib/components/layout/Layout2";
import OrderSearchInput from "../lib/components/Orders/OrderSearchInput";
import Calender from "../lib/components/Orders/Calender";
import ExitArrow2 from "../lib/components/Icons/ExitArrow2";
import NoData from "../lib/components/Shared/NoData";
import ExitArrow from "../lib/components/Icons/ExitArrow";
import Pagination from "../lib/components/Shared/Pagination";
import OrderCardLoading from "../lib/components/cards/OrderCardLoading";

interface CompanyOrdersResponse {
  pageIndex: number;
  pageSize: number;
  count: number;
  pageCount: number;
  data: OrderDto[];
}

interface OrderDto {
  id: string;
  createdAt: string;
  lastModifiedAt: string | null;
  orderNumber: string;
  description: string;
  status: OrderStatus;
  paymentPlanType: PaymentPlanType;
  supplierName: string;
  totalAmount: number;
  isPaid: boolean;
  installments: number;
  installmentsPaid: number;
  paymentStatus: PaymentStatus;
  paymentDate: string | null;
  dueDate: string;
}

interface FilterRequest {
  pageSize: number;
  pageIndex: number;
  selectedFilter: number;
  companyId: string;
  startTime: string | null;
  endTime: string | null;
  searchKeyword: string;
}

const ArchivedOrders: React.FC = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const { data, postData } = useApi<CompanyOrdersResponse>();
  const { getValue: getCompanyId } = useLocalStorage<string>();
  const [searchValue, setSearchValue] = useState<string>("");
  const [fromDate, setFromDate] = useState<Date | null>(null);
  const [toDate, setToDate] = useState<Date | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [orders, setOrders] = useState<OrderDto[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(0);

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
  };

  const fetchOrders = useCallback(
    async (page: number, startDate?: Date | null, endDate?: Date | null) => {
      const companyId = getCompanyId("companyId");

      if (!companyId) {
        console.error("Company ID is not available");
        return;
      }

      setLoading(true);
      const requestData: FilterRequest = {
        pageSize: 10,
        pageIndex: page - 1, // API expects 0-based index
        selectedFilter: 0, // Adjust as needed
        companyId,
        startTime: startDate ? startDate.toISOString() : null,
        endTime: endDate ? endDate.toISOString() : null,
        searchKeyword: searchValue,
      };

      try {
        const url = `/orders/company/${companyId}/archived`;
        const { data: response } = await postData(url, requestData);
        if (response && response.data) {
          setOrders(response.data);
          setTotalPages(response.pageCount);
        }
      } catch (error) {
        console.error("Failed to fetch orders:", error);
      } finally {
        setTimeout(() => setLoading(false), 1000);
      }
    },
    [searchValue]
  );

  // const handleSearchClick = useCallback(async () => {
  //   await fetchOrders(currentPage);
  // }, []);

  const handleDateFilterClick = useCallback(async () => {
    await fetchOrders(1, fromDate, toDate); // Reset to first page
  }, [fetchOrders, fromDate, toDate]);

  const handleDateFilterRemove = useCallback(async () => {
    setFromDate(null);
    setToDate(null);
    await fetchOrders(1, null, null);
  }, [fetchOrders]);

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
    fetchOrders(page, fromDate, toDate);
  };

  useEffect(() => {
    fetchOrders(currentPage);
  }, []);

  useEffect(() => {
    const debounceSearch = setTimeout(() => {
      fetchOrders(currentPage, fromDate, toDate);
    }, 300); // Debounce time

    return () => clearTimeout(debounceSearch);
  }, [searchValue]);
  function handleBack() {
    navigate(-1);
  }

  return (
    <Layout2 title={t("currentPayables")}>
      <div className="flex flex-col h-full gap-4 p-6">
        <div className="flex justify-between w-full max-sm:flex-col max-sm:gap-6 ">
          <div className="flex items-center justify-center gap-2 max-sm:justify-start">
            <div onClick={handleBack} className="cursor-pointer">
              {i18n.language === "en" ? <ExitArrow2 /> : <ExitArrow />}
            </div>
            <h1 className="text-[24px] font-bold leading-6 text-nowrap text-white text-opacity-[0.87]">
              {t("Archived Orders")}
            </h1>
          </div>
          <div className="flex items-center justify-end gap-4">
            <OrderSearchInput
              placeholder="clientnameorOrdernumber"
              searchValue={searchValue}
              onSearchChange={handleSearchChange}
              // onSearchClick={handleSearchClick}
            />
            <Calender
              label={t("alltimes")}
              fromDate={fromDate}
              toDate={toDate}
              onFromDateChange={setFromDate}
              onToDateChange={setToDate}
              onFilterClick={handleDateFilterClick}
              onFilteRemove={handleDateFilterRemove}
            />
          </div>
        </div>
        <div dir={i18n.dir()} className="flex flex-col h-full gap-6">
          <div className="flex flex-col h-full gap-2 overflow-y-auto scrollbar-w-2 scrollbar-track-gray-100 scrollbar-thumb-gray-500 hover:scrollbar-thumb-gray-600">
            {loading ? (
              <div className="flex flex-col gap-2">
                {Array.from({ length: 4 }).map((_, index) => (
                  <OrderCardLoading key={index} />
                ))}
              </div>
            ) : orders.length > 0 ? (
              <>
                {orders.map((order) => (
                  <Link
                    to={`${PageRoutes.ORDER_DETAILS.replace(":id", order.id)}`}
                    key={order.id}
                  >
                    <OrderCard {...order} />
                  </Link>
                ))}
                <Pagination
                  currentPage={currentPage}
                  totalPages={totalPages}
                  onPageChange={handlePageChange}
                />
              </>
            ) : (
              <div className="flex items-center self-center justify-center w-full h-full">
                <NoData />
              </div>
            )}
          </div>
        </div>
      </div>
    </Layout2>
  );
};

export default ArchivedOrders;
