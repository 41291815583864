import React, { useState, useEffect, useRef } from "react";
import { useFormikContext } from "formik";
import useLocalStorage from "../../hooks/useLocalStorage";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { OrganizationType } from "../../constants";
import Toast from "../Shared/Toast";
import InputField from "../Forms/InputField";
import { ICompanyDetails } from "../../../pages/CompanyDetailsSignIn";
import Dropdown from "../Forms/Dropdown";
import NoteIcon from "../Icons/NoteIcon";
import OTPInput from "../Shared/OTPInput";
import { useFormik } from "formik";
import SuccessNoteIcon from "../Icons/SuccessNoteIcon";
import { API_BASE_URL } from "../../constants/api-constants";

const BasicInfo = ({ onPageChange }) => {
  const { t, i18n } = useTranslation();

  const OrganizationTypeLabels = {
    [OrganizationType.SoleProprietorship]: t("SoleProprietorship"),
    [OrganizationType.Partnership]: t("Partnership"),
    [OrganizationType.LimitedLiabilityCompany]: t("LimitedLiabilityCompany"),
    [OrganizationType.Franchise]: t("Franchise"),
    [OrganizationType.Nonprofit]: t("Nonprofit"),
    [OrganizationType.ListedCompany]: t("ListedCompany"),
    [OrganizationType.Other]: t("Other"),
    [OrganizationType.CompanyLimitedByGuarantee]: t(
      "CompanyLimitedByGuarantee"
    ),
    [OrganizationType.SmallMediumSizedEnterprise]: t(
      "SmallMediumSizedEnterprise"
    ),
  };

  const { setValue: setOrgValue, getValue: getOrgValue } =
    useLocalStorage<Organization>();
  const { getValue: getCrNumber } = useLocalStorage<string>();
  const { getValue: getCompanyData } = useLocalStorage<CompanyDto>();
  const { getValue: getUserInfo } = useLocalStorage<any>();

  const {
    values,
    setFieldValue,
    validateForm,
    setTouched,
    setFieldTouched,
    errors,
    touched,
  } = useFormikContext<any>();

  const [currentOpenDropdown, setCurrentOpenDropdown] = useState<string | null>(
    null
  );
  const [showToast, setShowToast] = useState<boolean>(false);
  const [toastTitle, setToastTitle] = useState<string>("");
  const [toastMessage, setToastMessage] = useState<string>("");
  const [showNote, setShowNote] = useState(false);
  const inputRefs = useRef<HTMLInputElement[]>([]);
  const [isOtpSent, setIsOtpSent] = useState(false);
  const [counter, setCounter] = useState(120);
  const crNumber = getCrNumber("CR");
  const userInfo = getUserInfo("userInfo");
  const defaultEmail = userInfo?.email ?? "";
  const [showVerifyMessage, setShowVerifyMessage] = useState(true);
  const [showOtpProcess, setShowOtpProcess] = useState(false);
  const [otpError, setOtpError] = useState<string | null>(null);
  const [isValidPhone, setIsValidPhone] = useState(false);
  const [toastType, setToastType] = useState<"success" | "error">("error");
  const [isOtpVerified, setIsOtpVerified] = useState(false);
  const [otpInputDisabled, setOtpInputDisabled] = useState(true);
  const [isValidOtp, setIsValidOtp] = useState(false);
  const [isEditingPhone, setIsEditingPhone] = useState(false);
  const getToken = () => sessionStorage.getItem("keycloak_token");
  const getUserId = () => localStorage.getItem("userId");

  // Function to format the timer
  const formatTime = (seconds: number) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes.toString().padStart(2, "0")}:${remainingSeconds
      .toString()
      .padStart(2, "0")}`;
  };
  const handleSendOtp = async () => {
    if (isValidSaudiNumber(values.phone)) {
      try {
        const token = getToken();
        const userId = getUserId();
        const headers = new Headers({
          Authorization: `Bearer ${token}`,
          "user-id": userId.replaceAll('"', ""),
          "Content-Type": "application/json",
        });

        // Format the phone number with +966 prefix
        const formattedPhone = `+966${values.phone}`;

        const response = await fetch(`${API_BASE_URL}/user/otp/request`, {
          method: "POST",
          headers: headers,
          body: JSON.stringify(formattedPhone),
        });

        if (response.status === 200) {
          setIsOtpSent(true);
          setCounter(120);
          setOtpError(null);
          setOtpInputDisabled(false);
          setToastType("success");
          setToastTitle("successSendOtpTitlebasicInfoP");
          setToastMessage("successSendOtpMessagebasicInfoP");
          setShowToast(true);
          formik.resetForm(); // Reset OTP input fields
        } else {
          setOtpInputDisabled(false);
          setToastType("error");
          setToastTitle("errSendOtpTitlebasicInfoP");
          setToastMessage("errSendOtpMessagebasicInfoP");
          setShowToast(true);
        }
      } catch (error) {
        console.error("Fetch Error:", error);
        setOtpInputDisabled(false);
        setToastType("error");
        setToastTitle("errSendOtpTitlebasicInfoP");
        setToastMessage("errSendOtpMessagebasicInfoP");
        setShowToast(true);
      }
    } else {
      setOtpError(t("InvalidPhoneNumber"));
      setToastType("error");
      setToastTitle("errSendOtpTitlebasicInfoP");
      setToastMessage("errSendOtpMessagebasicInfoP");
      setShowToast(true);
    }
  };
  const handleResendOtp = async () => {
    if (isValidSaudiNumber(values.phone)) {
      try {
        const token = getToken();
        const userId = getUserId();

        const headers = new Headers({
          Authorization: `Bearer ${token}`,
          "user-id": userId.replaceAll('"', ""),
          "Content-Type": "application/json",
        });

        // Format the phone number with +966 prefix
        const formattedPhone = `+966${values.phone}`;

        const response = await fetch(`${API_BASE_URL}/user/otp/request`, {
          method: "POST",
          headers: headers,
          body: JSON.stringify(formattedPhone),
        });

        if (response.ok) {
          setToastType("success");
          setToastTitle("successResendOtpTitlebasicInfoP");
          setToastMessage("successResendOtpMessagebasicInfoP");
          setIsOtpSent(true);
          setCounter(120);
          setOtpError(null);
          setOtpInputDisabled(false);
          setShowToast(true);
          formik.setValues(initialValues);
        } else {
          setOtpInputDisabled(false);
          setToastType("error");
          setToastTitle("errResendOtpTitlebasicInfoP");
          setToastMessage("errResendOtpMessagebasicInfoP");
          setShowToast(true);
        }
      } catch (error) {
        console.error("Fetch Error:", error);
      }
    } else {
      setOtpError(t("InvalidPhoneNumber"));
    }
  };
  const handleOtpSubmit = async () => {
    const otpValue = Object.values(formik.values).join("");

    if (otpValue.length !== 4 || !/^\d+$/.test(otpValue)) {
      setOtpError(t("InvalidOtp"));
      return;
    }

    try {
      const token = getToken();
      const userId = getUserId();
      const headers = new Headers({
        Authorization: `Bearer ${token}`,
        "user-id": userId.replaceAll('"', ""),
        "Content-Type": "application/json",
      });

      // Format the phone number with +966 prefix
      const formattedPhone = `+966${values.phone}`;

      const response = await fetch(`${API_BASE_URL}/user/otp/verify`, {
        method: "POST",
        headers: headers,
        body: JSON.stringify({
          number: formattedPhone,
          otp: otpValue,
        }),
      });

      if (response.ok) {
        const data = await response.json();

        if (data) {
          setToastType("success");
          setToastTitle("successVerifyOtpTitlebasicInfoP");
          setToastMessage("successVerifyOtpMessagebasicInfoP");
          setIsOtpSent(true);
          setCounter(120);
          setOtpError(null);
          setOtpInputDisabled(false);
          setShowToast(true);
          setIsOtpVerified(true);
        } else {
          // Handle the case where the response is not successful
          setOtpInputDisabled(false);
          setToastType("error");
          setToastTitle("errVerifyOtpTitlebasicInfoP");
          setToastMessage("errVerifyOtpMessagebasicInfoP");
          setShowToast(true);
          setIsOtpVerified(false);
        }
      } else {
        const errorData = await response.json();
        console.error("Error:", errorData);
        setIsOtpVerified(true);
        setOtpInputDisabled(false);
        setToastType("error");
        setToastTitle("errVerifyOtpTitlebasicInfoP");
        setToastMessage("errVerifyOtpMessagebasicInfoP");
        setShowToast(true);
      }
    } catch (error) {
      console.error("Fetch Error:", error);
      setOtpInputDisabled(false);
      setToastType("error");
      setToastTitle("errVerifyOtpTitlebasicInfoP");
      setToastMessage("errVerifyOtpMessagebasicInfoP");
      setShowToast(true);
    }
  };

  const isValidSaudiNumber = (phone: string) => {
    // Check if the number is exactly 9 digits and starts with 5
    return phone.length === 9 && phone.startsWith("5");
  };

  const handleInputChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >
  ) => {
    const { id, value } = e.target;

    if (id === "phone") {
      // Only allow digits and limit to 9 characters
      const filteredValue = value.replace(/\D/g, "").slice(0, 9);
      setFieldValue(id, filteredValue);
    } else {
      setFieldValue(id, value);
    }

    const orgData = getOrgValue("orgData") || {};
    setOrgValue("orgData", {
      ...orgData,
      [id]: value,
    } as Organization);
  };

  useEffect(() => {
    if (defaultEmail) {
      setFieldValue("email", defaultEmail);
    }
  }, []);

  const handleNext = async () => {
    const stepFields = ["website", "email", "phone", "organizationType"];

    // Set touched for all fields first
    setTouched({
      website: true,
      email: true,
      phone: true,
      organizationType: true,
    });

    // Validate the entire form
    const validationErrors = await validateForm();

    // Filter errors for the current step fields
    const currentErrors = stepFields.reduce((acc, field) => {
      if (validationErrors[field]) {
        acc[field] = validationErrors[field];
      }
      return acc;
    }, {} as Partial<ICompanyDetails>);

    const isStepValid = Object.keys(currentErrors).length === 0;

    if (isStepValid && isOtpVerified) {
      onPageChange("second");
    } else {
      // Show specific error messages
      if (!isOtpVerified) {
        setToastType("error");
        setToastTitle("errVerifyOtpTitlebasicInfoP");
        setToastMessage("Please verify your phone number before proceeding");
        setShowToast(true);
      } else {
        setToastType("error");
        setToastTitle("Validation Error");
        setToastMessage("Please fill in all required fields correctly");
        setShowToast(true);
      }
    }
  };

  const toggleDropdown = (dropdown: string) => {
    setCurrentOpenDropdown((prev) => (prev === dropdown ? null : dropdown));
  };

  const handlePhoneFocus = () => {
    setShowNote(false);
    setShowVerifyMessage(false);
    setShowOtpProcess(true);
  };

  // Validation schema for OTP
  const validationSchema = Yup.object({
    otp0: Yup.number().required(t("Required")).typeError(t("MustBeANumber")),
    otp1: Yup.number().required(t("Required")).typeError(t("MustBeANumber")),
    otp2: Yup.number().required(t("Required")).typeError(t("MustBeANumber")),
    otp3: Yup.number().required(t("Required")).typeError(t("MustBeANumber")),
  });

  // Initial values for Formik
  const initialValues = {
    otp0: "",
    otp1: "",
    otp2: "",
    otp3: "",
  };
  const handleSubmit = async (values: any) => {
    const otp = `${values.otp0}${values.otp1}${values.otp2}${values.otp3}`;
    setIsValidOtp(otp.length === 4 && /^\d+$/.test(otp));
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: handleSubmit,
    validateOnChange: true,
    validateOnBlur: true,
  });

  useEffect(() => {
    const otp = `${formik.values.otp0}${formik.values.otp1}${formik.values.otp2}${formik.values.otp3}`;
    setIsValidOtp(otp.length === 4 && /^\d+$/.test(otp));
  }, [formik.values]);

  useEffect(() => {
    if (inputRefs.current[0]) {
      inputRefs.current[0].focus();
    }
  }, []);

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (isOtpSent && counter > 0) {
      timer = setInterval(() => {
        setCounter((prevCounter) => prevCounter - 1);
      }, 1000);
    }
    return () => {
      if (timer) clearInterval(timer);
    };
  }, [isOtpSent]);

  const handleChangeNumber = () => {
    setIsEditingPhone(true);
    setIsOtpSent(false);
    setIsOtpVerified(false);
    setFieldValue("phone", "");
    // Reset OTP inputs using formik
    formik.resetForm();
    // Additional state resets
    setOtpError(null);
    setIsValidOtp(false);
    setOtpInputDisabled(true);
    setShowOtpProcess(true);
  };

  // Add this useEffect to monitor phone number changes
  useEffect(() => {
    // Check if phone number is exactly 9 digits and starts with 5
    const isValid = values.phone?.length === 9 && values.phone.startsWith("5");
    setIsValidPhone(isValid);
  }, [values.phone]);

  return (
    <section className="flex flex-col w-full gap-4 sm:gap-8">
      <div className="flex flex-col w-full gap-3 sm:gap-4">
        <InputField
          mandStar={true}
          placeholder={t("CompanyName")}
          id="name"
          name="name"
          label={t("CompanyName")}
          defaultValue={getCompanyData("companyData")?.name ?? ""}
          disabled
          className="text-white text-opacity-60"
          inputClassName="inputDisapled"
        />
        <div className="flex flex-col gap-3 sm:flex-row sm:gap-4">
          <InputField
            placeholder={t("CrNumber")}
            mandStar={true}
            id="cr"
            name="cr"
            label={t("CrNumber")}
            defaultValue={crNumber ?? ""}
            inputClassName="inputDisapled"
            disabled
            className="w-full sm:w-1/2"
          />
          <InputField
            placeholder={t("companyWebsitePlaceholder")}
            id="website"
            name="website"
            label={t("companyWebsite")}
            type="url"
            value={values.website}
            onChange={handleInputChange}
            className="w-full sm:w-1/2"
          />
        </div>
        <div className="flex flex-col gap-3 sm:flex-row sm:gap-4">
          <InputField
            placeholder={t("emailPlaceholder")}
            mandStar={true}
            id="email"
            name="email"
            label={t("email")}
            type="email"
            value={values.email || defaultEmail}
            onChange={handleInputChange}
            disabled
            className="w-full sm:w-1/2"
          />
          <Dropdown
            className="w-full sm:w-1/2"
            label={t("organizationType")}
            placeholder={t("organizationTypePlaceholder")}
            mandStar={true}
            value={values.organizationType}
            options={Object.entries(OrganizationTypeLabels).map(
              ([value, label]) => ({
                value,
                label,
              })
            )}
            onSelect={(value) => setFieldValue("organizationType", value)}
            name="organizationType"
            isOpen={currentOpenDropdown === "organizationType"}
            toggleDropdown={() => toggleDropdown("organizationType")}
          />
        </div>
        <div className="flex flex-col gap-3 sm:flex-row sm:gap-4">
          <div className="flex flex-col w-full gap-2 sm:w-1/2 sm:gap-3">
            <div className="flex flex-row items-center justify-between">
              <label
                htmlFor="phone"
                className="flex gap-1 text-sm font-medium text-gray-300"
              >
                {t("PhoneNumber")}
                <span className="text-[#EF6B2A] font-[800] leading-6 text-[14px]">
                  *
                </span>
              </label>
              {isOtpVerified && (
                <button
                  type="button"
                  onClick={handleChangeNumber}
                  className="block mb-2 text-sm font-medium gradient-text"
                >
                  {t("Change Number?")}
                </button>
              )}
            </div>
            <div className="flex w-full gap-2">
              <input
                id="phonePrefix"
                name="phonePrefix"
                type="text"
                className="flex-none w-[60px] placeholder-style text-[14px] placeholder:text-[#ffffff80] px-[14px] py-[12px] bg-[#1e1e1e] text-white font-normal rounded-[12px] border border-[#ffffff29] focus:outline-none focus:ring-2 focus:ring-orange-500"
                disabled
                value="+966"
              />
              <input
                id="phone"
                name="phone"
                type="text"
                className="w-full min-w-0 placeholder-style text-[14px] placeholder:text-[#ffffff80] px-[16px] py-[12px] bg-[#1e1e1e] text-white font-normal rounded-[12px] border border-[#ffffff29] focus:outline-none focus:ring-2 focus:ring-orange-500"
                placeholder={t("5XXXXXXXX")}
                maxLength={9}
                pattern="5\d{8}"
                onInput={(e) => {
                  const input = e.currentTarget;
                  let value = input.value.replace(/\D/g, "");
                  if (value.length > 0 && value[0] !== "5") {
                    value = "5" + value.slice(1);
                  }
                  input.value = value.slice(0, 9);
                  setFieldValue("phone", value);
                }}
                value={values.phone}
                onChange={handleInputChange}
                onFocus={handlePhoneFocus}
                disabled={isOtpVerified && !isEditingPhone}
              />
            </div>
            {touched.phone && errors.phone && (
              <div className="mt-1 text-sm text-red-500"></div>
            )}
          </div>
          {showOtpProcess && !isOtpVerified && (
            <form
              onSubmit={formik.handleSubmit}
              className="flex flex-col w-full gap-2 sm:w-1/2 sm:gap-3"
            >
              <label
                htmlFor="otp"
                className="flex gap-1 text-sm font-medium text-gray-300"
              >
                {t("EnterOtp")}
                <span className="text-[#EF6B2A] font-[800] leading-6 text-[14px]">
                  *
                </span>
              </label>
              <div className="flex flex-row w-full gap-2 sm:gap-4">
                {Object.keys(initialValues).map((key, index) => (
                  <OTPInput
                    className="w-full h-[48px]"
                    key={index}
                    value={formik.values[key]}
                    onChange={(e) => {
                      formik.handleChange(e);
                      if (e.target.value && index < 3) {
                        inputRefs.current[index + 1]?.focus();
                      }
                      if (!e.target.value && index > 0) {
                        inputRefs.current[index - 1]?.focus();
                      }
                    }}
                    ref={(el) => (inputRefs.current[index] = el!)}
                    name={key}
                    disabled={otpInputDisabled}
                  />
                ))}
              </div>
              {otpError && (
                <p className="mt-1 text-sm text-red-500">{otpError}</p>
              )}
            </form>
          )}
          {showVerifyMessage && (
            <div className="flex items-center w-full sm:w-1/2 max-justify-start sm:mt-7 ">
              <div className=" flex flex-row gap-[6px] max-w-[215px] justify-center items-center">
                <NoteIcon width={30} height={31} />
                <p className="text-[#EF8D32] text-[14px] sm:text-[16px] font-normal leading-6 text-nowrap">
                  {t("verifyYourPhoneNumber")}
                </p>
              </div>
            </div>
          )}
          {isOtpVerified && (
            <div className="flex items-center w-full sm:w-1/2 max-justify-start sm:mt-7">
              <div className=" flex flex-row gap-[6px] max-w-[215px] justify-center items-center">
                <SuccessNoteIcon
                  width={40}
                  height={40}
                  className="custom-class"
                  color="#005E38"
                />
                <p className="text-[#008E45] text-[14px] sm:text-[16px] font-normal leading-6 text-nowrap">
                  {t("verifiedSuccessfully")}
                </p>
              </div>
            </div>
          )}
        </div>
        {showNote ||
          (!isOtpVerified && (
            <div className="flex px-3 sm:px-4 items-center gap-4 self-stretch border-solid border-r border-t border-b border-l-[3px] border-[#EF8D32] rounded-[12px] bg-[#393939]">
              <div className="w-4 h-4 sm:w-5 sm:h-5">
                <NoteIcon />
              </div>
              <div className="flex flex-row py-3 sm:py-4 items-start gap-[2px] flex-1">
                <p className="text-[12px] sm:text-[14px] text-white font-normal leading-[18px] sm:leading-[20px] tracking-[0.16]">
                  <span className="font-bold">{t("note")}</span>
                  {t("verifyMessage")}
                </p>
              </div>
            </div>
          ))}
        {!isOtpVerified && (
          <div className="flex flex-row items-center justify-start w-full gap-3 ">
            {!isOtpSent ? (
              showOtpProcess && (
                <button
                  onClick={handleSendOtp}
                  type="button"
                  disabled={!isValidPhone}
                  className={`w-full sm:w-auto text-nowrap max-w-[125px] bg-gradient-to-r from-orange-500 to-orange-300 flex items-center justify-center gap-2 px-4 py-2  text-white rounded-3xl focus:outline-none ${
                    isValidPhone ? "" : "cursor-not-allowed opacity-50"
                  }`}
                >
                  {t("GetOtp")}
                </button>
              )
            ) : (
              <button
                onClick={handleOtpSubmit}
                type="button"
                disabled={!isValidOtp}
                className={`w-full sm:w-auto max-w-[119px] flex items-center justify-center gap-2 px-4 py-2  text-white bg-gradient-to-r from-orange-500 to-orange-300 rounded-3xl focus:outline-none ${
                  isValidOtp ? "" : "cursor-not-allowed opacity-50"
                }`}
              >
                {t("SubmitOtp")}
              </button>
            )}
            {isOtpSent && (
              <p
                className={`text-center gradient-text sm:text-left cursor-not-allowed  ${
                  counter > 0 ? "opacity-50" : ""
                }`}
              >
                {counter > 0 ? (
                  <>
                    {t("ResendOTP")} ({formatTime(counter)})
                  </>
                ) : (
                  <button
                    onClick={handleResendOtp}
                    className="gradient-text focus:outline-none"
                  >
                    {t("ResendOTP")}
                  </button>
                )}
              </p>
            )}
          </div>
        )}
      </div>

      <div className="flex justify-center ">
        <button
          type="button"
          onClick={() => {
            //consloe.log("Next button clicked");
            //consloe.log("isOtpVerified:", isOtpVerified);
            //consloe.log("Current form values:", values);
            handleNext();
          }}
          disabled={!isOtpVerified}
          className={`w-full sm:w-1/2 max-w-[300px] bg-gradient-to-r from-orange-500 to-orange-300 px-6 py-3 gap-[6px] text-white rounded-[24px] ${
            isOtpVerified ? "" : "cursor-not-allowed opacity-50"
          }`}
        >
          <span className="flex items-center justify-center text-white text-center leading-6 text-[16px]">
            {t("next")}
          </span>
        </button>
      </div>
      {showToast && (
        <Toast
          onClose={() => {
            setShowToast(false);
          }}
          type={toastType}
          titleKey={toastTitle}
          messageKey={toastMessage}
        />
      )}
    </section>
  );
};

export default BasicInfo;
