import React, { useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import DashboardPage from "./pages/DashboardPage";
import Signin from "./pages/Signin";
import { PageRoutes } from "./lib/constants";
import OrdersPage from "./pages/OrdersPage";
import TransactionsPage from "./pages/TransactionsPage";
import OrderDetails from "./pages/OrderDetails";
import UserProfile from "./pages/UserProfile";
import CompanyProfile from "./pages/CompanyProfile";
import Register from "./pages/Register";
import ConnectBankAccount from "./pages/ConnectBankAccount";
import LeanPage from "./pages/LeanPage";
import CreditScorePage from "./pages/CreditScorePage";
import LogOut from "./pages/LogOut";
import CompanyDetailsSignIn from "./pages/CompanyDetailsSignIn";
import ManualCreditScore from "./pages/ManualCreditScore";
import ManualAccPending from "./pages/ManualAccPending";
import { LoadingProvider } from "./lib/context/LoadingContext";
import { PrivateRoute } from "./PrivateRoute";
import CheckingCompanyStatusRoute from "./CheckingCompanyStatusRoute";
import ArchivedOrders from "./pages/ArchivedOrders";
import { PaymentOtpProvider } from "./lib/context/PaymentOtpContext";

import "react-loading-skeleton/dist/skeleton.css";

import Loadingwithoutlogo from "./pages/loadingwithoutlogo";
import ProfilePage from "./pages/ProfilePage";
import { UserProvider } from "./lib/context/UserContext";
import Layout3 from "./lib/components/layout/Layout3";
import { useTranslation } from "react-i18next";

const App = () => {
  const { t } = useTranslation();

  // useEffect(() => {
  //   function getCookie(name) {
  //     const value = `; ${document.cookie}`;
  //     const parts = value.split(`; ${name}=`);
  //     if (parts.length === 2) return parts.pop().split(";").shift();
  //     return null;
  //   }

  //   // Retrieve the value of the "KEYCLOAK_LOCALE" cookie
  //   const savedLanguage = getCookie("KEYCLOAK_LOCALE");

  //   //consloe.log("the saved lang is from cookie:>", savedLanguage);
  //   sessionStorage.setItem("lang", savedLanguage);
  //   //consloe.log(
  //     "this is saved lang in session storage:>",
  //     sessionStorage.getItem("lang")
  //   );
  // }, []);
  return (
    <LoadingProvider>
      <BrowserRouter>
        <PaymentOtpProvider>
          <Routes>
            <Route path={PageRoutes.LOGOUT} element={<LogOut />} />
            <Route
              path={PageRoutes.Loadingwithoutlogo}
              element={<Loadingwithoutlogo />}
            />
            <Route
              path={PageRoutes.DASHBOARD}
              element={
                <PrivateRoute>
                  <CheckingCompanyStatusRoute
                    element={DashboardPage}
                    allowedStatuses={[2, 3, 4, 5]}
                    redirectRoutes={{
                      0: PageRoutes.SIGN_IN,
                      1: PageRoutes.SIGN_IN,
                    }}
                  />
                </PrivateRoute>
              }
            />

            <Route
              path={PageRoutes.ORDERS}
              element={
                <PrivateRoute>
                  <CheckingCompanyStatusRoute
                    element={OrdersPage}
                    allowedStatuses={[2, 3, 4, 5]}
                    redirectRoutes={{
                      0: PageRoutes.SIGN_IN,
                      1: PageRoutes.SIGN_IN,
                    }}
                  />
                </PrivateRoute>
              }
            />
            <Route
              path={PageRoutes.ARCHIVE}
              element={
                <PrivateRoute>
                  <CheckingCompanyStatusRoute
                    element={ArchivedOrders}
                    allowedStatuses={[2, 3, 4, 5]}
                    redirectRoutes={{
                      0: PageRoutes.SIGN_IN,
                      1: PageRoutes.SIGN_IN,
                    }}
                  />
                </PrivateRoute>
              }
            />

            <Route
              path={PageRoutes.PAYMENTS}
              element={
                <PrivateRoute>
                  <CheckingCompanyStatusRoute
                    element={TransactionsPage}
                    allowedStatuses={[2, 3, 4, 5]}
                    redirectRoutes={{
                      0: PageRoutes.SIGN_IN,
                      1: PageRoutes.SIGN_IN,
                    }}
                  />
                </PrivateRoute>
              }
            />

            <Route
              path={PageRoutes.ORDER_DETAILS}
              element={
                <PrivateRoute>
                  <CheckingCompanyStatusRoute
                    element={OrderDetails}
                    allowedStatuses={[2, 3, 4, 5]}
                    redirectRoutes={{
                      0: PageRoutes.SIGN_IN,
                      1: PageRoutes.SIGN_IN,
                    }}
                  />
                </PrivateRoute>
              }
            />

            <Route
              path={PageRoutes.PROFILE}
              element={
                <PrivateRoute>
                  <CheckingCompanyStatusRoute
                    // element={UserProfile}
                    element={ProfilePage}
                    allowedStatuses={[2, 3, 4, 5]}
                    redirectRoutes={{
                      0: PageRoutes.SIGN_IN,
                      1: PageRoutes.SIGN_IN,
                    }}
                  />
                </PrivateRoute>
              }
            />

            <Route
              path={PageRoutes.COMPANY_PROFILE}
              element={
                <PrivateRoute>
                  <CheckingCompanyStatusRoute
                    element={CompanyProfile}
                    allowedStatuses={[2, 3, 4, 5]}
                    redirectRoutes={{
                      0: PageRoutes.SIGN_IN,
                      1: PageRoutes.SIGN_IN,
                    }}
                  />
                </PrivateRoute>
              }
            />
            <Route path={PageRoutes.SIGN_IN} element={<Signin />} />
            <Route
              path={PageRoutes.REGISTER}
              element={
                // <Register />
                <PrivateRoute>
                  <CheckingCompanyStatusRoute
                    element={Register}
                    allowedStatuses={[0, 1]}
                    redirectRoutes={{
                      2: PageRoutes.DASHBOARD,
                      3: PageRoutes.DASHBOARD,
                      4: PageRoutes.DASHBOARD,
                      5: PageRoutes.DASHBOARD,
                      undefined: PageRoutes.REGISTER,
                    }}
                    allowUndefinedStatus={true}
                  />
                </PrivateRoute>
              }
            />
            <Route
              path={PageRoutes.LEAN}
              element={
                <PrivateRoute>
                  <CheckingCompanyStatusRoute
                    element={LeanPage}
                    allowedStatuses={[0, 1]}
                    redirectRoutes={{}}
                    allowUndefinedStatus={true}
                  />
                </PrivateRoute>
              }
            />
            <Route
              path={PageRoutes.CREDIT_SCORE}
              element={
                <PrivateRoute>
                  {/* <CreditScorePage /> */}
                  <CheckingCompanyStatusRoute
                    element={CreditScorePage}
                    allowedStatuses={[1, 2, 3, 4, 5]}
                    allowUndefinedStatus={false}
                    redirectRoutes={{ 0: PageRoutes.SIGN_IN }}
                  />
                </PrivateRoute>
              }
            />

            <Route
              path={PageRoutes.COMPANY_INFO_REGISTER}
              element={
                // <CompanyDetailsSignIn/>
                <PrivateRoute>
                  <CheckingCompanyStatusRoute
                    element={CompanyDetailsSignIn}
                    allowedStatuses={[0, 1, 3, 4, 5]}
                    redirectRoutes={{ 2: PageRoutes.DASHBOARD }}
                    allowUndefinedStatus={true}
                  />
                </PrivateRoute>
              }
            />

            <Route
              path={PageRoutes.MANUAL_BANK_CONNECT}
              element={
                <PrivateRoute>
                  <CheckingCompanyStatusRoute
                    element={ManualCreditScore}
                    allowedStatuses={[0, 1, 3, 4, 5]}
                    redirectRoutes={{ 2: PageRoutes.DASHBOARD }}
                  />
                </PrivateRoute>
              }
            />

            <Route
              path={PageRoutes.MANUAL_PENDING_CREDIT_SCORE}
              element={
                <PrivateRoute>
                  <CheckingCompanyStatusRoute
                    element={ManualAccPending}
                    allowedStatuses={[0, 1, 3, 4, 5]}
                    redirectRoutes={{ 2: PageRoutes.DASHBOARD }}
                    allowUndefinedStatus={true}
                  />
                </PrivateRoute>
              }
            />

            <Route
              path={PageRoutes.CONNECT_BANK}
              element={
                <PrivateRoute>
                  <CheckingCompanyStatusRoute
                    element={ConnectBankAccount}
                    allowedStatuses={[0, 1, 2, 3, 4, 5]}
                    redirectRoutes={{}}
                    allowUndefinedStatus={true}
                  />
                </PrivateRoute>
              }
            />
            <Route
              path="*"
              element={
                <Layout3>
                  <h1>{t("notFound")}</h1>
                </Layout3>
              }
            />
          </Routes>
        </PaymentOtpProvider>
      </BrowserRouter>
    </LoadingProvider>
  );
};

export default App;
