import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import CorrectIcon from "../Icons/CorrectIcon";
import useApi from "../../hooks/useApi";
import useLocalStorage from "../../hooks/useLocalStorage";
import { formatNumber } from "../../utils/formatNumber";

const BigCard = ({ companyName }: { companyName: string }) => {
  const { t, i18n } = useTranslation();
  const {
    data: creditData,
    status,
    error,
    getData,
  } = useApi<{
    id: string;
    createdAt: string;
    lastModifiedAt: string;
    companyId: string;
    creditLimit: number;
    availableBalance: number;
    outstandingBalance: number;
    dueFromCredit: number;
    dueFromInstallments: number;
  }>();

  const { getValue: getCompanyId } = useLocalStorage();
  const companyId = getCompanyId("companyId");

  useEffect(() => {
    if (companyId) {
      getData(`/credit/${companyId}`);
    }
  }, []);

  return (
    <div
      dir={i18n.dir()}
      className="px-6 py-8 max-sm:p-4 col-span-1 flex flex-col gap-8 flex-1 self-stretch dash-card-bg rounded-[16px] overflow-hidden"
    >
      <div className="flex flex-col items-start self-stretch justify-center gap-2">
        <h1
          className={`text-[24px] text-white font-bold ${
            i18n.language === "ar" ? "text-right" : "text-left"
          }`}
        >
          {companyName}
        </h1>
        <div className="flex flex-row items-end justify-center gap-1">
          <div className="flex flex-row justify-between items-center 2xl:mx-0.5 h-7 w-auto  gap-1 bg-black text-white rounded-3xl p-1">
            <div className="h-full">
              <CorrectIcon />
            </div>
            <p className="text-nowrap mr-3  text-[11px]">{t("installment")}</p>
          </div>
          <div className="mx-[0.5px]" />
          <div className="flex flex-row justify-between items-center 2xl:mx-0.5 h-7 w-auto gap-1 bg-black text-white rounded-3xl p-1">
            <div className="h-full">
              <CorrectIcon />
            </div>
            <p className="text-nowrap mr-3  text-[11px]">
              {t("payment_postponement")}
            </p>
          </div>
        </div>
      </div>

      <div className="flex flex-row flex-wrap items-center self-stretch w-full h-full gap-2 justify-evenly">
        <div className="flex flex-col flex-1 text-white">
          <p className="text-nowrap flex max-sm:text-[12px] justify-start">
            {t("credit_limit")}
          </p>
          <p className="flex flex-row items-baseline gap-1">
            <span className="text-2xl max-sm:text-[20px]  text-white font-bold">
              {formatNumber(creditData?.creditLimit, true) ?? "0.00"}
            </span>
            <span className=" text-sm max-sm:text-[12px] text-nowrap">
              {t("sar")}
            </span>
          </p>
        </div>
        <div className="flex flex-col flex-1 text-white">
          <p className="text-nowrap max-sm:text-[12px]">
            {t("available_balance")}
          </p>
          <p className="flex flex-row items-baseline gap-1">
            <span className="text-2xl  max-sm:text-[20px] text-white font-bold">
              {formatNumber(creditData?.availableBalance, true) ?? "0.00"}
            </span>
            <span className=" text-sm  max-sm:text-[12px] text-nowrap">
              {t("sar")}
            </span>
          </p>
        </div>
        <div className="flex flex-col flex-1 text-white ">
          <p className="text-nowrap max-sm:text-[12px]">{t("due_amount")}</p>
          <div className="flex flex-row items-baseline gap-1">
            <span className="text-2xl max-sm:text-[20px] text-white font-bold">
              {formatNumber(creditData?.outstandingBalance, true) ?? "0.00"}
            </span>
            <span className=" text-sm max-sm:text-[12px]   text-nowrap">
              {t("sar")}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BigCard;
