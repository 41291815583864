const InfoItem: React.FC<InfoItemProps> = ({ label, value }) => (
    <div className="flex flex-col flex-1">
      <div className="text-sm leading-6 text-white text-opacity-60">{label}</div>
      <div className="mt-1 text-base font-bold leading-6 text-white">
        {value || "-"}
      </div>
    </div>
  );


export default InfoItem;