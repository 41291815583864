import React, { useState, useEffect, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ExitArrow from "../Icons/ExitArrow";
import ExitArrow2 from "../Icons/ExitArrow2";
import Button from "../Buttons/Button";
import OrderIdCard from "./OrderIdCard";
import PaymentStatusLapel from "./PaymentStatusLapel";
import InstallmentStatusLabel from "./InstallmentStatusLabel";
import CompletedOrderIcon from "../Icons/CompletedOrderIcon";
import ArchiveConfirmation from "./ArchiveConfirmation";
import PaymentModal from "../Payments/PaymentModal";
import PaymentBill, { IAddress } from "./PaymentBill";
import useApi from "../../hooks/useApi";
import {
  OrderStatus,
  PageRoutes,
  PaymentStatus,
  PaymentRequestType,
  PaymentPlanType,
} from "../../constants";
import { formatNumber } from "../../utils/formatNumber";

interface Payment {
  id: string;
  paymentTransactionId: string;
  paymentCode: string;
  paymentDate: string;
  amount: number;
  isPaid: boolean;
  dueDate: string;
  invoiceId: string;
  createdAt: string;
}

interface Supplier {
  id: string;
  name: string;
  crNumber: string;
  address: IAddress;
}

interface Installment {
  id: number;
  label: string;
  date: string;
  status: number;
  amount: number;
  paymentData: Payment;
}

interface OrderDetails {
  id: string;
  createdAt: string;
  orderNumber: string;
  totalAmount: number;
  description: string;
  status: number;
  creditAmount: number;
  installmentsPaid: number;
  pendingAmount: number;
  payments: Payment[];
  supplier: Supplier;
  paymentStatus: PaymentStatus;
  paymentPlanType: number;
}

interface DetailsProps {
  indexofInstallment?: number;
  pendingAmount: number;
  orderNumber: string;
  orderDate: string;
  orderId: string;
  paymentPlanType: number;
  orderValue: number;
  installmentsCount: number;
  paymentValue: string;
  installments: Installment[];
  creditAmount: number;
  paymentStatus: PaymentStatus;
  orderStatus: OrderStatus;
  onInstallmentClick: (payment: Payment) => void;
}
export const InstallmentNumberContext = React.createContext<number | null>(
  null
);

const Details: React.FC<DetailsProps> = ({
  pendingAmount,
  orderNumber,
  orderDate,
  orderId,
  orderValue,
  installmentsCount,
  paymentValue,
  installments,
  paymentStatus,
  creditAmount,
  orderStatus,
  onInstallmentClick,
  paymentPlanType,
  indexofInstallment,
}) => {
  const { t, i18n } = useTranslation();
  const [isInstallmentModalOpen, setIsInstallmentModalOpen] =
    useState<boolean>(false);
  const [selectedInstallmentId, setSelectedInstallmentId] = useState<number>(0);
  const { getData: getOrderData, data: orderData } = useApi<OrderDetails>();
  const [orderDetails, setOrderDetails] = useState<OrderDetails | null>(null);
  const { id } = useParams<{ id: string }>();
  const [allPaid, setAllPaid] = useState(false);
  const [isArchiveModalVisible, setArchiveModalVisible] = useState(false);
  const navigate = useNavigate();
  const [showOrderPaymentModal, setShowOrderPaymentModal] = useState(false);
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 640);
  const [isArchiveBtnAppear, setIsArchiveBtnAppear] = useState<boolean>(false);
  const isMidScreen = window.innerWidth >= 0 && window.innerWidth <= 900;

  useEffect(() => {
    const fetchOrderDetails = async () => {
      const { data: response } = await getOrderData(`/order/${id}`);
      if (response) {
        setOrderDetails(response);
      }
      if (response.status === 4) {
        setIsArchiveBtnAppear(false);
      }
      if (response.status === 2) {
        setIsArchiveBtnAppear(true);
        setShowOrderPaymentModal(false);
      }
    };

    if (id) {
      fetchOrderDetails();
    }
  }, [id]);

  useEffect(() => {
    const allPaidStatus = installments.every(
      (installment) => installment.paymentData.isPaid
    );
    setAllPaid(allPaidStatus);
  }, [installments]);

  const handleBack = () => {
    navigate(PageRoutes.ORDERS);
  };

  const handleInstallmentClick = (
    installmentId: number,
    paymentData: Payment
  ) => {
    setSelectedInstallmentId(installmentId);
    onInstallmentClick(paymentData);
    setIsInstallmentModalOpen(true);
  };

  const getInstallmentStatus = (
    payment: Payment
  ): "Paid" | "Upcoming" | "Over Due" => {
    if (payment.isPaid) return "Paid";

    const today = new Date();
    const dueDate = new Date(payment.dueDate);

    if (today.toDateString() === dueDate.toDateString()) return "Upcoming";
    if (today > dueDate) return "Over Due";

    return "Upcoming";
  };
  const calculateDaysRemaining = (dueDate: string): string => {
    const today = new Date();
    const dueDateObj = new Date(dueDate);
    const differenceInTime = dueDateObj.getTime() - today.getTime();
    const differenceInDays = Math.ceil(differenceInTime / (1000 * 3600 * 24));

    if (differenceInDays > 0)
      return t("daysAfter", { count: differenceInDays });
    if (differenceInDays < 0)
      return t("daysAgo", { count: Math.abs(differenceInDays) });

    return t("today");
  };

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 640);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleArchiveSuccess = () => {
    //consloe.log("Order archived successfully.");
    setArchiveModalVisible(false);
  };

  const selectedInstallment = installments.find(
    (installment) => installment.id === selectedInstallmentId
  );

  return (
    <InstallmentNumberContext.Provider value={selectedInstallmentId}>
      <div className="flex flex-col gap-6 bg-[#080808] p-6 w-full overflow-auto h-full scrollbar-hide">
        <div className="flex flex-col self-stretch gap-4 max-sm:gap-6">
          <div className="flex items-center self-stretch justify-between w-full gap-2 max-sm:flex-col max-sm:items-start max-sm:gap-6">
            <div className="flex items-center justify-center gap-2">
              <div onClick={handleBack} className="cursor-pointer">
                {i18n.language === "en" ? <ExitArrow2 /> : <ExitArrow />}
              </div>
              <div>
                <h2 className="text-[24px] font-bold leading-7 text-white text-opacity-[0.87] ">
                  {t("orderNumberLabel")}: {orderNumber}
                </h2>
                <p className="text-[14px] font-bold leading-[18px] text-white text-opacity-60">
                  {t("orderDateLabel")}: {orderDate}
                </p>
              </div>
            </div>
            <div className="flex max-sm:w-full">
              {allPaid ? (
                <Button
                  label={t("archiveNow")}
                  className={`${
                    !isArchiveBtnAppear ? "hidden" : ""
                  } gradient-bg px-6 py-3 justify-center items-center gap-6 flex-1 rounded-[24px]`}
                  labelClassName="text-white text-[16px] font-bold leading-6"
                  onClick={() => setArchiveModalVisible(true)}
                />
              ) : (
                <Button
                  onClick={() => setShowOrderPaymentModal(true)}
                  label={t("fullPayment")}
                  className={` gradient-bg px-6 py-3 justify-center items-center gap-6 flex-1 rounded-[24px]`}
                  labelClassName="text-white text-[16px] font-bold leading-6 "
                />
              )}
            </div>
          </div>
          <div className="flex items-center self-stretch justify-between w-full gap-2 max-sm:gap-4 max-sm:flex-col max-sm:items-start ">
            <OrderIdCard id={orderId} textSize="text-[12px]" />
            {orderStatus === OrderStatus.Completed ? (
              <div className="flex items-center gap-4 justify-center p-4 rounded-[16px] bg-[#008E45]">
                <CompletedOrderIcon />
                <span className="text-white text-[16px] font-bold">
                  {t("completed")}
                </span>
              </div>
            ) : (
              <PaymentStatusLapel
                showIcon={true}
                className="px-[16px] py-[4px]"
                status={paymentStatus}
              />
            )}
          </div>
        </div>
        <div className="flex flex-col self-stretch flex-1 gap-4">
          {paymentPlanType === PaymentPlanType.Installment ? (
            <div className="gap-2 md:grid md:grid-cols-3 max-sm:grid-rows-2 ">
              <div className="max-sm:mb-2 max-sm:row-span-1 max-sm:w-full col-span-1 flex p-4 items-center gap-2 flex-1 self-stretch rounded-[16px] bg-white">
                <div className="flex flex-col items-start justify-center flex-1 gap-1">
                  <div className="flex text-nowrap">
                    <p className="text-[#1a1a1a] text-[18px] font-extrabold leading-[24px]">
                      {formatNumber(orderValue, true)}
                    </p>
                    <p className="text-[#1a1a1a] text-[14px] font-normal leading-[24px]">
                      &nbsp; {t("sar")}
                    </p>
                  </div>
                  <div className="text-[#1a1a1a] text-[14px] font-normal leading-[18px]">
                    {t("orderValue")}
                  </div>
                </div>
              </div>
              <div className="flex flex-row flex-1 col-span-2 gap-2 max-sm:row-span-2">
                <div className="flex p-4 items-center gap-2 flex-1 self-stretch rounded-[16px] bg-[#1f1f1f]">
                  <div className="flex flex-col items-start justify-center flex-1 gap-1">
                    <div className="text-[20px] font-extrabold leading-8 gradient-text">
                      {installmentsCount}
                    </div>
                    <div className="text-[14px] font-normal leading-[18px] gradient-text">
                      {t("installments")}
                    </div>
                  </div>
                </div>
                <div className="flex p-4 items-center gap-2 flex-1 self-stretch rounded-[16px] bg-[#1f1f1f]">
                  <div className="flex flex-col items-start justify-center flex-1 gap-1">
                    <div className="flex text-nowrap">
                      <p className="text-[18px] font-extrabold leading-6 text-white">
                        {formatNumber(orderValue - pendingAmount, true)}
                      </p>
                      <p className="text-[14px] font-normal leading-6 text-white">
                        &nbsp; {t("sar")}
                      </p>
                    </div>
                    <p className="text-[14px] text-white text-opacity-[0.87] font-normal leading-[18px]">
                      {t("totalPaid")}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          ) : paymentPlanType === PaymentPlanType.Credit ? (
            <div className="flex items-start self-stretch justify-between gap-2">
              <div className="flex p-4 items-center gap-2 flex-1 self-stretch rounded-[16px] bg-white">
                <div className="flex flex-col items-start justify-center flex-1 gap-1">
                  <div className="flex gap-1 text-[#1a1a1a] text-lg font-extrabold">
                    {formatNumber(orderValue, true)}
                    <p className="text-[#1a1a1a] text-[14px] font-normal">
                      {t("sar")}
                    </p>
                  </div>
                  <div className="text-[#1a1a1a] text-sm">
                    {t("orderValue")}
                  </div>
                </div>
              </div>
              <div className="flex p-4 items-center gap-2 flex-1 self-stretch rounded-[16px] bg-[#1f1f1f]">
                <div className="flex flex-col items-start justify-center flex-1 gap-1">
                  <div className="text-white text-[18px] font-extrabold leading-[23px] gradient-text text-nowrap ">
                    {t("orderType")}
                  </div>
                  <div className="text-sm text-gray-400 gradient-text">
                    {t("creditC")}
                  </div>
                </div>
              </div>
            </div>
          ) : null}
          {installments.map((installment) => {
            const installmentDate = new Date(installment.date);

            return (
              <div
                key={installment.id}
                onClick={(e) => {
                  e.stopPropagation();
                  handleInstallmentClick(
                    installment.id,
                    installment.paymentData
                  );
                }}
                className={`bg-[#1f1f1f] md:p-4 max-sm:px-3 max-sm:py-4 justify-between flex flex-col items-start self-stretch rounded-[16px] cursor-pointer gap-4 ${
                  selectedInstallmentId === installment.id
                    ? "border border-solid border-[#EF6B2A]"
                    : ""
                }`}
              >
                <div className="flex justify-between w-full">
                  <div className="flex items-center gap-3 max-sm:items-start">
                    <div
                      style={{
                        background:
                          "linear-gradient(129deg, rgba(239, 107, 42, 0.00) 0%, rgba(241, 188, 98, 0.60) 100%)",
                      }}
                      className="rounded-full w-[48px] h-[48px] flex items-center justify-center"
                    >
                      <div className="gradient-text text-[18px] leading-6 font-bold">
                        {installment.id}
                      </div>
                    </div>
                    <div className="flex flex-col max-sm:gap-1">
                      <div className="gradient-text text-[14px] font-normal leading-6 text-nowrap">
                        {paymentPlanType === PaymentPlanType.Installment
                          ? installment.label
                          : paymentPlanType === PaymentPlanType.Credit
                          ? t("CreditTaken")
                          : null}
                      </div>
                      <div className="text-sm text-gray-400">
                        {/* Use the ISO string for displaying the date */}
                        {`(${installmentDate.toLocaleDateString("en-GB")})`}
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col gap-[6px] max-sm:gap-[2px]">
                    <div className="flex gap-3 max-sm:flex-col max-sm:gap-[2px]">
                      <div className="flex justify-end">
                        <InstallmentStatusLabel
                          variant={isSmallScreen ? "small" : "large"}
                          status={getInstallmentStatus(installment.paymentData)}
                        />
                      </div>
                      <div className="text-white">
                        {formatNumber(installment.amount, true)}
                        {t("sar")}
                      </div>
                    </div>
                    <div className="flex justify-end">
                      <div className="text-sm text-gray-400">
                        {calculateDaysRemaining(
                          installment.paymentData.dueDate
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="w-full lg:hidden xl:hidden 2xl:hidden ">
                  {selectedInstallmentId === installment.id &&
                    isInstallmentModalOpen && (
                      <PaymentBill
                        indexofInstallment={indexofInstallment}
                        paymentSummaryColor="bg-[#080808]"
                        orderSummaryColor="bg-[#080808]"
                        className={`
                          ${isMidScreen ? "" : "hidden"}
                          flex-1 py-3 bg-[#1f1f1f] border-t-[4px] border-[#080808]`}
                        orderDetails={{
                          supplierName: orderDetails?.supplier?.name || "",
                          crNumber: orderDetails?.supplier?.crNumber || "",
                          address: orderDetails?.supplier?.address || null,
                          description: orderDetails?.description || "",
                          orderNumber: orderDetails?.orderNumber || "",
                          createdAt: new Date(
                            orderDetails?.createdAt || ""
                          ).toLocaleDateString(),
                          totalAmount: orderDetails?.totalAmount || 0,
                          installmentsPaid: orderDetails?.installmentsPaid || 0,
                          paymentPlanType: orderDetails?.paymentPlanType || 0,
                          payments: [
                            {
                              ...selectedInstallment.paymentData,
                              paymentValue: selectedInstallment.amount,
                            },
                          ],
                        }}
                        installmentStatus={getInstallmentStatus(
                          selectedInstallment.paymentData
                        )}
                      />
                    )}
                </div>
              </div>
            );
          })}
        </div>

        <ArchiveConfirmation
          isVisible={isArchiveModalVisible}
          onClose={() => setArchiveModalVisible(false)}
          onArchiveSuccess={handleArchiveSuccess}
          orderNumber={orderNumber}
        />
        {showOrderPaymentModal && orderId && (
          <PaymentModal
            orderId={orderId}
            paymentRequestType={PaymentRequestType.OrderPayment}
            onClose={() => setShowOrderPaymentModal(false)}
            func="Payment"
          />
        )}
      </div>
    </InstallmentNumberContext.Provider>
  );
};

export default Details;
