import React, { ForwardedRef } from "react";
import { useField } from "formik";
import { useTranslation } from "react-i18next";

interface InputFieldProps {
  inputClassName?: string;
  mandStar?: boolean;
  id: string;
  label: string;
  type?: string;
  defaultValue?: string;
  value?: string | number;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  className?: string;
  placeholder?: string;
  name: string;
  required?: boolean;
  formatNumber?: boolean;
}

const NewInputField = React.forwardRef(
  (
    {
      inputClassName,
      mandStar,
      id,
      label,
      placeholder,
      type = "text",
      defaultValue = "",
      value = "",
      onChange,
      disabled = false,
      className,
      name,
      required = false,
      formatNumber = false,
    }: InputFieldProps,
    ref: ForwardedRef<HTMLInputElement>
  ) => {
    const [field, meta, helpers] = useField(name);
    const { setValue } = helpers;
    const { t, i18n } = useTranslation();
    const direction = i18n.dir();
    // Determine the actual input type
    const inputType = React.useMemo(() => {
      if (type === "number" && formatNumber) {
        return "text";
      }
      return type;
    }, [type, formatNumber]);

    // Format display value while keeping the actual value in Formik state
    const displayValue = React.useMemo(() => {
      if (formatNumber && type === "number" && field.value) {
        // Store the raw number in Formik state but display formatted
        return field.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
      return field.value;
    }, [field.value, formatNumber, type]);

    // Handler to restrict invalid characters for number input
    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (type !== "number") return;

      // Allow navigation keys
      const allowedKeys = [
        "Backspace",
        "Delete",
        "ArrowLeft",
        "ArrowRight",
        "Tab",
      ];
      if (allowedKeys.includes(e.key)) return;

      // Allow number keys
      if (/^\d$/.test(e.key)) return;

      // Prevent all other keys
      e.preventDefault();
    };

    // Handler for input changes
    const handleInput = (e: React.FormEvent<HTMLInputElement>) => {
      if (type !== "tel" && type !== "number") return;

      const input = e.currentTarget;
      let sanitizedValue = input.value.replace(/[^0-9]/g, "");

      if (type === "tel") {
        setValue(sanitizedValue);
      } else if (type === "number") {
        // Always store the raw number in Formik state
        setValue(sanitizedValue);

        // Update display value with commas if formatNumber is true
        if (formatNumber) {
          const formattedValue = sanitizedValue.replace(
            /\B(?=(\d{3})+(?!\d))/g,
            ","
          );
          input.value = formattedValue;
        } else {
          input.value = sanitizedValue;
        }
      }

      // Call the additional onChange handler if provided
      if (onChange) {
        const changeEvent = {
          ...e,
          target: {
            ...e.currentTarget,
            // Pass the raw value to the onChange handler
            value: sanitizedValue,
          },
        } as React.ChangeEvent<HTMLInputElement>;
        onChange(changeEvent);
      }
    };

    // Handle paste events
    const handlePaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
      if (type !== "number") return;

      e.preventDefault();
      const paste = e.clipboardData.getData("text");
      const sanitizedPaste = paste.replace(/[^0-9]/g, "");

      setValue(sanitizedPaste);
    };

    return (
      <div className={`flex flex-col flex-1 gap-2 ${className}`}>
        <span className="flex gap-1">
          <label className="text-white text-opacity-60">{label}</label>
          {mandStar && (
            <span className="text-[#EF6B2A] font-[800] leading-6 text-[14px]">
              *
            </span>
          )}
        </span>
        <input
          {...field}
          placeholder={placeholder}
          ref={ref}
          id={id}
          className={`${inputClassName} justify-center px-4 py-3 text-white text-opacity-50 rounded-xl border border-solid bg-white bg-opacity-10 border-white border-opacity-20  focus:border-[#EF6B2A] focus:ring-1 focus:ring-[#EF6B2A] ${
            type === "number" ? "no-spinner" : ""
          } ${meta.touched && meta.error ? "border-red-500" : ""}`}
          type={inputType}
          value={displayValue}
          disabled={disabled}
          onInput={handleInput}
          onKeyDown={handleKeyDown}
          onPaste={handlePaste}
          required={required}
          dir={direction}
          data-format-number={formatNumber ? "true" : undefined}
          {...(type === "tel" ? { minLength: 9, maxLength: 12 } : {})}
        />
        {meta.touched && meta.error && (
          <span className="text-red-500 text-opacity-80">
            {t(`${meta.error}`)}
          </span>
        )}
      </div>
    );
  }
);

export default NewInputField;
