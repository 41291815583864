import React from "react";

const DownIcon = ({
  width = 14,
  height = 8,
  color = "url(#paint0_linear_712_903)",
  className = "",
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 14 8"
      fill="none"
      className={className}
    >
      <path
        d="M13 1L7 7L1 1"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_712_903"
          x1="1"
          y1="1"
          x2="4.81884"
          y2="10.3437"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EF6B2A" />
          <stop offset="1" stopColor="#F1BC62" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default DownIcon;
