import React, { useEffect, useState } from "react";
import DataSharing from "./DataSharing";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { PageRoutes } from "../../constants";

const Consent = ({
  onPageChange,
}: {
  onPageChange: (pageNumber: number) => void;
}) => {
  const navigate = useNavigate();
  const [accessToDate, setAccessToDate] = useState("");
  const [accessFromDate, setAccessFromDate] = useState("");
  const { t, i18n } = useTranslation();

  useEffect(() => {
    const today = new Date();
    const access_to = new Date(today);
    const access_from = new Date(today);

    access_to.setDate(today.getDate() + 1);
    access_from.setMonth(today.getMonth() - 6);

    const access_to_date = access_to.toLocaleDateString("en-GB", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    });
    const access_from_date = access_from.toLocaleDateString("en-GB", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    });

    setAccessToDate(access_to_date);
    setAccessFromDate(access_from_date);
  }, []);

  return (
    <div className="flex flex-col items-center justify-center w-full p-4">
      <img src="./assets/probuy.svg" className="" alt="probuy logo" />
      <div className="w-full md:w-[500px] bg-white bg-opacity-5 p-4 rounded-xl shadow-xl flex flex-col ">
        <h1 className="my-2 text-4xl font-bold text-center text-nowrap">
          {t("consent.title")}
        </h1>

        <h2
          className={`text-lg font-bold ${
            i18n.language === "ar" ? "text-right" : "text-left"
          } `}
        >
          {t("consent.subtitle")}
        </h2>
        <div className="flex flex-col items-center justify-center">
          <p className="my-2 text-lg max-md:max-w-full">
            {t("consent.description1")}
          </p>
          <p className="my-1 text-lg max-md:max-w-full">
            {t("consent.description2")}
          </p>
        </div>
        <p className="my-2 text-lg max-md:max-w-full">
          {t("consent.description3")}
        </p>
        <div className="flex items-center justify-center w-full gap-4 mt-8">
          <div className="flex flex-col justify-center flex-1">
            <div className="font-bold text-white">
              {t("consent.accessFrom")}
            </div>
            <div className="mt-1 text-white">{accessFromDate}</div>
          </div>
          <div className="flex flex-col justify-center flex-1">
            <div className="font-bold text-white">{t("consent.accessTo")}</div>
            <div className="mt-1 text-white">{accessToDate}</div>
          </div>
          <div className="flex flex-col justify-center flex-1 whitespace-nowrap">
            <div className="font-bold text-white">{t("consent.expires")}</div>
            <div className="mt-1 text-white">{accessToDate}</div>
          </div>
        </div>
        <DataSharing />
        <div className="flex items-center justify-center">
          {/* to be uncommented after lean fixed */}
          {/* <button
            onClick={() => {
              navigate(PageRoutes.LEAN);
            }}
            className="w-40 px-4 py-2 my-4 text-white bg-gradient-bg rounded-3xl focus:outline-none"
          >
            {t("consent.continueButton")}
          </button> */}
        </div>
      </div>
    </div>
  );
};

export default Consent;
