import React, { useState, useEffect } from "react";
import PaymentIcon from "../Icons/PaymentIcon";
import DownloadIcon from "../Icons/DownloadIcon";
import OrderSummary from "./OrderSummary";
import PaymentSummary from "./PaymentSummary";
import Button from "../Buttons/Button";
import SendMailIcon from "../Icons/SendMailIcon";
import useApi from "../../hooks/useApi";
import PaymentModal from "../Payments/PaymentModal";
import { useTranslation } from "react-i18next";
import Toast, { IToast } from "../Shared/Toast";
import convertToHttps from "../../utils/convertHttp";
import LoadingIcon from "../Icons/LoadingIcon";

enum PaymentRequestType {
  WalletRecharge = 0,
  OutstandingInstallments,
  OutstandingCredit,
  DueInstallments,
  DueCredit,
  OrderInstallment,
  OrderPayment,
}

export interface IAddress {
  addressLine1: string;
  addressLine2: string;
  city: string;
  stateOrProvince: string;
  country: string;
  postalCode: string;
}

interface PaymentBillProps {
  indexofInstallment?: number;
  orderSummaryColor?: string;
  paymentSummaryColor?: string;
  className?: string;
  orderDetails: {
    supplierName: string;
    crNumber: string;
    address?: IAddress;
    description: string;
    orderNumber: string;
    createdAt: string;
    totalAmount: number;
    installmentsPaid: number;
    paymentPlanType: number;
    payments: {
      id: string;
      paymentTransactionId: string; // Added this line
      paymentCode: string;
      paymentDate: string;
      paymentValue: number;
      invoiceId?: string;
      isPaid: boolean;
    }[];
  };
  installmentStatus: "Paid" | "Upcoming" | "Over Due";
}

interface Invoice {
  id: string;
  createdAt: string;
  lastModifiedAt: string;
  invoiceNumber: string;
  invoiceDate: string;
  totalAmount: number;
  orderId: string;
  paymentId: string;
  customerName: string;
  customerAddress: string;
  customerEmail: string;
  customerPhone: string;
  customerTaxNumber: string;
  description: string;
}

const PaymentBill: React.FC<PaymentBillProps> = ({
  className = "",
  orderDetails,
  installmentStatus,
  orderSummaryColor,
  paymentSummaryColor,
  indexofInstallment,
}) => {
  const { t, i18n } = useTranslation();
  const [showInstallmentPaymentModal, setShowInstallmentPaymentModal] =
    useState(false);
  const [loadingPdf, setLoadingPdf] = useState(false);
  const [loadingEmail, setLoadingEmail] = useState(false); // New state for email loading
  const [paymentId, setPaymentId] = useState<string | null>(null);
  const [toast, setToast] = useState<IToast | null>(null);

  const { getData: getInvoicePdf } = useApi<string>();

  const handleDownloadClick = async () => {
    const paymentId = orderDetails.payments[0]?.id;
    if (paymentId) {
      // disable button till finish calling the api
      setLoadingPdf(true);
      const { data: InvoicePDFUrl } = await getInvoicePdf(
        `/invoice/${paymentId}/pdf`
      );
      if (InvoicePDFUrl == "Pdf will be sent via email") {
        setLoadingPdf(false);
        return setToast({
          type: "success",
          titleKey: "PDF sent",
          messageKey: "Pdf will be sent via email.",
        });
      }
      try {
        const response = await fetch(convertToHttps(InvoicePDFUrl), {
          method: "GET",
          mode: "cors",
        });

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        const blob = await response.blob();
        const pdfSelfUrl = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = pdfSelfUrl;
        a.download = `invoice-${paymentId}.pdf`; // Specify the name of the downloaded file
        document.body.appendChild(a);
        a.click();
        a.remove();
        window.URL.revokeObjectURL(pdfSelfUrl);

        // window.open(InvoicePDFUrl, "_blank");
      } catch (error) {
        setToast({
          type: "error",
          titleKey: "Error",
          messageKey: "there is an error.",
        });
        console.error("Download failed:", error);
      } finally {
        setLoadingPdf(false);
      }
    }
  };

  const { getData: SendMail, status: sendEmailStatus } = useApi<string>();

  const handleSendMail = async () => {
    const paymentId = orderDetails.payments[0]?.id;
    if (paymentId) {
      setLoadingEmail(true); // Set loading state to true
      const sendMail = await SendMail(`/invoice/${paymentId}/send-via-email`);
      //consloe.log("status", sendEmailStatus);
      if (sendEmailStatus == 200) {
        setToast({
          type: "success",
          messageKey: "Email sent Successfully",
        });
      }
      setLoadingEmail(false); // Set loading state to false after completion
    }
  };

  return (
    <>
      <div
        className={`${className} sm:w-full flex md:py-6 md:pt-6 md:pl-8 md:pr-6 flex-col items-start sm:items-center justify-center gap-6 shrink-0  overflow-auto  max-h-[150%]`}
      >
        <div className="flex flex-col items-start self-stretch flex-1 gap-4 overflow-y-auto scrollbar-hide ">
          <div className="flex items-center self-stretch gap-2 flex-end">
            <div className="flex flex-col justify-center flex-1 h-8">
              <h2 className="text-white text-opacity-60 text-[18px] font-bold leading-6">
                {t("paymentBillTitle")}
              </h2>
            </div>
            <PaymentIcon />
          </div>
          <div className="flex flex-col justify-between w-full gap-4">
            <div className="flex flex-col items-start self-stretch gap-3">
              <OrderSummary
                color={orderSummaryColor}
                supplierName={orderDetails.supplierName}
                crNumber={orderDetails.crNumber}
                address={orderDetails.address}
                description={orderDetails.description}
                orderNumber={orderDetails.orderNumber}
                createdAt={orderDetails.createdAt}
                totalAmount={orderDetails.totalAmount}
                installmentsPaid={orderDetails.installmentsPaid}
                paymentPlanType={orderDetails.paymentPlanType}
              />
              {orderDetails.payments
                .filter((payment) => payment.isPaid)
                .map((payment, index) => (
                  <PaymentSummary
                    indexofInstallment={indexofInstallment}
                    color={paymentSummaryColor}
                    isPaid={payment.isPaid}
                    key={payment.id}
                    installmentNumber={index + 1}
                    paymentCode={payment.paymentTransactionId}
                    paymentDate={new Date(
                      payment.paymentDate
                    ).toLocaleDateString()}
                    paymentValue={payment.paymentValue}
                    paymentPlanType={orderDetails.paymentPlanType}
                  />
                ))}
            </div>
            <div className="flex items-start self-stretch gap-2 pb-6 max-sm:items-center max-sm:flex-col max-sm:self-stretch max-sm:flex-1 ">
              {installmentStatus === "Over Due" && (
                <Button
                  label={t("payNow")}
                  iconPosition="left"
                  className="gradient-bg px-6 py-3 justify-center items-center gap-6 flex-1 rounded-[24px] max-sm:self-stretch !max-sm:w-full"
                  labelClassName="text-white text-[16px] font-bold leading-6"
                  onClick={(e) => {
                    e.stopPropagation();
                    setPaymentId(orderDetails.payments[0]?.id);
                    setShowInstallmentPaymentModal(true);
                  }}
                />
              )}
              {installmentStatus === "Upcoming" && (
                <Button
                  label={t("payInAdvance")}
                  iconPosition="left"
                  className="gradient-bg px-6 py-3 justify-center items-center gap-6 flex-1 rounded-[24px]"
                  labelClassName="text-white text-[16px] font-bold leading-6"
                  onClick={() => {
                    setPaymentId(orderDetails.payments[0]?.id);
                    setShowInstallmentPaymentModal(true);
                  }}
                />
              )}
              {installmentStatus === "Paid" && (
                <>
                  <Button
                    label={t("download")}
                    iconPosition="left"
                    className="gradient-bg px-6 py-3 justify-center items-center gap-6 flex-1 rounded-[24px]"
                    labelClassName="text-white text-[16px] font-bold leading-6 text-nowrap"
                    icon={
                      loadingPdf ? (
                        <LoadingIcon size="20px" color="#fff" />
                      ) : (
                        <DownloadIcon color="white" />
                      )
                    }
                    onClick={handleDownloadClick}
                    disabled={loadingPdf}
                  />
                  <Button
                    label={t("sendMail")}
                    iconPosition="left"
                    icon={
                      loadingEmail ? (
                        <LoadingIcon size="20px" color="#fff" />
                      ) : (
                        <SendMailIcon />
                      )
                    }
                    className="px-6 py-3 justify-center items-center gap-6 flex-1 rounded-[24px] border-[#EF6B2A] border"
                    labelClassName="text-white text-[16px] font-bold leading-6 text-nowrap"
                    onClick={handleSendMail}
                    disabled={loadingEmail} // Disable button during email sending
                  />
                </>
              )}
            </div>
          </div>
        </div>
        {showInstallmentPaymentModal && paymentId && (
          <PaymentModal
            func="Payment"
            paymentId={paymentId}
            paymentRequestType={PaymentRequestType.OrderInstallment}
            onClose={() => setShowInstallmentPaymentModal(false)}
          />
        )}
      </div>
      {toast && (
        <Toast
          type={toast.type}
          titleKey={toast.titleKey}
          messageKey={toast.messageKey}
          onClose={() => setToast(null)}
        />
      )}
    </>
  );
};

export default PaymentBill;
