import React from "react";
import { useTranslation } from "react-i18next";
import FullyPayedIcon from "../Icons/FullyPayedIcon";
import ParPayedIcon from "../Icons/ParPayedIcon";
import TickIcon from "../Icons/TickIcon";
import RewindIcon from "../Icons/RewindIcon";
import UnpaidIcon from "../Icons/UnpaidIcon";

enum PaymentStatus {
  Unpaid = 0,
  PartiallyPaid,
  Paid,
  Overdue,
}

interface PaymentStatusLapelProps {
  status: PaymentStatus;
  showIcon?: boolean;
  className?: string;
}

const statusStyles: { [key in PaymentStatus]: string } = {
  [PaymentStatus.Unpaid]: "bg-[#DB9236] text-white",
  [PaymentStatus.PartiallyPaid]: "bg-[#DB9236] text-white",
  [PaymentStatus.Paid]: "bg-[#008E45] text-white",
  [PaymentStatus.Overdue]: "bg-[#DD1631] text-white",
};

const statusIcons: { [key in PaymentStatus]?: React.ReactElement } = {
  [PaymentStatus.PartiallyPaid]: <ParPayedIcon width={24} height={24} />,
  [PaymentStatus.Unpaid]: <UnpaidIcon />,
  [PaymentStatus.Paid]: <FullyPayedIcon width={24} height={24} />,
  [PaymentStatus.Overdue]: <RewindIcon width={24} height={24} />,
};

const PaymentStatusLapel: React.FC<PaymentStatusLapelProps> = ({
  status,
  showIcon = true,
  className,
}) => {
  const { t } = useTranslation();

  return (
    <div
      className={`${className} flex text-nowrap items-center justify-center leading-none rounded-full font-normal ${statusStyles[status]}`}
    >
      {showIcon && statusIcons[status] && (
        <span className="mr-1"> {statusIcons[status]}</span>
      )}
      {t(`paymentStatus.${PaymentStatus[status]}`)}
    </div>
  );
};

export default PaymentStatusLapel;
