import React from "react";
import { useTranslation } from "react-i18next";
import CorrectIcon from "../Icons/CorrectIcon";
import WrongIcon from "../Icons/WrongIcon";
import Chip from "../Chip";

const ElligbleCard = ({
  isEllgible,
  creditData,
  companyData,
}: {
  isEllgible: boolean;
  creditData: CreditScoreResponse | number;
  companyData?: any;
}) => {
  const { t, i18n } = useTranslation();
  return (
    <div
      dir={i18n.dir()}
      className="flex flex-col self-stretch justify-between w-full px-4 py-1 mb-2 h-52 rounded-xl 2xl:mt-4 items card-bg"
    >
      <div className="flex flex-col items-center justify-center mt-4">
        <h1
          className={`text-xl mx-1 text-white font-bold ${
            i18n.language === "ar" ? "text-right" : "text-left"
          }`}
        >
          {companyData.name}
        </h1>
        <div className="flex flex-row p-1 pr-2">
          <Chip
            label="installment"
            icon={isEllgible ? <CorrectIcon /> : <WrongIcon />}
          />
          {/* <div className="flex flex-row justify-between items-center 2xl:mx-0.5 h-7 bg-black text-white rounded-3xl w-auto p-1">
            <div className="h-full">
              {isEllgible ? <CorrectIcon /> : <WrongIcon />}
            </div>
            <p className="text-nowrap mr-3 text-[11px]">{t("installment")}</p>
          </div> */}
          <div className="mx-[0.5px]" />
          <Chip
            label="payment_postponement"
            icon={isEllgible ? <CorrectIcon /> : <WrongIcon />}
          />
          {/* <div className="flex flex-row justify-between items-center 2xl:mx-0.5 h-7 w-auto bg-black text-white rounded-3xl p-1">
            <div className="h-full">
              {isEllgible ? <CorrectIcon /> : <WrongIcon />}
            </div>
            <p className="text-nowrap mr-3 text-[11px]">{t("payment_postponement")}</p>
          </div> */}
        </div>
        <div className="flex flex-col my-4">
          <h2 className="my-1 text-xl font-light">Credit Limit</h2>
          <p className="flex flex-row justify-center text-center">
            <span className="text-2xl font-bold text-white">
              {isEllgible
                ? (creditData as CreditScoreResponse).creditLimit
                : (creditData as number)}
            </span>
            <span className="mx-1 mt-1 2xl:text-xl text-nowrap">
              {t("sar")}
            </span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default ElligbleCard;
