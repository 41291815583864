import React from "react";
import Header from "./Header";
import Sidebar from "../sidebar/Sidebar";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store/rootReducer";

const Layout2: React.FC<LayoutProps> = ({ children }) => {
  const { i18n } = useTranslation();
  const direction = i18n.dir();
  const activeClass = useSelector((state: RootState) => state.css.activeClass);

  return (
    <div
      dir={direction}
      className={`w-full h-screen overflow-hidden ${
        direction === "rtl" ? "rtl" : ""
      }`}
    >
      <div className="fixed top-0 w-full z-50">
        <Header />
      </div>
      <div className="grid grid-cols-12 bg-black flex-col xl:flex-row mt-[64px] h-[calc(100vh-64px)]">
        <div
          className={`col-span-3 w-[16.6%] hidden xl:flex fixed h-[calc(100vh-64px)] top-[64px] ${
            direction === "rtl" ? "right-0" : "left-0"
          }`}
        >
          <Sidebar />
        </div>
        <div
          className={`col-span-12 bg-[#080808]  h-[calc(100vh-64px)] ${activeClass} ${
            direction === "rtl" ? "xl:mr-[16.6%]" : "xl:ml-[16.6%]"
          }`}
        >
          {children}
        </div>
      </div>
    </div>
  );
};

export default Layout2;
