import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import CorrectIcon from "../Icons/CorrectIcon";
import PaymentModal from "../Payments/PaymentModal";
import { formatNumber } from "../../utils/formatNumber";

enum PaymentRequestType {
  WalletRecharge = 0,
  OutstandingInstallments,
  OutstandingCredit,
  DueInstallments,
  DueCredit,
  OrderInstallment,
  OrderPayment,
  renew,
}

const DashboardCard = ({
  companyName,
  creditRecord,
}: {
  companyName: string;
  creditRecord: any;
}) => {
  const { t, i18n } = useTranslation();
  const [loading, setLoading] = useState<boolean>(false);
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [paymentRequestType, setPaymentRequestType] =
    useState<PaymentRequestType | null>(null);

  const handleButtonClick = (type: PaymentRequestType) => {
    setPaymentRequestType(type);
    setShowPaymentModal(true);
  };

  return (
    <div
      dir={i18n.dir()}
      className="flex flex-col items-start self-stretch w-full px-4 py-2 rounded-xl dash-card-bg"
    >
      <div className="flex flex-row justify-between w-full mt-4">
        <div className="flex flex-col justify-start mt-2">
          <h1
            className={`text-[20px] md:text-[24px] text-nowrap mx-1 text-white font-bold ${
              i18n.language === "ar" ? "text-right" : "text-left"
            }`}
          >
            {companyName}
          </h1>
          <div className="flex flex-row p-1 pr-2">
            <div className="flex flex-row justify-between items-center 2xl:mx-0.5 h-7 w-auto bg-black text-white gap-1 rounded-3xl p-1">
              <div className="h-full">
                <CorrectIcon />
              </div>
              <p className="text-nowrap mr-3 text-[11px] rtl:ml-3 rtl:mr-0">
                {t("installment")}
              </p>
            </div>
            <div className="mx-[0.5px]" />
            <div className="flex flex-row justify-between items-center 2xl:mx-0.5 h-7 w-auto bg-black gap-1 text-white rounded-3xl p-1">
              <div className="h-full">
                <CorrectIcon />
              </div>
              <p className="text-nowrap mr-3 text-[11px] rtl:ml-3 rtl:mr-0">
                {t("payment_postponement")}
              </p>
            </div>
          </div>
        </div>

        <div className="flex h-full mt-0.5 flex-row w-2/3 justify-evenly items-center self-stretch">
          <div className="flex flex-col mx-2 text-white">
            <p className="flex justify-start text-xl text-nowrap">
              {t("credit_limit")}
            </p>
            <p className="flex flex-row">
              <span className="text-3xl font-bold text-white">
                {loading
                  ? "0.000"
                  : formatNumber(creditRecord?.creditLimit, true) ?? 0}
              </span>
              <span className="mx-1 mt-3 text-sm text-nowrap">{t("sar")}</span>
            </p>
          </div>
          <div className="flex flex-col ml-4 mr-4 text-white">
            <p className="text-xl text-nowrap">{t("available_balance")}</p>
            <p className="flex flex-row">
              <span className="text-3xl font-bold text-white">
                {loading
                  ? "0.000"
                  : formatNumber(creditRecord?.availableBalance, true) ?? 0}
              </span>
              <span className="mx-1 mt-3 text-sm text-nowrap">{t("sar")}</span>
            </p>
          </div>
        </div>
      </div>

      <hr className="w-full my-2 bg-black border-black" />
      <div className="flex justify-center w-full h-full py-2">
        <div className="flex flex-col items-start w-full text-black">
          <p className="text-xl text-nowrap">{t("Outstanding")}</p>
          <p className="flex flex-row">
            <span className="text-3xl font-bold">
              {loading
                ? "0.000"
                : formatNumber(creditRecord?.outstandingBalance, true) ?? 0}
            </span>
            <span className="mx-1 mt-3 text-sm text-nowrap">{t("sar")}</span>
          </p>
          <div className="flex flex-row my-2 text-white gap-x-20">
            <div className="flex flex-col gap-1">
              <div>
                <p className="text-2xl text-nowrap">{t("installment")}</p>
                <div className="flex flex-row items-baseline">
                  <span className="text-xl font-bold">
                    {loading
                      ? "0.000"
                      : formatNumber(creditRecord?.dueFromInstallments, true) ??
                        0}
                  </span>
                  <span className="mx-1 mt-2 text-sm text-nowrap">
                    {t("sar")}
                  </span>
                </div>
              </div>
              {creditRecord?.dueFromInstallments !== 0 && (
                <button
                  className={`
                   max-w-[112px] bg-gradient-to-r text-nowrap from-orange-500 to-orange-300 text-white px-4 py-2 rounded-3xl text-sm focus:outline-none`}
                  onClick={() =>
                    handleButtonClick(
                      PaymentRequestType.OutstandingInstallments
                    )
                  }
                >
                  {t("payAdvance")}
                </button>
              )}
            </div>
            <div className="flex flex-col gap-1">
              <div>
                <p className="text-2xl text-nowrap">{t("credit")}</p>
                <div className="flex flex-row items-baseline">
                  <span className="text-xl font-bold">
                    {loading
                      ? "0.000"
                      : formatNumber(creditRecord?.dueFromCredit, true) ?? 0}
                  </span>
                  <span className="items-end mx-1 mt-2 text-sm text-nowrap">
                    {t("sar")}
                  </span>
                </div>
              </div>
              {creditRecord?.dueFromCredit !== 0 && (
                <button
                  className={`
                   max-w-[112px] bg-gradient-to-r text-nowrap from-orange-500 to-orange-300 text-white px-4 py-2 rounded-3xl text-sm focus:outline-none`}
                  onClick={() =>
                    handleButtonClick(PaymentRequestType.OutstandingCredit)
                  }
                >
                  {t("payAdvance")}
                </button>
              )}
            </div>
          </div>
        </div>
        <div className="h-full bg-black w-[2px] mx-2" />
        <div className="flex flex-col items-start w-full px-4 text-black">
          <p className="text-xl text-nowrap">{t("DueAmount")}</p>
          <p className="flex flex-row">
            <span className="text-3xl font-bold">
              {loading
                ? "0.000"
                : formatNumber(creditRecord?.currentDueBalance, true) ?? 0}
            </span>
            <span className="mx-1 mt-3 text-sm text-nowrap">{t("sar")}</span>
          </p>
          <div className="flex flex-row my-2 text-white gap-x-20">
            <div className="flex flex-col gap-1">
              <div>
                <p className="text-2xl text-nowrap">{t("installment")}</p>
                <div className="flex flex-row items-baseline">
                  <span className="text-xl font-bold">
                    {loading
                      ? "0.000"
                      : formatNumber(
                          creditRecord?.currentDueFromInstallments,
                          true
                        ) ?? 0}
                  </span>
                  <span className="mx-1 mt-2 text-sm text-nowrap">
                    {t("sar")}
                  </span>
                </div>
              </div>

              {creditRecord?.currentDueFromInstallments !== 0 && (
                <button
                  className={`
    
                  max-w-[86px] bg-black border border-solid border-orange-500 px-4 py-2 rounded-3xl text-sm focus:outline-none`}
                  onClick={() =>
                    handleButtonClick(PaymentRequestType.DueInstallments)
                  }
                >
                  <span className="gradient-text text-nowrap">
                    {t("payNow")}
                  </span>
                </button>
              )}
            </div>
            <div className="flex flex-col gap-1">
              <div>
                <p className="text-2xl text-nowrap">{t("credit")}</p>
                <div className="flex flex-row items-baseline">
                  <span className="text-xl font-bold">
                    {loading
                      ? "0.000"
                      : formatNumber(
                          creditRecord?.currentDueFromCredit,
                          true
                        ) ?? 0}
                  </span>
                  <span className="mx-1 mt-2 text-sm text-nowrap">
                    {t("sar")}
                  </span>
                </div>
              </div>
              {creditRecord?.currentDueFromCredit !== 0 && (
                <button
                  className={`                  
                  max-w-[86px] bg-black border border-solid border-orange-500 px-4 py-2 rounded-3xl text-sm focus:outline-none`}
                  onClick={() =>
                    handleButtonClick(PaymentRequestType.DueCredit)
                  }
                >
                  <span className="gradient-text text-nowrap">
                    {t("payNow")}
                  </span>
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
      {showPaymentModal && paymentRequestType !== null && (
        <PaymentModal
          paymentRequestType={paymentRequestType}
          onClose={() => setShowPaymentModal(false)}
          func="DashPay"
        />
      )}
    </div>
  );
};

export default DashboardCard;
