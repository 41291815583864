import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import * as Yup from "yup";
import useLocalStorage from "../hooks/useLocalStorage";
import useApi from "../hooks/useApi";
import { useNavigate } from "react-router-dom";
import { PageRoutes } from "../constants";
import Toast, { IToast } from "./Shared/Toast";
import LoadingIcon from "./Icons/LoadingIcon";
import NoteIcon from "./Icons/NoteIcon";
import { requestRegistration } from "../service/RegisterationService";

const GetOtp: React.FC<RegistrationProps> = ({ setScreen }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { getData: getCompanyData } = useApi<Organization>();
  const { setValue: setCr } = useLocalStorage<string>();
  const { getValue: getUserId } = useLocalStorage<string>();
  const { setValue: setRequestId } = useLocalStorage<string>();
  const { postData: sendOtp } = useApi<string>();
  const [showCrError, setShowCrError] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [showNote, setShowNote] = useState<boolean>(false);
  const [toast, setToast] = useState<IToast | null>(null);
  const formik = useFormik({
    initialValues: {
      cr: "",
    },
    validationSchema: Yup.object({
      cr: Yup.string()
        // .matches(/^[0-9]+$/, t("mustBeDigits"))
        // .matches(/^[12]/, t("mustStartWith1Or2"))
        .matches(/^\d{10}$/, t("crDigitNumber"))
        .required(t("requiredcr")),
    }),
    onSubmit: async (values) => {
      if (values.cr.length < 10) {
        setShowCrError(true);
        setToast({
          type: "error",
          titleKey: "toastErrorTitle",
          messageKey: "crDigitNumber",
        });
        return;
      } else {
        setShowCrError(false);
        try {
          setLoading(true);
          setCr("CR", values.cr);
          const response = await requestRegistration(values.cr);
          const data = response.data;
          setRequestId("requestId", data);
          setLoading(false);
          setScreen("sendOtp");
        } catch (error) {
          //consloe.log("this is err code: ", error.response?.data?.code);
          setLoading(false);
          const errorCode = error.response?.data?.code;
          switch (errorCode) {
            case "CS001":
              setToast({
                type: "error",
                titleKey: "toastErrorTitle",
                messageKey: "companyAlreadyExists",
              });
              break;
            case "CS002":
              setToast({
                type: "error",
                titleKey: "toastErrorTitle",
                messageKey: "invalidCR",
              });
              break;
            case "CS003":
              setToast({
                type: "error",
                titleKey: "toastErrorTitle",
                messageKey: "invalidCR",
              });
              break;
            case "CS004":
              setToast({
                type: "error",
                titleKey: "toastErrorTitle",
                messageKey: "expiredCR",
              });
              break;
            case "CS005":
              setToast({
                type: "error",
                titleKey: "toastErrorTitle",
                messageKey: "managerNotFound",
              });
              break;
            default:
              setToast({
                type: "error",
                titleKey: "toastErrorTitle",
                messageKey: "unknownError",
              });
              break;
          }
          //consloe.log("Error:", error);
        }
      }
    },
  });

  useEffect(() => {
    const fetchData = async () => {
      const userId = getUserId("userId");
      const { data: companyData } = await getCompanyData(
        `/company/byuser/${userId}/check`
      );

      // if (companyData) {
      //   navigate(PageRoutes.SIGN_IN);
      // }
    };

    fetchData();
  }, []);

  const convertToWesternDigits = (input: string) => {
    const arabicToWesternMap: Record<string, string> = {
      "٠": "0",
      "١": "1",
      "٢": "2",
      "٣": "3",
      "٤": "4",
      "٥": "5",
      "٦": "6",
      "٧": "7",
      "٨": "8",
      "٩": "9",
    };

    return input.replace(/[٠-٩]/g, (d) => arabicToWesternMap[d]);
  };

  const handleCrChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = convertToWesternDigits(e.target.value);
    formik.setFieldValue("cr", value.slice(0, 10));
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const invalidChars = ["e", "E", "+", "-", ".", " "]; // List of invalid characters
    if (invalidChars.includes(e.key)) {
      e.preventDefault();
    }
  };

  const handlePaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
    e.preventDefault();
    const paste = e.clipboardData.getData("text");
    const sanitizedPaste = convertToWesternDigits(paste)
      .replace(/[^0-9]/g, "")
      .slice(0, 10);
    formik.setFieldValue("cr", sanitizedPaste);
  };

  const handleFocus = () => {
    setShowNote(true);
  };

  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    formik.handleBlur(e);
    setShowNote(false);
  };

  return (
    <div className="flex flex-col items-center justify-center w-full gap-10 p-4 m-4 min-h-dvh md:w-1/2 md:p-0">
      <div className="flex flex-col md:w-[500px] items-center justify-center w-full md:w-3/4">
        <img src="./assets/probuy.svg" className="" alt="probuy logo" />
        <h1 className=" text-[36px] max-sm:text-[28px] font-bold text-center text-nowrap">
          {t("CompanyRegister")}
        </h1>
        <div className="md:w-[400px]">
          <p className=" text-base   font-light text-center text-white text-[16px]">
            {t("CrText")}
          </p>
        </div>
      </div>
      <div className="w-full md:w-[500px] bg-white bg-opacity-[0.05] p-4 rounded-xl shadow-xl flex flex-col justify-center items-center">
        <h2 className="my-1 text-2xl text-white">{t("CrNumber")}</h2>
        <form onSubmit={formik.handleSubmit} className="w-full p-4">
          <label className="hidden" htmlFor="cr">
            {t("CrNumber")}
          </label>
          <input
            type="number"
            name="cr"
            placeholder={t("CrPlaceholder")}
            value={formik.values.cr}
            onChange={handleCrChange}
            onKeyDown={handleKeyDown}
            onFocus={handleFocus}
            // onBlur={handleBlur}
            onPaste={handlePaste}
            maxLength={10}
            className={`no-spinner block w-full px-4 py-2 my-4 text-center rounded-md border ${
              formik.errors.cr && formik.touched.cr
                ? "border-red-500"
                : "border-white border-opacity-[0.16]"
            } bg-white bg-opacity-[0.07] text-slate-300 opacity-60 focus:outline-none`}
            required
          />
          {formik.errors.cr && (formik.dirty || formik.touched.cr) && (
            <div className="mt-2 mb-2 text-sm text-red-500">
              {t(`${formik.errors.cr}`)}
            </div>
          )}
          {showNote && (
            <div className="flex px-4 items-center gap-4 self-stretch border-solid border-r border-t border-b border-l-[3px] border-[#EF8D32] rounded-[12px] bg-[#393939] mb-8">
              <div className="w-5 h-5">
                <NoteIcon />
              </div>
              <div className="flex flex-row py-4 items-start gap-[2px] flex-1">
                <p className="text-[14px] text-white font-normal leading-[20px] tracking-[0.16] ">
                  <span className="font-bold">{t("note")}</span>
                  {t("verifyCRMessage")}
                </p>
              </div>
            </div>
          )}
          <div className="flex flex-row self-stretch justify-center w-full">
            <button
              disabled={loading}
              type="submit"
              className="max-w-[300px] flex items-center flex-1 self-stretch justify-center gap-2 px-4 py-2 mt-1 text-white bg-gradient-to-r from-orange-500 to-orange-300 rounded-3xl focus:outline-none"
            >
              {t("GetOtp")}
              {loading && (
                <div role="status">
                  <LoadingIcon color="#fff" />
                </div>
              )}
            </button>
          </div>
        </form>
      </div>
      {toast && (
        <Toast
          type={toast.type}
          titleKey={toast.titleKey}
          messageKey={toast.messageKey}
          onClose={() => setToast(null)}
        />
      )}
    </div>
  );
};

export default GetOtp;
