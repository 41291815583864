import React from "react";
import DownIcon from "../Icons/DownIcon";
import { useField } from "formik";
import { t } from "i18next";

interface DropdownProps {
  className?: string;
  placeholder?: string;
  mandStar?: boolean;
  label?: string;
  value?: string | number | null;
  options?: Array<{ value: string | number; label: string }>;
  onSelect?: (value: string | number) => void;
  name: string;
  isOpen: boolean;
  toggleDropdown: () => void;
}

const Dropdown: React.FC<DropdownProps> = ({
  className,
  placeholder,
  label,
  mandStar = false,
  value,
  options = [],
  onSelect,
  name,
  isOpen = false,
  toggleDropdown,
}) => {
  const [field, meta, helpers] = useField(name);

  const handleItemClick = (value: string | number) => {
    helpers.setValue(value); // Set Formik field value
    if (onSelect) {
      onSelect(value);
    }
    toggleDropdown(); // Close the dropdown after selection
  };

  const selectedOptionLabel =
    options.find((option) => option.value === value)?.label || placeholder;

  return (
    <div className={`${className} relative flex flex-col w-full gap-2`}>
      <span className="flex gap-1">
        <label className="text-white text-opacity-60">{label}</label>
        {mandStar && (
          <span className="text-[#EF6B2A] font-[800] leading-6 text-[14px]">
            *
          </span>
        )}
      </span>
      <button
        type="button"
        onClick={toggleDropdown}
        className="flex justify-between items-center gap-1.5 px-4 py-3  tracking-normal text-white rounded-xl border border-solid bg-white bg-opacity-10 border-white border-opacity-20"
      >
        <div className="w-full flex items-center justify-between">
          <span
            style={{ letterSpacing: "0.095px" }}
            className="text-white text-[14px] leading-6 font-normal text-nowrap"
          >
            {selectedOptionLabel}
          </span>
          <DownIcon width={16} height={16} />
        </div>
      </button>
      {isOpen && (
        <ul className="absolute  w-full max-h-60 overflow-y-auto bg-[#080808] border border-gray-300 rounded-md shadow-lg z-10 scrollbar-hide">
          {options.map((option) => (
            <li
              key={option.value}
              onClick={() => handleItemClick(option.value)}
              className="px-4 py-2 text-white text-nowrap border-gray-200 cursor-pointer hover:bg-white hover:text-black"
            >
              {option.label}
            </li>
          ))}
        </ul>
      )}
      {meta.touched && meta.error && (
        <span className=" text-red-500 text-opacity-80">
          {t(`${meta.error}`)}
        </span>
      )}
    </div>
  );
};

export default Dropdown;
