import React, { useState } from "react";
import { useFormikContext, ErrorMessage } from "formik";
import Dropdown from "../../Forms/Dropdown";
import RadioButtonGroup from "../../Forms/RadioGroup";
import InputField from "../../Forms/InputField";
import { BusinessPremisesType } from "../../../constants";
import sectorList from "../../../types/sectorList";
import productServiceList from "../../../types/ProductServiceList";
import { useTranslation } from "react-i18next";
import NewInputField from "../../Forms/NewInputField";

const BusinessInformation: React.FC = () => {
  const { t } = useTranslation();
  const { values, setFieldValue } = useFormikContext<any>();

  const [currentOpenDropdown, setCurrentOpenDropdown] = useState<string | null>(
    null
  );

  const yearsOptions = Array.from({ length: 100 }, (_, index) => ({
    value: index,
    label: `${index} ${t(index === 0 || index === 1 ? "year" : "years")}`,
  }));

  const monthOptions = Array.from({ length: 12 }, (_, index) => ({
    value: index,
    label: `${index} ${t(index === 0 || index === 1 ? "month" : "months")}`,
  }));

  const sectorOptions = sectorList.map((sector) => ({
    value: sector.value,
    label: t(`${sector.labelKey}`),
  }));

  const ProductServiceOptions = productServiceList.map(
    (productOrServiceProvided) => ({
      value: productOrServiceProvided.value,
      label: t(productOrServiceProvided.labelKey),
    })
  );

  const handleSelect = (name: string, value: string | number | boolean) => {
    setFieldValue(name, value);
  };

  const handleInputChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >
  ) => {
    const { name, value } = e.target;
    setFieldValue(name, value);
  };

  const toggleDropdown = (dropdown: string) => {
    setCurrentOpenDropdown((prev) => (prev === dropdown ? null : dropdown));
  };

  const handleItemClick = (value: number, type: "years" | "months") => {
    if (type === "years") {
      setFieldValue("yearsInBusiness", value);
    } else if (type === "months") {
      setFieldValue("monthsInBusiness", value);
    }
    setTimeout(() => setCurrentOpenDropdown(null), 100);
  };

  const salesChannelOptions = [
    { value: 1, label: t("Online") },
    { value: 2, label: t("Offline") },
    { value: 3, label: t("Both") },
  ];

  return (
    <section className="flex flex-col gap-[18px] p-6 rounded-2xl bg-white bg-opacity-[0.05] max-w-full relative">
      <h2 className="flex flex-wrap gap-2 text-lg font-bold leading-6 text-white">
        <img
          loading="lazy"
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/3f416806d7057b84c6eaea1dcf207cb2a7d921080328cd9d962fcba316c321b4?apiKey=eec791618aaf41dca83b63772a937206&"
          className="w-6 my-auto shrink-0 aspect-square"
          alt=""
        />
        <span className="flex-1">{t("Business Information")}</span>
      </h2>

      <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-3">
        <div>
          <div className="mb-1 text-white text-opacity-60 ">
            {t("Years In Business")}{" "}
          </div>
          <div className="grid grid-cols-2 col-span-1 gap-4">
            <Dropdown
              name="yearsInBusiness"
              placeholder={t("years")}
              value={values.yearsInBusiness}
              options={yearsOptions}
              onSelect={(value) => handleItemClick(value as number, "years")}
              isOpen={currentOpenDropdown === "yearsInBusiness"}
              toggleDropdown={() => toggleDropdown("yearsInBusiness")}
            />
            <Dropdown
              name="monthsInBusiness"
              placeholder={t("months")}
              value={values.monthsInBusiness}
              options={monthOptions}
              onSelect={(value) => handleItemClick(value as number, "months")}
              isOpen={currentOpenDropdown === "monthsInBusiness"}
              toggleDropdown={() => toggleDropdown("monthsInBusiness")}
            />
          </div>
        </div>

        <RadioButtonGroup
          label={t("Is Saudi Company")}
          name="isSaudiCompany"
          options={[
            { label: t("Yes"), value: true },
            { label: t("No"), value: false },
          ]}
          selectedValue={values.isSaudiCompany}
          onChange={(e) =>
            handleSelect("isSaudiCompany", e.target.value === "true")
          }
        />

        <Dropdown
          name="sector"
          label={t("Sector")}
          placeholder={t("Select Sector")}
          options={sectorOptions}
          value={values.sector}
          onSelect={(value) => handleSelect("sector", value)}
          isOpen={currentOpenDropdown === "sector"}
          toggleDropdown={() => toggleDropdown("sector")}
        />
      </div>

      <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-3">
        <InputField
          id="productOrServiceProvided"
          name="productOrServiceProvided"
          label={t("Product/Service Provided")}
          placeholder={t("Enter Product/Service Provided")}
          type="text"
          value={values.productOrServiceProvided}
          onChange={handleInputChange}
        />

        <NewInputField
          id="headcount"
          name="headcount"
          label={t("Head count")}
          placeholder={t("Enter Member Count")}
          type="number"
          value={values.headcount}
          onChange={handleInputChange}
          formatNumber
        />

        <Dropdown
          name="salesChannel"
          label={t("Sales Channel Type")}
          placeholder={t("Select Sales Channel Type")}
          options={salesChannelOptions}
          value={values.salesChannel}
          onSelect={(value) => handleSelect("salesChannel", value)}
          isOpen={currentOpenDropdown === "salesChannel"}
          toggleDropdown={() => toggleDropdown("salesChannel")}
        />
      </div>

      <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-3">
        <RadioButtonGroup
          label={t("Business Premises Type")}
          name="businessPremises"
          options={[
            { label: t("Owned"), value: BusinessPremisesType.Owned },
            { label: t("Rent"), value: BusinessPremisesType.Rent },
          ]}
          selectedValue={values.businessPremises}
          onChange={(e) => {
            const selectedValue = parseInt(e.target.value, 10);
            handleSelect("businessPremises", selectedValue);
            if (selectedValue === BusinessPremisesType.Owned) {
              handleSelect("monthlyRent", 0);
            }
          }}
        />

        {/* Conditionally render the InputField based on business premises selection */}
        <NewInputField
          id="monthlyRent"
          name="monthlyRent"
          label={t("Monthly Rent for 1 branch")}
          placeholder={t("Enter Monthly Rent")}
          type="number"
          value={values.monthlyRent}
          onChange={handleInputChange}
          disabled={values.businessPremises === BusinessPremisesType.Owned}
          inputClassName={`${
            values.businessPremises === BusinessPremisesType.Owned
              ? "inputDisapled"
              : ""
          }`}
          formatNumber
        />

        <NewInputField
          id="annualRevenue"
          name="annualRevenue"
          label={t("Annual Revenue (SAR)")}
          placeholder={t("Enter Annual Revenue")}
          type="number"
          value={values.annualRevenue}
          onChange={handleInputChange}
          formatNumber
        />
      </div>

      <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-3">
        <RadioButtonGroup
          label={t("Are your accounts externally audited?")}
          name="isAudited"
          options={[
            { label: t("Yes"), value: true },
            { label: t("No"), value: false },
          ]}
          selectedValue={values.isAudited}
          onChange={(e) => handleSelect("isAudited", e.target.value === "true")}
        />

        <NewInputField
          id="clientCount"
          name="clientCount"
          label={t("Number of Clients")}
          placeholder={t("Enter Number Of Clients")}
          type="number"
          value={values.clientCount}
          onChange={handleInputChange}
          formatNumber
        />
      </div>
    </section>
  );
};

export default BusinessInformation;
