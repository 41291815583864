import React from "react";

interface SendMailIconProps {
  className?: string;
  width?: string;
  height?: string;
}

const SendMailIcon: React.FC<SendMailIconProps> = ({
  className,
  width = "25",
  height = "24",
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 25 24"
      fill="none"
      className={className}
    >
      <g clipPath="url(#clip0_3417_20479)">
        <path
          d="M8.6818 14.1819H4.3182C3.99092 14.1819 3.77277 14.4001 3.77277 14.7273C3.77277 15.0546 3.99097 15.2728 4.3182 15.2728H8.68184C9.00913 15.2728 9.22728 15.0546 9.22728 14.7273C9.22728 14.4001 9.00908 14.1819 8.6818 14.1819ZM7.04544 11.4547H2.6818C2.35452 11.4547 2.13636 11.6729 2.13636 12.0001C2.13636 12.3273 2.35456 12.5455 2.6818 12.5455H7.04544C7.37272 12.5455 7.59088 12.3273 7.59088 12.0001C7.59088 11.6729 7.37272 11.4547 7.04544 11.4547ZM5.40908 8.72738H1.04544C0.718203 8.72738 0.5 8.94554 0.5 9.27282C0.5 9.6001 0.718203 9.81826 1.04544 9.81826H5.40908C5.73636 9.81826 5.95452 9.60005 5.95452 9.27282C5.95452 8.94559 5.73636 8.72738 5.40908 8.72738ZM22.2091 7.30918C22.0454 7.09098 21.6636 7.03646 21.4454 7.2001L15.6636 11.6183C15.0636 12.0547 14.2454 12.0547 13.6454 11.6183L7.86364 7.2001C7.64544 7.03646 7.26364 7.03646 7.1 7.30918C6.93636 7.52738 6.93636 7.90918 7.20908 8.07282L13.0454 12.491C13.5364 12.8728 14.1364 13.0365 14.6818 13.0365C15.2272 13.0365 15.8818 12.8728 16.3182 12.491L22.1 8.07282C22.3182 7.90918 22.3727 7.52738 22.2091 7.30918Z"
          fill="white"
        />
        <path
          d="M22.8636 4.90918H5.40908C5.0818 4.90918 4.86364 5.12738 4.86364 5.45462C4.86364 5.78185 5.0818 6.0001 5.40908 6.0001H22.8636C23.1909 6.0001 23.4091 6.2183 23.4091 6.54554V17.4546C23.4091 17.7819 23.1909 18.0001 22.8636 18.0001H5.40908C5.0818 18.0001 4.86364 18.2183 4.86364 18.5455C4.86364 18.8728 5.08184 19.0909 5.40908 19.0909H22.8636C23.7909 19.0909 24.5 18.3819 24.5 17.4546V6.54554C24.5 5.6183 23.7909 4.90918 22.8636 4.90918Z"
          fill="white"
        />
        <path
          d="M22.1546 15.9819L18.8818 12.7092C18.6636 12.491 18.3364 12.491 18.1182 12.7092C17.9 12.9274 17.9 13.2546 18.1182 13.4728L21.3909 16.7455C21.5 16.8546 21.6636 16.9092 21.7727 16.9092C21.8818 16.9092 22.0454 16.8546 22.1546 16.7455C22.3727 16.5274 22.3727 16.2001 22.1546 15.9819Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_3417_20479">
          <rect
            width="24"
            height="24"
            fill="white"
            transform="translate(0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SendMailIcon;
