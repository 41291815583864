import React from "react";
import { useTranslation } from "react-i18next";

// enum PartiallyPaid {
//   OneQuarter = 1,
//   Half = 2,
//   ThreeQuarters = 3,
// }

interface PartiallyPaidStatusLabelProps {
  status: string;
  className?: string;
}

// const backgroundColors: Record<PartiallyPaid, string> = {
//   [PartiallyPaid.OneQuarter]: "#EF8D32",
//   [PartiallyPaid.Half]: "#EF8D32",
//   [PartiallyPaid.ThreeQuarters]: "#EF8D32",
// };

const PartiallyPaidStatusLabel: React.FC<PartiallyPaidStatusLabelProps> = ({
  status,
  className,
}) => {
  const { t } = useTranslation();

  return (
    <div
      className={`${className} flex text-nowrap items-center justify-center rounded-full text-white `}
      style={{ backgroundColor: "#EF8D32" }}
    >
      <span className="max-w-min text-[14px] max-sm:text-[12px] font-normal leading-none">
        {status} {t("paid")}
      </span>
    </div>
  );
};

export default PartiallyPaidStatusLabel;
