import React, { useContext, useEffect, useState } from "react";
import { FaRegCircleUser } from "react-icons/fa6";
import { GoBell } from "react-icons/go";
import { IoCloseSharp } from "react-icons/io5";
import { GiHamburgerMenu } from "react-icons/gi";
import { PageRoutes } from "../../constants";
import SidebarMobile from "../sidebar/SidebarMobile";
import { AppContext } from "../../context/app-context";
import { Link } from "react-router-dom";
import UserIcon from "../Icons/UserIcon";
import useLocalStorage from "../../hooks/useLocalStorage";
import sectorList, { CompanySector } from "../../types/sectorList";
import useApi from "../../hooks/useApi";
import { useTranslation } from "react-i18next";
import Skeleton from "react-loading-skeleton";
import { useUserContext } from "../../context/UserContext";
const Header = () => {
  // const { userImage } = useContext<AppState>(AppContext);
  const { t } = useTranslation();
  const { getValue } = useLocalStorage<any>();
  const { getData } = useApi<any>();
  const { userData, loading: userDataLoading } = useUserContext();
  const [loading, setLoading] = useState<boolean>(true);
  const [companyData, setCompanyData] = useState<any>(null);
  const [isSideBarOpen, setIsSideBarOpen] = useState<IsSidebarOpen>("close");
  // Assume you have a variable indicating whether there are unread notifications
  const hasUnreadNotifications = true;
  const toggleSideBar = () => {
    setIsSideBarOpen(isSideBarOpen === "open" ? "close" : "open");
  };

  const fetchCompanyData = async () => {
    setLoading(true);
    try {
      const userId = getValue("userId");
      if (!userId) {
        throw new Error("User ID not found in localStorage");
      }

      const { data } = await getData(`/company/byuser/${userId}`);
      if (!data) {
        throw new Error("Failed to fetch company data");
      }

      setCompanyData(data);
    } catch (error) {
      // setError(error.message);
      console.error("Error fetching company data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCompanyData();
  }, []);
  return (
    <div className="relative flex flex-col ">
      <div className="flex flex-row justify-between py-0 px-4 md:px-8  h-[64px] bg-black relative border-b border-[#FFF] border-opacity-[0.24]">
        <div className="flex items-center justify-center gap-2">
          <div className="flex xl:hidden">
            {isSideBarOpen === "open" ? (
              <button onClick={toggleSideBar} className="p-2 text-white">
                <IoCloseSharp />
              </button>
            ) : (
              <button onClick={toggleSideBar} className="p-2 text-white">
                <GiHamburgerMenu />
              </button>
            )}
          </div>
          <img
            src="/assets/probuy.svg"
            className="w-[139px] h-[63px] overflow-hidden "
            alt="probuy logo"
          />
        </div>
        <div className="flex flex-row items-center gap-3 ">
          {/* <div className="relative bg-[#2f2f2f] rounded-full w-10 h-10  flex justify-center items-center">
            <GoBell className="text-xl text-white" />
            {hasUnreadNotifications && (
              <div className="absolute w-2 h-2 -mt-1 -mr-1 bg-red-500 rounded-full top-1 right-1" />
            )}
          </div> */}
          {/* Display uploaded avatar if available, otherwise show default icon */}
          {/* {userImage ? (
            <Link to={PageRoutes.PROFILE}>
              <img
                src={URL.createObjectURL(userImage as File)}
                className="w-8 h-8 rounded-full"
                alt="User Avatar"
              />
            </Link>
          ) : ( */}
          {/* <div > */}
          <Link
            to={PageRoutes.PROFILE}
            className="flex items-center justify-center w-auto h-10 gap-3"
          >
            <div className="flex flex-col flex-nowrap max-sm:hidden">
              <h4
                className="text-white text-[18px] font-[700] max-w-[140px] text-ellipsis overflow-hidden text-nowrap"
                style={{
                  direction: "ltr",
                  textAlign: "left",
                }}
              >
                {userDataLoading && userData ? (
                  <Skeleton width={120} height={20} />
                ) : (
                  `${userData?.firstName} ${userData?.lastName}`
                )}
              </h4>
              <span
                className="text-white text-[14px] font-[700] opacity-60 max-w-[140px] text-ellipsis overflow-hidden text-nowrap"
                style={{
                  direction: "ltr",
                  textAlign: "left",
                }}
              >
                {loading && companyData ? (
                  <Skeleton width={100} height={15} />
                ) : (
                  companyData?.name
                )}
              </span>
            </div>
            <UserIcon />
          </Link>
          {/* </div> */}
          {/* )} */}
        </div>
      </div>

      <div
        className={`absolute top-10 bg-black left-0 w-full mt-4 h-full transition-opacity duration-300 ${
          isSideBarOpen === "open"
            ? "opacity-100"
            : "opacity-0 pointer-events-none"
        }`}
      >
        <SidebarMobile
          onClick={toggleSideBar}
          isOpen={isSideBarOpen === "open"}
        />
      </div>
    </div>
  );
};

export default Header;
