// const sectorList: Sector[] = [
//   { value: "Agriculture", labelKey: "sector.agriculture" },
//   { value: "Automotive", labelKey: "sector.automotive" },
//   { value: "Banking and Finance", labelKey: "sector.bankingFinance" },
//   { value: "Construction", labelKey: "sector.construction" },
//   { value: "Consumer Goods", labelKey: "sector.consumerGoods" },
//   { value: "Education", labelKey: "sector.education" },
//   { value: "Energy", labelKey: "sector.energy" },
//   { value: "Entertainment and Media", labelKey: "sector.entertainmentMedia" },
//   {
//     value: "Healthcare and Pharmaceuticals",
//     labelKey: "sector.healthcarePharma",
//   },
//   { value: "Hospitality and Tourism", labelKey: "sector.hospitalityTourism" },
//   { value: "Information Technology", labelKey: "sector.informationTechnology" },
//   { value: "Manufacturing", labelKey: "sector.manufacturing" },
//   { value: "Non-Profit", labelKey: "sector.nonProfit" },
//   { value: "Professional Services", labelKey: "sector.professionalServices" },
//   { value: "Real Estate", labelKey: "sector.realEstate" },
//   { value: "Retail", labelKey: "sector.retail" },
//   { value: "Telecommunications", labelKey: "sector.telecommunications" },
//   {
//     value: "Transportation and Logistics",
//     labelKey: "sector.transportationLogistics",
//   },
//   { value: "Utilities", labelKey: "sector.utilities" },
//   { value: "Wholesale", labelKey: "sector.wholesale" },
// ];
interface Sector {
  value: CompanySector;
  labelKey: string; // Key to look up in the translation files
}
export enum CompanySector {
  Others,
  Technology,
  Construction,
  Chemicals,
  Logistics,
  FnB,
  Hospitality,
  Medical,
  Retail,
  Industrial,
}

const sectorList: Sector[] = [
  { value: CompanySector.Others, labelKey: "sector.others" },
  { value: CompanySector.Technology, labelKey: "sector.technology" },
  { value: CompanySector.Construction, labelKey: "sector.construction" },
  { value: CompanySector.Chemicals, labelKey: "sector.chemicals" },
  { value: CompanySector.Logistics, labelKey: "sector.logistics" },
  { value: CompanySector.FnB, labelKey: "sector.fb" },
  { value: CompanySector.Hospitality, labelKey: "sector.hospitality" },
  { value: CompanySector.Medical, labelKey: "sector.medical" },
  { value: CompanySector.Retail, labelKey: "sector.retail" },
  { value: CompanySector.Industrial, labelKey: "sector.industrial" },
];

export default sectorList;
