import React, { ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { HiLanguage } from "react-icons/hi2";

const Layout3 = ({ children }: { children: ReactNode }) => {
  const { i18n } = useTranslation();

  const changeLanguage = () => {
    const newLang = i18n.language === "ar" ? "en" : "ar";
    i18n.changeLanguage(newLang);
  };

  return (
    <div
      dir={i18n.dir()}
      className="relative flex flex-col items-center justify-center w-full min-h-screen text-white bg-black lg:h-full"
    >
      <div className="absolute top-0 right-0 hidden md:block">
        <img
          src="./assets/signinDes1.svg"
          className="w-80 h-100"
          alt="sign in design"
        />
      </div>
      <div className="absolute  top-4 left-0 flex flex-row items-center justify-center mx-2 md:mx-4 w-24 py-2 px-6 border border-[#8a8a8a] rounded-2xl outline-none text-white">
        <button
          onClick={changeLanguage}
          className="flex flex-row mx-2 text-sm text-bold"
        >
          <HiLanguage className={`mt-0.5 mx-1 text-[#8a8a8a]`} />
          {i18n.language === "ar" ? "English" : "  العربية"}
        </button>
      </div>
      <div className="flex flex-col items-center justify-center w-full p-4">
        {children}
      </div>
      <div className="absolute bottom-0 left-0 z-0 hidden md:block">
        <div className="relative overflow-hidden w-52 h-60">
          <img
            src="./assets/Ellipse.svg"
            className="absolute bottom-0"
            alt="sign in design"
          />
        </div>
      </div>
    </div>
  );
};

export default Layout3;
