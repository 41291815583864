import React from "react";

const WrongIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
    >
      <path
        d="M12.5 22.4999C18.0228 22.4999 22.5 18.0228 22.5 12.4999C22.5 6.97709 18.0228 2.49994 12.5 2.49994C6.97715 2.49994 2.5 6.97709 2.5 12.4999C2.5 18.0228 6.97715 22.4999 12.5 22.4999Z"
        stroke="#F83D5C"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M15.6318 9.00031L9.00075 15.6314"
        stroke="#F83D5C"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M16 15.9998L9.00052 9.00034"
        stroke="#F83D5C"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default WrongIcon;
