import React from "react";
import Card from "../cards/Card";
import HandIcon from "../Icons/HandIcon";
import RewindIcon from "../Icons/RewindIcon";
import TickIcon from "../Icons/TickIcon";
import { BiLoader } from "react-icons/bi";
import { useTranslation } from "react-i18next";
import AcceptedOrdersIcon from "../Icons/AcceptedOrdersIcon";
import PendingOrderIcon from "../Icons/PendingOrderIcon";
import FullyPayedIcon from "../Icons/FullyPayedIcon";
import ParPayedIcon from "../Icons/ParPayedIcon";

interface OrdersCountCardsProps {
  countByStatus?: {
    totalOrders: number;
    pendingOrders: number;
    acceptedOrders: number;
    partiallyPaid: number;
    paid: number;
    overdue: number;
    archived: number;
  };
  loading?: boolean;
}

const OrdersCountCards: React.FC<OrdersCountCardsProps> = ({
  countByStatus,
  loading = false,
}) => {
  const { t } = useTranslation();

  // Function to format numbers to have at least two digits, except for 0
  const formatNumber = (num?: number) => {
    if (num === 0) return `${num}`; // Keep 0 as it is
    return num !== undefined && num < 10 ? `0${num}` : `${num}`;
  };

  return (
    <div className="flex flex-row gap-2 overflow-x-auto sm:flex sm:flex-row lg:flex lg:flex-row lg:justify-between xl:justify-between hide-scrollbar">
      <Card
        bgColor="bg-[#1F1F1F]"
        number={formatNumber(countByStatus?.totalOrders ?? 0)}
        title={t("totalOrders")}
        icon={<HandIcon color="#fff" height={44} width={44} />}
        loading={loading}
      />
      <Card
        bgColor="bg-[#F83E57]"
        number={formatNumber(countByStatus?.pendingOrders ?? 0)}
        title={t("pendingOrders")}
        icon={<PendingOrderIcon color="#fff" height={44} width={44} />}
        loading={loading}
      />
      {/* <Card
        bgColor="bg-[#8A3FFC]"
        number={formatNumber(countByStatus?.acceptedOrders ?? 0)}
        title={t("acceptedOrders")}
        icon={<AcceptedOrdersIcon color="#fff" height={44} width={44} />}
        loading={loading}
      /> */}
      <Card
        bgColor="bg-[#DB9236]"
        number={formatNumber(countByStatus?.partiallyPaid ?? 0)}
        title={t("partiallyPaid")}
        icon={
          <ParPayedIcon color="#fff" className="h-[44px] w-[44px] font-light" />
        }
        loading={loading}
      />
      <Card
        bgColor="bg-[#008E45]"
        number={formatNumber(countByStatus?.paid ?? 0)}
        title={t("fullyPaid")}
        icon={<FullyPayedIcon color="#fff" height={44} width={44} />}
        loading={loading}
      />
      <Card
        bgColor="bg-[#DD1631]"
        number={formatNumber(countByStatus?.overdue ?? 0)}
        title={t("overDue")}
        icon={<RewindIcon color="#fff" height={44} width={44} />}
        loading={loading}
      />
    </div>
  );
};

export default OrdersCountCards;
