import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface CssState {
  activeClass: string | null;
}

const initialState: CssState = {
  activeClass: null,
};

const cssSlice = createSlice({
  name: "css",
  initialState,
  reducers: {
    setActiveClass(state, action: PayloadAction<string | null>) {
      state.activeClass = action.payload;
    },
    addZIndex(state) {
      state.activeClass = "z-[-1]";
    },
    resetActiveClass(state) {
      state.activeClass = null; // Resetting to opposite
    },
  },
});

export const { setActiveClass, addZIndex, resetActiveClass } = cssSlice.actions;
export default cssSlice.reducer;
