import React from "react";

const DustbinIcon: React.FC<IconProps> = ({ className }) => {
  return (
    <svg
      className={className}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.84 22.25H8.16C7.39162 22.2389 6.65575 21.9381 6.09955 21.4079C5.54334 20.8776 5.20779 20.157 5.16 19.39L4.25 5.55C4.24533 5.44966 4.26061 5.34938 4.29494 5.25498C4.32927 5.16057 4.38197 5.07391 4.45 5C4.52009 4.9234 4.60494 4.86177 4.69946 4.81881C4.79398 4.77584 4.8962 4.75244 5 4.75H19C19.1028 4.74977 19.2046 4.7707 19.299 4.81147C19.3935 4.85225 19.4785 4.91201 19.5488 4.98704C19.6192 5.06207 19.6733 5.15076 19.7079 5.24761C19.7425 5.34446 19.7569 5.44739 19.75 5.55L18.88 19.39C18.8317 20.1638 18.4905 20.8902 17.9258 21.4214C17.3611 21.9527 16.6153 22.249 15.84 22.25ZM5.84 6.25L6.61 19.3C6.63544 19.6935 6.80991 20.0625 7.09788 20.3319C7.38585 20.6013 7.76567 20.7508 8.16 20.75H15.84C16.2336 20.7485 16.6121 20.5982 16.8996 20.3292C17.1871 20.0603 17.3622 19.6927 17.39 19.3L18.2 6.3L5.84 6.25Z"
        fill="#F83D5C"
      />
      <path
        d="M21 6.25H3C2.80109 6.25 2.61032 6.17098 2.46967 6.03033C2.32902 5.88968 2.25 5.69891 2.25 5.5C2.25 5.30109 2.32902 5.11032 2.46967 4.96967C2.61032 4.82902 2.80109 4.75 3 4.75H21C21.1989 4.75 21.3897 4.82902 21.5303 4.96967C21.671 5.11032 21.75 5.30109 21.75 5.5C21.75 5.69891 21.671 5.88968 21.5303 6.03033C21.3897 6.17098 21.1989 6.25 21 6.25Z"
        fill="#F83D5C"
      />
      <path
        d="M15 6.25H9C8.80189 6.24741 8.61263 6.16756 8.47253 6.02747C8.33244 5.88737 8.25259 5.69811 8.25 5.5V3.7C8.26268 3.18682 8.47219 2.69816 8.83518 2.33518C9.19816 1.97219 9.68682 1.76268 10.2 1.75H13.8C14.3217 1.76305 14.8177 1.97951 15.182 2.35317C15.5463 2.72684 15.7502 3.22812 15.75 3.75V5.5C15.7474 5.69811 15.6676 5.88737 15.5275 6.02747C15.3874 6.16756 15.1981 6.24741 15 6.25ZM9.75 4.75H14.25V3.75C14.25 3.63065 14.2026 3.51619 14.1182 3.4318C14.0338 3.34741 13.9193 3.3 13.8 3.3H10.2C10.0807 3.3 9.96619 3.34741 9.8818 3.4318C9.79741 3.51619 9.75 3.63065 9.75 3.75V4.75ZM15 18.25C14.8019 18.2474 14.6126 18.1676 14.4725 18.0275C14.3324 17.8874 14.2526 17.6981 14.25 17.5V9.5C14.25 9.30109 14.329 9.11032 14.4697 8.96967C14.6103 8.82902 14.8011 8.75 15 8.75C15.1989 8.75 15.3897 8.82902 15.5303 8.96967C15.671 9.11032 15.75 9.30109 15.75 9.5V17.5C15.7474 17.6981 15.6676 17.8874 15.5275 18.0275C15.3874 18.1676 15.1981 18.2474 15 18.25ZM9 18.25C8.80189 18.2474 8.61263 18.1676 8.47253 18.0275C8.33244 17.8874 8.25259 17.6981 8.25 17.5V9.5C8.25 9.30109 8.32902 9.11032 8.46967 8.96967C8.61032 8.82902 8.80109 8.75 9 8.75C9.19891 8.75 9.38968 8.82902 9.53033 8.96967C9.67098 9.11032 9.75 9.30109 9.75 9.5V17.5C9.74741 17.6981 9.66756 17.8874 9.52747 18.0275C9.38737 18.1676 9.19811 18.2474 9 18.25ZM12 18.25C11.8019 18.2474 11.6126 18.1676 11.4725 18.0275C11.3324 17.8874 11.2526 17.6981 11.25 17.5V9.5C11.25 9.30109 11.329 9.11032 11.4697 8.96967C11.6103 8.82902 11.8011 8.75 12 8.75C12.1989 8.75 12.3897 8.82902 12.5303 8.96967C12.671 9.11032 12.75 9.30109 12.75 9.5V17.5C12.7474 17.6981 12.6676 17.8874 12.5275 18.0275C12.3874 18.1676 12.1981 18.2474 12 18.25Z"
        fill="#F83D5C"
      />
    </svg>
  );
};

export default DustbinIcon;
